import { defineStore } from "pinia";
import { ref } from 'vue'
import { useVmsStore } from './vms';

export const useDatePickerRangeStore = defineStore('datepickerRange', {
  state: () => ({
    startDate: useVmsStore().getStartDate,
    endDate: useVmsStore().getEndDate,
    error: false
  }),
  actions: {
    initDate() {
      this.setErrorstate(false);
      this.startDate = useVmsStore().getStartDate;
      this.endDate = useVmsStore().getEndDate;
    },
    setStartDate(date: Date) {
      this.startDate = date;
    },
    setEndDate(date: Date) {
      this.endDate = date;
    },
    setErrorstate(state: boolean) {
      this.error = state;
    }
  },
  getters: {
    getStartDate(state) {
      return state.startDate;
    },
    getEndDate(state) {
      return state.endDate;
    },
    getErrorState(state) {
      return state.error;
    }
  },
});