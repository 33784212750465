import { defineStore } from 'pinia'
import { getUploadOptions, updateAccept,  } from '../api';


// gsm_accept table의 데이터가 저장되는 store

interface Options {
  // options: {
    id: number,
    allAllow: number,
    allName: number,
    allPurpose: number,
    allDivision: number,
    allOfficeNum: number,
    autoDelete: number,
    autoDate: Date,
    updateVideo: number,
    exist_admin: number,
    installer_path: string,
  // }
}
export const useUploadOptionsStore = defineStore('options', {
  state: (): Options => ({
    // options: {
      id: 0,
      allAllow: 0,
      allName: 0,
      allPurpose: 0,
      allDivision: 0,
      allOfficeNum: 0,
      autoDelete: 0,
      autoDate: new Date(),
      updateVideo: 0,
      exist_admin: 0,
      installer_path: '',
    // }
  }),
  actions: {
    async getOptions() {
      try {
        const res = await getUploadOptions();
        this.id = res[0]['id'];
        this.allAllow = res[0]['allAllow'];
        this.allName = res[0]['allName'];
        this.allPurpose = res[0]['allPurpose'];
        this.allDivision = res[0]['allDivision'];
        this.allOfficeNum = res[0]['allOfficeNum'];
        this.autoDelete = res[0]['autoDelete'];
        this.autoDate = res[0]['autoDate'];
        this.updateVideo = res[0]['updateVideo'];
        this.exist_admin = res[0]['exist_admin'];
        this.installer_path = res[0]['installer_path'];
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    async setAllAllow(newValue: number) {
      this.allAllow = newValue; // 변경하려는 값으로 대체
      try {
        await updateAccept('allAllow', this.allAllow); // 변경된 값을 API로 전송
      } catch (error) {
        console.error('Error setting allAllow:', error);
        throw error;
      }
    },
    
  },
  getters: {
    // getOption(state) {
    //   return state;
    // },
    getAllAllow(state) {
      return state.allAllow;
    },
    getAutoDelete(state){
      return state.autoDelete;
    },
    getAutoDate(state){
      return state.autoDate;
    },
    getInstallerPath(state){
      return state.installer_path;
    }
  },
});
