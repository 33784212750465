
import { defineComponent, ref, computed, watch } from 'vue';
import { useDatePickerStore } from '../../store/datepicker';
import { useLocaleStore } from '@/store/locale';
import 'v-calendar/style.css';
import "@/css/style.css";

export default defineComponent({
  name: 'DatePicker',
  components: {
  },
  props:{
    reSize: {
      type: Boolean,
      required: false,
      default: () => (true) // true=반응형, false=고정
    }
  },
  setup(props, {emit}) {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);

    const dpStore = useDatePickerStore();
    const date = computed(() => dpStore.getDate);
    const pick = ref();
    const isFocused = ref(false);

    const popover = ref({
      visibility: 'click',
    });

    const setDate = () => {
      if(pick.value == null || pick.value > new Date()) {
        pick.value = new Date();
      }
      dpStore.setDate(pick.value);
    }

    watch(date, (newDate) => {
      pick.value = newDate;
    });

    return {
      locale,
      pick,
      popover,
      isFocused,
      setDate,
      date
    };
  },
});
