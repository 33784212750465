
import { defineComponent, computed, ref } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';
import { useSummaryStore } from '@/store/summary';

export default defineComponent({
  components:{

  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const summaryStore = useSummaryStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const timeRange = computed(() => conditionStore.getTimeRange);
    const textTimeRange = computed(() => conditionStore.getTextTimeRange);
    const setTimeRange = {start:{hour:'', min:''}, end:{hour:'', min:''}}; // 부분요약 시간 설정
    const timeRangeError = ref("");
    const durationTime = computed(() => summaryStore.getDurationTime); // 영상 전체 길이

    const acceptTimeRange = () => {
      // 부분 요약 값이 제대로 들어가있는지 확인 후 적용
      let startTimeRange = parseInt(setTimeRange.start.hour === ''?'0':setTimeRange.start.hour) * 60 + parseInt(setTimeRange.start.min === ''?'0':setTimeRange.start.min);
      let endTimeRange = parseInt(setTimeRange.end.hour === ''?'0':setTimeRange.end.hour) * 60 + parseInt(setTimeRange.end.min === ''?'0':setTimeRange.end.min);
      if(endTimeRange >= (durationTime.value / 60)){
        timeRangeError.value = language.value["PLAYER"]["msg_no_long"][locale.value];  //'종료 시간이 영상 길이를 넘길 수 없습니다.';
      }
      else if(endTimeRange <= startTimeRange){
        timeRangeError.value = language.value["PLAYER"]["msg_no_fast"][locale.value];  //'시작 시간이 종료시간보다 클 수 없습니다.';
      }
      else{
        conditionStore.setTimeRange([startTimeRange, endTimeRange]);
        setTextTime();
        setOptionOpenState('section', false);
      }
    };
    // 부분요약 범위를 텍스트로 정리 
    const setTextTime = () => {
      let startHour = parseInt(setTimeRange.start.hour === ''?'0':setTimeRange.start.hour);
      let startMin = parseInt(setTimeRange.start.min === ''?'0':setTimeRange.start.min);
      let endHour = parseInt(setTimeRange.end.hour === ''?'0':setTimeRange.end.hour);
      let endMin = parseInt(setTimeRange.end.min === ''?'0':setTimeRange.end.min);

      let startTime = (startHour === 0?'00':(startHour<10?'0'+startHour:startHour)) + ":" + (startMin === 0?'00':(startMin<10?'0'+startMin:startMin));
      let endTime = (endHour === 0?'00':(endHour<10?'0'+endHour:endHour)) + ":" + (endMin === 0?'00':(endMin<10?'0'+endMin:endMin));

      conditionStore.setTextTimeRange(startTime + " ~ " + endTime);

    };
    
    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    return {
      locale,
      language,
      setOptionOpenState,
      acceptTimeRange,
      timeRange,
      textTimeRange,
      setTimeRange,
      timeRangeError
    }
  }
  
})
