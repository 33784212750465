
import { defineComponent, ref, computed, watch } from 'vue';
import SummaryInfo from '@/components/Player/SummaryInfo.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import { API_BASE_URL, maskingDownload } from '@/api'
import { useSummaryStore } from '@/store/summary';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useMaskingStore } from '@/store/masking';
import { useSummaryPageStore } from '../../store/summaryPage';
import axios from 'axios'
import { useLocaleStore } from '@/store/locale';
import DatePickerRange from '../Common/DatePickerRange.vue';
import { useDatePickerRangeStore } from '../../store/datepickerRange';
import { isInteger, isNumber } from 'lodash';
import moment from 'moment';
import MaskingErrorPopup from '../Popup/MaskingErrorPopup.vue';

export default defineComponent({
  components: {
    SummaryInfo,
    PopupModule,
    DatePickerRange,
    MaskingErrorPopup
  },
  emits:['playObject'],
  setup(props, {emit}) {
    const summaryStore = useSummaryStore();
    const summaryMenuStore = useSummaryMenuStore();
    const summaryPageStore = useSummaryPageStore();
    const maskingStore = useMaskingStore();
    const localeStore = useLocaleStore();
    const dpStore = useDatePickerRangeStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const currPage = computed(() => summaryPageStore.getCurrPage);
    const menu = computed(() => summaryMenuStore.getMaskingState);

    const popupContents = ref({ message: '', type: '', show: false });

    const selectTypeList = [
      {id:"class", name: language.value["PLAYER"]["masking_class"][locale.value]}, // 객체별 마스킹
      {id:"object", name: language.value["PLAYER"]["masking_object"][locale.value]}  // 객체 직접 선택 후 마스킹
    ];
    const selectedItem = ref<any>({id:"object", name: language.value["PLAYER"]["masking_object"][locale.value]}); // 객체 직접 선택 후 마스킹
    const isDropdownOpen = ref<boolean>(false);
    const isBoxVisible = ref<boolean>(true);
    const isTimeBoxVisible = ref<boolean>(false);
    const maskingSelectObject = computed(() => maskingStore.getMaskingSelectObject);
    const showMaskingArray =  ref<any>([]);
    const summaryResult = computed(() => summaryStore.getSummaryResult);

    const maskingStatus = computed(() => maskingStore.getMaskingStatus);
    const maskingPercent = computed(() => maskingStore.getMaskingPercent);
    const downloadStatus = ref('wait');

    const video = computed(() => summaryStore.getVideo);

    
    /* 마스킹 시간 설정 */
    const startDate = computed(() => dpStore.getStartDate);
    const endDate = computed(() => dpStore.getEndDate);
    const error = computed(() => dpStore.getErrorState);
    const duration = computed(() => summaryStore.getDurationTime);
    const defaultStartTime = ref(new Date());
    const defaultEndTime = ref(new Date());
    const maskingErrorShow = ref(false);

    // 임시
    // const startTime = ref("");
    // const endTime = ref("");

    const classCondition = ref<any>([
        {title:'PERSION', 
          sub:[{type:'man', checked:false, name: language.value["SUMMARY"]["man"][locale.value]}, // 남자
              {type:'woman', checked:false, name: language.value["SUMMARY"]["woman"][locale.value]}]}, // 여자
        {title:'ANIMAL', 
          sub:[{type:'cat', checked:false, name: language.value["SUMMARY"]["cat"][locale.value]},  // 고양이
              {type:'dog', checked:false, name: language.value["SUMMARY"]["dog"][locale.value]}]}, // 개
        {title:'TWO-WHEELED', 
          sub:[{type:'bike', checked:false, name: language.value["SUMMARY"]["bike"][locale.value]},  // 자전거
              {type:'motorcycle', checked:false, name: language.value["SUMMARY"]["motorcycle"][locale.value]}]}, // 오토바이
        {title:'FOUR-WHEELED', 
          sub:[{type:'car', checked:false, name: language.value["SUMMARY"]["car"][locale.value]},  // 자가용
              {type:'truck', checked:false, name: language.value["SUMMARY"]["truck"][locale.value]},  // 트럭
              {type:'bus', checked:false, name: language.value["SUMMARY"]["bus"][locale.value]}]}  // 버스
      ]);

    const setMaskingBox = () => {
      isBoxVisible.value = !isBoxVisible.value;
    }

    const setMaskingTimeBox = () => {
      isTimeBoxVisible.value = !isTimeBoxVisible.value;
    }

    const selectItem = (item:any) => {
      selectedItem.value = item;
    }

    //객체 클릭
    const classSelect = (value:any) => {
      value.checked = !value.checked;
    };

    const comboBoxHeight = computed(() => ({ 
      height: isDropdownOpen.value ? 
      "clamp("+26*(selectTypeList.length+1)+"px, "+3.7*(selectTypeList.length+1)+"vh, "+3.7*(selectTypeList.length+1)+"vh)"
       : "clamp(26px, 3.7vh, 3.7vh)"
    }));

    // 선택한 객체 보여주기
    const inputData = () => {
      if(maskingSelectObject.value.length !== 0 && summaryResult.value.result_images !==null){
        let jsonResultImg = JSON.parse(summaryResult.value.result_images);
        showMaskingArray.value = [];
        for(let i=0; i<maskingSelectObject.value.length; i++){
          for(let j=0; j<jsonResultImg.length; j++){
            if(maskingSelectObject.value[i] === parseInt(jsonResultImg[j]['i'])){
              showMaskingArray.value.push({img:API_BASE_URL+jsonResultImg[j]['p'],isBookmark:jsonResultImg[j]['b'],id:jsonResultImg[j]['i'],frame:jsonResultImg[j]['f'],oriPath:jsonResultImg[j]['p']})
              break;
            }
          }
        }
      }
    }

    // 마스킹 리셋
    const resetMasking = () => {
      classCondition.value = [
        {title:'PERSION', 
          sub:[{type:'man', checked:false, name: language.value["SUMMARY"]["man"][locale.value]}, // 남자
              {type:'woman', checked:false, name: language.value["SUMMARY"]["woman"][locale.value]}]}, // 여자
        {title:'ANIMAL', 
          sub:[{type:'cat', checked:false, name: language.value["SUMMARY"]["cat"][locale.value]},  // 고양이
              {type:'dog', checked:false, name: language.value["SUMMARY"]["dog"][locale.value]}]}, // 개
        {title:'TWO-WHEELED', 
          sub:[{type:'bike', checked:false, name: language.value["SUMMARY"]["bike"][locale.value]},  // 자전거
              {type:'motorcycle', checked:false, name: language.value["SUMMARY"]["motorcycle"][locale.value]}]}, // 오토바이
        {title:'FOUR-WHEELED', 
          sub:[{type:'car', checked:false, name: language.value["SUMMARY"]["car"][locale.value]},  // 자가용
              {type:'truck', checked:false, name: language.value["SUMMARY"]["truck"][locale.value]},  // 트럭
              {type:'bus', checked:false, name: language.value["SUMMARY"]["bus"][locale.value]}]}  // 버스
      ];
      maskingStore.setMaskingSelectObject([]);
      isBoxVisible.value = true;
      isTimeBoxVisible.value = false;
    }

    const playObject = (item:any) => {
      emit('playObject', item.frame);
    }

    const deleteObject = (item:any) => {
      let index = maskingSelectObject.value.indexOf(Number(item.id))
      maskingStore.spliceMaskingSelectObject(index);
    }

    // 마스킹 설정 보여주는 함수 
    const setMaskingCondition = () => {
      summaryMenuStore.showMaskingCondition(!menu.value.maskingOption);
    }

    // 마스킹 설정 적용
    const setMaskingOption = async () => {
      if(selectedItem.value.id === 'object'){
        if(maskingSelectObject.value.length === 0 ){
          popupContents.value.message = language.value["PLAYER"]["msg_no_select_m_object"][locale.value]; // 객체 선택 안 됨
          popupContents.value.type = "error";
          popupContents.value.show = true;
          return;
        }
      }
      else if(selectedItem.value.id === 'class'){
        let flag = false;
        for(let i=0;i<classCondition.value.length;i++){
          for(let j=0;j<classCondition.value[i].sub.length;j++){
            if(classCondition.value[i].sub[j].checked){
              flag = true;
            }
          }
        }

        if(!flag){
          popupContents.value.message = language.value["PLAYER"]["msg_no_select_m_object"][locale.value]; // 클래스 선택 안 됨
          popupContents.value.type = "error";
          popupContents.value.show = true;
          return;
        }
      }

      // 시간 설정
      let timerange;
      let objTimeFlag = true;
      if(video.value !== undefined){
        let start = new Date(startDate.value);
        let originStart = new Date(video.value.beginDate);
        let end = new Date(endDate.value);
        
        let startDiff = ((start.getTime() - originStart.getTime())/1000) >> 0;
        let endDiff = ((end.getTime() - originStart.getTime())/1000) >> 0;
        rangeTime.value = {startDiff:startDiff, endDiff: endDiff};
      
        if(!isTimeBoxVisible.value){
          timerange = [-1, -1];
          objTimeFlag = true;
        }
        else{
          timerange = [startDiff, endDiff];
          if(selectedItem.value.id === 'object'){
            objTimeFlag = await maskingStore.checkObjectTime(startDiff*8, (endDiff+1)*8, showMaskingArray.value);
          }
        }
      }
      console.log('timerange >> ',timerange);
      console.log('objTimeFlag >> ',objTimeFlag);

      if(objTimeFlag){
        let maskingOption;
        if(selectedItem.value.id === 'object'){
          maskingOption = {
            type : "object",
            id : maskingSelectObject.value,
            box : isBoxVisible.value?1:0,
            reverse : 0,
            mosaic : 10,
            timerange : timerange
          };
        }
        else if(selectedItem.value.id === 'class'){
          let selectClass = [];
          for(let i=0;i<classCondition.value.length;i++){
            for(let j=0;j<classCondition.value[i].sub.length;j++){
              if(classCondition.value[i].sub[j].checked){
                selectClass.push(classCondition.value[i].sub[j].type);
              }
            }
          }

          maskingOption = {
            type : "class",
            class : selectClass,
            box : isBoxVisible.value?1:0,
            reverse : 0,
            mosaic : 10,
            timerange : timerange
          };
        }
        console.log('set >> ', maskingOption);
        // maskingStore.checkObjectTime(timerange);
        maskingStore.setMaskingOption(maskingOption);
        summaryMenuStore.showMaskingSelect(true);
        downloadStatus.value = 'wait';
      }
      else{
        maskingErrorShow.value = true;
        return;
      }
    }

    const goOrigin = () => {
      summaryPageStore.setCurrPage('origin');
      summaryMenuStore.initOptionOpenState();
    }

    // 마스킹 재설정
    const retryMasking = () => {
      maskingStore.resetMaskingOption();
    }

    const downloadPercent = ref<any>(0);
    // 마스킹 영상 다운로드
    const maskingDownloadStart = () => {
      downloadPercent.value = 0;
      downloadStatus.value = 'running';
      const videoDownload = async (): Promise<void> => {
        const res = await maskingDownload();
        
        let url = API_BASE_URL+res.path+'/file.mp4';

        axios.get(url, { 
          responseType: 'blob',
          onDownloadProgress: (ProgressEvent) => {
            if(ProgressEvent !== undefined && ProgressEvent.total !== null && ProgressEvent.total !== undefined){
              const progress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
              downloadPercent.value = progress.toFixed(0);
            }
          } 
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/mp4' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);

            let originName=(video.value!==undefined?video.value.name:'');
            let splitName=originName.split('.');
            let name = splitName[0] + '.mp4';
            link.download = name;
            link.click();
            URL.revokeObjectURL(link.href);
            downloadStatus.value = 'complete';
          }).catch(error => {
            console.log('download error >> ', error);
            downloadStatus.value = 'fail';
          })

      }
      videoDownload();
    }

    const reload = () => {
      location.reload();
    }


    // watch(() => startDate.value, (value) => {
    //   console.log('start Date >> ', startDate.value);
    // });

    // watch(() => endDate.value, (value) => {
    //   console.log('end Date >> ', endDate.value);
    // });

    watch(() => video.value, () => {
      console.log('check video >> ', video.value);
      if(video.value !== undefined){
        dpStore.setStartDate(new Date(video.value.beginDate));
        defaultStartTime.value = new Date(video.value.beginDate);
        // console.log('defaultStartTime >> ', defaultStartTime.value);
        // 임시
        // startTime.value = moment(new Date(video.value.beginDate)).format('YYYY.MM.DD HH:mm:ss');
      }
      else{
        dpStore.initDate();
      }
    });
    
    watch(() => duration.value, () => {
      // console.log('duration >> ', duration.value);
      // if(duration.value !)
      if(!isNaN(duration.value)){
        const finishTime = new Date(startDate.value);
        finishTime.setTime(finishTime.getTime() + (duration.value * 1000));
        dpStore.setEndDate(new Date(finishTime));
        defaultEndTime.value = new Date(finishTime);
        // 임시
        // endTime.value = moment(new Date(finishTime)).format('YYYY.MM.DD HH:mm:ss');
      }
    })

    const rangeTime = ref<any>();
    const changeRangeTime = () => {
      if(video.value!==undefined){
        let timerange;
        let start = new Date(startDate.value);
        let originStart = new Date(video.value.beginDate);
        let end = new Date(endDate.value);
        
        let startDiff = (start.getTime() - originStart.getTime())/1000;
        let endDiff = (end.getTime() - originStart.getTime())/1000;
        rangeTime.value = {startDiff:startDiff, endDiff: endDiff};
        timerange = [startDiff, endDiff]
        console.log('timerange >> ', timerange);
      }

    }
   
    /* 마스킹 시간 설정 */

    watch(() => maskingSelectObject.value.length, () => {
      selectItem({id:"object", name: language.value["PLAYER"]["masking_object"][locale.value]}); // 객체 직접 선택 후 마스킹
      inputData();
    });

    watch(() => currPage.value, () => {
      isDropdownOpen.value = false;
    });

    watch(() => maskingStatus.value, (newVal) => {
      if(newVal == "invalid") {
        popupContents.value.message = language.value["PLAYER"]["msg_disconnect"][locale.value];  // 다른 사용자가 사용중입니다.
        popupContents.value.type = "warning";
        popupContents.value.show = true;
      }
    });

    return {
      selectTypeList,
      isDropdownOpen,
      setMaskingBox,
      setMaskingTimeBox,
      selectItem,
      selectedItem,
      comboBoxHeight,
      isBoxVisible,
      isTimeBoxVisible,
      classCondition,
      classSelect,
      API_BASE_URL,
      showMaskingArray,
      inputData,
      maskingSelectObject,
      resetMasking,
      playObject,
      deleteObject,
      setMaskingCondition,
      menu,
      goOrigin,
      setMaskingOption,
      maskingStatus,
      maskingPercent,
      retryMasking,
      maskingDownloadStart,
      downloadStatus,
      locale,
      language,
      popupContents,
      reload,
      // startTime,
      // endTime,
      rangeTime,
      changeRangeTime,
      defaultStartTime,
      defaultEndTime,
      maskingErrorShow,
      downloadPercent
    };
  },
});
