import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { getVideoEach, getDataRequestInsert, getRequestStatusEach, summaryRequest, IRRequest, FaceRequest, FaceUpload, insertBookmark, updateBookmark, getBookmark, getObjBookmark, deleteBookmark } from '../api';
import moment from 'moment';
import { useSummaryPageStore } from './summaryPage';
import router from '@/router';
import { useLocaleStore } from './locale';
import { usePageStore } from './pageNumber';
import { useConditionStore } from './condition';
import { useMaskingStore } from './masking';

interface Video {
  uploadUserId: string,
  name: string,
  beginDate : string,
  memo:string,
  background: string, // thumbnail
  videoPath: string,
  videoId: string,
}

// interface SummaryOption {
//   face: Ref<string|null>, // 안면인식
//   arrow: null, // 경로지정
//   class: null, // 객체
//   color: null, // 색상
//   dwell: null, // 관심영역
//   object: null, // 안면인식/텍스트검색 결과(객체 아이디)
//   ir_query: null, // 텍스트검색 문구
//   direction: null, // 방향지정
//   timerange: null, // 부분요약
//   ir_threshold: null, // 텍스트검색 임계값(숫자)
//   license_plate: null, // 차량검색
//   compressibility: 12 // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
//   size: null, // 미사용
//   speed: null, // 미사용
// }

// 요약 store
export const useSummaryStore = defineStore('summary', {
  state: () => ({
    video: ref<Video | undefined>({
      uploadUserId: "",
      name: "", // 영상 제목
      beginDate : "",
      memo:"",
      background: "",
      videoPath: "",
      videoId: ""
    }),
    getDataLoading : "wait",
    currVideoTime : "",
    durationVideoTime : 0,
    finishVideoTime : "",
    //현재 요약 요청 넣는 옵션
    summaryOption : {
      face: null, // 안면인식
      arrow: null, // 경로지정
      class: null, // 객체
      color: null, // 색상
      dwell: null, // 관심영역
      object: null, // 안면인식/텍스트검색 결과(객체 아이디)
      ir_query: null, // 텍스트검색 문구
      direction: null, // 방향지정
      timerange: null, // 부분요약
      ir_threshold: null, // 텍스트검색 임계값(숫자)
      license_plate: null, // 차량검색
      compressibility: 2, // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
      size: null, // 미사용
      speed: null, // 미사용,
      is_dvideo:0
    },
    faceImg: [],
    //현재 설정하고 있는 값
    currSummaryOption : {
      face: [], // 안면인식
      class: [
        {title:'PERSION', sub:[{type:'man', checked:true, name:'남자'}, {type:'woman', checked:true, name:'여자'}]},
        {title:'ANIMAL', sub:[{type:'cat', checked:true, name:'고양이'}, {type:'dog', checked:true, name:'개'}]},
        {title:'TWO-WHEELED', sub:[{type:'bike', checked:true, name:'자전거'}, {type:'motorcycle', checked:true, name:'오토바이'}]},
        {title:'FOUR-WHEELED', sub:[{type:'car', checked:true, name:'자가용'}, {type:'truck', checked:true, name:'트럭'}, {type:'bus', checked:true, name:'버스'}]}
      ], // 객체
      color: [
        {type:'#851B1B', send:'#FF0000', checked:true},
        {type:'#775907', send:'#9C8136', checked:true},
        {type:'#FFE400', send:'#FFE400', checked:true},
        {type:'#2B6A28', send:'#1DDB16', checked:true},
        {type:'#3062A7', send:'#368AFF', checked:true},
        {type:'#8544E2', send:'#A566FF', checked:true},
        {type:'#DB71DB', send:'#FF5AFF', checked:true},
        {type:'#FFFFFF', send:'#FFFFFF', checked:true},
        {type:'#787878', send:'#7F7F7F', checked:true},
        {type:'#151515', send:'#000000', checked:true},
      ], // 색상
      direction: [
        {type:'E', send:0, checked:true, name:'동'},
        {type:'NE', send:45, checked:true, name:'북동'},
        {type:'N', send:90, checked:true, name:'북'},
        {type:'NW', send:135, checked:true, name:'북서'},
        {type:'W', send:180, checked:true, name:'서'},
        {type:'SW', send:225, checked:true, name:'남서'},
        {type:'S', send:270, checked:true, name:'남'},
        {type:'SE', send:315, checked:true, name:'남동'},
      ], // 방향지정
      dwell: {firstPoints:[], secondPoints:[], inputType:'', selectType : ''}, // 관심영역
      arrow: {line:[], arrow:[]}, // 경로지정
      object: null, // 안면인식/텍스트검색 결과(객체 아이디)
      ir_query: "", // 텍스트검색 문구
      timerange: [], // 부분요약
      textTimerange : "",
      ir_threshold: 0.3, // 텍스트검색 임계값(숫자)
      license_plate: null, // 차량검색
      compressibility: 2, // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
      size: null, // 미사용
      speed: null, // 미사용
      is_dvideo:0, // 관심영역 확대 영상 사용 유무(1: 사용, 0: 미사용)
    },
    // 요약 완료되어, 현재 요약 영상의 조건 
    applySummaryOption : {
      face: null, // 안면인식
      arrow: null, // 경로지정
      class: null, // 객체
      color: null, // 색상
      dwell: null, // 관심영역
      object: null, // 안면인식/텍스트검색 결과(객체 아이디)
      ir_query: null, // 텍스트검색 문구
      direction: null, // 방향지정
      timerange: null, // 부분요약
      ir_threshold: null, // 텍스트검색 임계값(숫자)
      license_plate: null, // 차량검색
      compressibility: 2, // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
      size: null, // 미사용
      speed: null, // 미사용
      is_dvideo:0, // 관심영역 확대 영상 사용 유무(1: 사용, 0: 미사용)
    },
    timestamp : 1,
    applyTimeStamp : 1, // 현재 요약 영상의 타임스탬프
    summaryResult : {
      heatmap:null,
      pathmap : null,
      result : null,
      result_images:null,
      result_objects:null,
      serial:null,
      statistic : null,
      dwell_video: null,
    },
    summaryLoading : false,
    summaryStatus : 'wait',
    dwellStatus : 'wait',
    firstSummaryLoading : false,
    percent : 0,
    selectObject: {
      thumbnail: '',
      realPath: '', 
      date: '', 
      frame: null, 
      isBookmark: 0,
      id: null,
      textTime: '',
      realTime: '',
      memo: ''
    },
    bookmark: ref<any>([]),
    appearance: ref<null|number>()
  }),
  actions: {
    setVideo(info: Video) {
      this.video = info;
    },
    setDuration(value: number){
      this.durationVideoTime = value;
      if(this.video){
        const finishTime = new Date(this.video?.beginDate);
        finishTime.setTime(finishTime.getTime() + (value * 1000));
        this.finishVideoTime = moment(finishTime).format('HH:mm:ss');
      }
      
    },
    async requestVideoStatus(videoId: string){
      this.video = undefined;
      try{
        const res = await getVideoEach(videoId);
        res.videoId = videoId;
        console.log(res);
        let date = new Date(res.beginDate);
        date.setSeconds(0);
        date.setMilliseconds(0);
        res.beginDate = date;
        this.durationVideoTime = 0;
        this.setVideo(res);
        this.getDataInsert();
      } catch (error) {
        console.error('Error get video: ', error);
        throw error;
      }
    },
    async getDataInsert(){
      try{
        const res = await getDataRequestInsert();
        this.getDataLoading = 'running'
        this.checkGetData(res.serial);
      } catch (error){
        console.error('Error get data: ', error);
        throw error;
      }
    },
    async checkGetData(serial:string){
      try{
        const res = await getRequestStatusEach(serial);
        if(res.status_pct < 100){
          setTimeout(() => {this.checkGetData(serial)}, 1000);
        }
        else{
          this.getDataLoading = 'complete'
          this.firstSummaryRequest();
        }
      } catch (error){
        console.error('Error get data check: ', error);
        throw error;
      }
    },
    async firstSummaryRequest(){
      try{
        this.summaryLoading = true;
        this.summaryStatus = 'running'
        this.dwellStatus = 'wait';
        this.firstSummaryLoading = true; 
        const res = await summaryRequest();
        // console.log('first summary >> ', res);
        if(res !== undefined){
          if(res.serial != undefined) {
            this.checkSummary(res.serial);
          }
        }

      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }

    },
    async userSummaryRequest(){
      this.summaryLoading = true;
      this.summaryStatus = 'running';
      
      if(this.summaryOption.is_dvideo == 1){
        this.dwellStatus = 'running';
      }
      else{
        this.dwellStatus = 'wait';
      }
      // console.log('dewllStatus >> ', this.dwellStatus);
      this.firstSummaryLoading = false;
      useMaskingStore().resetMaskingOptionSetting();
      try{
        const res = await summaryRequest();
        if(res !== undefined){
          if(res.serial != undefined) {
            this.checkSummary(res.serial);
          }
        }
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    async checkSummary(serial:string){
      if(router.currentRoute.value.name === 'player'){
        try{
          const res = await getRequestStatusEach(serial);
          if(!this.summaryLoading){
            this.summaryStatus = 'complete'
            return;
          }
          // console.log('res>> ',res);
          if(res.status == 'EMPTY'){
            this.summaryStatus = 'blank'
          }
          else if(res.status == 'SHOWING' || res.status == 'COMPLETE'){
            this.summaryStatus = 'complete'
            // let dwellVideo = [];
            // dwellVideo.push(res.reault);
            let dwellVideo = null;
            if(res.dwell_video !== null && JSON.parse(res.dwell_video).length!==0){
              dwellVideo = JSON.parse(res.dwell_video);
            }
            this.summaryResult = {
              heatmap : res.heatmap,
              pathmap : res.pathmap,
              result : res.result,
              result_images:res.result_images,
              result_objects:res.result_objects,
              serial:res.serial,
              statistic : res.statistic,
              dwell_video: dwellVideo
            };

            if(res.dwell_video !== null && res.dwell_video !== undefined && this.dwellStatus == 'running'){
              this.dwellStatus = 'complete';
            }

            console.log('summaryResult', this.summaryResult);
            this.setApplySummaryOption();
            // console.log('applySummaryOption >> ', this.applySummaryOption);
            if(this.applySummaryOption.is_dvideo == 1 && (this.summaryResult.dwell_video == null || this.summaryResult.dwell_video == undefined)){
              this.checkDwellSummary(serial);
            }
          }
          else if(res.status === 'STOPPED'){
            // console.log('요약이 정지되었습니다.')
          }
          else if(res.status == 'INVALID') {
            this.summaryStatus = 'invalid';
            // console.log("정지");
          }
          else{
            setTimeout(() => {this.checkSummary(serial) }, 1000);
          }
        } catch (error){
          this.summaryStatus = 'error'
          console.error('Error summary check: ', error);
          throw error;
        }
      }
    },
    async checkDwellSummary(serial:any){
      if(router.currentRoute.value.name === 'player'){
        try{
          const res = await getRequestStatusEach(serial);
          console.log('dwell res >> ',res);

          if(res.dwell_video !== null && res.dwell_video !== undefined){
            this.summaryResult.dwell_video = JSON.parse(res.dwell_video);
            this.dwellStatus = 'complete';
          }
          else{
            setTimeout(() => {this.checkDwellSummary(serial) }, 1000);
          }
        } catch (error){
          this.summaryStatus = 'error'
          console.error('Error summary dwell check: ', error);
          throw error;
        }
      }
    },
    async userIRRequest(){
      this.summaryStatus = 'ir'
      this.percent = 0;
      try{
        const res = await IRRequest();
        this.checkIRRequest(res.serial);
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    async checkIRRequest(serial:string){
      try{
        const res = await getRequestStatusEach(serial);

        if(res.status !== 'COMPLETE' && res.result === null){
          this.percent = res.status_pct
          setTimeout(() => {this.checkIRRequest(serial) }, 1000);
        }
        else{
          const object = JSON.parse(res.result);
          this.summaryOption.object = object;
          this.summaryStatus = 'running'
          this.userSummaryRequest();
        }
      } catch (error){
        console.error('Error summary check: ', error);
        throw error;
      }
    },
    async userFaceRequest(){
      try{
        const res = await FaceRequest();
        this.checkFaceRequest(res.serial);
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    async checkFaceRequest(serial:string){
      try{
        const res = await getRequestStatusEach(serial);

        if(res.status !== 'COMPLETE' && res.result === null){
          this.percent = res.status_pct
          setTimeout(() => {this.checkFaceRequest(serial) }, 1000);
        }
        else{
          const object = JSON.parse(res.result);
          this.summaryOption.object = object;
          this.summaryStatus = 'running'
          this.userSummaryRequest();
        }
      } catch (error){
        console.error('Error Face check: ', error);
        throw error;
      }
    },
    setCondition(){
      this.currSummaryOption.class = useConditionStore().getClass;
      this.currSummaryOption.color = useConditionStore().getColor;
      this.currSummaryOption.direction = useConditionStore().getDirection;
      this.currSummaryOption.dwell = useConditionStore().getDwell;
      this.currSummaryOption.arrow = useConditionStore().getArrow;
      this.currSummaryOption.timerange = useConditionStore().getTimeRange;
      this.timestamp = useConditionStore().getTimeStamp;
      this.currSummaryOption.ir_query = useConditionStore().getIrText;
      this.currSummaryOption.ir_threshold = useConditionStore().getIrThreshold;
      this.currSummaryOption.face = useConditionStore().getFace;
      this.currSummaryOption.is_dvideo = useConditionStore().getDwellVideo;
      console.log('currSummaryOption >> ', this.currSummaryOption);
    },
    setCurrVideoTime(value: any){
      this.currVideoTime = value;
    },
    setTimeStamp(value: number){
      this.timestamp = value;
    },
    setSummaryOption(value: any){
      this.summaryOption = value;
      this.userSummaryRequest();
    },
    setIrOption(value: any){
      this.summaryOption = value;
      this.userIRRequest();
    },
    setFaceOption(value: any){
      this.summaryOption = value;
      this.userFaceRequest();
    },
    async uploadFaceFile(){
      this.summaryStatus = 'face'
      this.percent = 0;
      const faceFile = this.currSummaryOption.face;
      const faceResult = [];
      for(let i=0;i<faceFile.length;i++){
        try{
          const res = await FaceUpload(faceFile[i]);
          faceResult.push(res.data.items.upload_path);
          
          if(faceFile.length === faceResult.length){
            return faceResult;
          }
        } catch (error){
          console.error('Error fetching users:', error);
        }
      }
    },
    setApplySummaryOption(){
      this.applySummaryOption = this.summaryOption;
      this.setFaceImg(this.currSummaryOption.face);
      this.applyTimeStamp = this.timestamp;
      this.summaryLoading = false;
      this.summaryStatus = 'complete'
      if(!this.firstSummaryLoading){
        useSummaryPageStore().setCurrPage('summary');
      }
      this.firstSummaryLoading = false;
    },
    setFaceImg(array: any) {
      const face = ref<any>([]);
      this.faceImg = [];
      for(let i=0; i<array.length; i++){
        face.value.push(array[i]);
      }
      this.faceImg = face;
    },
    cancelSummary(){
      this.summaryLoading = false;
      this.summaryStatus = 'cancel'
    },
    initOption(){
      const localeStore = useLocaleStore();
      const locale = computed(() => localeStore.getLocale);
      const language = computed(() => localeStore.getLanguage);

      useConditionStore().initOption();

      this.summaryOption = {
        face: null, // 안면인식
        arrow: null, // 경로지정
        class: null, // 객체
        color: null, // 색상
        dwell: null, // 관심영역
        object: null, // 안면인식/텍스트검색 결과(객체 아이디)
        ir_query: null, // 텍스트검색 문구
        direction: null, // 방향지정
        timerange: null, // 부분요약
        ir_threshold: null, // 텍스트검색 임계값(숫자)
        license_plate: null, // 차량검색
        compressibility: 2, // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
        size: null, // 미사용
        speed: null, // 미사용
        is_dvideo:0, // 관심영역 확대 영상 생성 유무
      };
      
      this.currSummaryOption = {
        face: [], // 안면인식
        class: [
          {title:'PERSION', 
            sub:[{type:'man', checked:true, name: language.value["SUMMARY"]["man"][locale.value]}, // 남자
                {type:'woman', checked:true, name: language.value["SUMMARY"]["woman"][locale.value]}]}, // 여자
          {title:'ANIMAL', 
            sub:[{type:'cat', checked:true, name: language.value["SUMMARY"]["cat"][locale.value]},  // 고양이
                {type:'dog', checked:true, name: language.value["SUMMARY"]["dog"][locale.value]}]}, // 개
          {title:'TWO-WHEELED', 
            sub:[{type:'bike', checked:true, name: language.value["SUMMARY"]["bike"][locale.value]},  // 자전거
                {type:'motorcycle', checked:true, name: language.value["SUMMARY"]["motorcycle"][locale.value]}]}, // 오토바이
          {title:'FOUR-WHEELED', 
            sub:[{type:'car', checked:true, name: language.value["SUMMARY"]["car"][locale.value]},  // 자가용
                {type:'truck', checked:true, name: language.value["SUMMARY"]["truck"][locale.value]},  // 트럭
                {type:'bus', checked:true, name: language.value["SUMMARY"]["bus"][locale.value]}]}  // 버스
        ], // 객체
        color: [
          {type:'#851B1B', send:'#FF0000', checked:true},
          {type:'#775907', send:'#9C8136', checked:true},
          {type:'#FFE400', send:'#FFE400', checked:true},
          {type:'#2B6A28', send:'#1DDB16', checked:true},
          {type:'#3062A7', send:'#368AFF', checked:true},
          {type:'#8544E2', send:'#A566FF', checked:true},
          {type:'#DB71DB', send:'#FF5AFF', checked:true},
          {type:'#FFFFFF', send:'#FFFFFF', checked:true},
          {type:'#787878', send:'#7F7F7F', checked:true},
          {type:'#151515', send:'#000000', checked:true},
        ], // 색상
        direction: [
          {type:'E', send:0, checked:true, name:language.value["SUMMARY"]["e"][locale.value]},
          {type:'NE', send:45, checked:true, name:language.value["SUMMARY"]["ne"][locale.value]},
          {type:'N', send:90, checked:true, name:language.value["SUMMARY"]["n"][locale.value]},
          {type:'NW', send:135, checked:true, name:language.value["SUMMARY"]["nw"][locale.value]},
          {type:'W', send:180, checked:true, name:language.value["SUMMARY"]["w"][locale.value]},
          {type:'SW', send:225, checked:true, name:language.value["SUMMARY"]["sw"][locale.value]},
          {type:'S', send:270, checked:true, name:language.value["SUMMARY"]["s"][locale.value]},
          {type:'SE', send:315, checked:true, name:language.value["SUMMARY"]["se"][locale.value]},
        ], // 방향지정  
        dwell: {firstPoints:[], secondPoints:[], inputType:'', selectType : ''}, // 관심영역
        arrow: {line:[], arrow:[]}, // 경로지정
        object: null, // 안면인식/텍스트검색 결과(객체 아이디)
        ir_query: "", // 텍스트검색 문구
        timerange: [], // 부분요약
        textTimerange : "",
        ir_threshold: 0.3, // 텍스트검색 임계값(숫자)
        license_plate: null, // 차량검색
        compressibility: 2, // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
        size: null, // 미사용
        speed: null, // 미사용
        is_dvideo: 0, // 관심영역 확대 영상 사용 유무(true: 사용, flase: 미사용)
      };
      this.timestamp = 1;
      this.summaryResult = {
        heatmap:null,
        pathmap : null,
        result : null,
        result_images:null,
        result_objects:null,
        serial:null,
        statistic : null,
        dwell_video: null,
      };
      this.currVideoTime = "";
      this.durationVideoTime = 0;
      this.finishVideoTime = "";
      this.summaryStatus = 'wait';
      this.summaryLoading = false;
      this.firstSummaryLoading = false;
    },
    setSelectObject(value:any) {
      this.selectObject = value;
      // console.log(this.selectObject);
    },
    resetSelectObject(){
      this.selectObject = {
        thumbnail: '',
        realPath: '', 
        date: '', 
        frame: null, 
        isBookmark: 0,
        id: null,
        textTime: '',
        realTime: '',
        memo: ''
      }
    },
    initSelectObject() {
      this.selectObject = {
        thumbnail: '',
        realPath: '', 
        date: '', 
        frame: null, 
        isBookmark: 0,
        id: null,
        textTime: '',
        realTime: '',
        memo: ''
      }
      usePageStore().setCurrNumber(1);
    },
    async insertBookmark(){
      const videoId = ref("");
      if(this.video !== undefined) {
        videoId.value = this.video.videoId;
      }
      try{
        await insertBookmark(videoId.value, this.selectObject);
        // const res = await insertBookmark(videoId.value, this.selectObject);
        this.getBookmarkList();
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    async updateBookmark(){ // 요약 페이지 > 북마크 수정 시 사용
      const videoId = ref("");
      if(this.video !== undefined) {
        videoId.value = this.video.videoId;
      }
      try{
        await updateBookmark(videoId.value, this.selectObject);
        // const res = await updateBookmark(videoId.value, this.selectObject);
        this.getBookmarkList();
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    async editBookmark(videoId: string, obj: any) { // 객체 북마크 메뉴에서 사용
      try{
        const res = await updateBookmark(videoId, obj);
        return res;
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    async deleteBookmark(videoId: string, objectId: number) {
      // for (let i = 0; i < this.bookmark.length; i++) {
      //   const obj = this.bookmark[i];
      //   if(obj.checked) {
      try{
        const res = await deleteBookmark(videoId, objectId);
        return res;
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
      //   }
      // }
    },
    async getBookmarkList() {
      if(this.video !== undefined) {
        try{
          const res = await getBookmark(this.video.videoId);
          this.bookmark = res;
        } catch (error){
          console.error('Error summary: ', error);
          throw error;
        }
      }
    },
    async getObjBookmarkList() {
      try{
        const res = await getObjBookmark();
        this.bookmark = res;
      } catch (error){
        console.error('Error summary: ', error);
        throw error;
      }
    },
    setAppearance(frame: number) {
      this.appearance = frame;
    }
  },
  getters: {
    getVideo(state) {
      return state.video;
    },
    getData(state){
      return state.getDataLoading;
    },
    getSummaryOption(state){
      return state.summaryOption;
    },
    getTimestamp(state){
      return state.timestamp;
    },
    getSummaryResult(state){
      return state.summaryResult;
    },
    getCurrSummary(state){
      return state.currSummaryOption;
    },
    getDurationTime(state){
      return state.durationVideoTime;
    },
    getFinishVideoTime(state){
      return state.finishVideoTime;
    },
    getSummaryLoading(state){
      return state.summaryLoading;
    },
    getSummaryStatus(state){
      return state.summaryStatus;
    },
    getFirstSummaryLoading(state){
      return state.firstSummaryLoading;
    },
    getDwellStatus(state){
      return state.dwellStatus;
    },
    getPercent(state){
      return state.percent;
    },
    getSelectObject(state){
      return state.selectObject;
    },
    getBookmark(state) {
      return state.bookmark;
    },
    getApplySummaryOption(state){
      return state.applySummaryOption;
    },
    getApplytimestamp(state){
      return state.applyTimeStamp;
    },
    getAppearance(state){
      return state.appearance;
    },
    getFaceImg(state) {
      return state.faceImg;
    }
  },
});