
import { defineComponent, computed} from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';

export default defineComponent({
  components:{

  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const classCondition = computed(() => conditionStore.getClass);
    const allCheckClass = computed(() => conditionStore.getAllClass);

    //객체 클릭
    const classSelect = (value:any) => {
      value.checked = !value.checked;
      checkClassAll();
      conditionStore.setClass(classCondition.value);
    };

    // 객체가 전부 선택되었는지 확인하여 객체 전체 선택 상태 변경
    const checkClassAll = () => {
      let check = true;
      for(let i=0;i<classCondition.value.length;i++){
        for(let j=0;j<classCondition.value[i].sub.length;j++){
          if(!classCondition.value[i].sub[j].checked){
            check = false;
            break;
          }
        }
      }
      conditionStore.setAllClass(check);
    };

    // 객체 전체 선택 클릭
    const classAllCheck = () => {
      let check = !allCheckClass.value;

      for(let i=0;i<classCondition.value.length;i++){
        for(let j=0;j<classCondition.value[i].sub.length;j++){
          classCondition.value[i].sub[j].checked = check;
        }
      }
      conditionStore.setAllClass(check);
      conditionStore.setClass(classCondition.value);
    };

    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    const reset = () => {
      for(let i=0;i<classCondition.value.length;i++){
        for(let j=0;j<classCondition.value[i].sub.length;j++){
          classCondition.value[i].sub[j].checked = true;
        }
      }
      conditionStore.setClass(classCondition.value);
      conditionStore.setAllClass(true);
    }

    return {
      locale,
      language,
      setOptionOpenState,
      classSelect,
      checkClassAll,
      classAllCheck,
      allCheckClass,
      reset,
      classCondition
    }
  }
  
})
