
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useComboSearchVmsStore } from '../../store/comboSearchVms';
import { useVmsStore } from '../../store/vms';
import moment from 'moment';
import PopupModule from '../Popup/PopupModule.vue';
import VmsAddPopup from '../Management/VmsAddPopup.vue';
/* pagenation */
import { usePageStore } from '../../store/pageNumber';
import { useLocaleStore } from '@/store/locale';
import PageNumber from '../../components/Common/PageNumber.vue';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';
/* pagenation */
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    ComboboxEdit,
    PageNumber,
    PopupModule,
    VmsAddPopup
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const vmsStore = useVmsStore();
    const comboboxStore = useComboSearchVmsStore();
    const vmsList = computed(() => vmsStore.getVmsList);
    const isOpen = computed(() => comboboxStore.getIsOpen);
    const router = useRouter();
    /* pagenation */
    const pageStore = usePageStore();
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref([] as any);
    const searchVMS = ref([] as any);
    /* pagenation */

    const showCreateVMS = ref(false);
    const loadingVmsList = ref(true);
    const popupContents = ref({ message: '', type: 'success', show: false });

    const selectCount = ref<any>(0);

    const searchArray = ref<any>([
      { id: 'vms', label: 'VMS' },
      { id: 'id', label: 'ID' },
      { id: 'ip', label: 'IP' },
      { id: 'port', label: 'PORT' },
    ]);
    const selectedSearch = ref<any>(searchArray.value[0]);
    const searchText = ref<any>('');

    const setOpenState = () => {
      comboboxStore.setIsOpen(!isOpen.value);
    }

    const setFormat = (lastAccess: Date) => {
      return moment(lastAccess).format('YYYY-MM-DD HH:mm');
    };

    const inputVMS = () => {
      showCreateVMS.value = true;
    }

    // vms 리스트 클릭 시
    const selectList = (vms: any) => {
      vms.checked = !vms.checked;
      if(vms.checked){
        selectCount.value++;
      }
      else{
        selectCount.value--;
      }
    }

    // 콤보박스 선택시
    const selectedItem = (item: any) => {
      selectedSearch.value = item;
      searchVmsEvent();
    }

    const deletePopupCheck = () => {
      popupContents.value.type = 'delete';
      popupContents.value.message = language.value["ADMIN"]["msg_delete_vms"][locale.value]; // 선택한 VMS를 삭제하시겠습니까?
      popupContents.value.show = true;
    }
    // 선택 삭제 이벤트
    const deleteVms = async (): Promise<void> => {
      let deleteValue = [];
      for(let i=0;i<pageList.value.length;i++){
        if(pageList.value[i].checked){
          deleteValue.push(pageList.value[i]);
        }
      }

      const res = await vmsStore.deleteVmsInfo(deleteValue);
      if(res){
        popupContents.value.type = 'success';
        popupContents.value.message = language.value["ADMIN"]["msg_delete_vms_c"][locale.value]; // VMS 삭제가 완료되었습니다.
        popupContents.value.show = true;
      }
    }

    const successCheck = () => {
      popupContents.value.show = false;
      loadingVmsList.value = true;
      defaultSet();
    }

    const defaultSet = async (): Promise<void> => {
      await vmsStore.getVmsInfo('admin');
      loadingVmsList.value = false;
      searchVmsEvent();
    }

    // 검색 기능
    const searchVmsEvent = () => {
      searchVMS.value = [];
      if(router.currentRoute.value.name == 'vms'){
        pageStore.setCurrNumber(1);
      
        for(let i=0;i<vmsList.value.length;i++){
          let log = vmsList.value[i];
          let flag = true;
          
          if(searchText.value !== ''){
            switch(selectedSearch.value.id){
              case 'vms':
                if(log.type.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                  flag = false;
                }
                break;
              case 'id':
                if(log.id.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                  flag = false;
                }
                break;
              case 'ip':
                if(log.ip.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                  flag = false;
                }
                break;
              case 'port':
                if(log.port.toString().indexOf(searchText.value) === -1){
                  flag = false;
                }
                break;
            }
          }

          if(flag){
            searchVMS.value.push(log);
          }
        }
        pageStore.setTotalPage(searchVMS.value.length, 13);
        getCurrPage();
      }
    };

    const getCurrPage = () => {
      selectCount.value = 0;
      pageList.value = [];
      
      for(let i=(currNumber.value -1)*13; i<Math.min((currNumber.value)*13, searchVMS.value.length);i++){
        let log = searchVMS.value[i];
        pageList.value.push(log);
      }
    }
    watch(() => vmsList.value, () => {
      searchVmsEvent();
    });
    
    watch(() => currNumber.value, () => {
      getCurrPage();
    });

    onMounted(() => {
      pageStore.setCurrNumber(1);
      defaultSet();
    });

    return {
      locale,
      language,
      setOpenState,
      setFormat,
      vmsList,
      getCurrPage,
      pageList,
      inputVMS,
      selectedItem,
      searchArray,
      selectedSearch,
      searchText,
      searchVmsEvent,
      selectList,
      selectCount,
      deletePopupCheck,
      deleteVms,
      popupContents,
      showCreateVMS,
      successCheck,
      loadingVmsList,
      defaultSet
    };
  },
});
