
import { defineComponent, computed } from 'vue';
import { useSummaryPageStore } from '../../store/summaryPage';
import SummaryInfo from '@/components/Player/SummaryInfo.vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import { useSummaryStore } from '@/store/summary';

export default defineComponent({
  components: {
    SummaryInfo
  },
  setup() {
    const summaryPageStore = useSummaryPageStore();
    const localeStore = useLocaleStore();
    const summaryMenuStore = useSummaryMenuStore();
    const summaryStore = useSummaryStore();
    
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const dwellShow = computed(() => summaryMenuStore.getTabState.dwellShow);

    const summaryResult = computed(() => summaryStore.getSummaryResult);
    const dwellStatus = computed(() => summaryStore.getDwellStatus);

    const setDwellShow = () => {
      summaryMenuStore.setDwellShow(!dwellShow.value);
    }

    const goOrigin = () => {
      summaryPageStore.setCurrPage('origin');
      summaryMenuStore.initOptionOpenState();
    }

    return {
      goOrigin,
      locale,
      language,
      dwellShow,
      setDwellShow,
      summaryResult,
      dwellStatus
    };
  },
});
