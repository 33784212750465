
import { defineComponent, ref, computed} from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import { API_BASE_URL } from '@/api';
import { useLocaleStore } from '@/store/locale';
import { useConditionStore } from '@/store/condition';

export default defineComponent({
  components: {

  },
  setup(){
    const summaryStore = useSummaryStore();
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const video = computed(() => summaryStore.getVideo);

    const arrowCondition = computed(() => conditionStore.getArrow);
    
    const linePoints = ref<any>([]);
    const arrowPoints = ref<any>([]);
    const linePolygon = ref({
      points: ref<number[]>([]),
      stroke: 'white',
      strokeWidth: 4,
      lineCap:'round',
      lineJoin: 'round',
    });
    const arrowPolygon = ref({
      points : ref<number[]>([]),
      stroke : 'white',
      fill: 'white',
      strokewidth: 4,
      closed : true,
      lineCap:'round',
      lineJoin: 'round',
    });
    const dragging = ref<any | undefined>(undefined);
    const startX = ref<any | null>(null);
    const startY = ref<any | null>(null);

    if(arrowCondition.value.line.length!==0){
      let line = arrowCondition.value.line;
      let arrow = arrowCondition.value.arrow;
      let width = 520;
      let height = 320;

      let linePoly = [];
      let arrowPoly = [];

      for(let i=0;i<line.length;i++){
        linePoly.push(line[i][0] * width);
        linePoly.push(line[i][1] * height);
        linePoints.value.push([line[i][0] * width, line[i][1] * height]);
      }
      for(let i=0;i<arrow.length;i++){
        arrowPoly.push(arrow[i][0] * width);
        arrowPoly.push(arrow[i][1] * height);
        arrowPoints.value.push([arrow[i][0] * width, arrow[i][1] * height]);
      }

      linePolygon.value.points = linePoly;
      arrowPolygon.value.points = arrowPoly;
      dragging.value = false;
    }

    // 화살표 그리기
    const drawArrow = (points: any) => {
      let arrowPoly = ref<number[]>([]);
      let finishX = points[0];
      let finishY = points[1];
      let length = 16;
      let distance = Math.sqrt(Math.abs(Math.pow(startX.value - finishX, 2)-Math.pow(startY.value - finishY, 2)));
      let arrow1, arrow2;
      let th = 0;

      th=Math.atan2(finishY-startY.value,finishX-startX.value);
      arrow1=[Math.trunc((distance - length) * Math.cos(th) - (length/2) * Math.sin(th)) + startX.value,
        Math.trunc((distance - length) * Math.sin(th) + (length/2) * Math.cos(th)) + startY.value];
      arrow2=[Math.trunc((distance - length) * Math.cos(th) + (length/2) * Math.sin(th)) + startX.value,
        Math.trunc((distance - length) * Math.sin(th) - (length/2) * Math.cos(th)) + startY.value];
      
      arrowPoly.value.push(points[0]);
      arrowPoly.value.push(points[1]);
      arrowPoly.value.push(arrow1[0]);
      arrowPoly.value.push(arrow1[1]);
      arrowPoly.value.push(arrow2[0]);
      arrowPoly.value.push(arrow2[1]);

      arrowPolygon.value.points = arrowPoly.value;

      arrowPoints.value[0]=points;
      arrowPoints.value[1]=arrow1;
      arrowPoints.value[2]=arrow2;
    };

    // 드래그 시작
    const canvasClick = () => {
      if(dragging.value===false){
        linePoints.value=[];
        linePolygon.value.points=[];
        dragging.value=undefined;
        arrowPolygon.value.points=[];
        arrowPoints.value=[];
      }
      dragging.value=true;

    };

    // 드래그해서 라인 그리기 
    const dragArrow = (mouse:any) => {
      if(dragging.value === true){
        const currentPoly = linePolygon.value;
        currentPoly.points.push(mouse.evt.layerX);
        currentPoly.points.push(mouse.evt.layerY);
        linePolygon.value = JSON.parse(JSON.stringify(currentPoly));
        linePoints.value.push([mouse.evt.layerX, mouse.evt.layerY]);
      }

    };

    // 라인 그리기 종료
    const canvasEndClick = () => {
      dragging.value=false;
      let count = linePoints.value.length;
      if(count>=10){
        startX.value = linePoints.value[count-5][0];
        startY.value = linePoints.value[count-5][1];
        drawArrow(linePoints.value[count-1]);
      }else{
        linePoints.value=[];
        linePolygon.value.points=[];
        dragging.value=undefined;
        arrowPolygon.value.points=[];
        arrowPoints.value=[];
      }

    };

    // 선택한 방향 store에 저장
    const setArrowOption = () => {
      let lineValue = ref<any>([]);
      let arrowValue = ref<any>([]);
      let width = 520;
      let height = 320;

      for(let i=0;i<linePoints.value.length;i++){
        lineValue.value.push([linePoints.value[i][0]/width, linePoints.value[i][1]/height]);
      }
      for(let i=0;i<arrowPoints.value.length;i++){
        arrowValue.value.push([arrowPoints.value[i][0]/width, arrowPoints.value[i][1]/height]);
      }

      conditionStore.setArrow({lineValue:lineValue.value, arrowValue:arrowValue.value});
      summaryMenuStore.setOptionOpenState('route', false);
    }

    // 화살표 리셋
    const resetArrow = () => {
      linePoints.value = [];
      linePolygon.value.points = [];
      dragging.value = undefined;
      arrowPolygon.value.points=[];
      arrowPoints.value=[];
    }
    
    return {
      video,
      linePolygon,
      arrowPolygon,
      canvasClick,
      dragArrow,
      canvasEndClick,
      setArrowOption,
      resetArrow,
      API_BASE_URL,
      locale,
      language,
      
    };
  }
})

