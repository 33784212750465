
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationStore } from '../store/navigation'
import SelectLanguage from "../components/Common/SelectLanguage.vue";
import JoinInput from "../components/Login/JoinInput.vue";

export default defineComponent({
  components: {
    SelectLanguage,
    JoinInput
  },
  setup() {
    const navigationStore = useNavigationStore();
    const router = useRouter();
    const moveLogin = () => {
      router.push({'name': 'login'});
      navigationStore.setCurrentPage('login');
    };

    return {
      moveLogin
    };
  },
});
