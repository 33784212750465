import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { insertVmsInfo, vmsAccess } from '../api';

interface VmsInfo {
  type: string,
  id: string,
  password: string,
  ip: string,
  port: string,
}

interface VmsInfoState {
  vms: Ref<VmsInfo>,
  api: any
}

// 새로운 vms
export const useVmsAddStore = defineStore('vmsAdd', {
  state: (): VmsInfoState => ({
    vms: ref<VmsInfo>({
      type: "",
      id: "",
      password: "",
      ip: "",
      port: "",
    }),
    api:{ip:'', port:''}
  }),
  actions: {
    async insertVmsInfo() {
      try {
        await insertVmsInfo();
        // const res = await insertVmsInfo();
        // console.log('vms insert >> ', res);
        return true;
      } catch (error) {
        console.error('Error fetching vms:', error);
        return false;
      }
      
    },
    setInsertVmsInfo(vms:any){
      this.vms = vms;
    },
    async vmsAccess(){
      try {
        // await vmsAccess();
        const res = await vmsAccess();
        if(this.vms.type === 'LEOPARD'){
          if(res.status === 200){
            return true;
          }
          else if(res.status === 203){
            return false;
          }
        }else {
          return true;
        }
        // console.log('vms access >> ', res);
      } catch (error) {
        console.error('Error fetching users:', error);
        return false;
      }
    }
  },
  getters: {
    getVmsAddInfo(state) {
      return state.vms;
    },
    getVmsAPI(state){
      return state.api;
    }
  },
});
