
import { defineComponent } from 'vue';
import Menu from "../components/Management/Menu.vue";
import VideoList from "../components/Management/VideoList.vue";

export default defineComponent({
  components: {
    Menu,
    VideoList,
  },
  setup() {
    return {
    };
  },
});
