
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useComboSearchLogStore } from '../../store/comboSearchLog';
import { useLogsStore } from '../../store/logs';
import { useLocaleStore } from '@/store/locale';
import moment from 'moment';
import { usePageStore } from '../../store/pageNumber';
import PageNumber from '../../components/Common/PageNumber.vue';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';

export default defineComponent({
  components: {
    ComboboxEdit,
    PageNumber
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const logsStore = useLogsStore();
    const comboboxStore = useComboSearchLogStore();
    const logs = computed(() => logsStore.getData);
    const isOpen = computed(() => comboboxStore.getIsOpen);
    /* pagenation */
    const pageStore = usePageStore();
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref([] as any)
    const searchLogs = ref([] as any)
    /* pagenation */

    const searchArray = ref<any>([
      { id: 'id', label: 'ID' },
      { id: 'type', label: language.value["INFO"]["contents"][locale.value] }, // 내용
    ]);
    const selectedSearch = ref<any>({ id: 'id', label: 'ID'});
    const searchText = ref<any>('');

    const setOpenState = () => {
      comboboxStore.setIsOpen(!isOpen.value);
    }

    const setFormat = (lastAccess: Date) => {
      return moment(lastAccess).format('YYYY-MM-DD HH:mm');
    };

    const setType = (type: string) => {
      let content = "";
      switch(type){
        case 'login':
          content = language.value["LOGIN"]["login"][locale.value]; // 로그인
          break;
        case 'upload':
          content = language.value["ADMIN"]["upload_video"][locale.value]; // 영상 업로드
          break;
        case 'summary':
          content = language.value["PLAYER"]["summary"][locale.value]; // 요약
          break;
        case 'masking':
          content = language.value["PLAYER"]["masking"][locale.value]; // 마스킹
          break;
      }
      return content;
    }

    // 콤보박스 선택시
    const selectedItem = (item: any) => {
      selectedSearch.value = item;
      searchLogEvent();
    }

    // 검색 기능
    const searchLogEvent = () => {
      searchLogs.value = [];
      pageStore.setCurrNumber(1);
      for(let i=0;i<logs.value.length;i++){
        let log = logs.value[i];
        let flag = true;
        let currType = setType(log.type);

        if(searchText.value !== ''){
          switch(selectedSearch.value.id){
            case 'id':
              if(log.id.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
            case 'type':
              if(currType.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
          }
        }

        if(flag){
          let inputData = {date:log.date, id:log.id, type:log.type, info:log.info, showType:currType}
          searchLogs.value.push(inputData);
        }
      }
      pageStore.setTotalPage(searchLogs.value.length, 13);
      getCurrPage();
    }

    /*change info*/
    //요약 상태 텍스트 변경
    const changeSummaryInfoText = (info: string) => {
      let value = JSON.parse(info);
      let infoText = "";
      let count = 0;
      
      if(value.class !== null){
        if(count === 0){
          infoText = language.value["SUMMARY"]["class"][locale.value]; // 객체
          count ++;
        }
      }
      if(value.color !== null){
        if(count === 0){
          infoText = language.value["SUMMARY"]["color"][locale.value]; // 색상
          count ++;
        }
        else{
          infoText = infoText + ', ' + language.value["SUMMARY"]["color"][locale.value]; // 색상
        }
      }
      if(value.direction !== null){
        if(count === 0){
          infoText = language.value["SUMMARY"]["way"][locale.value]; // 방향
          count ++;
        }
        else{
          infoText = infoText + ', '+ language.value["SUMMARY"]["way"][locale.value]; // 방향
        }
      }
      if(value.dwell !== null){
        if(count === 0){
          infoText = language.value["SUMMARY"]["dwell"][locale.value]; // 관심 영역
          count ++;
        }
        else{
          infoText = infoText + ', '+ language.value["SUMMARY"]["dwell"][locale.value]; // 관심 영역
        }
      }
      if(value.face !== null){
        if(count === 0){
          infoText = language.value["PLAYER"]["face"][locale.value]; // 안면인식
          count ++;
        }
        else{
          infoText = infoText + ', '+ language.value["PLAYER"]["face"][locale.value]; // 안면인식
        }
      }
      if(value.ir_query !== null){
        if(count === 0){
          infoText = language.value["SUMMARY"]["text_search"][locale.value]; // 텍스트 검색
          count ++;
        }
        else{
          infoText = infoText + ', '+ language.value["SUMMARY"]["text_search"][locale.value] // 텍스트 검색
        }
      }
      if(count === 0){
        infoText = language.value["COMMON"]["all"][locale.value] // 전체
      }

      return infoText;
    }
    //마스킹 상태 텍스트 변경
    const changeMaskingInfoText = (info: string) => {
      // console.log(info);
      let value = JSON.parse(info);
      let infoText = "";

      if(value.type === 'class'){
        infoText = language.value["ADMIN"]["class_masking"][locale.value] // 클래스 마스킹
      }
      else if(value.type === 'object'){
        infoText = value.id.length+language.value["ADMIN"]["object_masking"][locale.value] // 개의 객체 마스킹
      }

      return infoText;
    }

    const getCurrPage = () => {
      pageList.value = [];
      for(let i=(currNumber.value -1)*13; i<Math.min((currNumber.value)*13, searchLogs.value.length);i++){
        let log = searchLogs.value[i];
        let info = log.info;
        if(log.type === 'summary'){
          info = changeSummaryInfoText(log.info);
        }
        else if(log.type === 'masking'){
          info = changeMaskingInfoText(log.info);
        }
        else if(log.type === 'login'){
          if(log.info === 'success'){
            info = language.value["COMMON"]["success"][locale.value]; // 성공
          }
          else{
            info = language.value["COMMON"]["fail"][locale.value]; // 실패
          }
        }
        
        let inputData = {date:log.date, id:log.id, type:log.type, info:info, showType:log.showType}
        pageList.value.push(inputData);
      }
    }

    onMounted(() => {
      pageStore.setCurrNumber(1);
      logsStore.getLogs();
    })

    watch(() => logs.value, () => {
      pageStore.setTotalPage(logs.value.length, 13);
      searchLogEvent();
    });
    watch(() => currNumber.value, () => {
      getCurrPage();
    })

    onUnmounted(() => {
      comboboxStore.setIsOpen(false);
    });

    return {
      locale,
      language,
      setOpenState,
      logs,
      setFormat,
      selectedItem,
      searchArray,
      selectedSearch,
      searchText,
      searchLogEvent,
      getCurrPage,
      pageList,
      changeSummaryInfoText,
      changeMaskingInfoText
    };
  },
});
