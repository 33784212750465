
import { defineComponent, ref, computed, onMounted, watch, shallowRef } from 'vue';
import { API_BASE_URL } from '@/api';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import { VideoPlayer, VideoPlayerState } from '@videojs-player/vue'
import { useLocaleStore } from '@/store/locale';

type VideoJsPlayer = ReturnType<typeof videojs>

export default defineComponent({
  props: {
    videoSrc : {
      type: String,
      required: true
    }
  },
  setup(props, {emit}) {
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    
    const isSeeking = ref<boolean>(false);
    const isDwellVideoPause = ref<boolean>(false);
    const dwellVideoRef = ref();

    console.log('videoSrc >> ', props.videoSrc);

    const player = shallowRef<VideoJsPlayer>()
    const state = shallowRef<VideoPlayerState>()
    const handleMounted = (payload: any) => {
      player.value = payload.player;
      state.value = payload.state
    }

    const onSeekingDwellVideo = () => {
      isSeeking.value = true;
    };
    const onSeekedDwellVideo = () => {
      isSeeking.value = false;
    };
    const onPlayDwellVideo = () => {
      isDwellVideoPause.value = false;
    };
    const onPauseDwellVideo = (log: any) => {
      if(player.value!==undefined){
        if(log !== player.value.duration()){
          setTimeout(() => { showSummaryBox(log) }, 5);
        }
      }
    };

    const showSummaryBox = (evt: any) => {
      console.log('show box');
    }

    return {
      locale,
      language,
      API_BASE_URL,
      handleMounted,
      onPauseDwellVideo,
      onSeekingDwellVideo,
      onSeekedDwellVideo,
      onPlayDwellVideo,
      player
    };
  }
})

