
import { defineComponent, ref, computed, onMounted, onBeforeMount } from 'vue';
import { useSummaryStore } from '../store/summary';
import { useSummaryPageStore } from '../store/summaryPage';
import { useSummaryMenuStore } from '../store/summaryMenu';
import SummaryOption from "../components/Player/Summary.vue";
import ObjectOption from "../components/Player/ObjectOption.vue";
import TopBox from "../components/Player/TopBox.vue";
import NavBar from "../components/Common/NavBar.vue";
import OriginVideo from "../components/Player/OriginVideo.vue";
import SummaryVideo from "../components/Player/SummaryVideo.vue";
import Object from "../components/Player/Object.vue";
import Statistics from "../components/Player/Statistics.vue";
import { useRouter } from 'vue-router';
import SummarySelectPopup from '@/components/Popup/SummarySelectPopup.vue';
import { API_BASE_URL } from '@/api';
import SummaryTab from '@/components/Player/SummaryTab.vue';
import StatisticOption from '@/components/Player/StatisticOption.vue';
import MaskingOption from '@/components/Player/MaskingOption.vue';
import MaskingSelectPopup from '@/components/Popup/MaskingSelectPopup.vue';
import MaskingVideo from '@/components/Player/MaskingVideo.vue';
import { useMaskingStore } from '@/store/masking';

export default defineComponent({
  components: {
    SummaryOption,
    NavBar,
    TopBox,
    OriginVideo,
    SummaryVideo,
    ObjectOption,
    Object,
    Statistics,
    SummarySelectPopup,
    SummaryTab,
    StatisticOption,
    MaskingOption,
    MaskingSelectPopup,
    MaskingVideo
  },
  setup() {
    const summaryStore = useSummaryStore();
    const summaryPageStore = useSummaryPageStore();
    const summaryMenuStore = useSummaryMenuStore();
    const maskingStore = useMaskingStore();

    const video = computed(() => summaryStore.getVideo);
    const currPage = computed(() => summaryPageStore.getCurrPage);
    const router = useRouter();
    const summaryResult = computed(() => summaryStore.getSummaryResult);

    const popup = computed(() => summaryMenuStore.getPopupState);

    const maskingStatus = computed(() => maskingStore.getMaskingStatus);

    const originVideoTab = ref();

    const playObject = (frame:any) => {
      summaryPageStore.setCurrPage('origin');
      originVideoTab.value.playObject(frame);
      summaryMenuStore.initOptionOpenState();
    }

    onMounted(() => {
      summaryMenuStore.showSummarySelect(false);
      summaryMenuStore.showMaskingSelect(false);
      summaryPageStore.setCurrPage('origin');
      let currVideoId = router.currentRoute.value.params.video_id;
      summaryStore.requestVideoStatus(currVideoId.toString());
    })

    onBeforeMount(() => {
      summaryStore.initOption();
      maskingStore.resetMaskingOption();
    })

    return {
      video,
      summaryResult,
      currPage,
      popup,
      API_BASE_URL,
      playObject,
      originVideoTab,
      maskingStatus
    };
  },
});
