
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useLocaleStore } from '@/store/locale';
import 'v-calendar/style.css';
import "@/css/style.css";

export default defineComponent({
  name: 'DatePicker',
  components: {
  },
  props:{
    inputDate: {
      type: String,
      required: true,
    },
    timeType: {
      type: String,
      required: true,
    }
  },
  setup(props, {emit}) {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);

    const pick = ref<any>();
    const pick2 = computed(() => props.inputDate);
    const isFocused = ref(false);

    const popover = ref({
      visibility: 'click',
    });

    const setDate = () => {
      if(pick.value == null || pick.value > new Date()) {
        pick.value = new Date();
      }
      emit('setDate', pick.value);
    }

    onMounted(() => {
      pick.value = props.inputDate;
    })

    watch(pick2, (newDate) => {
      pick.value = newDate;
    });

    return {
      locale,
      pick,
      popover,
      isFocused,
      setDate,
    };
  },
});
