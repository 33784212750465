
import { defineComponent } from 'vue';
import Menu from "../components/Management/Menu.vue";
import Log from "../components/Management/LogList.vue";

export default defineComponent({
  components: {
    Menu,
    Log,
  },
  setup() {
    return {
    };
  },
});
