
import { defineComponent } from 'vue';
import Menu from "../components/Management/Menu.vue";
import UserList from "../components/Management/UserList.vue";

export default defineComponent({
  components: {
    Menu,
    UserList,
  },
  setup() {
    return {
    };
  },
});
