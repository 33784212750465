
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useVmsStore, Vms, Camera } from '../../store/vms';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
  },
  setup(props, {emit}) {
    /* store */
    const localeStore = useLocaleStore();
    const vmsStore = useVmsStore();
    
    /* data */
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const vmsList = computed(() => vmsStore.getVmsList);
    const currList = ref<any>(vmsList.value);

    /* function */
    const closeVmsList = (index: number | '') => {
      for(let i=0; i<vmsList.value.length; i++) {
        if(index !== i) {
          vmsList.value[i].style = {}; // 열려있는 VMS가 있다면 닫아줌
          vmsList.value[i].opened = false;
        }
      }
    }

    const vmsListLayouts = ref<any>(null);
    const vmsListLayout = ref<any>(null);
    const currVmsIndex = ref<any>(0);

    const toggleDropdown = (vms: Vms, index: number) => { // 드롭다운 메뉴 열기/닫기
      vmsListLayouts.value = document.querySelectorAll('.vms-camera-list');
      vmsListLayout.value = vmsListLayouts.value[index];
      currVmsIndex.value = index;

      if(vms.cameras.length > 0){
        closeVmsList(index);
        vms.opened = !vms.opened;
        if(vms.opened && vms.cameras.length > 0) {
          vms.style = { height: (vms.cameras.length * 60) + 55 + "px" , 'border-color':'white'};
        } else {
          vms.style = {};
        }
        vmsStore.setThumbnail(currVmsIndex.value, 0);
      }
    };

    const handleListClick = (event: MouseEvent) => {
      event.preventDefault(); // 리스트를 클릭할 때 텍스트 선택을 방지하기 위해 이벤트의 기본 동작을 막음
    };

    const handleItemClick = (camera: Camera, event: MouseEvent) => {
      camera.selected = !camera.selected;

      if(camera.selected) {
        vmsStore.pushSelectedCamera(camera);
      } else {
        vmsStore.popSelectedCamera(camera);
        vmsStore.toggleCamera(camera);
      }
      
      event.stopPropagation(); // 이벤트 전파 막기
    };

    const searchText = ref(''); // 입력한 검색어
    const searchResult = ref<any[]>([]); // 검색 결과
    const existResult = ref(false); // 검색 결과 존재 여부
    const clickResult = ref(false); // 검색 결과 클릭 여부

    const searchCamera = () => {
      searchResult.value = [];
      existResult.value = false;

      if(searchText.value == '') {
        removeSearchText();
      } else {
        for(let i=0; i<vmsList.value.length; i++) {
          const vms = vmsList.value[i];
          const foundCamera = vms.cameras.filter(
            camera => (camera.name.toUpperCase()).includes(searchText.value.toUpperCase())
          );

          if(foundCamera.length > 0) {
            existResult.value = true;
            for(let j=0; j<foundCamera.length; j++) {
              const cameraName = foundCamera[j].name;
              const index = (cameraName.toUpperCase()).indexOf(searchText.value.toUpperCase());
              const highlight = cameraName.substr(index, searchText.value.length);

              searchResult.value.push({
                index: vms.index,
                type: vms.type,
                url: vms.url,
                id: vms.id,
                password: vms.password,
                ip: vms.ip,
                port: vms.port,
                api_serial: vms.api_serial, 
                auth_token: vms.auth_token,
                user_serial: vms.user_serial,
                opened: true,
                style: {height: '115px'},
                cameras: [foundCamera[j]],
                html: cameraName.replace(highlight, '<span style=" color: #2E8EFF; font-weight: 600;">' + highlight + '</span>'),
              });
            }
          }
        }
      }
    }

    const removeSearchText = () => {
      searchText.value = '';
      searchResult.value = [];
      existResult.value = false;
      currList.value = vmsList.value;
      if(clickResult.value) {
        closeVmsList('');
        clickResult.value = false;
      }
    }

    const resVms = ref<any>([]);
    const setSearchRes = (vms: any) => {
      resVms.value = [];
      resVms.value.push(vms);

      clickResult.value = true;

      searchText.value = vms.cameras[0].name;
      searchResult.value = [];

      currList.value = resVms.value;
    }

    watch(() => vmsList.value, (value) => {
      currList.value = value;
    });

    const scrollEvent = ref<any>();

    watch(() => vmsListLayout.value, () => {
      if(vmsListLayout.value !== null){
        vmsListLayout.value.addEventListener('scroll', () => {
          clearTimeout(scrollEvent.value);
          scrollEvent.value = setTimeout(() => {
              checkThumbnail(vmsListLayout.value.scrollTop);
          }, 700);
        })
      }
    })

    const checkThumbnail = (scroll: number) => {
      let startIndex = Math.ceil(scroll/60)!== 0 ? Math.ceil(scroll/60) -1:0;
      vmsStore.setThumbnail(currVmsIndex.value, startIndex);
    }

    const refresh = () => {
      emit('refresh');
      vmsStore.initDate();
      removeSearchText();
    }

    onMounted(() => {
      vmsStore.initDate();
    });

    onBeforeUnmount(() => {
      closeVmsList('');
    });

    return {
      locale,
      language,
      vmsList,
      toggleDropdown,
      handleItemClick,
      handleListClick,
      searchCamera,
      searchResult,
      searchText,
      existResult,
      removeSearchText,
      setSearchRes,
      resVms,
      currList,
      refresh
    };
  },
});
