import { computed } from 'vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { useUserStore } from '../store/loginUser';

// 페이지 컴포넌트 임포트
import Login from "@/views/Login.vue";
import Join from "@/views/Join.vue";
import Home from "@/views/Home.vue";
import Setting from "@/views/Setting.vue";
import Player from "@/views/Player.vue";
import MngUser from "@/views/MngUser.vue";
import MngVms from "@/views/MngVms.vue";
import MngVideo from "@/views/MngVideo.vue";
import MngLog from "@/views/MngLog.vue";

const routes: Array<RouteRecordRaw> = [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/join',
      name: 'join',
      component: Join,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    { 
      path: '/player/:video_id',
      name: 'player',
      component: Player
    },
    {
      path: "/setting",
      name: "setting",
      component: Setting,
      children: [
        {
          path: "user",
          name: "user",
          component: MngUser,
        },
        {
          path: "vms",
          name: "vms",
          component: MngVms,
        },
        {
          path: "video",
          name: "video",
          component: MngVideo,
        },
        {
          path: "log",
          name: "log",
          component: MngLog,
        },
      ]
    },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const isLoggedIn = computed(() => userStore.getUserIsLoggedIn);

  // console.log('getUserIsLoggedIn >> ', userStore.getUserIsLoggedIn, to, from);
  
  if(!isLoggedIn.value) {
    if(to.name == "home") {
      next({ name: "login" });
    } 
    else if(to.name == "player" || to.path.indexOf('setting')!== -1){
      next(false)
    }
    else {
      next();
    }
  } 
  else {
    if(to.name == "login") {
      next({ name: "home" });
    } else {
      next();
    }
  }
});


export default router;