
import { defineComponent, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationStore } from '../store/navigation'
import { useFilesStore } from '@/store/files';
import { useProgressStore } from '@/store/progress';
import { useUploadPopupStateStore } from '@/store/uploadPopup';
import { useLocaleStore } from '@/store/locale';
import LoginInput from "../components/Login/LoginInput.vue";
import SelectLanguage from "../components/Common/SelectLanguage.vue";

export default defineComponent({
  components: {
    LoginInput,
    SelectLanguage
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const navigationStore = useNavigationStore();
    const filesStore = useFilesStore();
    const progressStore = useProgressStore();
    const popupStateStore = useUploadPopupStateStore();
    const router = useRouter();
    const goToAboutPage = (page: string) => {
      router.push({'name': page});
      navigationStore.setCurrentPage(page);
    };

    onMounted(() => {
      filesStore.initFiles();
      progressStore.initProgressFiles();
      popupStateStore.setState("",false);
    });

    return {
      locale,
      language,
      goToAboutPage
    };
  },
});
