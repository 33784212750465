import { defineStore } from 'pinia';
import { Item, ComboBoxState } from './comboBox';

export const useComboSearchVmsStore = defineStore('combosearchvms', {
  state: (): ComboBoxState => ({
    defaultItem: { id: 'vms', label: 'VMS' },
    selectedItem: { id: 'vms', label: 'VMS' },
    items: [
      { id: 'vms', label: 'VMS' },
      { id: 'id', label: 'ID' },
      { id: 'ip', label: 'IP' },
      { id: 'port', label: 'PORT' },
    ],
    isOpen: false
  }),
  actions: {
    setSelectedItem(item: Item) {
      this.selectedItem = item;
    },
    setIsOpen(state: boolean) {
      this.isOpen = state;
    }
  },
  getters: {
    getDefaultItem(state) {
      return state.defaultItem;
    },
    getSelectedItem(state) {
      return state.selectedItem;
    },
    getItems(state) {
      return state.items;
    },
    getIsOpen(state) {
      return state.isOpen;
    }
  },
});
