import { defineStore } from "pinia";
import { ref, computed } from 'vue';
import { useLocaleStore } from './locale';

export const useConditionStore = defineStore('condition', {
  state: () => ({
    classCondition: [
      {title:'PERSION', sub:[{type:'man', checked:true, name:'남자'}, {type:'woman', checked:true, name:'여자'}]},
      {title:'ANIMAL', sub:[{type:'cat', checked:true, name:'고양이'}, {type:'dog', checked:true, name:'개'}]},
      {title:'TWO-WHEELED', sub:[{type:'bike', checked:true, name:'자전거'}, {type:'motorcycle', checked:true, name:'오토바이'}]},
      {title:'FOUR-WHEELED', sub:[{type:'car', checked:true, name:'자가용'}, {type:'truck', checked:true, name:'트럭'}, {type:'bus', checked:true, name:'버스'}]}
    ],
    allCheckClass: true,

    colorCondition: [
      {type:'#851B1B', send:'#FF0000', checked:true},
      {type:'#775907', send:'#9C8136', checked:true},
      {type:'#FFE400', send:'#FFE400', checked:true},
      {type:'#2B6A28', send:'#1DDB16', checked:true},
      {type:'#3062A7', send:'#368AFF', checked:true},
      {type:'#8544E2', send:'#A566FF', checked:true},
      {type:'#DB71DB', send:'#FF5AFF', checked:true},
      {type:'#FFFFFF', send:'#FFFFFF', checked:true},
      {type:'#787878', send:'#7F7F7F', checked:true},
      {type:'#151515', send:'#000000', checked:true},
    ],
    allCheckColor: true,
    
    direction: [
      {type:'E', send:0, checked:true, name:'동'},
      {type:'NE', send:45, checked:true, name:'북동'},
      {type:'N', send:90, checked:true, name:'북'},
      {type:'NW', send:135, checked:true, name:'북서'},
      {type:'W', send:180, checked:true, name:'서'},
      {type:'SW', send:225, checked:true, name:'남서'},
      {type:'S', send:270, checked:true, name:'남'},
      {type:'SE', send:315, checked:true, name:'남동'},
    ],
    allCheckDirection: true,
    currDirection: {type:'', send:0, checked:true, name:''},

    dwell: {firstPoints:[], secondPoints:[], inputType:'', selectType : ''}, // 관심영역
    dwellPoints: {firstPolygon:ref<any>([]), secondPolygon:ref<any>([]), firstPoint:ref<any>([]), secondPoint:ref<any>([])},
    is_dvideo: 0,

    arrow: {line:[], arrow:[]},

    timerange: [],
    textTimerange : "",

    timeStamp: 1,

    irText: "",
    irThreshold: 0.3,

    face: [],
  }),
  actions: {
    setClass(value: any){
      this.classCondition = value;
    },
    setAllClass(value: any){
      this.allCheckClass = value;
    },
    resetClass(){
      const localeStore = useLocaleStore();
      const locale = computed(() => localeStore.getLocale);
      const language = computed(() => localeStore.getLanguage);

      this.classCondition = [
        {title:'PERSION', 
          sub:[{type:'man', checked:true, name: language.value["SUMMARY"]["man"][locale.value]}, // 남자
              {type:'woman', checked:true, name: language.value["SUMMARY"]["woman"][locale.value]}]}, // 여자
        {title:'ANIMAL', 
          sub:[{type:'cat', checked:true, name: language.value["SUMMARY"]["cat"][locale.value]},  // 고양이
              {type:'dog', checked:true, name: language.value["SUMMARY"]["dog"][locale.value]}]}, // 개
        {title:'TWO-WHEELED', 
          sub:[{type:'bike', checked:true, name: language.value["SUMMARY"]["bike"][locale.value]},  // 자전거
              {type:'motorcycle', checked:true, name: language.value["SUMMARY"]["motorcycle"][locale.value]}]}, // 오토바이
        {title:'FOUR-WHEELED', 
          sub:[{type:'car', checked:true, name: language.value["SUMMARY"]["car"][locale.value]},  // 자가용
              {type:'truck', checked:true, name: language.value["SUMMARY"]["truck"][locale.value]},  // 트럭
              {type:'bus', checked:true, name: language.value["SUMMARY"]["bus"][locale.value]}]}  // 버스
      ];
      this.allCheckClass = true;
    },
    setColor(value: any){
      this.colorCondition = value;
    },
    setAllColor(value: any){
      this.allCheckColor = value;
    },
    resetColor(){
      this.colorCondition = [
        {type:'#851B1B', send:'#FF0000', checked:true},
        {type:'#775907', send:'#9C8136', checked:true},
        {type:'#FFE400', send:'#FFE400', checked:true},
        {type:'#2B6A28', send:'#1DDB16', checked:true},
        {type:'#3062A7', send:'#368AFF', checked:true},
        {type:'#8544E2', send:'#A566FF', checked:true},
        {type:'#DB71DB', send:'#FF5AFF', checked:true},
        {type:'#FFFFFF', send:'#FFFFFF', checked:true},
        {type:'#787878', send:'#7F7F7F', checked:true},
        {type:'#151515', send:'#000000', checked:true},
      ];
      this.allCheckColor = true;
    },
    setDirection(value: any, currValue: any){
      this.direction = value;
      this.currDirection = currValue;
    },
    setAllDirection(value: any){
      this.allCheckDirection = value;
    },
    resetDirection(){
      const localeStore = useLocaleStore();
      const locale = computed(() => localeStore.getLocale);
      const language = computed(() => localeStore.getLanguage);

      this.direction = [
        {type:'E', send:0, checked:true, name:language.value["SUMMARY"]["e"][locale.value]},
        {type:'NE', send:45, checked:true, name:language.value["SUMMARY"]["ne"][locale.value]},
        {type:'N', send:90, checked:true, name:language.value["SUMMARY"]["n"][locale.value]},
        {type:'NW', send:135, checked:true, name:language.value["SUMMARY"]["nw"][locale.value]},
        {type:'W', send:180, checked:true, name:language.value["SUMMARY"]["w"][locale.value]},
        {type:'SW', send:225, checked:true, name:language.value["SUMMARY"]["sw"][locale.value]},
        {type:'S', send:270, checked:true, name:language.value["SUMMARY"]["s"][locale.value]},
        {type:'SE', send:315, checked:true, name:language.value["SUMMARY"]["se"][locale.value]},
      ];
      this.currDirection = {type:'', send:0, checked:true, name:''};
      this.allCheckDirection = true;
    },
    setDwellType(value: any){
      this.dwell.selectType = value;
      this.dwellPoints = {firstPolygon:[], secondPolygon:[], firstPoint:[], secondPoint:[]};
    },
    setDwell(value: any, data: any){
      this.dwell.firstPoints = value.firstValue; 
      this.dwell.secondPoints = value.secondValue; 
      this.dwell.inputType = value.inputType; 
      this.dwellPoints = data;
    },
    // setDwellData(){
    //   const width = 520;
    //   const height = 320;

    //   const firstPoints = [];
    //   for(let i=0;i<this.dwell.firstPoints.length;i++){
    //     firstPoints.push(this.dwell.firstPoints[i][0] * width);
    //     firstPoints.push(this.dwell.firstPoints[i][1] * height);
    //   }

    //   const secondPoints = [];
    //   if(this.dwell.inputType !== 'one'){
    //     for(let i=0;i<this.dwell.secondPoints.length;i++){
    //       secondPoints.push(this.dwell.secondPoints[i][0] * width);
    //       secondPoints.push(this.dwell.secondPoints[i][1] * height);
    //     }
    //   }

    //   this.dwellPoints = {firstPolygon:firstPoints, secondPolygon:secondPoints};
    // },
    setIsDwellVideo(value:any){
      if(value){
        this.is_dvideo = 1;
      }
      else{
        this.is_dvideo = 0;
      }
    },
    resetDwell(){
      this.dwell = {
        firstPoints : [],
        secondPoints : [],
        inputType : '',
        selectType : ''
      }
      this.dwellPoints = {firstPolygon:[], secondPolygon:[], firstPoint:[], secondPoint:[]};
      this.is_dvideo = 0;
    },
    setArrow(value:any){
      this.arrow.line = value.lineValue;
      this.arrow.arrow = value.arrowValue;
    },
    resetArrow(){
      this.arrow = {line:[], arrow:[]};
    },
    setTimeRange(value: any){
      this.timerange = value;
    },
    setTextTimeRange(value: any){
      this.textTimerange = value;
    },
    resetTimeRange(){
      this.timerange = [];
      this.textTimerange = "";
    },
    setTimeStamp(value:any){
      this.timeStamp = value;
    },
    resetTimeStamp(){
      this.timeStamp = 1;
    },
    setIr(text:any, threshold:any){
      this.irText = text;
      this.irThreshold = threshold;
    },
    resetIr(){
      this.irText = "";
      this.irThreshold = 0.3;
    },
    setFace(value:any){
      this.face = value;
    },
    resetFace(){
      this.face = [];
    },
    initOption(){
      this.resetClass();
      this.resetColor();
      this.resetDirection();
      this.resetDwell();
      this.resetArrow();
      this.resetTimeRange();
      this.resetTimeStamp();
      this.resetIr();
      this.resetFace();
    }
  },
  getters: {
    getClass(state){
      return state.classCondition;
    },
    getAllClass(state){
      return state.allCheckClass;
    },
    getColor(state){
      return state.colorCondition;
    },
    getAllColor(state){
      return state.allCheckColor;
    },
    getDirection(state){
      return state.direction;
    },
    getCurrDirection(state){
      return state.currDirection;
    },
    getAllDirection(state){
      return state.allCheckDirection;
    },
    getDwell(state){
      return state.dwell;
    },
    getDwellData(state){
      return state.dwellPoints;
    },
    getDwellVideo(state){
      return state.is_dvideo;
    },
    getArrow(state){
      return state.arrow;
    },
    getTimeRange(state){
      return state.timerange;
    },
    getTextTimeRange(state){
      return state.textTimerange;
    },
    getTimeStamp(state){
      return state.timeStamp;
    },
    getIrText(state){
      return state.irText;
    },
    getIrThreshold(state){
      return state.irThreshold;
    },
    getFace(state){
      return state.face;
    }
  }
})