
import { defineComponent, ref, computed, onMounted, watch, shallowRef, onUnmounted } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useSummaryPageStore } from '../../store/summaryPage';
import { useMaskingStore } from '@/store/masking';
import { API_BASE_URL } from '@/api';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import { VideoPlayer, VideoPlayerState } from '@videojs-player/vue'
import moment from "moment";
import MemoModule from '@/components/Popup/MemoModule.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';
import DwellVideoPopup from '@/components/Player/DwellVideoPopup.vue'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import { useSummaryMenuStore } from '../../store/summaryMenu';

type VideoJsPlayer = ReturnType<typeof videojs>

export default defineComponent({
  components: {
    VideoPlayer,
    MemoModule,
    PopupModule,
    DwellVideoPopup,
    Vue3DraggableResizable
  },
  emits:['playObject'],
  setup(props, {emit}) {
    const summaryStore = useSummaryStore();
    const summaryPageStore = useSummaryPageStore();
    const maskingStore = useMaskingStore();
    const localeStore = useLocaleStore();
    const summaryMenuStore = useSummaryMenuStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const video = computed(() => summaryStore.getVideo);
    const currPage = computed(() => summaryPageStore.getCurrPage);
    const summaryResult = computed(() => summaryStore.getSummaryResult);
    const isSeeking = ref<boolean>(false);
    const isSummaryVideoPause = ref<boolean>(false);
    const summaryVideoRef = ref();

    const memoPopup = ref({ open: false });
    const popupContents = ref({ message: '', type: 'success', show: false });

    const summaryCanvasRef = ref(); // 캔버스 정의
    const summaryDwellAreaCanvasRef = ref(); // 캔버스 정의
    const summaryBoxRef = ref();  
    const currFrame = ref(null);  // 현재 프레임
    const currSummaryBox = ref<any>([]);  // 현재 요약 시간대에 등장하는 객체 박스를 담는 변수
    const currMaskingBox = ref<any>([]);  // 현재 선택한 마스킹 객체 박스를 담는 변수 
    const maskingSelectObject = computed(() => maskingStore.getMaskingSelectObject);  // 현재 선택한 객체
    const summarySelectObject = {img:'', time:'', isBookmark:0, frame : 0, id:null, memo:''} // 요약화면에서 선택한 객체 정보
    const objectPopup = ref<boolean>(false);  // 요약화면에서 객체 팝업 유무
    const objects = ref();
    const videos = computed(() => summaryStore.getVideo);

    const selectObject = computed(() => summaryStore.getSelectObject);
    const bookmark = computed(() => summaryStore.getBookmark);

    /*관심영역 확대영상*/
    const dwellShow = computed(() => summaryMenuStore.getTabState.dwellShow);
    const isSecondArea = ref<any>(false);
    const summaryOption = computed(() => summaryStore.getApplySummaryOption);
    const dwellFirstBtn = ref<HTMLInputElement | null>(null);
    const dwellSecondBtn = ref<HTMLInputElement | null>(null);

    const setDwellShowButton = () => {
      let width = summaryBoxRef.value.clientWidth;
      let height = summaryBoxRef.value.clientHeight;
      
      // let ratio1 = 720 / height;
      // let ratio2 = 1280 / width;

      let canvasWidth = height * (1280.0/720.0);
      let blankX = (width - canvasWidth)/2;
      let canvasHeight = width * (720.0/1280.0);
      let blankY = (height - canvasHeight)/2;

      let rate = height / width;

      let realWidth, realHeight;

      if(rate < 9/16){
        realWidth = canvasWidth;
        realHeight = height;
      }
      else{
        realWidth = width;
        realHeight = canvasHeight;
      }

      let dwellArea = summaryOption.value.dwell
      if(dwellArea !== null && dwellArea !== undefined){
         // 관심영역 1개일때
        let firstArea = dwellArea["0"];
        let firstCalc = calculateCenter(firstArea);

        dwellFirstBtn.value = document.querySelector('.dwell-video-btn-first');
        if(dwellFirstBtn.value !== null){
          if(rate < 9/16){
            let left = realWidth * firstCalc.center_x + blankX;
            let top = realHeight * firstCalc.center_y;
            dwellFirstBtn.value.style.left = `${left}px`
            dwellFirstBtn.value.style.top = `${top}px`
          }
          else{
            let left = realWidth * firstCalc.center_x;
            let top = realHeight * firstCalc.center_y + blankY;
            dwellFirstBtn.value.style.left = `${left}px`
            dwellFirstBtn.value.style.top = `${top}px`
          }
        }

        console.log('dwell area >> ', dwellArea, dwellArea['o'] !== undefined);

        if(dwellArea['o'] !== undefined){  // 관심영역 2개일때
          let secondArea = dwellArea["1"];
          let secondCalc = calculateCenter(secondArea);

          dwellSecondBtn.value = document.querySelector('.dwell-video-btn-second');
          if(dwellSecondBtn.value !== null){
            if(rate < 9/16){
              let left = realWidth * secondCalc.center_x + blankX;
              let top = realHeight * secondCalc.center_y;
              dwellSecondBtn.value.style.left = `${left}px`
              dwellSecondBtn.value.style.top = `${top}px`
            }
            else{
              let left = realWidth * secondCalc.center_x;
              let top = realHeight * secondCalc.center_y + blankY;
              dwellSecondBtn.value.style.left = `${left}px`
              dwellSecondBtn.value.style.top = `${top}px`
            }
          }
        }
      }

    }

    const calculateCenter = (points:any) => {
      function midpoint(x1:any, y1:any, x2:any, y2:any) {
          return [(x1 + x2) / 2, (y1 + y2) / 2];
      }

      function findIntersection(m1:any, c1:any, m2:any, c2:any) {
          const x = (c2 - c1) / (m1 - m2);
          const y = m1 * x + c1;
          return [x, y];
      }

      const [x1, y1] = points[0];
      const [x2, y2] = points[1];
      const [x3, y3] = points[2];
      const [x4, y4] = points[3];

      const [m1x, m1y] = midpoint(x1, y1, x2, y2);
      const [m2x, m2y] = midpoint(x2, y2, x3, y3);
      const [m3x, m3y] = midpoint(x3, y3, x4, y4);
      const [m4x, m4y] = midpoint(x4, y4, x1, y1);

      const m1 = (m3y - m1y) / (m3x - m1x);
      const c1 = m1y - m1 * m1x;

      const m2 = (m4y - m2y) / (m4x - m2x);
      const c2 = m2y - m2 * m2x;

      const [center_x, center_y] = findIntersection(m1, c1, m2, c2);

      return { center_x, center_y };
    }

    const playDwellVideoSrc = ref<any>("");
    const popup = computed(() => summaryMenuStore.getPopupState);

    const vmodelX = ref<any>(100);
    const vmodelY = ref<any>(100);

    const movePopup = ref<any>({
      initWidth: 520, // 초기 사이즈 & 최소 사이즈
      initHeight: 430,
      vmodelWidth: 520,
      vmodelHeight: 430,
      vmodelX:vmodelX,
      vmodelY:vmodelY,
      vmodelActive: true,
    })


    const closeDwellPopup = () => {
      summaryMenuStore.setDwellVideoPopup(false);
      // dwellVideoShow.value = false;
    }

    const player = shallowRef<VideoJsPlayer>()
    const state = shallowRef<VideoPlayerState>()
    const handleMounted = (payload: any) => {
      player.value = payload.player;
      state.value = payload.state;
      
      setDwellVideoPosition();
    }

    // 관심 영역 확대 영상 나오는 위치 지정
    const setDwellVideoPosition = () => {
      const summaryTabDiv = document.querySelector('.gray-fill-box.right')
      if(summaryTabDiv !== null){
        vmodelX.value = Number(summaryTabDiv.getBoundingClientRect().left.toFixed());
        vmodelY.value = Number(summaryTabDiv.getBoundingClientRect().top.toFixed());
        console.log(vmodelX.value, vmodelY.value)
      }
    }

    // 요약 영상을 일시정지 했을 때 등장하는 객체 박스를 그리는 함수 호출. log=frame
    const onPauseSummaryVideo = (log: any) => {
      if(player.value!==undefined){
        if(log !== player.value.duration()){
           setTimeout(() => { showSummaryBox(log) }, 5);
        }
      }
      
    };
    const onSeekingSummaryVideo = () => {
      isSeeking.value = true;
    };
    const onSeekedSummaryVideo = () => {
      isSeeking.value = false;
    };
    const onPlaySummaryVideo = () => {
      isSummaryVideoPause.value = false;
    };

    // 객체 박스를 canvas에 그리는 함수
    const showSummaryBox = (evt: any) => {
      if(!isSeeking.value && player.value !== undefined){
        if(summaryCanvasRef.value === undefined){
          return;
        }
        /* 캔버스에 현재 화면을 그리는 부분 */
        summaryCanvasRef.value.width = 1280;
        summaryCanvasRef.value.height = 720;
        let ctx = summaryCanvasRef.value.getContext('2d');
       
        let videoId = player.value.getAttribute('id');
        let video = document.getElementById((videoId!==null?videoId:'')+"_html5_api")

        ctx.drawImage(video, 0, 0, summaryCanvasRef.value.width, summaryCanvasRef.value.height);

        let frameNumHelper = 0;
        let frameData = ctx.getImageData(0, 719, 1280, 1).data;
        /* 캔버스에 현재 화면을 그리는 부분 */

        /* 현재 프레임 정보를 구하는 부분*/
        for (let i = 0; i < 1280; i++) {
          if (frameData[i * 4] > 80) {
            frameNumHelper += i + 1;
            break;
          }
        }

        currFrame.value = null;
        currFrame.value = evt;

        let frameNum = Math.round(evt* 8);

        frameNum = frameNum + (frameNumHelper - frameNum % 1280) -1;
        /* 현재 프레임 정보를 구하는 부분*/

        /* 객체 박스 정보(result_objects)를 바탕으로 현재 프레임에 해당하는 값을 불러와 박스를 그리는 부분 */
        if(summaryResult.value.result_objects !== null) {
          if(JSON.parse(summaryResult.value.result_objects)[frameNum] === undefined){
            return;
          }
          let objects = summaryResult.value.result_objects;
          let currBox = JSON.parse(objects)[frameNum]['o']; // 현재 프레임에 맞는 object 정보값 불러옴
          /* ex)
            "o": [
              "457,4505,1080,135,35,86",
              "482,4683,650,26,43,37",
              "483,4686,1038,141,29,82",
              "494,4783,244,369,390,201"
            ]
          */
          currSummaryBox.value = [];
          currMaskingBox.value = [];
          if(currBox!==null){
            for(let i=0;i<currBox.length;i++){
              let box = JSON.parse('['+currBox[i]+']');
              // ex) box = [457,4505,1080,135,35,86] = [id, frame, x, y, width, height]
              currSummaryBox.value.push(box);
              if(maskingSelectObject.value.length!==0){
                for(let j=0; j<maskingSelectObject.value.length;j++){
                  if(maskingSelectObject.value[j] === box[0]){
                    currMaskingBox.value.push(box);
                  }
                  else{
                    ctx.beginPath();
                    ctx.lineWidth = "2";
                    ctx.strokeStyle = "white";
                    ctx.rect(box[2], box[3], box[4], box[5])
                    ctx.stroke()
                  }
                }
              }
              else{
                ctx.beginPath();
                ctx.lineWidth = "2";
                ctx.strokeStyle = "white";
                ctx.rect(box[2], box[3], box[4], box[5])
                ctx.stroke()
              }
              
            }
            if(currMaskingBox.value.length !== 0){
              for(let k=0; k<currMaskingBox.value.length;k++){
                ctx.beginPath();
                ctx.lineWidth = "4";
                ctx.strokeStyle = "#2F8EFE";
                ctx.rect(currMaskingBox.value[k][2], currMaskingBox.value[k][3], currMaskingBox.value[k][4], currMaskingBox.value[k][5])
                ctx.stroke()
              }
            }
          }
          isSummaryVideoPause.value = true;
        }
        /* 객체 박스 정보(result_objects)를 바탕으로 현재 프레임에 해당하는 값을 불러와 박스를 그리는 부분 */
      }
    };

    // canvas 클릭 이벤트를 바탕으로 객체클릭 유무 판별
    const summaryCanvasClick = (evt: any) => {
      if(isSummaryVideoPause.value){
        /* 현재 영상의 크기를 바탕으로 사용자가 클릭한 곳이 실질적으로 영상의 어느 좌표에 해당하는지 구하는 부분 */
        let width = summaryBoxRef.value.clientWidth;
        let height = summaryBoxRef.value.clientHeight;
        
        let ratio1 = 720 / height;
        let ratio2 = 1280 / width;

        let canvasWidth = height * (1280.0/720.0);
        let leftBlank = (width - canvasWidth)/2;
        let canvasHeight = width * (720.0/1280.0);
        let topBlank = (height - canvasHeight)/2;

        let rate = height / width;

        let clickY = evt.layerY * ratio1;
        let clickX = evt.layerX * ratio1;

        if(rate < (9/16)){
          clickY = evt.layerY * ratio1;
          clickX = (evt.layerX- leftBlank) * ratio1;

        }else{
          clickY = (evt.layerY - topBlank) * ratio2;
          clickX = evt.layerX * ratio2;
        }
        /* 현재 영상의 크기를 바탕으로 사용자가 클릭한 곳이 실질적으로 영상의 어느 좌표에 해당하는지 구하는 부분 */

        // 현재 탭이 요약일 경우 객체 팝업
        if(currPage.value === 'summary'){
          let objectBox = [];
          for (let i = 0; i < currSummaryBox.value.length; i++) {
            if (clickY > currSummaryBox.value[i][3] && clickY < currSummaryBox.value[i][3] + currSummaryBox.value[i][5] && clickX > currSummaryBox.value[i][2] && clickX < currSummaryBox.value[i][2] + currSummaryBox.value[i][4]) {
              objectBox.push(currSummaryBox.value[i]);
              // [id, frame, x, y, 넓이, 높이]
            }
          }

          if(objectBox.length !== 0){
            let selectObject;

            if(objectBox.length === 1){
              selectObject = objectBox[0];
            }
            else{
              // 넓이 x 높이 해서 가장 작은거
              let smallObject = objectBox[0];
              for(let k=1;k<objectBox.length;k++){
                if(smallObject[4]*smallObject[5] > objectBox[k][4]*objectBox[k][5]){
                  smallObject = objectBox[k];
                }
              }
              selectObject = smallObject;
            }

            const beginDate = ref();
            if(videos.value !== undefined){
              beginDate.value = new Date(videos.value.beginDate);
            }
            for(let j=0; j<objects.value.length; j++){
              if(selectObject[0] === parseInt(objects.value[j].i)){
                let log = objects.value[j];
                let objTime = new Date(beginDate.value.getTime() + (log.f / 8 * 1000));

                summarySelectObject.img = API_BASE_URL + log.p;
                summarySelectObject.id = selectObject[0];
                summarySelectObject.frame = parseInt(log.f);
                summarySelectObject.time = moment(objTime).format('hh:mm A');
                summarySelectObject.isBookmark = 0;

                for(let k=0;k<bookmark.value.length;k++){
                  if(bookmark.value[k]['id'] === Number(summarySelectObject.id)){
                    summarySelectObject.isBookmark = 1;
                    break;
                  }
                }

                summaryStore.setSelectObject({
                  thumbnail: API_BASE_URL + log.p,
                  realPath: log.p, 
                  date: objTime, 
                  frame: log.f, 
                  isBookmark: summarySelectObject.isBookmark,
                  id: selectObject[0],
                  textTime: moment(objTime).format('hh:mm A'),
                  realTime: objTime,
                  memo: ''
                })

                objectPopup.value = true;
                break;
              }
            }
            return;
          }
        }
        // 현재 탭이 마스킹일 경우 마스킹할 객체 선택(파란색 테두리)
        else if(currPage.value === 'masking'){
          let objectBox = [];
          for (let i = 0; i < currSummaryBox.value.length; i++) {
            if (clickY > currSummaryBox.value[i][3] && clickY < currSummaryBox.value[i][3] + currSummaryBox.value[i][5] && clickX > currSummaryBox.value[i][2] && clickX < currSummaryBox.value[i][2] + currSummaryBox.value[i][4]) {
              objectBox.push(currSummaryBox.value[i]);
            }
          }

          if(objectBox.length!==0){
            let selectObject;

            if(objectBox.length === 1){
              selectObject = objectBox[0];
            }
            else{
              // 넓이 x 높이 해서 가장 작은거
              let smallObject = objectBox[0];
              for(let k=1;k<objectBox.length;k++){
                if(smallObject[4]*smallObject[5] > objectBox[k][4]*objectBox[k][5]){
                  smallObject = objectBox[k];
                }
              }
              selectObject = smallObject;
            }

            let input = true;
            for(let j=0; j<maskingSelectObject.value.length;j++){
              if(maskingSelectObject.value[j] === selectObject[0]){
                input = false;
              }
            }
            if(input){
              maskingStore.pushMaskingSelectObject(selectObject)
              showSummaryBox(currFrame.value);
            }
            else{
              let index = maskingSelectObject.value.indexOf(selectObject[0])
              if(index > -1){
                maskingStore.spliceMaskingSelectObject(index);
                showSummaryBox(currFrame.value);
              }
            }
            return
          }
        }
        
        // 객체가 아닌 부분을 클릭했을 경우 재생
        isSummaryVideoPause.value = false
        currFrame.value = null;
        if(player.value !== undefined){
          player.value.play();
        }
      }
    };

    const playObjectTime = () => {
      objectPopup.value = false;
      emit('playObject', summarySelectObject.frame);
    }

    const setObject = () => {
      if(summaryResult.value.result_images !== null) {
        objects.value = JSON.parse(summaryResult.value.result_images);

      }
    };

    const openPopup = () => {
      if(summarySelectObject.id !== null) {
        memoPopup.value.open = true;
      }
    }

    const setBookmark = (memo: string) => {
      memoPopup.value.open = false;
      popupContents.value.show = true;
      selectObject.value.memo = memo;
      selectObject.value.isBookmark = 1;
      
    }

    const insertBookmarkHandler = (memo: string) => {
      setBookmark(memo);
      popupContents.value.message = language.value["PLAYER"]["msg_complete_add"][locale.value]; //'객체 북마크 추가가 완료되었습니다.';
    }

    const updateBookmarkHandler = (memo: string) => {
      setBookmark(memo);
      popupContents.value.message = language.value["PLAYER"]["msg_complete_edit"][locale.value]; //'객체 북마크 수정이 완료되었습니다.';
    }

    const closePopup = () => {
      memoPopup.value.open = false;
    }
    
    const closeConfirmPopup = () => {
      popupContents.value.show = false;
      objectPopup.value = false;
    }

    const dwellVideoShow = (value:any) => {
      if(summaryResult.value.dwell_video !== null && summaryResult.value.dwell_video !== undefined){
        let dwellVideo = summaryResult.value.dwell_video;
        playDwellVideoSrc.value = dwellVideo[value];
        console.log('playDwellVideoSrc >> ', playDwellVideoSrc.value);
        summaryMenuStore.setDwellVideoPopup(true);
      }
    }

    watch(() => summaryResult.value, (newVal) => {
      if(newVal.result_images !== null) {
        setObject();
      }
      if(summaryOption.value.dwell !== null && summaryOption.value.dwell !== undefined && summaryOption.value.dwell["o"] !== undefined){
        isSecondArea.value = true;
      }
      else{
        isSecondArea.value = false;
      }
    });

    watch(() => dwellShow.value, () => {
      setDwellShowButton();
    })

    watch(() => maskingSelectObject.value.length , () => {
      showSummaryBox(currFrame.value);
    })

    const reloadDwellVideoPopup = () => {
      const summaryTabDiv = document.querySelector('.gray-fill-box.right')
      if(popup.value.dwellVideo && summaryTabDiv !==null){
        vmodelX.value = 100;
        vmodelY.value = 100;
        vmodelX.value = Number(summaryTabDiv.getBoundingClientRect().left.toFixed());
        vmodelY.value = Number(summaryTabDiv.getBoundingClientRect().top.toFixed());
        movePopup.value = {
          initWidth: 520, // 초기 사이즈 & 최소 사이즈
          initHeight: 430,
          vmodelWidth: 520,
          vmodelHeight: 430,
          vmodelX:vmodelX,
          vmodelY:vmodelY,
          vmodelActive: true,
        }
        summaryMenuStore.setDwellVideoPopup(false);
        setTimeout(() => {summaryMenuStore.setDwellVideoPopup(true);}, 10);
      }
    }

    onMounted(() => {
      setObject();
      window.addEventListener("resize", reloadDwellVideoPopup);
      window.addEventListener("resize", setDwellShowButton);
    })

    onUnmounted(() => {
      window.removeEventListener("resize", reloadDwellVideoPopup);
      window.removeEventListener("resize", setDwellShowButton);
    })

    return {
      video,
      isSeeking,
      isSummaryVideoPause,
      showSummaryBox,
      onPauseSummaryVideo,
      onSeekedSummaryVideo,
      onSeekingSummaryVideo,
      onPlaySummaryVideo,
      summaryResult,
      summaryVideoRef,
      currPage,
      API_BASE_URL,
      summaryCanvasRef,
      summaryDwellAreaCanvasRef,
      summaryCanvasClick,
      handleMounted,
      player,
      summaryBoxRef,
      summarySelectObject,
      objectPopup,
      playObjectTime,
      memoPopup,
      openPopup,
      insertBookmarkHandler,
      updateBookmarkHandler,
      closePopup,
      popupContents,
      closeConfirmPopup,
      locale,
      language,
      playDwellVideoSrc,
      movePopup,
      closeDwellPopup,
      popup,
      dwellShow,
      dwellVideoShow,
      isSecondArea
    };
  },
});
