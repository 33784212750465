
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default defineComponent({
  components:{
    VueSlider
  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const textareaRef = ref<HTMLInputElement | null>(null);
    const inputText = ref("");
    const minHeight = 3.6;
    const sliderVal = ref(0.3);
    const min = ref(0.2);
    const max = ref(0.8);
    const interval = ref(0.1);

    // textarea의 내용이 변경될 때마다 크기를 재조절
    const handleInput = () => {
      if (textareaRef.value) {
        const scrollHeight = textareaRef.value.scrollHeight;
        if (inputText.value == "") {
          textareaRef.value.style.height = minHeight + 'vh';
        } else {
          let vh = Math.floor((document.body.clientHeight / 100) * 1.8)
          textareaRef.value.style.height = Math.floor(scrollHeight / vh) * 1.8 + 'vh';
        }
      }
      conditionStore.setIr(inputText.value, sliderVal.value);
    };

    const reset = () => {
      inputText.value = "";
      sliderVal.value = 0.3;
    }

    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    watch(() => sliderVal.value, () => {
      conditionStore.setIr(inputText.value, sliderVal.value);
    })

    onMounted(() => {
      textareaRef.value = document.querySelector('.search-box');
    })

    return {
      locale,
      language,
      setOptionOpenState,
      handleInput,
      textareaRef,
      inputText,
      sliderVal,
      min,
      max,
      interval,
      reset
    }
  }
  
})
