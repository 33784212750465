import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { useUserStore } from './loginUser';
import { Item } from './comboBox';
import { useLocaleStore } from './locale';
import { getVmsInfo, vmsLogin, deleteVmsInfo, getVmsThumbnail } from '../api';
import { MSAPI } from '@/milestone-api';
import lodash from 'lodash';

export interface Vms {
  index: number,
  type: string,
  url: string,
  id: string,
  password: string,
  ip: string,
  port: string,
  /* vurix만 사용 */
  api_serial: number, 
  auth_token: string,
  user_serial: number,
  /* vurix만 사용 */
  opened: boolean,
  style: object,
  status: string,
  cameras: Camera[]
}

export interface Camera {
  type: string,
  vms_index: number,
  url: string,
  auth_token: string,
  api_serial: number, 
  user_serial: number,
  id: string,
  name: string,
  thumbnail: string,
  selected: boolean,
  login: any
}

export interface SelectedCamera {
  camera: Camera,
  vms: string,
  info: Information,
  selected: boolean,
  checked: boolean,
  split: boolean
}

export interface Information {
  startDate: Date,
  endDate: Date,
  contents: Item,
  contentsInput: string,
  place: Item,
  period: Item,
  userId: string,
  division: string,
  officeNum: string,
  memo: string,
}

export const useVmsStore = defineStore('vms', {
  state: () => ({
    vmsList: ref<Vms[]>([]),
    selectedCameras: ref<SelectedCamera[]>([]),
    selectedCamera: ref<SelectedCamera>(),
    milestoneState: 'noVMS',
    leopardState: 'disconnect',
    showVmsList: true,
    showCheckBox: false, // 저장할 CCTV 선택 버튼 클릭 시 true
    checkedCount: 0, // 저장할 CCTV 선택 버튼 클릭 후 선택한 CCTV 리스트에서 체크한 개수
    startDate: ref(),
    endDate: ref(),
    locale: useLocaleStore().getLocale,
    language: useLocaleStore().getLanguage,
    split: false
  }),
  actions: {
    // db에 저장되어 있는 vms 정보를 가져온다.
    async getVmsInfo(type: string) {
      try {
        await getVmsInfo();

        for(let i=0; i< this.vmsList.length; i++) {
          vmsLogin(this.vmsList[i], type); // vms 로그인 시도
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    // set vms camera list
    setCameras(vms: any) {
      if(this.vmsList.length > 0) {
        if(vms.index!==undefined){
          const targetVms = this.vmsList[vms.index];
          console.log(vms);
          if(vms.cameras.length > 0) {
            targetVms.cameras = vms.cameras;
          }
        }
        // vms.index가 오지 않는 경우, 맞는걸 찾아서 넣어주기
        else{
          if(vms.cameras.length > 0){
            for(let i=0;i<this.vmsList.length;i++){
              let info = vms.cameras[0];
              if(info.url === this.vmsList[i].url + ":" + this.vmsList[i].port && info.id == this.vmsList[i].id && info.password == this.vmsList[i].password){
                this.vmsList[i].cameras = vms.cameras;
              }
            }
          }
        }

      }
    },
    setThumbnail(vmsIndex: any, index: any){
      const targetVms = this.vmsList[vmsIndex];

      if(targetVms.type == "VURIX") {
        targetVms.api_serial = targetVms.cameras[0].api_serial;
        targetVms.auth_token = targetVms.cameras[0].auth_token;
        targetVms.user_serial = targetVms.cameras[0].user_serial;
      }

      let max = targetVms.cameras.length<=5?targetVms.cameras.length:6;
      if(targetVms.type == "VURIX" || targetVms.type == "DANUSYS" || targetVms.type == 'LEOPARD') {
        for(let i=0; i<max; i++) {
          if(targetVms.cameras[i+index].thumbnail == ''){
            getVmsThumbnail(targetVms.cameras[i+index]);
          }
        }
      }
      else if(targetVms.type == "MILESTONE") {
        for(let i=0; i<max; i++) {
          if(targetVms.cameras[i+index].thumbnail == ''){
            MSAPI.getCamThumbnail(targetVms.cameras[i+index].id, function(err:any, res:any){
              if(err){
                console.log(err);
              }
              else{
                targetVms.cameras[i+index].thumbnail = res;
              }
            })
          }
        }
      }
    },
    initDate() {
      const currentDate = new Date();
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);
      const oneHourAgo = ref(new Date(currentDate));
      oneHourAgo.value.setHours(currentDate.getHours() - 1);
      oneHourAgo.value = new Date(oneHourAgo.value);

      this.startDate = oneHourAgo.value;
      this.endDate = currentDate;
    },
    pushSelectedCamera(camera: Camera) { // selectedCameras에서 선택한 카메라 push
      this.selectedCameras.push({
        camera: camera,
        vms: this.vmsList[camera.vms_index].type,
        info: {
          startDate: this.startDate,
          endDate: this.endDate,
          contents: { id: false, label: this.language["COMBOBOX"]["contents_option1"][this.locale] },
          contentsInput: "",
          place: { id: '2', label: this.language["COMBOBOX"]["place_option1"][this.locale] },
          period: { id: '90', label: '3'+this.language["COMBOBOX"]["period_option2"][this.locale] },
          userId: useUserStore().getUserId,
          division: useUserStore().getDivision,
          officeNum: "",
          memo: "",
        },
        selected: false,
        checked: false,
        split: false
      });
    },
    popSelectedCamera(camera: Camera) { // selectedCameras에서 선택한 카메라 pop
      this.selectedCameras = this.selectedCameras.filter(
        item => !(camera.vms_index == item.camera.vms_index && camera.id == item.camera.id)
      );
    },
    initSelectedCameras() { // 선택한 카메라 리스트(selectedCameras) 모두 삭제
      this.selectedCameras = [];
    },
    initSelectedState() { // 선택한 카메라 리스트(selectedCameras) 모두 체크 해제
      for(let i=0; i<this.selectedCameras.length; i++) {
        this.selectedCameras[i].checked = false;
      }
    },
    toggleCamera(target: Camera) { // VMS 카메라 리스트에서 카메라 선택 해제
      const foundObject = this.vmsList.find(
        obj => obj.cameras.some(camera => camera.name === target.name)
      );

      if (foundObject) {
        const foundCamera = foundObject.cameras.find(
          camera => camera.vms_index == target.vms_index && camera.name == target.name
        );
        if (foundCamera) {
          foundCamera.selected = false;
        }
      }
    },
    async deleteVmsInfo(vms:any) { // DB에 저장되어 있는 vms 정보를 삭제
      try {
        await deleteVmsInfo(vms);
        return true;
      } catch (error) {
        console.error('Error fetching users:', error);
        return false;
      }
    },
    initVms(){
      this.vmsList = [];
    },
    setVms(vms: Vms) {
      this.vmsList.push(vms);
    },
    setMilestoneState(value: string){
      this.milestoneState = value;
    },
    setShowVmsListState(show: boolean) { // VMS 리스트 컴포넌트 > show state toggle
      this.showVmsList = show;
    },
    setSelectedCamera(target: SelectedCamera, index: number) { // 현재 선택되어 있는 카메라 (단일 선택)
      for(let i=0; i<this.selectedCameras.length; i++) {
        if(i !== index) {
          this.selectedCameras[i].selected = false;
        }
      }

      target.selected = !target.selected;

      if(target.selected) {
        this.selectedCamera = target;
      } else {
        this.initSelectedCamera();
      }
    },
    initSelectedCamera() { // 선택한 카메라 초기화
      this.selectedCamera = undefined;
    },
    setCheckBoxState(state: boolean) { // 선택한 CCTV 리스트 컴포넌트 > checkbox show state toggle
      this.showCheckBox = state;
    },
    unselectCameras() { // selectedCameras 전부 unselect
      for(let i=0; i<this.selectedCameras.length; i++) {
        this.selectedCameras[i].selected = false;
      }
    },
    setCheckedCount(count: number) {
      this.checkedCount = count;
    },
    setLeopardState(value: string){
      this.leopardState = value;
    },
    setSplitState(checked: boolean) {
      this.split = checked;
    }
  },
  getters: {
    getVmsList(state) {
      return state.vmsList;
    },
    getSelectedCameras(state) {
      return state.selectedCameras;
    },
    getSelectedCamera(state) {
      return state.selectedCamera
    },
    getMilestoneState(state){
      return state.milestoneState;
    },
    getShowVmsListState(state) {
      return state.showVmsList;
    },
    getShowCheckBoxState(state) {
      return state.showCheckBox;
    },
    getStartDate(state) {
      return state.startDate;
    },
    getEndDate(state) {
      return state.endDate;
    },
    getCheckedCount(state) {
      return state.checkedCount;
    },
    getLeopardState(state){
      return state.leopardState;
    },
    getSplitState(state) {
      return state.split;
    }
  },
});
