import axios from 'axios';
import { computed, ref } from 'vue';
import { useUserStore } from './store/loginUser';
import { useFilesStore } from './store/files';
import { useVmsStore, Vms, Camera } from './store/vms';
import { useProgressStore, ProgressFile } from './store/progress';
import { useVmsAddStore } from './store/vmsAdd';
import { useSummaryStore } from './store/summary';
import { useLibraryVideosStore } from './store/libraryVideo';
import { useMaskingStore } from './store/masking';
import { MSAPI } from '@/milestone-api';
import moment from 'moment';

export const API_BASE_URL = '';
// export const API_BASE_URL = 'http://10.10.21.51:16656';

/**
 * 언어 데이터를 가져온다.
 * @returns {string} 언어 데이터
 */
export async function getLanguageData() {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/language`, {
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 서버 상태와 용량 정보를 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 */
export async function getServerInfo() {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/server`, {
      headers: {
        "x-auth-token": token,
      },
      params: {
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 로그인 시 사용자 정보를 가져온다.
 * @param {string} inputId 입력한 id
 * @param {string} inputPassword 입력한 pw
 * @returns {string} 사용자 정보
 */
export async function getUser(inputId: string, inputPassword: string) {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/user`, {
      headers: {
        "x-account-pw": inputPassword
      },
      params:{
        "x-account-id": inputId
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 모든 사용자 정보를 가져온다.
 * @returns {string} 사용자 정보
 */
export async function getUsers() {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/users`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 회원가입 기능.
 * @param {string} accountInfo 가입할 사용자 정보
 */
export async function createAccount(accountInfo:any) {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/insert/user`, {
      id: accountInfo.id,
      password: accountInfo.password,
      name: accountInfo.name,
      division: accountInfo.division,
      role: 'user',
    }, {
      headers: {
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}


/**
 * 특정 비디오 정보를 가져온다.
 * @param {string} videoId 가져올 비디오 아이디
 * @returns {string} 비디오 정보
 */
export async function getVideoEach(videoId: string) {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/video/each`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "videoId":videoId,
      }
    });
    return response.data[0];
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}
/**
 * 특정 요청(request) 정보를 가져온다.
 * @param {string} serial 가져올 요청 아이디
 * @returns {string} 요청 정보
 */
export async function getRequestStatusEach(serial:string) {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/request/each`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "serial":serial,
      }

    });
    return response.data[0];
  } catch (error) {
    console.error('Error fetching request:', error);
    return [];
  }
}

/**
 * GD 요청 보내기.
 * @param {string} videoId 영상 id
 * @param {string} userId 사용자 id
 */
export async function getDataRequestInsert() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const user = useUserStore().getUserInfo;
  if(video !==undefined && user !== null){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/request/insert/get-data`, {
        videoId: video.videoId,
        userId: user.id
      }, {
        headers: {
          "x-auth-token": token,
        },
      });
      
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}

/**
 * default summary 요청 보내기.
 * @param {string} condition 요약 옵션
 * @param {string} timestamp 타임스탬프
 * @param {string} beginDate 영상 시작 날짜
 * @param {string} videoId 영상 id
 * @param {string} userId 사용자 id
 */
export async function summaryRequest() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const user = useUserStore().getUserInfo;
  const condition = useSummaryStore().getSummaryOption;
  const timestamp = useSummaryStore().getTimestamp;

  if(video !==undefined && user !== null){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/request/insert/summary`, {
        condition:JSON.stringify(condition),
        timestamp:timestamp,
        beginDate: moment(video.beginDate).format('YYYY-MM-DD HH:mm'),
        videoId:video.videoId,
        userId:user.id
      }, {
        headers: {
          "x-auth-token": token,
        },
      });
      
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}

/**
 * 텍스트검색 요청 보내기.
 * @param {string} condition 요약 옵션
 * @param {string} timestamp 타임스탬프
 * @param {string} beginDate 영상 시작 날짜
 * @param {string} videoId 영상 id
 * @param {string} userId 사용자 id
 */
export async function IRRequest() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const user = useUserStore().getUserInfo;
  const condition = useSummaryStore().getSummaryOption;
  const timestamp = useSummaryStore().getTimestamp;

  if(video !==undefined && user !== null){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/request/insert/ir`, {
        condition:JSON.stringify(condition),
        timestamp:timestamp,
        beginDate: moment(video.beginDate).format('YYYY-MM-DD HH:mm'),
        videoId:video.videoId,
        userId:user.id
      }, {
        headers: {
          "x-auth-token": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}

/**
 * 안면인식 이미지 업로드.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} id 사용자 id
 */
export async function FaceUpload(uploadFile: any) {
  const token = useUserStore().getUserToken;
  const id = useUserStore().getUserId;
  const formData = new FormData();
  formData.append("faceFile", uploadFile.file);

  try {
    const response = await axios.post(`${API_BASE_URL}/api/upload/face`, formData, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        "x-account-id": id,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    return error;
  }
}

/**
 * 안면인식 요청 보내기.
 * @param {string} condition 요약 옵션
 * @param {string} timestamp 타임스탬프
 * @param {string} beginDate 영상 시작 날짜
 * @param {string} videoId 영상 id
 * @param {string} userId 사용자 id
 */
export async function FaceRequest() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const user = useUserStore().getUserInfo;
  const condition = useSummaryStore().getSummaryOption;
  const timestamp = useSummaryStore().getTimestamp;
  // console.log(condition);
  if(video !==undefined && user !== null){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/request/insert/face`, {
        condition:JSON.stringify(condition),
        timestamp:timestamp,
        beginDate: moment(video.beginDate).format('YYYY-MM-DD HH:mm'),
        videoId:video.videoId,
        userId:user.id
      }, {
        headers: {
          "x-auth-token": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}

/**
 * 마스킹 시 객체 출현시간 가져오기
 */
export async function ObjectRequest() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const objects = useMaskingStore().getMaskingSelectObject;
  // console.log(condition);
  if(video !==undefined){
    try {
      const response = await axios.get(`${API_BASE_URL}/api/request/time/obj`, {
        headers: {
          "x-auth-token": token,
        },
        params:{
          objects:objects,
          videoId:video.videoId,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}

/**
 * 마스킹 요청 보내기.
 * @param {string} condition 요약 옵션
 * @param {string} timestamp 타임스탬프 // 필요없어서 빼도 됨
 * @param {string} beginDate 영상 시작 날짜
 * @param {string} videoId 영상 id
 * @param {string} userId 사용자 id
 */
export async function maskingRequest() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const user = useUserStore().getUserInfo;
  const condition = useMaskingStore().getMaskingOption;

  if(video !==undefined && user !== null){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/request/insert/masking`, {
        condition:JSON.stringify(condition),
        timestamp:1,
        beginDate: moment(video.beginDate).format('YYYY-MM-DD HH:mm'),
        videoId:video.videoId,
        userId:user.id
      }, {
        headers: {
          "x-auth-token": token,
        },
      });
      
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}

/**
 * 마스킹 영상 다운로드
 * @param {string} videoId 영상 id
 * @param {string} maskingId 마스킹 id
 */
export async function maskingDownload() {
  const token = useUserStore().getUserToken;
  const video = useSummaryStore().getVideo;
  const masking = useMaskingStore().getMaskingInfo;

  if(video !==undefined){
    try {
      const response = await axios.get(`${API_BASE_URL}/api/masking/download`, {
        headers: {
          "x-auth-token": token,
        },
        params:{
          "videoId":video.videoId,
          "maskingId":masking.maskingId
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
}


/**
 * 영상 업로드 시 사용되는 설정값을 가져온다.
 * @returns gsm_accept table의 설정값
 */
export async function getUploadOptions() {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/option`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * accept 값 업데이트
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} type 업데이트 할 accept 이름
 * @param {string} value 업데이트 할 accept 값
 */
export async function updateAccept(type:string, value:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/option/accept`, {
      type: type,
      value: value
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}


/**
 * gsm_accept table의 allAllow(업로드된 영상 자동 승인 여부) 값을 업데이트 한다.
 */
export async function setAllAllow(isAllow: number) {
  const token = useUserStore().getUserToken;

  try {
    await axios.post(`${API_BASE_URL}/api/update/option/allow`, {
    // const response = await axios.post(`${API_BASE_URL}/api/update/option/allow`, {
      "isAllow": isAllow
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 사용자가 업로드한 비디오 파일을 서버와 DB에 저장한다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} id 사용자 id
 * @param {string} lastModified 파일이 마지막으로 수정된 날짜
 */
export async function uploadVideoFile(video: ProgressFile) {
  const token = useUserStore().getUserToken;
  const id = useUserStore().getUserId;
  // const address = (document.location.href).split(':16629')[0];
  const { protocol, hostname, port, pathname, search, hash } = window.location;

  // 포트가 없을 때 기본 포트 설정 (http: 80, https: 443)
  const currentPort = port || (protocol === 'https:' ? '443' : '80');
  
  // 포트를 숫자로 변환하고 +1 하기
  const newPort = parseInt(currentPort, 10) + 1;
  const address = `${protocol}//${hostname}`;
  
  const formData = new FormData();
  const file = video.file;
  formData.append("file", file.file);

  const source = axios.CancelToken.source();
  video.cancelToken = source;

  useProgressStore().setUploadState(true);
  try {
    video.inProgress = true;
    if(file.file.size<1024*1024*1024*2){
      await axios.post(`${API_BASE_URL}/api/upload/video`, formData, {
        // const response = await axios.post(`${API_BASE_URL}/api/upload/video`, formData, {
          headers: {
            "x-auth-token": token,
          },
          params: {
            "x-account-id": id,
            "data": file.data,
            "info": file.info,
          },
          cancelToken: source.token,
          onUploadProgress: (ProgressEvent) => {
            video.percent = Number(((ProgressEvent.loaded*100) / Number(ProgressEvent.total)).toFixed());
          }
        });
        video.completed = true;
        useProgressStore().checkUploadState();
        useLibraryVideosStore().getLibraryVideoList();
        return true;
    }
    else{
      console.log('big !!!! ');
      const json = JSON.stringify({
        "x-account-id": id,
        "data": file.data,
        "info": file.info,
      })
      formData.append('json', json);
      // await axios.post(`http://10.10.20.201:46630/api/upload/video`, formData, {
      await axios.post(`${address}:${newPort}/api/upload/video`, formData, { // 대용량 파일 업로드 테스트 시 주석 해제
        headers: {
          "x-auth-token": token,
        },
        cancelToken: source.token,
        onUploadProgress: (ProgressEvent) => {
          useProgressStore().setUploadState(true);
          video.percent = Number(((ProgressEvent.loaded*100) / Number(ProgressEvent.total)).toFixed());
        }
      });
      video.completed = true;
      useProgressStore().checkUploadState();
      useLibraryVideosStore().getLibraryVideoList();
      return true;

    }
    
  } catch (error) {
    if(axios.isCancel(error)){
      video.res = {state: false, msg: "file_cancel"};
    }
    console.error('Error fetching posts:', error);
    video.completed = true;
    useProgressStore().checkUploadState();
    return false;
  }
}

/**
 * 업로드된 파일의 정보를 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} videoId 가져올 파일의 video id
 */
export async function getVideoFile(videoId: string) {
  const token = useUserStore().getUserToken;
  const filesStore = useFilesStore();

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/file-info`, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        videoId: videoId
      }
    });
    filesStore.setFile(response.data[0]);
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 사용자가 업로드한 비디오 파일의 정보를 수정한다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} idx 수정할 파일의 인덱스
 */
export async function updateVideoFile(idx: number) {
  const token = useUserStore().getUserToken;
  const filesStore = useFilesStore();
  const file = filesStore.getFiles[idx];

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/file-info`, file, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        file: file
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}
/**
 * vms api server connect check
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} type VMS 종류 (emstone, vurix ..)
 */
export async function vmsConnectCheck(vms:any, type:any) {
  const token = useUserStore().getUserToken;

  try{
    await axios.get(`${API_BASE_URL}/api/vms/${vms.type}/checkConnect`, {
      headers: {
        "x-auth-token": token,
      },
      params: {
      },
      timeout:10000
    })

    return true;
  }
  catch (err) {
    return false;
  }
}

/**
 * vms 데이터 입력 전 로그인 시도
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} type VMS 종류 (emstone, vurix ..)
 * @param {string} id VMS id
 * @param {string} password VMS password
 * @param {string} url VMS 접속 url
 */
export async function vmsAccess() {
  const token = useUserStore().getUserToken;
  const vms = useVmsAddStore().getVmsAddInfo;

  let url = "";

  if(Number(vms.port) === 0){
    url = vms.ip;
  }
  else{
    url = 'http://' + vms.ip + ':' + vms.port;
  }

  if(vms.type === "LEOPARD"){
    try {
      const response = await axios.get(`${API_BASE_URL}/api/vms/${vms.type}/login`, {
        headers: {
          "x-auth-token": token,
          "x-account-id": vms.id,
          "x-account-pw": vms.password,
        },
        params: {
          ip: vms.ip,
          port: vms.port,
        }
      });

      return response;
    } catch (error) {
      console.error('access api error:', error);
      throw error;
    }
  }
  else if(vms.type !== "MILESTONE"){
    try {
      const response = await axios.get(`${API_BASE_URL}/api/vms/${vms.type}/login`, {
        headers: {
          "x-auth-token": token,
          "x-account-id": vms.id,
          "x-account-pw": vms.password,
        },
        params: {
          url: url,
        }
      });
      // const res = response.data.res;

      return response.data;
    } catch (error) {
      console.error('access vms access api error:', error);
      throw error;
      // return error;
    }
  }
  else{
    try {
      MSAPI.login(vms.ip, vms.port, vms.id, vms.password, function(err:any, res:any){
        if(err){
          console.log(err);
        }
        else{
          return res;
        }
      })
    } catch (error) {
      console.error('access api error:', error);
      throw error;
    }
    
  }
  
}

/**
 * 새로운 vms 정보를 추가한다.
 * @param {string} token 로그인 시 생성된 토큰
 */
export async function insertVmsInfo() {
  const token = useUserStore().getUserToken;
  const vmsAddStore = useVmsAddStore();
  const vms = vmsAddStore.getVmsAddInfo;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/insert/vms-info`, {
      type: vms.type,
      id: vms.id,
      password: vms.password,
      ip: vms.ip,
      port: vms.port,
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}

/**
 * vms 정보를 가져온다.
 */
export async function getVmsInfo() {
  const token = useUserStore().getUserToken;
  const vmsStore = useVmsStore();
  useVmsStore().setLeopardState('loading');

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/vms-info`, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    vmsStore.initVms();
    const res = response.data;

    for(let i=0; i<res.length; i++) {
      let url = "";
      if(res[i].port === 0){
        url = res[i].ip;
      }
      else{
        url = 'http://' + res[i].ip + ':' + res[i].port;
      }
      
      vmsStore.setVms({
        index: i,
        type: res[i].kind,
        url: url,
        id: res[i].id,
        password: res[i].password,
        ip: res[i].ip,
        port: res[i].port,
        api_serial: 0, 
        auth_token: "",
        user_serial: 0,
        opened: false,
        style: {},
        cameras: [],
        status:'wait'
      });
    }
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * vms 로그인을 시도한다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} type api 호출 페이지 정보 (user - 사용자 페이지, admin- 관리자 페이지)
 * @param {string} id VMS id
 * @param {string} password VMS password
 * @param {string} url VMS 접속 url
 */
export async function vmsLogin(vms: Vms, type: string) {
  const token = useUserStore().getUserToken;
  const vmsStore = useVmsStore();

  if(vms.type === "LEOPARD"){
    vms.status = 'loading'
    // useVmsStore().setLeopardState('loading');
    const check = await vmsConnectCheck(vms, 'access');
    if(check){
      try {
        const response = await axios.get(`${API_BASE_URL}/api/vms/${vms.type}/login`, {
          headers: {
            "x-auth-token": token,
            "x-account-id": vms.id,
            "x-account-pw": vms.password,
          },
          params: {
            index: vms.index,
            url: vms.url,
            ip: vms.ip,
            port: vms.port,
            usePage: type
          },
        });

        if(response.status === 200){
          vms.status = 'login'
          if(type === 'user'){
            vmsStore.setCameras(response.data);
          }
        }
        else if(response.status === 203){
          vms.status = 'loginError'
          useVmsStore().setLeopardState('loginError');
        }
        else {
          vms.status = 'download'
          useVmsStore().setLeopardState('download');
        }
      } catch (error) {
        console.log('leopard login err >>', error);
        console.error('Error fetching posts:', error);
      }
    }
    else {
      console.error('Error fetching posts:', check);
      vms.status = 'download'
      useVmsStore().setLeopardState('download');
      // api 서버 다운로드
    }
    
  }
  else if(vms.type == "MILESTONE"){
    vms.status = 'loading';
    try {
      MSAPI.login(vms.ip, vms.port, vms.id, vms.password, function(err:any, res:any){
        if(err){
          vms.status = 'error';
        }
        if(res) {
          const loginInfo = {
            vmsIndex: vms.index,
            url: 'http://' + vms.ip + ':' + vms.port,
            accountId: vms.id,
            accountPw: vms.password,
            auth_token: "",
            api_serial: "", 
            user_serial: "",
            address: vms.ip,
            port: vms.port
          }
          if(type === 'user'){
            MSAPI.getCameraList(loginInfo, function(err:any, res:any){
              if(err){
                console.log(err);
                vms.status = 'error';
              }
              else{
                vmsStore.setCameras({index: loginInfo.vmsIndex, type:"MILESTONE", cameras:res});
                vms.status = 'success';
  
              }
            })
          }
          else {
            vms.status = 'success';
          }
        }
      })
    } catch (error) {
      console.error('access api error:', error);
      vms.status = 'error';
    }
  } 
  else{
    try {
      vms.status = 'loading';
      const response = await axios.get(`${API_BASE_URL}/api/vms/${vms.type}/login`, {
        headers: {
          "x-auth-token": token,
          "x-account-id": vms.id,
          "x-account-pw": vms.password,
        },
        params: {
          index: vms.index,
          url: vms.url,
          usePage: type
        },
        timeout: 5000
      });
      vms.status = 'success';
      if(type === 'user'){
        vmsStore.setCameras(response.data);
      }
    } catch (error) {
      vms.status = 'error';
      console.error('Error fetching posts:', error);
    }
  }
  // }
}

/**
 * vms 카메라 리스트를 불러온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} type VMS 종류 (emstone, vurix ..)
 * @param {string} url VMS 접속 url
 * @param {string} id VMS id
 * @param {string} password VMS password
 * @param {number} apiSerial vurix 로그인 정보 중 api-serial 값
 * @param {string} vurixToken vurix 로그인 정보 중 auth_token 값
 * @param {number} userSerial vurix 로그인 정보 중 user_serial 값
 */
// export async function getVmsCameraList(idx: number) {
//   const token = useUserStore().getUserToken;
//   const vmsStore = useVmsStore();
//   const camerasStore = useCamerasStore();
//   const vms = (vmsStore.getVmsList)[idx];

//   if(vms.type !== 'MILESTONE'){
//     try {
//       const response = await axios.get(`${API_BASE_URL}/api/vms/${vms.type}/cameras`, {
//         headers: {
//           "x-auth-token": token,
//           "x-account-id": vms.id,
//           "x-account-pw": vms.password,
//           "api-serial": vms.apiSerial,
//           "auth_token": vms.vurixToken,
//           "user-serial": vms.userSerial
//         },
//         params: {
//           url: vms.url,
//         }
//       });
//       for(let i=0; i<response.data.length; i++) {
//         camerasStore.setCameras(vms, response.data[i]);
//       }
//     } catch (error) {
//       console.error('Error fetching posts:', error);
//     }
//   } 
//   else {
//     try {
//       MSAPI.getCameraList(function(err:any, res:any){
//         if(err){
//           console.log(err);
//           vmsStore.setMilestoneState('error');
//         }
//         else{
//           // console.log('milestone camera >> ', res);
//           for(let i=0; i<res.length; i++) {
//             MSAPI.getCamThumbnail(res[i].id, function(err:any, res2:any){
//               if(err){
//                 console.log(err);
//                 vmsStore.setMilestoneState('error');
//               }
//               else{
//                 const log = {id:res[i].id, name:res[i].name, thumbnail:res2};
//                 camerasStore.setCameras(vms, log);
//                 // console.log(i, res.length, '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
//                 if(i == res.length - 1){
//                   vms.connected = true;
//                   vmsStore.setMilestoneState('success');
//                 }
//               }
//             })
//           }
//         }
//       })
//     } catch (error) {
//       console.error('Error fetching posts:', error);
//       vmsStore.setMilestoneState('error');
//     }
//   }
// }

/**
 * [vurix/danusys/leopard] vms 카메라 썸네일을 불러온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} type VMS 종류
 * @param {string} id VMS id
 * @param {string} password VMS password
 * @param {string} url VMS 접속 url
 * @param {string} cameraId VMS 카메라 id
 */
export async function getVmsThumbnail(camera: Camera) {
  const token = useUserStore().getUserToken;

  try { 
    const response = await axios.get(`${API_BASE_URL}/api/vms/${camera.type}/thumbnail`, {
      headers: {
        "x-auth-token": token,
        "api-serial": camera.api_serial, // vurix만 사용
        "auth-token": camera.auth_token, // vurix만 사용
      },
      params: {
        "url": camera.url,
        "camera-id": camera.id,
      },
      responseType: "blob"
    });

    const imageBlob = new Blob([response.data], { type: 'image/jpeg' });
    const imageUrl = URL.createObjectURL(imageBlob);

    camera.thumbnail = imageUrl;

  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 등록되어 있는 vms 정보를 삭제한다.
 * @param {string} token 로그인 시 생성된 토큰
 */
export async function deleteVmsInfo(vms: any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/vms-info`, {
      vms:vms
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}
/**
 * vms 업로드 취소
 * @param {string} token 로그인 시 생성된 토큰
 */
export async function cancelVmsUpload(videoId: string) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/download/vms/cancel`, {}, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        videoId: videoId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * vms에서 선택한 카메라의 영상을 받아온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} selectedCameraList 선택된 카메라 리스트
 */
export async function downloadVmsVideo(file: ProgressFile) {
  const token = useUserStore().getUserToken;
  const tokentest = computed(() => useUserStore().getUserToken);
  const target = file.camera;
  try {
    file.inProgress = true;
    useProgressStore().setUploadState(true);

    if(target !== undefined && tokentest.value !== '') {
      if(target.camera.type !== 'MILESTONE'){
        const interval = setInterval(() => {
          getVideoEach(file.videoId)
          .then((data) => {
            if(data.status == "WEBWAIT") {
              // useProgressStore().setUploadState(true);
              file.percent = data.status_pct;
            }
            else {
              clearInterval(interval);
              file.percent = 100;
              file.completed = true;
              // setTimeout(() => {file.completed = true}, 1000);
              if(tokentest.value !== ''){
                useLibraryVideosStore().getLibraryVideoList();
              }
            }
          })
          .catch((error) => {
            console.log('error', error)
            clearInterval(interval);
          });
        }, 1000);

        const response = await axios.post(`${API_BASE_URL}/api/download/vms/video`, {
          camera: target,
          videoId: file.videoId
        }, {
          headers: {
            "x-auth-token": token,
          },
          // timeout: 50000
        });
    
        const res = response.data;
        if(!res.state) {
          file.res = res;
          file.completed = true;
          file.percent = 100;
        }
      }
      else {
        // file.inProgress = true;
        await useProgressStore().milestoneDownloadFunc(file);
        // await milestoneDownload(file);
      }   
    }
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 다운받은 마일스톤 파일을 서버에 저장 
 * @param {any} file 현재 업로드중인 카메라 정보
 * @param {any} start 해당 파일의 시작시간
 * @param {any} uploadFile 다운로드 받은 파일
 */
export async function milestoneVideoUpload(file:any, start:any, uploadFile:any, index:any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  const uploadFileData = new FormData();
  uploadFileData.append('videoFile', uploadFile);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/upload/milestone/video`, uploadFileData, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        "x-account-id": userId,
        "req_date":moment(new Date(start)).format('YYYYMMDDhhmm'),
        "start":start,
        "video_id":file.videoId,
        "index":index
      },
    });
    return response.data;
    
  } catch (error) {
    console.error('Error fetching posts:', error);
    file.res = {state:false, msg:'save'};
    file.completed = true;
    return false;
  }
}


/**
 * DB에 milestone 업로드 값 입력
 * @param {any} file 현재 업로드중인 카메라 정보
 * @param {any} upload 업로드 경로 + range
 */
export async function milestonVideoInsert(file:any, upload:any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/milestone/video/insert`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "x-account-id": userId,
        "camera":file.camera,
        "videoId":file.videoId,
        "upload":upload
      }
    });
    file.completed = true;
    // useProgressStore().setUploadState(false);
    useLibraryVideosStore().getLibraryVideoList();
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    file.res = {state:false, msg:'save'};
    file.completed = true;
    return [];
  }
}

/**
 * DB에 milestone 업로드 값 입력
 * @param {any} file 현재 업로드중인 카메라 정보
 * @param {any} upload 업로드 경로 + range
 */
export async function milestonFailVideoInsert(file:any, upload:any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/milestone/failvideo/insert`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "x-account-id": userId,
        "camera":file.camera,
        "videoId":file.videoId,
        "upload":upload
      }
    });
    file.completed = true;
    file.res = {state:false, msg:'vms_cancel'};
    // useProgressStore().setUploadState(false);
    useLibraryVideosStore().getLibraryVideoList();
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    file.res = {state:false, msg:'save'};
    file.completed = true;
    return [];
  }
}
/**
 * 라이브러리의 비디오 목록을 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 유저 id
 */
export async function getLibraryVideoList() {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/library`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "x-account-id": userId,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 라이브러리의 비디오 중 분석이 완료되지 않은 영상의 정보를 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} videoId 가져올 비디오 id
 */
export async function videoStatusEach(videoId: string) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/video/status/each`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "videoId": videoId,
      }
    });
    return response.data[0];
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
}

/**
 * 라이브러리의 공유된 비디오 목록을 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 유저 id
 */
export async function getSharedVideoList() {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/shared`, {
      headers: {
        "x-auth-token": token,
      },
      params:{
        "x-account-id": userId,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 라이브러리 비디오의 공유 상태를 변경한다.
 */
export async function shareVideo(state: number) {
  const token = useUserStore().getUserToken;
  const videos = useLibraryVideosStore().getVideoList;
  const target = ref<any>([]);

  for(let i=0; i<videos.length; i++) {
    const video = videos[i];
    if(video.checked) {
      target.value.push(video);
    }
  }
  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/share`, {
      state: state,
      target: target.value
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 라이브러리 비디오를 삭제한다.
 */
export async function deleteVideo() {
  const token = useUserStore().getUserToken;
  const videos = useLibraryVideosStore().getVideoList;
  const target = ref<any>([]);

  for(let i=0; i<videos.length; i++) {
    const video = videos[i];
    if(video.checked) {
      target.value.push(video);
    }
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/video`, {
      // videoId: video.videoId
      target: target.value
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 라이브러리에 있는 비디오의 메모를 저장한다.
 * @param {string} videoId 객체가 등장한 비디오 id
 * @param {string} memo 메모
 */
export async function updateVideoMemo(videoId: string, memo: string) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/memo`, {
      videoId: videoId,
      memo: memo
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 해당되는 비디오의 북마크 정보를 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} videoId 가져올 파일의 video id
 */
export async function getBookmark(videoId: string) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/bookmark`, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        videoId: videoId
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 사용자 별 객체 북마크 정보를 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 사용자 id
 */
export async function getObjBookmark() {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/bookmark/obj`, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        userId: userId
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 객체 북마크를 추가한다.
 * @param {object} object 북마크 등록할 객체의 정보
 * @param {string} videoId 객체가 등장한 비디오 id
 * @param {string} userId 사용자 id
 */
export async function insertBookmark(videoId: string, object: any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/insert/bookmark`, {
      object: object,
      videoId: videoId,
      userId: userId
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    updateObjBookmark(videoId, object.id, 1);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 북마크된 객체의 메모를 수정한다.
 * @param {object} object 북마크 등록할 객체의 정보
 * @param {string} videoId 객체가 등장한 비디오 id
 * @param {string} userId 사용자 id
 */
export async function updateBookmark(videoId: string, object: any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/bookmark`, {
      object: object,
      videoId: videoId,
      userId: userId
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 북마크된 객체를 삭제한다.
 * @param {object} object 북마크 등록할 객체의 정보
 * @param {string} videoId 객체가 등장한 비디오 id
 * @param {string} userId 사용자 id
 */
export async function deleteBookmark(videoId: string, objectId: number) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/bookmark`, {
      videoId: videoId,
      objectId: objectId,
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    updateObjBookmark(videoId, objectId, 0);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * Obj DB에 저장된 객체 북마크 상태를 변경한다.
 * @param {string} videoId 객체가 등장한 비디오 id
 * @param {string} userId 사용자 id
 * @param {number} bookmark 북마크 여부 (0 or 1)
 */
export async function updateObjBookmark(videoId: string, objectId: any, bookmark: number) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/object/bookmark`, {
      objectId: objectId,
      videoId: videoId,
      bookmark: bookmark
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 저장된 플레이리스트를 가져온다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 사용자 ID
 */
export async function getPlaylist() {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/playlist`, {
      headers: {
        "x-auth-token": token,
      },
      params: {
        "x-account-id": userId
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 플레이리스트를 생성한다.
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 사용자 ID
 * @param {string} name 플레이리스트 이름
 * @param {array} videos 비디오 배열
 */
export async function playlistInsert(name: string, videos: any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/insert/playlist`, {
      userId: userId,
      name: name,
      videos: videos
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 플레이리스트 이름 변경
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 사용자 ID
 * @param {string} videoId 영상 ID
 * @param {string} name 플레이리스트 이름
 */
export async function updatePlaylistName(beforeName: string, afterName:string) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/playlist/name`, {
      userId: userId,
      beforeName: beforeName,
      afterName: afterName
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 플레이리스트 삭제
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 사용자 ID
 * @param {string} name 플레이리스트 이름
 */
export async function deletePlaylist(name:string) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/playlist`, {
      userId: userId,
      name: name
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 플레이리스트에서 특정 영상 삭제
 * @param {string} token 로그인 시 생성된 토큰
 * @param {string} userId 사용자 ID
 * @param {string} name 플레이리스트 이름
 * @param {string} videoId 삭제할 비디오 아이디 배열
 */
export async function deletePlaylistVideo(name:string, videos: any) {
  const token = useUserStore().getUserToken;
  const userId = useUserStore().getUserId;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/playlist/video`, {
      userId: userId,
      name: name,
      videoId: videos
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}


/*summary change*/
/**
 * 로그 데이터를 가져온다.
 * @returns {string} 사용자 정보
 */
export async function getLogs() {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/logs`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 사용자 정보 업데이트.
 * @param {string} editInfo 수정할 내용
 */
export async function updateAccount(editInfo:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/user/info`, {
      id: editInfo.id,
      password: editInfo.editPassword,
      name: editInfo.name,
      division: editInfo.division,
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 사용자 정보 삭제.
 * @param {string} deleteUsers 수정할 내용
 */
export async function deleteAccount(deleteUsers:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/user`, {
      userId: deleteUsers
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 특정 사용자의 모든 영상 지우기.
 * @param {string} deleteUsersVideo 수정할 내용
 */
export async function deleteAccountVideo(deleteUsers:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/user/video`, {
      userId: deleteUsers
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * 모든 비디오 정보를 가져온다.
 * @returns {string} 사용자 정보
 */
export async function getVideos() {
  const token = useUserStore().getUserToken;
  try {
    const response = await axios.get(`${API_BASE_URL}/api/get/videos`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * 입력받은 비디오를 삭제한다. - 관리자 페이지
 */
export async function deleteSelectVideo(videos:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/video`, {
      target: videos
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}

/**
 * 입력받은 비디오의 승인 여부를 수정한다. - 관리자 페이지
 */
export async function updateVideosAllow(videos:any, isAllow:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/video/allow`, {
      videos: videos,
      isAllow: isAllow
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}

/**
 * 영상 파일 정보 수정
 * @param {string} token 로그인 시 생성된 토큰
 * @param {object} info 수정할 영상 정보
 */
export async function updateVideoInfo(info: any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/update/video/info`, {
      info: info
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}

/**
 * gsm_request 자동 삭제 기능 
 */
export async function autoDeleteRequest(currDate:any) {
  const token = useUserStore().getUserToken;

  try {
    const response = await axios.post(`${API_BASE_URL}/api/delete/request`, {
      currDate: currDate,
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}