
import { defineComponent } from 'vue';
import NavBar from "../components/Common/NavBar.vue";

export default defineComponent({
  components: {
    NavBar
  },
  setup() {

    return {
    };
  },
});
