
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useSummaryStore } from '../../store/summary';
import moment from 'moment';
import { API_BASE_URL } from '@/api';
import { usePageStore } from '../../store/pageNumber';
import { useLibrarySortStore } from '@/store/librarySort';
import PageNumber from '../../components/Common/PageNumber.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import VideoMemoModule from '@/components/Popup/VideoMemoModule.vue';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    PageNumber,
    PopupModule,
    VideoMemoModule
  },
  setup() {
    const summaryStore = useSummaryStore();
    const pageStore = usePageStore();
    const librarySortStore = useLibrarySortStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const currNumber = computed(() => pageStore.getCurrNumber);
    const objects = computed(() => summaryStore.getBookmark);
    const pageList = ref<any>([]);
    const target = ref<any>();
    const searchObjects = ref<any>([]);
    const activeCheckbox = ref(false);
    const selectedCount = ref(0);
    const popupContents = ref({ message: '', type: '', show: false });

    const searchText = computed(() => librarySortStore.getSearchText);
    const selectedSearch = computed(() => librarySortStore.getSelectedSearch);

    // 검색 및 필터 함수
    const searchEvt = () => {
      searchObjects.value= [];
      pageStore.setCurrNumber(1);
      uncheckHandler();
      
      for(let i=0;i<objects.value.length;i++){
        let log = objects.value[i];

        let flag = true;
        // 검색
        if(searchText.value !== ''){
          switch(selectedSearch.value.id){
            case 'title':
              if(log.original_name.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
            case 'memo':
              if(log.bookmarkMemo.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
          }
        }
        
        if(flag){
          searchObjects.value.push(log);
        }
      }
      pageStore.setTotalPage(searchObjects.value.length, 12);
      getCurrPage();
    }

    const getCurrPage = () => {
      pageList.value = [];
      for (let i = (currNumber.value - 1) * 12; i < Math.min((currNumber.value) * 12, searchObjects.value.length); i++) {
        let log = searchObjects.value[i];
        log.beginTime = new Date(log.beginTime);
        pageList.value.push(log);
      }
    }

    const checkHandler = (obj: any) => {
      obj.checked = !obj.checked;
      activeCheckbox.value = false;
      selectedCount.value = 0;

      for (let i = 0; i < objects.value.length; i++) {
        if(objects.value[i]['checked']) {
          activeCheckbox.value = true;
          selectedCount.value++;
        }
      }
    }

    const uncheckHandler = () => {
      for (let i = 0; i < objects.value.length; i++) {
        objects.value[i]['checked'] = false;
      }
      activeCheckbox.value = false;
      selectedCount.value = 0;
    }

    const setAppearance = (frame: number) => {
      summaryStore.setAppearance(frame);
    }

    const confirmHandler = async (): Promise<void> => {
      const popup = popupContents.value;
      const success = ref(true);
      popup.message = language.value["LIBRARY"]["msg_doing"][locale.value]; // 작업 진행 중입니다.
      popup.type = 'spin';

      for (let i = 0; i < objects.value.length; i++) {
        const obj = objects.value[i];
        if(objects.value[i].checked) {
          const res = await summaryStore.deleteBookmark(obj.video_id, obj.id);
          success.value = success.value&&res;
        }
      }

      if(success.value) {
        popup.message = language.value["LIBRARY"]["msg_success"][locale.value]; // 완료되었습니다.
        popup.type = 'success';
      } else {
        popup.message = language.value["LIBRARY"]["msg_doing_error"][locale.value]; // 작업 중 에러가 발생했습니다.
        popup.type = 'error';
      }
    }

    const cancelHandler = () => {
      popupContents.value.show = false;
    }

    const completeHandler = () => {
      popupContents.value.show = false;
      summaryStore.getObjBookmarkList();
      activeCheckbox.value = false;
    }

    const deleteBookmark = () => {
      const popup = popupContents.value;
      popup.message = language.value["LIBRARY"]["msg_delete_bookmark"][locale.value]; // 선택한 객체를 북마크 해제하시겠습니까?
      popup.type = 'warning';
      popupContents.value.show = true;
    }

    const memoPopup = ref({ contents: '', open: false });

    const editMemo = (obj: any) => {
      const memo = memoPopup.value;
      memo.contents = obj.bookmarkMemo;
      memo.open = true;
      target.value = obj;

      // const memo = memoPopup.value;
      // memo.contents = video.memo;
      // memo.open = true;
    }

    const saveMemo = async (memo: any): Promise<void> => {
      const popup = popupContents.value;

      const obj = {
        memo: memo,
        id: target.value.id
      }

      const res = await summaryStore.editBookmark(target.value.video_id, obj);

      if(res) {
        memoPopup.value.open = false;
        popup.message = language.value["LIBRARY"]["msg_save_memo"][locale.value]; // '메모가 저장되었습니다.';
        popup.type = 'success';
        popup.show = true;
      }
    }

    const closeHandler = () => {
      memoPopup.value.open = false;
    }

    watch(() => objects.value, () => {
      // pageStore.setTotalPage(objects.value.length, 12);
      searchEvt();
    });

    watch(() => currNumber.value, () => {
      getCurrPage();
      uncheckHandler();
    });
    watch(() => searchText.value, () => {
      searchEvt();
    })
    watch(() => selectedSearch.value, () => {
      searchEvt();
    })

    onMounted(() => {
      summaryStore.getObjBookmarkList();
    });
   
    return {
      objects,
      API_BASE_URL,
      pageList,
      moment,
      checkHandler,
      uncheckHandler,
      activeCheckbox,
      selectedCount,
      setAppearance,
      popupContents,
      confirmHandler,
      cancelHandler,
      completeHandler,
      deleteBookmark,
      searchEvt,
      locale,
      language,
      editMemo,
      memoPopup,
      saveMemo,
      closeHandler,
      target
    };
  },
});
