import { ref, computed } from 'vue';
import { defineStore } from 'pinia'
import { getServerInfo } from '../api'
import { useLocaleStore } from './locale';


interface diskCapacity {
  inUse: string,
  total: string
}

interface ServerData {
  type: number,
  name: string,
  state: number,
  stateInfo: string
}

interface ServerState {
  diskCapacity: diskCapacity,
  serverState: ServerData[],
  server: string[],
  serverError: boolean, // 하나의 서버라도 에러가 발생했는지 체크
  serverInfoInterval: number
}

export const useServerStateStore = defineStore('server', {
  state: (): ServerState => ({
    diskCapacity: { inUse: "0GB", total: "0GB" },
    serverState: [],
    server: [],
    serverError: false,
    serverInfoInterval: 0,
  }),
  actions: {
    setServerName() {
      const localeStore = useLocaleStore();
      const locale = computed(() => localeStore.getLocale);
      const language = computed(() => localeStore.getLanguage);
      this.server = [];
      this.server.push(language.value["LIBRARY"]["server_anal"][locale.value]);
      this.server.push(language.value["LIBRARY"]["server_summary"][locale.value]);
      this.server.push(language.value["LIBRARY"]["server_trans"][locale.value]);
      this.server.push(language.value["LIBRARY"]["server_face"][locale.value]);
      this.server.push(language.value["LIBRARY"]["server_text"][locale.value]);
    },
    async getServerInfo() {
      const localeStore = useLocaleStore();
      const locale = computed(() => localeStore.getLocale);
      const language = computed(() => localeStore.getLanguage);

      try {
        const res = await getServerInfo();
        if(res !== undefined) {
          const capacity = res.capacity;
          this.diskCapacity = {
            inUse: ((capacity.total - capacity.available)/(1024*1024*1024)).toFixed(0) + "GB",
            total: (capacity.total/(1024*1024*1024)).toFixed(0) + "GB"
          };
  
          this.serverState = [];
          this.serverError = false;
  
          for(let i=0; i<res.server.length; i++) {
            const ret = res.server[i];
            const now = new Date(res.currDate);
            const lastUpdated = new Date(ret.lastupdated);
            const gap = Math.abs((now.getTime() - lastUpdated.getTime()) / 1000);
            const state = ref(0); // 0: 정상, 1: 사용중, 2: 이상
            const stateInfo = ref('');
  
            if(gap <= 5) {
              if(ret.is_running == 1) {
                state.value = 1;
                stateInfo.value = language.value["LIBRARY"]["use"][locale.value]; // 사용중
              } else {
                state.value = 0;
                stateInfo.value = language.value["LIBRARY"]["normal"][locale.value]; // 정상
              }
            } else {
              state.value = 2;
              stateInfo.value = language.value["LIBRARY"]["error"][locale.value]; // 이상
              this.serverError = true;
            }

            this.serverState.push({
              type: ret.type,
              name: this.server[ret.type],
              state: state.value,
              stateInfo: stateInfo.value,
            });
          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    startPollingServerState() {
      this.setServerName();
      this.getServerInfo();

      this.serverInfoInterval = setInterval(() => {
        this.getServerInfo();
      }, 3000);
    },
    stopServerInfoPolling() {
      clearInterval(this.serverInfoInterval);
    }
  },
  getters: {
    getDiskCapacity(state) {
      return state.diskCapacity;
    },
    getServerState(state) {
      return state.serverState;
    },
    getServerError(state) {
      return state.serverError;
    }
  },
});
 