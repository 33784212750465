
import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';
import DwellPopup from '../DwellPopup.vue';

export default defineComponent({
  components:{
    DwellPopup
  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const dwellCondition = computed(() => conditionStore.getDwell); 
    const showDwellPopup = ref(false);

    // 관심영역 옵션 선택
    const setDwellType = (value:string) => {
      conditionStore.setDwellType(value);
      showDwellPopup.value = true;
    };
    // 관심영역 팝업 닫기
    const closeDwellPopup = () => {
      showDwellPopup.value = false;
      // summaryMenuStore.setDwellPopup();
    };
    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    onBeforeMount(() => {
      if(dwellCondition.value.firstPoints.length!==0){
        showDwellPopup.value = true;
      }else{
        showDwellPopup.value = false;
      }
    })

    return {
      locale,
      language,
      setOptionOpenState,
      setDwellType,
      dwellCondition,
      closeDwellPopup,
      showDwellPopup
    }
  }
  
})
