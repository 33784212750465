
import { defineComponent, computed } from 'vue';
import { useSummaryStore } from '@/store/summary';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
  },
  props:{
    message:{
      type: String,
      required : true
    },
    type:{
      type: String,
      required : true
    }
  },
  emits: ['confirmClick', 'noClick', 'checkClick', 'errorClick', 'deleteClick', 'cancelClick', 'downloadClick', 'accessClick'],
  setup(props, { emit }) {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const summaryStore = useSummaryStore();
    const percent = computed(() => summaryStore.getPercent);
    
    const confirmClick = () => {
      emit('confirmClick');
    };
    const noClick = () => {
      emit('noClick');
    };
    const checkClick = () => {
      emit('checkClick');
    };
    const errorClick = () => {
      emit('errorClick');
    };
    const deleteClick = () => {
      emit('deleteClick');
    };
    const cancelClick = () => {
      emit('cancelClick');
    };
    const downloadClick = () => {
      emit('downloadClick');
    }
    const accessClick = () => {
      emit('accessClick');
    }

    return {
      locale,
      language,
      confirmClick,
      noClick,
      checkClick,
      errorClick,
      deleteClick,
      cancelClick,
      percent,
      downloadClick,
      accessClick
    };
  },
});
