import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getPlaylist, playlistInsert, getVideos, deletePlaylist, updatePlaylistName, deletePlaylistVideo } from '@/api';
import { useAcceptStore } from './accept';

// 플레이리스트 관련 정보
export const usePlaylistStore = defineStore('playlist', {
  state: () => ({
    playList : ref<any>([]),
    upgradeCount : 0,
    totalCount : 0,
    insertType : 'insert',
    selectPlaylist : ref<any>({}),
  }),
  actions: {
    // 플레이리스트 업그레이드 세팅
    async upgradePlaylistSet(){
      try{
        const result = await getVideos();
        const videos = result;
        const request = [];
        this.upgradeCount = 0;
        for(let i=0;i<videos.length;i++){
          const jPlayList = JSON.parse(videos[i].playlist);
          // console.log(jPlayList['l'].length)
          if(jPlayList['l'].length !== 0){
            for(let j=0;j<jPlayList['l'].length;j++){
              request.push({name:jPlayList['l'][j], id:videos[i].id, videos:[videos[i].videoId]})
            }
          }
        }
        this.totalCount = request.length;
        for(let i=0;i<request.length;i++){
          this.upgradePlaylist(request[i]);
        }
      } catch (error){
        console.log(error);
      }
    },
    // 플레이리스트 업그레이드
    async upgradePlaylist(request:any){
      try{
        await playlistInsert(request.name, request.videos);
        // const result = await playlistInsert(request.name, request.videos);
        this.upgradeCount++;
        if(this.upgradeCount === this.totalCount){
          useAcceptStore().updateAccept('update_playlist', 1);
        }
      } catch (error){
        console.log(error);
      }
    },
    // 플레이리스트 가져오기
    async getPlaylist() {
      this.initPlaylist();
      try {
        const result = await getPlaylist();
        // 플레이리스트에 해당되는 영상 넣기 
        this.playList = result;
        for(let i=0;i<this.playList.length;i++){
          const videos = this.playList[i].videos;
          this.playList[i].videos = videos.split(',');
        }
      } catch (error) {
        console.error('Error getPlaylist:', error);
        throw error;
      }
    },
    initPlaylist() {
      this.playList = [];
    },
    // 플레이리스트 생성
    async insertPlaylist(name: string, videoList: any) {
      try{
        await playlistInsert(name, videoList);
        // const result = await playlistInsert(name, videoList);
        const videoId = [];
        for(let i=0;i<videoList.length;i++){
          videoId.push(videoList[i].videoId);
        }

        return true;
      } catch (error) {
        console.error('Error insertPlaylist:', error);
        return false;
        throw error;
      }
    },
    // 플레이리스트 삭제
    async deletePlaylist(name: string){
      try{
        await deletePlaylist(name);
        // const result = await deletePlaylist(name);
        return true;
      } catch (error){
        console.error('Error deletePlaylist:', error);
        return false;
        throw error;
      }
    },
    // 플레이리스트 업데이트
    async updatePlaylist(beforeName: string, afterName: string, addVideos: any, deleteVideos: any){
      this.insertType = 'update';
      try{
        await updatePlaylistName(beforeName, afterName);
        // const result = await updatePlaylistName(beforeName, afterName);
        return this.deletePlaylistVideo(afterName, deleteVideos, addVideos); 
      } catch (error){
        console.error('Error updatePlaylist:', error);
        return false;
      }
    },
    // 플레이리스트 특정 비디오 삭제 - 수정에 이어지는 함수
    async deletePlaylistVideo(name: string, videos: any, addVideos: any){
      try{
        const videoList = [];
        for(let i=0;i<addVideos.length;i++){
          videoList.push({videoId:addVideos[i]});
        }
        await deletePlaylistVideo(name, videos);
        // const result = await deletePlaylistVideo(name, videos);
        return this.insertPlaylist(name, videoList);
      } catch (error){
        console.error('Error deletePlaylistVideo:', error);
        return false;
      }
    },
    // 플레이리스트 특정 비디오 삭제
    async deleteVideo(name: string, videos: any){
      try{
        await deletePlaylistVideo(name, videos);
        // const result = await deletePlaylistVideo(name, videos);
        return true;
      } catch (error){
        console.error('Error deletePlaylistVideo:', error);
        return false;
      }
    },
    setSelectPlaylist(list: any){
      this.selectPlaylist = list;
    }
  },
  getters: {
    getCurrPlaylist(state) {
      return state.playList;
    },
    getSelectPlaylist(state){
      return state.selectPlaylist;
    }
  },
});