type Messages = {
  [key: string]: {
    [key: string]: string;
  };
};

export const messages: Messages = {
  ko: {
    "id": "아이디",
    "pw": "비밀번호",
    "-": "정보 없음",
    "back": "뒤로",
    "choose_opt": "옵션을 선택하세요.",
    "si": "로그인"
  },
  en: {
    "id": "username",
    "pw": "password",
    "-": "-",
    "back": "Back",
    "choose_opt": "Choose an option.",
    "si": "SIGN IN"
  },
};