
import { defineComponent, computed, onMounted } from 'vue';
import NavBar from "../components/Common/NavBar.vue";
// import LibraryVideoList from "../components/Library/LibraryVideoList.vue";
import UploadPopup from "../components/Popup/UploadPopup.vue";
import FileUploadPopup from "../components/Popup/FileUploadPopup.vue";
import VmsUploadPopup from "../components/Popup/VmsUploadPopup.vue";
import Library from "../components/Library/Library.vue";
import { useUploadPopupStateStore } from '../store/uploadPopup';

export default defineComponent({
  components: {
    NavBar,
    FileUploadPopup,
    VmsUploadPopup,
    UploadPopup,
    Library
    // LibraryVideoList,
  },
  setup() {
    const popupStateStore = useUploadPopupStateStore();
    const uploadPopupState = computed(() => popupStateStore.getUploadPopup);
    const fileUploadPopupState = computed(() => popupStateStore.getFileUploadPopup);
    const vmsUploadPopupState = computed(() => popupStateStore.getVmsUploadPopup);

    onMounted(() => {
      // console.log('login user >> ', useUserStore().getUserInfo);
    });


    return {
      uploadPopupState,
      fileUploadPopupState,
      vmsUploadPopupState
    };
  },
});
