
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useUsersStore } from '../../store/users';
import { useLocaleStore } from '@/store/locale';
import moment from 'moment';
import CreateAccount from './CreateAccount.vue';
import PopupModule from '../Popup/PopupModule.vue';
import { usePageStore } from '../../store/pageNumber';
import PageNumber from '../../components/Common/PageNumber.vue';
import EditAccount from './EditAccount.vue';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';

export default defineComponent({
  components: {
    PageNumber,
    CreateAccount,
    EditAccount,
    ComboboxEdit,
    PopupModule
},
  setup() {
    const usersStore = useUsersStore();
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const users = computed(() => usersStore.getUsers);
    const pageStore = usePageStore();
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref([] as any);
    const searchUsers = ref([] as any);
    const showCreateAccount = ref<any>(false);
    const showEditAccount = ref<any>(false);
    
    const popupContents = ref({ message: '', type: 'success', show: false });

    const editType = ref<any>('');
    const selectUser = ref<any>({});
    const selectCount = ref<any>(0);

    const searchArray = ref<any>([
      { id: 'id', label: 'ID' },
      { id: 'name', label: language.value["INFO"]["name"][locale.value] }, // 이름
      { id: 'division', label: language.value["INFO"]["division"][locale.value] }, // 소속
    ]);
    const selectedSearch = ref<any>({ id: 'id', label: 'ID'});
    const searchText = ref<any>('');

    const setFormat = (lastAccess: Date) => {
      return moment(lastAccess).format('YYYY-MM-DD HH:mm');
    };

    // 새계정 추가 or 계정 정보 수정 팝업
    const openAccount = (type: string, user: any) => {
      editType.value = type;
      if(type === 'create'){
        showCreateAccount.value = true;
      }
      else{
        showEditAccount.value = true;
      }
      selectUser.value = user!==null?user:{};
    }

    // 사용자 리스트 클릭 시
    const selectList = (user: any) => {
      user.checked = !user.checked;
      if(user.checked){
        selectCount.value++;
      }
      else{
        selectCount.value--;
      }
    }

    // 콤보박스 선택시
    const selectedItem = (item: any) => {
      selectedSearch.value = item;
      searchUserEvent();
    }

    const deletePopupCheck = () => {
      popupContents.value.type='delete';
      popupContents.value.message = language.value["ADMIN"]["msg_delete_account"][locale.value]; // 선택한 계정을 삭제하시겠습니까?
      popupContents.value.show = true;
    }
    // 선택 삭제 이벤트
    const deleteUser = async (): Promise<void> => {
      let deleteValue = [];
      for(let i=0;i<pageList.value.length;i++){
        if(pageList.value[i].checked){
          deleteValue.push(pageList.value[i].id);
        }
      }

      const res = await usersStore.deleteAccount(deleteValue);
      if(res){
        popupContents.value.type='success';
        popupContents.value.message = language.value["ADMIN"]["msg_delete_account_c"][locale.value]; // 계정 삭제가 완료되었습니다.
        popupContents.value.show = true;
      }
    }

    const successCheck = () => {
      popupContents.value.show = false;
      usersStore.getUserList();
      searchUserEvent();
    }

    // 검색 기능
    const searchUserEvent = () => {
      searchUsers.value = [];
      pageStore.setCurrNumber(1);
      for(let i=0;i<users.value.length;i++){
        let log = users.value[i];
        let flag = true;

        if(log.role === 'admin'){
          flag = false;
        }

        if(searchText.value !== ''){
          // console.log('search >> ', searchText.value, log.id.indexOf(searchText.value));
          switch(selectedSearch.value.id){
            case 'id':
              if(log.id.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
            case 'name':
              if(log.name!==null?(log.name.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1):true){
                flag = false;
              }
              break;
            case 'division':
              if(log.division!==null?(log.division.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1):true){
                flag = false;
              }
              break;
          }
        }

        if(flag){
          let inputData = {
            name: log.name, 
            id: log.id, 
            division: log.division, 
            lastAccess: log.lastAccess, 
            salt: log.salt,
            password: log.password,
            checked: false
          }
          searchUsers.value.push(inputData);
        }
      }
      getCurrPage();
      pageStore.setTotalPage(searchUsers.value.length, 13);
    }

    /* pagenation */
    const getCurrPage = () => {
      selectCount.value = 0;
      pageList.value = [];
      
      for(let i=(currNumber.value -1)*13; i<Math.min((currNumber.value)*13, searchUsers.value.length);i++){
        let log = searchUsers.value[i];
        let inputData = {
          name: log.name, 
          id: log.id, 
          division: log.division, 
          lastAccess: log.lastAccess, 
          salt: log.salt,
          password: log.password,
          checked: false
        }
        pageList.value.push(inputData);
      }
    }
    /* pagenation */

    onMounted(() => {
      pageStore.setCurrNumber(1);
      usersStore.getUserList();
    });
    /* pagenation */
    watch(() => users.value, () => {
      searchUserEvent();
    });
    watch(() => currNumber.value, () => {
      getCurrPage();
    })
    /* pagenation */

    return {
      locale,
      language,
      users,
      setFormat,
      showCreateAccount,
      editType,
      openAccount,
      selectUser,
      showEditAccount,
      selectList,
      selectCount,
      searchText,
      selectedSearch,
      searchArray,
      selectedItem,
      deletePopupCheck,
      deleteUser,
      searchUserEvent,
      popupContents,
      successCheck,
      getCurrPage,
      pageList
    };
  },
});
