
import { defineComponent, ref, onMounted, computed } from 'vue';
import { usePlaylistStore } from '@/store/playlist';
import { useLibraryVideosStore } from '../../store/libraryVideo';
import { API_BASE_URL } from '@/api';
import PopupModule from './PopupModule.vue';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    PopupModule
  },
  props:{
    selectPlaylist: {
      type: Object,
      required: true,
      // default: () => ({ name:'', id: '', videos: [] })
    }
  },
  emit:['closePopup'],
  setup(props, {emit}) {
    const playlistStore = usePlaylistStore();
    const libraryStore = useLibraryVideosStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const videoList = computed(() => libraryStore.getVideoList);
    const checkVideoList = ref<any>([]);
    const checkCount = ref(0);
    const listName = ref("");
    const popupContents = ref({ message: '', type: 'success', show: false });
    const currPlaylist = computed(() => playlistStore.getCurrPlaylist);
    const beforePlaylist = ref<any>({});

    const currType = ref("insert");
    
    // 영상 클릭시 함수
    const checkVideo = (video:any) => {
      video.checked = !video.checked;
      if(video.checked){
        checkCount.value ++;
      }
      else{
        checkCount.value --;
      }
    };

    // 전체 선택
    const allCheckVideo = () => {
      for(let i=0;i<checkVideoList.value.length;i++){
        checkVideoList.value[i].checked = true;
      }
      checkCount.value = checkVideoList.value.length;
    };

    //전체 해제
    const allResetVideo = () => {
      for(let i=0;i<checkVideoList.value.length;i++){
        checkVideoList.value[i].checked = false;
      }
      checkCount.value = 0;
    };

    // 팝업 닫기
    const closePopup = () => {
      emit('closePopup', 'close');
    }

    // 플레이리스트 생성
    const addPlaylist = () => {
      for(let i=0;i<currPlaylist.value.length;i++){
        if(currPlaylist.value[i].name === listName.value){
          popupContents.value.message = language.value["LIBRARY"]["cant_use_name"][locale.value];  //"해당 이름은 사용할 수 없습니다.";
          popupContents.value.type = "error";
          popupContents.value.show = true;
          return;
        }
      }

      let inputVideo = [];
      for(let i=0;i<checkVideoList.value.length;i++){
        if(checkVideoList.value[i].checked ){
          inputVideo.push(checkVideoList.value[i]);
        }
      }

      insertPlaylist(inputVideo);
    }

    const insertPlaylist = async (inputVideo: any): Promise<void> => {
      const popup = popupContents.value;
      
      const res = await playlistStore.insertPlaylist(listName.value, inputVideo);
      if(res) {
        popup.message = language.value["LIBRARY"]["msg_add_playlist"][locale.value];  //'플레이리스트 생성이 완료되었습니다.';
        popup.type = 'success';
        popup.show = true;
      }
      else{
        popup.message = language.value["LIBRARY"]["msg_add_playlist_fail"][locale.value];  //'플레이리스트 생성에 실패하였습니다.';
        popup.type = 'error';
        popup.show = true;
      }
      
    }
    // 성공 시 팝업 닫기
    const successInsert = () => {
      popupContents.value.show = false;
      playlistStore.getPlaylist();
      emit('closePopup', 'success');
    }

    // 수정 요청 시 기본값 세팅
    const setVideos = () => {
      listName.value = props.selectPlaylist.name;
      const checkVideo = props.selectPlaylist.videos;
      for(let i=0;i<checkVideo.length;i++){
        for(let j=0;j<checkVideoList.value.length;j++){
          if(checkVideo[i] === checkVideoList.value[j].videoId){
            checkVideoList.value[j].checked = true;
          }
        }
      }
      checkCount.value = checkVideo.length;
    }

    // 수정하기
    const editPlaylistUpdate = async (): Promise<void> => {
      for(let i=0;i<currPlaylist.value.length;i++){
        if(currPlaylist.value[i].name === listName.value){
          if(beforePlaylist.value.name !== listName.value){
            popupContents.value.message = language.value["LIBRARY"]["cant_use_name"][locale.value];  //"해당 이름은 사용할 수 없습니다.";
            popupContents.value.type = "error";
            popupContents.value.show = true;
            return;
          }
        }
      }

      let inputVideo = [];
      for(let i=0;i<checkVideoList.value.length;i++){
        if(checkVideoList.value[i].checked ){
          inputVideo.push(checkVideoList.value[i].videoId);
        }
      }

      let beforeVideos = [];
      for(let i=0;i<beforePlaylist.value.videos.length;i++){
        beforeVideos.push(beforePlaylist.value.videos[i]);
      }

      let updateVideos = inputVideo.filter(x => beforePlaylist.value.videos.includes(x)); // 이름만 바뀌는것
      let addVideos = inputVideo.filter(x => !beforePlaylist.value.videos.includes(x)); // 추가해야 하는 것
      let deleteVideos = [];  // 삭제해야 하는 것

      for(let i=0;i<beforeVideos.length;i++){
        let flag = false;
        for(let j=0;j<updateVideos.length;j++){
          if(beforeVideos[i] === updateVideos[j]){
            flag = true;
          }
        }
        if(!flag){
          deleteVideos.push(beforeVideos[i]);
        }
      }
      popupContents.value.message = language.value["LIBRARY"]["msg_edit_playlist_doing"][locale.value]; //"플레이리스트 수정중 입니다.";
      popupContents.value.type = "loading";
      popupContents.value.show = true;
      const update = await playlistStore.updatePlaylist(beforePlaylist.value.name, listName.value, addVideos, deleteVideos);
      if(update){
        popupContents.value.message = language.value["LIBRARY"]["msg_edit_playlist"][locale.value]; //"플레이리스트 수정이 완료되었습니다.";
        popupContents.value.type = "success";
      }
    }
    

    // 전체 영상 리스트를 가져옴
    onMounted(() => {
      checkVideoList.value = [];
      let count = 0;
      // console.log(videoList.value);
      for(let i=0;i<videoList.value.length;i++){
        if(videoList.value[i].status.indexOf('WEB') === -1 && videoList.value[i].status.indexOf('FAIL') === -1){
          let data = {
            name: videoList.value[i].fileName,
            videoId: videoList.value[i].videoId,
            thumbnail: videoList.value[i].background,
            checked: videoList.value[i].checked,
            isAllow: videoList.value[i].isAllow
          }
          if(videoList.value[i].checked){
            count ++;
          }
          checkVideoList.value.push(data);
        }
      }
      checkCount.value = count;

      if(props.selectPlaylist.name !== undefined){
        if(props.selectPlaylist.name !== ''){
          currType.value = 'edit';
          beforePlaylist.value = props.selectPlaylist;
        }
        setVideos();
      }

    });

    return {
      checkVideoList,
      checkVideo,
      allCheckVideo,
      allResetVideo,
      API_BASE_URL,
      checkCount,
      listName,
      closePopup,
      addPlaylist,
      popupContents,
      successInsert,
      currType,
      editPlaylistUpdate,
      locale,
      language
    };
  },
});
