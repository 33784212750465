
import { defineComponent, ref, computed, onMounted, onBeforeMount  } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryStore } from '@/store/summary';
import { useMaskingStore } from '@/store/masking';
import { useDatePickerRangeStore } from '@/store/datepickerRange';
import moment from 'moment';

export default defineComponent({
  components: {

  },
  setup(props, {emit}) {
    const localeStore = useLocaleStore();
    const summaryStore = useSummaryStore();
    const maskingStore = useMaskingStore();
    const dpStore = useDatePickerRangeStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const video = computed(() => summaryStore.getVideo);
    const duration = computed(() => summaryStore.getDurationTime);

    const minFrame = computed(() => maskingStore.getTipTime.minFrame);
    const maxFrame = computed(() => maskingStore.getTipTime.maxFrame);

    const tipStartTime = ref(new Date());
    const tipEndTime = ref(new Date());
    const tipSelect = ref(false);

    const overObject = computed(() => maskingStore.getOverObject);

    const closePopup = () => {
      emit('closePopup');
    }

    const setRangeTime = () => {
      dpStore.setStartDate(tipStartTime.value);
      dpStore.setEndDate(tipEndTime.value);
      closePopup();
    }

    const setTipTime = () => {
      if(video.value !== undefined){
        let originStartTime = new Date(video.value.beginDate);
        // console.log('origin start time >> ', originStartTime);
        // console.log('min frame >> ', minFrame.value, (minFrame.value / 8), 'maxFrame >> ', maxFrame.value);
        // console.log('duration >> ', duration.value);
        let startSeconds = ((minFrame.value / 8) >> 0) - 3;
        let endSeconds = ((maxFrame.value / 8 ) >> 0) + 3;

        startSeconds = startSeconds <=0 ? 0 : startSeconds;
        endSeconds = endSeconds >= duration.value ? (duration.value) >> 0 : endSeconds;

        tipStartTime.value.setTime(originStartTime.getTime() + startSeconds * 1000);
        tipEndTime.value.setTime(originStartTime.getTime() + endSeconds*1000);

      }
    }

    onBeforeMount(() => {
      setTipTime();
    })

    return {
      locale,
      language,
      tipStartTime,
      tipEndTime,
      tipSelect,
      overObject,
      closePopup,
      setRangeTime,
      moment,

    };
  },
});
