
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useSummaryStore } from '../../store/summary';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import ArrowPopup from '@/components/Player/ArrowPopup.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';

import { useConditionStore } from '@/store/condition';
import ClassSelectBox from '@/components/Player/condition/ClassSelectBox.vue'
import ColorSelectBox from '@/components/Player/condition/ColorSelectBox.vue'
import DirectionSelectBox from './condition/DirectionSelectBox.vue';
import DwellSelectBox from './condition/DwellSelectBox.vue';
import TimeRangeSelectBox from './condition/TimeRangeSelectBox.vue';
import TimeStampSelectBox from './condition/TimeStampSelectBox.vue';
import TextSearchBox from './condition/TextSearchBox.vue';
import FaceUploadBox from './condition/FaceUploadBox.vue';

export default defineComponent({
  components: {
    ArrowPopup,
    PopupModule,

    ClassSelectBox,
    ColorSelectBox,
    DirectionSelectBox,
    DwellSelectBox,
    TimeRangeSelectBox,
    TimeStampSelectBox,
    TextSearchBox,
    FaceUploadBox
  },
  setup() {
    const router = useRouter();
    const summaryStore = useSummaryStore();
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const menu = computed(() => summaryMenuStore.getMenuState);
    const option = computed(() => summaryMenuStore.getOptionState);
    const popup = computed(() => summaryMenuStore.getPopupState);

    const popupContents = ref({message : '', type: '', show:false});
    const summaryLoadingPopup = ref({message : '', type: '', show:false});
    const summaryLoadingStatus = computed(() => summaryStore.getSummaryStatus);
    const firstSummaryLoadingStatus = computed(() => summaryStore.getFirstSummaryLoading);

    const video = computed(() => summaryStore.getVideo);
    const currPage = computed(() => useRoute().name);

    const getDataLoading = computed(() => summaryStore.getData);

    const classCondition = computed(() => conditionStore.getClass);  // 객체
    const colorCondition = computed(() => conditionStore.getColor);  // 색상
    const directionCondition = computed(() => conditionStore.getDirection);   // 방향지정
    const dwellCondition = computed(() => conditionStore.getDwell);  // 관심영역
    const dwellVideoCondition = computed(() => conditionStore.getDwellVideo); // 관심영역 영상 설정
    const arrowCondition = computed(() => conditionStore.getArrow);  // 경로지정
    const timeRange = computed(() => conditionStore.getTimeRange); // 부분요약
    const textTimerange = computed(() => conditionStore.getTextTimeRange); // 부분요약
    const timeStampCondition = computed(() => conditionStore.getTimeStamp); // 타임스탬프
    const durationTime = computed(() => summaryStore.getDurationTime); // 영상 전체 길이
    const irText = computed(() => conditionStore.getIrText);
    const faceImgArray = computed(() => conditionStore.getFace);  // 안면인식 입력값

    const textBoxRef = ref();
    const faceBoxRef = ref();

    const directionSelectValue = computed(() => conditionStore.getCurrDirection);
    const allCheckClass = computed(() => conditionStore.getAllClass);  //클래스 전체 선택 상태
    const allCheckColor = computed(() => conditionStore.getAllColor);  //색상 전체 선택 상태
    const allCheckDirection = computed(() => conditionStore.getAllDirection);

    const goBack = () => {
      router.go(-1);
    }

    const setMenuOpenState = (target: string, state: boolean) => {
      summaryMenuStore.initOptionOpenState();
      summaryMenuStore.setMenuOpenState(target, state);
    };

    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);

      if(target === 'area' && state){
        // conditionStore.setDwellData();
      }
    };

    // 관심영역 팝업 닫기
    const closeDwellPopup = () => {
      summaryMenuStore.showDwellPopup(false);
    };

    // 선택한 객체에 따른 텍스트 출력 변경
    const checkClassText = () => {
      let check = false;
      for(let i=0;i<classCondition.value.length;i++){
        for(let j=0;j<classCondition.value[i].sub.length;j++){
          if(classCondition.value[i].sub[j].checked){
            check = true;
            break;
          }
        }
      }

      if(check){
        return language.value["COMMON"]["selected"][locale.value]; // 선택됨
      }
      else{
        return ""; // 선택 안됨
      }
    }
    const checkColorText = () => {
      let check = false;
      for(let i=0;i<colorCondition.value.length;i++){
        if(colorCondition.value[i].checked){
          check = true;
          break;
        }
      }

      if(check){
        return language.value["COMMON"]["selected"][locale.value]; // 선택됨
      }
      else{
        return ""; // 선택 안됨
      }
    }
    // 설정 초기화
    const resetCondition = () => {
      // IR 초기화
      conditionStore.resetIr();
      textBoxRef.value.reset();

      // 객체 초기화
      conditionStore.resetClass();

      //색상 초기화
      conditionStore.resetColor();

      //방향 초기화
      conditionStore.resetDirection();

      //관심영역 초기화 
      conditionStore.resetDwell();

      //경로 지정 초기화
      conditionStore.resetArrow();

      //부분 요약 초기화
      conditionStore.resetTimeRange();

      //타임 스탬프 초기화
      conditionStore.resetTimeStamp();

      //안면 인식 초기화
      conditionStore.resetFace();
      faceBoxRef.value.deleteAllFace();

      summaryMenuStore.initOptionOpenState();
    };
    // 요약 버튼 클릭 시
    const summarySelect = () => {
      let check = checkOption();
      if(!check){
        popupContents.value.message = language.value["PLAYER"]["msg_blank"][locale.value];  // "객체 또는 색상이 비어있습니다.";
        popupContents.value.type = "error";
        popupContents.value.show = true;
        return;
      }

      summaryStore.setCondition();
      summaryMenuStore.initOptionOpenState();
      summaryMenuStore.showSummarySelect(true);
    }

    // 객체, 색상, 방향 중 선택한게 있는지 확인 
    const checkOption = () => {
      let classCheck = false;
      for(let i=0;i<classCondition.value.length;i++){
        for(let j=0;j<classCondition.value[i].sub.length;j++){
          if(classCondition.value[i].sub[j].checked){
            classCheck = true;
            break;
          }
        }
      }

      let colorCheck = false;
      for(let i=0;i<colorCondition.value.length;i++){
        if(colorCondition.value[i].checked){
          colorCheck = true;
          break;
        }
      }

      let directionCheck = false;
      for(let i=0;i<directionCondition.value.length;i++){
        if(directionCondition.value[i].checked){
          directionCheck = true;
          break;
        }
      }

      if(!classCheck || !colorCheck || !directionCheck){
        return false;
      }
      else{
        return true;
      }
    }

    //요약 취소
    const cancelSummary = () => {
      summaryStore.cancelSummary();
      summaryLoadingPopup.value.show = false;
    }

    const reload = () => {
      location.reload();
    }

    // 요약 상태에 따른 팝업
    watch(() => summaryLoadingStatus.value, () => {
      if(!firstSummaryLoadingStatus.value){
        if(summaryLoadingStatus.value === 'running'){
          summaryLoadingPopup.value.message = language.value["PLAYER"]["msg_summary"][locale.value];  //"요약 중입니다.";
          summaryLoadingPopup.value.type = "loading";
          summaryLoadingPopup.value.show = true;
        }
        else if(summaryLoadingStatus.value === 'ir'){
          summaryLoadingPopup.value.message = language.value["PLAYER"]["msg_ir"][locale.value];  //"텍스트 분석 중입니다.";
          summaryLoadingPopup.value.type = "percent";
          summaryLoadingPopup.value.show = true;
        }
        else if(summaryLoadingStatus.value === 'face'){
          summaryLoadingPopup.value.message = language.value["PLAYER"]["msg_face"][locale.value];  //"안면인식 분석 중입니다.";
          summaryLoadingPopup.value.type = "percent";
          summaryLoadingPopup.value.show = true;
        }
        else if(summaryLoadingStatus.value === 'blank'){
          summaryLoadingPopup.value.message = language.value["PLAYER"]["msg_no_object"][locale.value];  //"객체가 없습니다.";
          summaryLoadingPopup.value.type = "success";
          summaryLoadingPopup.value.show = true;
        }
        else if(summaryLoadingStatus.value === 'invalid'){
          summaryLoadingPopup.value.message = language.value["PLAYER"]["msg_disconnect"][locale.value];  //"다른 사용자가 사용중입니다.";
          summaryLoadingPopup.value.type = "warning";
          summaryLoadingPopup.value.show = true;
        }
        else{
          summaryLoadingPopup.value.show = false;
        }
      }
      else{
        if(summaryLoadingStatus.value === 'blank'){
          summaryLoadingPopup.value.message = language.value["PLAYER"]["msg_no_object"][locale.value];  //"객체가 없습니다.";
          summaryLoadingPopup.value.type = "success";
          summaryLoadingPopup.value.show = true;
        }
      }

    })

    onMounted(() => {
      /* 라이브러리 요약 조건 기본활성화 설정 부분 */
      summaryMenuStore.setMenuOpenState('detail', true);  // text=텍스트 검색, detail=요약 상세 조건, face=안면인식
      /* 라이브러리 요약 조건 기본활성화 설정 부분 */
      summaryMenuStore.initOptionOpenState();
    });

    return {
      video,
      goBack,
      currPage,
      menu,
      option,
      popup,
      setMenuOpenState,
      setOptionOpenState,
      getDataLoading,
      irText,
      classCondition,
      colorCondition,
      directionCondition,
      directionSelectValue,
      allCheckClass,
      allCheckColor,
      allCheckDirection,
      closeDwellPopup,
      dwellCondition,
      arrowCondition,
      timeStampCondition,
      resetCondition,
      summarySelect,
      durationTime,
      timeRange,
      textTimerange,
      faceImgArray,
      popupContents,
      cancelSummary,
      summaryLoadingPopup,
      locale,
      language,
      reload,

      textBoxRef,
      faceBoxRef,

      checkClassText,
      checkColorText,
      dwellVideoCondition
      
    };
  },
});
