
import { defineComponent } from 'vue';
import Menu from "../components/Management/Menu.vue";
import VmsList from "../components/Management/VmsList.vue";

export default defineComponent({
  components: {
    Menu,
    VmsList,
  },
  setup() {

    return {
    };
  },
});
