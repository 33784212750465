
import { defineComponent, computed, shallowRef, ref } from 'vue';
import { useSummaryStore } from '../../store/summary';
import moment from 'moment';
import videojs from 'video.js'
import { VideoPlayer } from '@videojs-player/vue'
// import "@/css/videojs.css";
import 'video.js/dist/video-js.css'
import { API_BASE_URL } from '@/api';
import VideoTimeBox from './VideoTimeBox.vue';

type VideoJsPlayer = ReturnType<typeof videojs>

export default defineComponent({
  components: {
    VideoPlayer,
    VideoTimeBox
  },
  setup() {
    const summaryStore = useSummaryStore();

    const video = computed(() => summaryStore.getVideo);
    const videoOptions = {
      autoplay : true
    }
    const originVideoRef = ref();
    const currVideoTime = computed(() => summaryStore.currVideoTime);

    const player = shallowRef<VideoJsPlayer>();
    const frame = computed(() => summaryStore.getAppearance);
    const handleMounted = (payload: any) => {
      player.value = payload.player;

      if(frame.value !== undefined) {
        playObject(summaryStore.getAppearance);
      }
    }

    const handleEvent = () => {
      if(player.value !== undefined){
        summaryStore.setDuration(player.value.duration());
      }

    }

    const timeUpdate = (log: any) => {
      if(video.value !==undefined){
        let currTime = log;
        let durationTime = new Date();
        let startTime = new Date(video.value.beginDate);
        durationTime.setTime(startTime.getTime() + (currTime * 1000));
        summaryStore.setCurrVideoTime(moment(durationTime).format('HH:mm:ss'));
      }
    }

    const playObject = (frame: any) => {
      if(frame !== null){
        if(player.value !== undefined){
          let playPromise = player.value.play();
          if (playPromise !== undefined) {
            playPromise.then(res => {
              if(player.value !==undefined){
                player.value.currentTime(frame/8);
                summaryStore.setAppearance(0);
              }
            })
            .catch(error => {
                console.log('error: ', error);
            });
          }
          // summaryStore.setAppearance(0);
        }
      }
    }
  

    return {
      video,
      player, 
      handleMounted, 
      handleEvent,
      videoOptions,
      timeUpdate,
      originVideoRef,
      currVideoTime,
      API_BASE_URL,
      playObject
    };
  },
});
