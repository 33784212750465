
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationStore } from '../../store/navigation'
import { useLocaleStore } from '../../store/locale'
import { messages } from '../../locale/common';
import { useUsersStore } from '../../store/users';
import { useUserStore } from '../../store/loginUser';
import PopupModule from '../Popup/PopupModule.vue';

export default defineComponent({
  components: {
    PopupModule
},
  props:{
    currPage: {
      type: String,
      required: false,
    },
    userInfo: {
      type: Object,
      required: false,
      default: () => ({ 
        id: '', 
        name: '', 
        role: '', 
        division: '',
        password: '',
        salt: '',
        lastAccess: ''
      })
    },
    currEditType:{
      type: String,
      required: false,
    }
  },
  setup(props, {emit}) {
    const navigationStore = useNavigationStore();
    const router = useRouter();
    const goToAboutPage = (page: string) => {
      router.push({'name': page});
      navigationStore.setCurrentPage(page);
    }

    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const msg = ref(messages);

    const usersStore = useUsersStore();
    const userStore = useUserStore();
    const users = computed(() => usersStore.getUsers);
    const title = ref("");

    const popupContents = ref({ message: '', type: 'success', show: false });

    const inputData = ref({
      id: "",
      password: "",
      checkPassword: "",
      name: "",
      division: "",
    });

    const editData = ref({
      id: "",
      currPassword: "",
      editPassword: "",
      name: "",
      division: "",
    })

    const showMessage = ref({
      idSuccess: false,
      idFail: false,
      pwSuccess: false,
      pwFail: false,
      adminId: false
    });

    const checkId = () => {
      let flag = false;
      if(inputData.value.id === 'admin'){
        showMessage.value.idSuccess = false;
        showMessage.value.idFail = false;
        showMessage.value.adminId = true;
      }
      else{
        for(let i=0;i<users.value.length;i++){
          if(users.value[i].id === inputData.value.id){
            flag = true;
          }
        }
        showMessage.value.idSuccess = !flag;
        showMessage.value.idFail = flag;
        showMessage.value.adminId = false;
      }
      
    }

    const resetIdMessage = () => {
      showMessage.value.idSuccess = false;
      showMessage.value.idFail = false;
      showMessage.value.adminId = false;
    }

    const checkPassword = () => {
      const data = inputData.value;

      if(data.password !== "" && data.checkPassword !== "") {
        const result = (data.password == data.checkPassword);
        showMessage.value.pwSuccess = result;
        showMessage.value.pwFail = !result;
      } else {
        showMessage.value.pwSuccess = false;
        showMessage.value.pwFail = false;
      }
    }

    const signUp = async (): Promise<void> => {
      if(showMessage.value.idSuccess && showMessage.value.pwSuccess){
        const res = await usersStore.createAccount(inputData.value);
        if(res){
          popupContents.value.type = 'success';
          popupContents.value.message = language.value["ADMIN"]["msg_add_account"][locale.value]; // 계정 생성이 완료되었습니다.
          if(props.currPage === 'signup'){
            popupContents.value.message = language.value["LOGIN"]["msg_complete"][locale.value]; // 회원가입이 완료되었습니다.
          }
          popupContents.value.show = true;
        }
      }
      else{
        popupContents.value.type='error';
        if(!showMessage.value.idSuccess){
          popupContents.value.message = language.value["LOGIN"]["msg_alert_id"][locale.value]; // 아이디 중복확인이 필요합니다.
        } else {
          popupContents.value.message = language.value["LOGIN"]["msg_alert_check"][locale.value]; // 입력정보를 확인해주세요.
        }
        popupContents.value.show = true;
      }
    }

    // 성공 시 팝업창에서 확인
    const successCheck = () => {
      popupContents.value.show = false;
      usersStore.getUserList();
      emit('closePopup');
    }

    // 사용자 정보 수정 - 관리자
    const editInput = async (): Promise<void> => {
      const res = await usersStore.updateAccount(editData.value);

      if(res){
        popupContents.value.type = 'success';
        popupContents.value.message = language.value["ADMIN"]["msg_edit_c"][locale.value]; // 회원 정보 수정이 완료되었습니다.
        popupContents.value.show = true;
        if(props.currEditType === 'user'){
          userStore.setUserInfo(editData.value);
        }
      }
      else {
        popupContents.value.type = 'error';
        popupContents.value.message = language.value["ADMIN"]["msg_edit_fail"][locale.value]; // 회원 정보 수정에 실패하였습니다.
        popupContents.value.show = true;
      }
    }

    // 사용자 정보 수정 - 사용자
    const checkEdit = async (): Promise<void> => {
      // 로그인 시도하여 성공하면 변경 가능, 실패하면 오류 표출
      const res = await userStore.checkPassword(editData.value.id, editData.value.currPassword);
      if(res !== null){
        if(res.isLoggedIn){
          editInput();
        }
        else{
          showMessage.value.pwFail = true;
        }
      }
    }

    const setTitle = () => {
      if(props.currPage === 'signup'){
        title.value = language.value["LOGIN"]["signup"][locale.value]; // 회원가입
      }
      else if(props.currPage === 'create'){
        title.value = language.value["ADMIN"]["add_account"][locale.value]; // 새 계정 추가
      }
      else{
        title.value = language.value["ADMIN"]["edit_account"][locale.value]; // 회원 정보 수정
        editData.value.id = props.userInfo.id;
        editData.value.name = props.userInfo.name===null?'':props.userInfo.name;
        editData.value.division = props.userInfo.division===null?'':props.userInfo.division;
      }
    }

    watch(() => locale.value, () => {
      setTitle();
    })

    onMounted(() => {
      usersStore.getUserList();
      setTitle();
    })

    return {
      locale,
      language,
      goToAboutPage,
      msg,
      checkId,
      checkPassword,
      inputData,
      showMessage,
      title,
      resetIdMessage,
      popupContents,
      signUp,
      editData,
      editInput,
      successCheck,
      checkEdit
    };
  },
});
