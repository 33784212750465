
import { defineComponent, ref, computed } from 'vue';
import { useMaskingStore } from '../../store/masking';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import { useLocaleStore } from '@/store/locale';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

import PopupModule from '@/components/Popup/PopupModule.vue';

export default defineComponent({
  components: {
    PopupModule,
    VueSlider
  },
  setup() {
    const maskingStore = useMaskingStore();
    const summaryMenuStore = useSummaryMenuStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const popupContents = ref({message : '', type: '', show:false});
    const maskingOption = computed(() => maskingStore.getMaskingOption);

    const maskingReverse = ref(0);

    const maskingStrength = ref(10);
    const minStrength = ref(4);
    const maxStrength = ref(16);
    const strengthMarks = ref([4, 10, 16]);

    const closeSelectMasking = () => {
      summaryMenuStore.showMaskingSelect(false);
    }

    const setMasking = () => {
      let setMasking = maskingOption.value;
      setMasking.reverse = maskingReverse.value;
      setMasking.mosaic = maskingStrength.value;
      // console.log(setMasking);
      maskingStore.setMaskingOption(setMasking);
      summaryMenuStore.showMaskingSelect(false);
      maskingStore.insertMaskingRequest();
    }
    
    return {
      popupContents,
      closeSelectMasking,
      setMasking,
      locale,
      language,
      maskingStrength,
      minStrength,
      maxStrength,
      strengthMarks,
      maskingReverse
    };
  },
});
