import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0debb050"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom-box" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "slider-box" }
const _hoisted_4 = { class: "slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider = _resolveComponent("vue-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
      ref: "textareaRef",
      class: "search-box",
      placeholder: _ctx.language["PLAYER"]["input_ir"][_ctx.locale],
      spellcheck: "false",
      rows: "1",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.inputText]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.language["PLAYER"]["threshold"][_ctx.locale]), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_vue_slider, {
          modelValue: _ctx.sliderVal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sliderVal) = $event)),
          min: _ctx.min,
          max: _ctx.max,
          tooltip: 'always',
          interval: _ctx.interval
        }, null, 8, ["modelValue", "min", "max", "interval"])
      ])
    ])
  ]))
}