
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useLibraryVideosStore, LibraryVideo } from '../../store/libraryVideo';
import moment from 'moment';
import { API_BASE_URL } from '@/api';
import { usePageStore } from '../../store/pageNumber';
import { useLibrarySortStore } from '@/store/librarySort';
import PageNumber from '../../components/Common/PageNumber.vue';
import VideoMemoModule from '@/components/Popup/VideoMemoModule.vue';
import { useRouter } from 'vue-router';
import { useLocaleStore } from '@/store/locale';
import { useLibraryMenuStore } from '../../store/libraryMenu';

export default defineComponent({
  components: {
    PageNumber,
    VideoMemoModule,
  },
  setup() {
    const libraryMenuStore = useLibraryMenuStore();
    const libraryStore = useLibraryVideosStore();
    const pageStore = usePageStore();
    const librarySortStore = useLibrarySortStore();
    const router = useRouter();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const currTab = computed(() => libraryMenuStore.getCurrTab);
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref<any>([]);
    const videos = computed(() => libraryStore.getSharedList);
    const filterVideos = ref<any>([]);
    const popupContents = ref({ message: '', type: '', show: false, state: '' });
    const memoPopup = ref({ contents: '', open: false });
    const target = ref<LibraryVideo>();
    const playlistPopupShow = ref<boolean>(false);
    const currPlaylist = ref<any>({ name: '', id: '', videos: [] });

    const searchText = computed(() => librarySortStore.getSearchText);
    const selectedSearch = computed(() => librarySortStore.getSelectedSearch);
    const selectedSort = computed(() => librarySortStore.getSelectedSort);
    const selectedFilter = computed(() => librarySortStore.getFilter);
    const doFilter = computed(() => librarySortStore.getFiltering);

    // 검색 및 필터 함수
    const searchEvt = () => {
      filterVideos.value= [];
      pageStore.setCurrNumber(1);
      
      for(let i=0;i<videos.value.length;i++){
        let log = videos.value[i];

        let flag = true;
        if(log.status.indexOf('WEB') !== -1){
          flag = false;
        }
        // 검색
        if(searchText.value !== ''){
          switch(selectedSearch.value.id){
            case 'title':
              if(log.fileName.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
            case 'memo':
              if(log.memo.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
          }
        }

        // 필터링 
        if(doFilter.value){
          if(selectedFilter.value.date !== undefined){
            let logDate = new Date(log.startDate);
            let filterDate = new Date(selectedFilter.value.date)
            if(logDate<filterDate){
              flag = false;
            }
          }
          if(selectedFilter.value.contents !== undefined){
            if(selectedFilter.value.contents["label"] == language.value["COMBOBOX"]["contents_other"][locale.value]){
              // 전체 리스트와 비교, 포함되면 false
              switch(log.contents){
                case language.value["COMBOBOX"]["contents_option1"][locale.value]: // 선택 안함
                case language.value["COMBOBOX"]["contents_option2"][locale.value]: // 실종
                case language.value["COMBOBOX"]["contents_option3"][locale.value]: // 자살의심(자해)
                case language.value["COMBOBOX"]["contents_option4"][locale.value]: // 주차뺑소니(물피도주)
                case language.value["COMBOBOX"]["contents_option5"][locale.value]: // 공공시설물 파손
                case language.value["COMBOBOX"]["contents_option6"][locale.value]: // 절도(용의자 추적)
                case language.value["COMBOBOX"]["contents_option7"][locale.value]: // 폭행(특수상해)
                case language.value["COMBOBOX"]["contents_option8"][locale.value]: // 무단투기
                case language.value["COMBOBOX"]["contents_option9"][locale.value]: // 교통사고(차량)
                case language.value["COMBOBOX"]["contents_option10"][locale.value]: // 교통사고(대인)
                case language.value["COMBOBOX"]["contents_option11"][locale.value]: // 음주운전 의심
                case language.value["COMBOBOX"]["contents_option12"][locale.value]: // 사고발견(위험신고)
                case language.value["COMBOBOX"]["contents_option13"][locale.value]: // 주거침입
                  flag = false;
                  break;
              }
              if(log.contents === ''){
                flag = false;
              }
            }
            else if(selectedFilter.value.contents["label"] == language.value["COMBOBOX"]["contents_option1"][locale.value]){ // 선택 안함
              if(log.contents !== '' && log.contents !== selectedFilter.value.contents["label"] ){
                flag = false;
              }
            }
            else{
              if(log.contents !== selectedFilter.value.contents){
                flag = false;
              }
            }
          }
          if(selectedFilter.value.place !== undefined){
            if(log.place !== selectedFilter.value.place["id"]){
              flag = false;
            }
          }
          if(selectedFilter.value.period !== undefined){
            const now = new Date();
            const date = new Date(log.availableUntil);
            const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));
            if(Number(selectedFilter.value.period["id"]) < diffDate){
              flag = false;
            }
          }
        }
        
        if(flag){
          const changeSize = changeFileSize(log.size);
          log.changeSize = changeSize;
          filterVideos.value.push(log);
        }
      }
      pageStore.setTotalPage(filterVideos.value.length, 12);
      sortEvt();
    }

    // 파일 사이즈 변환
    const changeFileSize = (size:number) => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (size === 0) 
        return '0 Byte';
      const i = (Math.floor(Math.log(size) / Math.log(1000)));
      return (Math.round((size / Math.pow(1024, i))*100)/100).toFixed(2) + ' ' + sizes[i];
    }

    // 정렬 함수
    const sortEvt = () => {
      let originArray = filterVideos.value;

      switch(selectedSort.value.id){
        case 'upload':
          // 업로드 날짜 최신 날짜부터
          originArray.sort(function(a:any,b:any){
            return new Date(a.uploadAt) > new Date(b.uploadAt) ? -1 : new Date(a.uploadAt) < new Date(b.uploadAt) ? 1 : 0;
          })
          break;
        case 'name':
          // 내림차순 문자 -> 숫자
          originArray.sort(function(a:any,b:any){
            return a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0;
          })
          break;
        case 'date':
          // 영상 날짜순 과거부터
          originArray.sort(function(a:any,b:any){
            return new Date(a.startDate) < new Date(b.startDate) ? -1 : new Date(a.startDate) > new Date(b.startDate) ? 1 : 0;
          })
          break;
        case 'period':
          // 남은저장기간순 적은것부터
          originArray.sort(function(a:any,b:any){
            return calcDateNumber(a.availableUntil) < calcDateNumber(b.availableUntil) ? -1 : 
                   calcDateNumber(a.availableUntil) > calcDateNumber(b.availableUntil) ? 1 : 0;
          })
          break;
      }
      getCurrPage();
    }

    const calcDateNumber = (period: Date) => {
      const now = new Date();
      const date = new Date(period);
      const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));

      return diffDate;
    }

    const getCurrPage = () => {
      pageList.value = [];
      for (let i = (currNumber.value - 1) * 12; i < Math.min((currNumber.value) * 12, filterVideos.value.length); i++) {
        let log = filterVideos.value[i];
        pageList.value.push(log);
      }
    }

    const calcDate = (period: Date) => {
      const now = new Date();
      const date = new Date(period);
      const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));
      const remain = ref();
      if (diffDate >= 3650) {
        remain.value = language.value["LIBRARY"]["permanent"][locale.value]; // 영구 보관
      } else {
        remain.value = diffDate + language.value["COMBOBOX"]["period_option1"][locale.value]; // 일
      }

      return remain.value;
    }

    const setFormat = (lastAccess: Date) => {
      return moment(lastAccess).format('YYYY-MM-DD HH:mm');
    };

    const editMemo = (video: LibraryVideo) => {
      const memo = memoPopup.value;
      target.value = video;
      memo.contents = video.memo;
      memo.open = true;
    }

    const closeHandler = () => {
      memoPopup.value.open = false;
    }

    const statusUpdate = async (): Promise<void> => {
      let allComplete = true;
      if(router.currentRoute.value.name == 'home' && currTab.value === 'shared'){
        for (let i=0;i<videos.value.length;i++){
          if(videos.value[i].status !== "COMPLETE" && videos.value[i].status.indexOf('FAIL')==-1){
            allComplete = false;
            
            let result = await libraryStore.videoStatusEach(videos.value[i].videoId);
            if(result != undefined) {
              videos.value[i].status = result.status;
              videos.value[i].statusPct = result.statusPct;
              videos.value[i].background = result.background;
              videos.value[i].size = result.size;
              const changeSize = changeFileSize(result.size);
              videos.value[i].changeSize = changeSize;
            }
          }
        }
      }

      if(!allComplete && router.currentRoute.value.name == 'home' && currTab.value === 'shared'){
        setTimeout(() => {statusUpdate()}, 3000);
      }
    }

    watch(() => videos.value, () => {
      searchEvt();
      statusUpdate();
    });

    watch(() => currNumber.value, () => {
      getCurrPage();
    });

    watch(() => searchText.value, () => {
      searchEvt();
    })
    watch(() => selectedSearch.value, () => {
      searchEvt();
    })

    watch(() => selectedSort.value, () => {
      searchEvt();
    })  

    onMounted(() => {
      libraryStore.getSharedVideoList();
    });

    return {
      videos,
      calcDate,
      setFormat,
      API_BASE_URL,
      popupContents,
      memoPopup,
      editMemo,
      closeHandler,
      pageList,
      playlistPopupShow,
      currPlaylist,
      searchEvt,
      statusUpdate,
      locale,
      language
    };
  },
});
