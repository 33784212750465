
import { defineComponent, computed } from 'vue';
import { usePageStore } from '../../store/pageNumber';

export default defineComponent({
  components: {

  },
  setup() {
    const pageStore = usePageStore();
    const currNumber = computed(() => pageStore.getCurrNumber);
    const currPage = computed(() => pageStore.getCurrPage);
    const totalPage = computed(() => pageStore.getTotalPage);

    // 선택한 페이지로 이동 
    const setCurrNumber = (num: number) => {
      pageStore.setCurrNumber(num);
      // resetCheck();
    }
    // 첫페이지 or 마지막 페이지로 이동
    const goPage = (value: string) => {
      if(value === 'first'){
        setCurrNumber(1);
      }
      else{
        setCurrNumber(totalPage.value);
      }
      // resetCheck();
    }
    // 10페이지씩 넘기기
    const nextTenPage = (value: string) => {
      if(value === 'back'){
        pageStore.setCurrPage(currPage.value - 1);
      }
      else{
        pageStore.setCurrPage(currPage.value + 1);
      }
      pageStore.setCurrNumber((currPage.value -1)*10 + 1)
      // resetCheck();
    }
    // 체크 해제
    // const resetCheck = () => {
    //   console.log('reset');
    // }

    return {
      currNumber,
      currPage,
      totalPage,
      setCurrNumber,
      goPage,
      // resetCheck,
      nextTenPage
    };
  },
});
