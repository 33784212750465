import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-309afe2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "arrow-img-area" }
const _hoisted_2 = { class: "arrow-canvas" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_line = _resolveComponent("v-line")!
  const _component_v_layer = _resolveComponent("v-layer")!
  const _component_v_stage = _resolveComponent("v-stage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_stage, {
          class: "arrow-canvas",
          config: {width:520, height:320},
          ref: "stage",
          onMousedown: _ctx.canvasClick,
          onMousemove: _ctx.dragArrow,
          onMouseup: _ctx.canvasEndClick,
          onMouseleave: _ctx.canvasEndClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_layer, { ref: "layer" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_line, { config: _ctx.linePolygon }, null, 8, ["config"]),
                _createVNode(_component_v_line, { config: _ctx.arrowPolygon }, null, 8, ["config"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }, 8, ["onMousedown", "onMousemove", "onMouseup", "onMouseleave"])
      ]),
      _createElementVNode("img", {
        class: "thumbnail-box",
        src: `${_ctx.API_BASE_URL}${_ctx.video.background}`
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "white-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setArrowOption && _ctx.setArrowOption(...args)))
      }, _toDisplayString(_ctx.language["COMMON"]["setting"][_ctx.locale]), 1),
      _createElementVNode("button", {
        class: "white-btn",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetArrow && _ctx.resetArrow(...args)))
      }, _toDisplayString(_ctx.language["SUMMARY"]["reset"][_ctx.locale]), 1)
    ])
  ], 64))
}