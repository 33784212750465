
import { defineComponent, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  setup() {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const router = useRouter();
    const currPage = computed(() => useRoute().name);

    const goBack = () => {
      router.go(-1);
    }

    return {
      locale,
      language,
      goBack,
      currPage
    };
  },
});
