
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useVmsStore } from '../../store/vms';
import { useUploadPopupStateStore } from '../../store/uploadPopup';
import { useLocaleStore } from '@/store/locale';
import VmsList from "./VmsList.vue";
import VmsSelectedCamera from "./VmsSelectedCamera.vue";
import VmsSettingBox from "./VmsSettingBox.vue";
import PopupModule from '@/components/Popup/PopupModule.vue';
import axios from 'axios'
import { useUploadOptionsStore } from '@/store/uploadOption'
import { API_BASE_URL } from '@/api'

export default defineComponent({
  components: {
    VmsList,
    VmsSelectedCamera,
    VmsSettingBox,
    PopupModule
  },
  setup() {
    /* store */
    const vmsStore = useVmsStore();
    const popupStateStore = useUploadPopupStateStore();
    const localeStore = useLocaleStore();
    // const loaded = ref<boolean>(false);
    const optionStore = useUploadOptionsStore();
    
    /* data */
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const popupContents = ref({ 
      message: language.value["UPLOAD"]["msg_upload"][locale.value], // 업로드 중입니다.
      type: 'spin', 
      show: false 
    });
    const milestoneState = computed(() => vmsStore.getMilestoneState);
    const leopardState = computed(() => vmsStore.getLeopardState);
    const showVmsList = computed(() => vmsStore.getShowVmsListState);
    const installerPath = computed(() => optionStore.getInstallerPath);
    const vmsSelectedCameraRef = ref();

    /* function */
    const setPopupState = () => {
      popupStateStore.setState("vmsUploadPopup", false);
    }

    const getVmsInfo = async (): Promise<void> => {
      await vmsStore.getVmsInfo('user');
    }

    const handleRefresh = (e: BeforeUnloadEvent) => { // 새로고침 시 alert
      e.preventDefault();
      e.returnValue = '';
    };

    // 레오파드 api 프로그램 다운로드
    const downloadClick = () => {
      // API_server_setup.zip
      // let url = API_BASE_URL+'/download/API_server_setup.zip';
      let url = API_BASE_URL+installerPath.value;

      axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'API_server_setup.zip';
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error)
        popupContents.value.show = false;
    }

    // 레오파드 스토리지 접근 함수
    const accessClick = () => {
      window.open('Apiserver://');
      popupContents.value.show = false;
    }

    // 레오파드 다운로드 팝업
    watch(() => leopardState.value, () => {
      if(leopardState.value === 'download'){
        popupContents.value.type = 'download';
        popupContents.value.message = language.value["UPLOAD"]["msg_err_leopard"][locale.value];
        // "LEOPARD VMS 서버 접속에 실패하였습니다.<br>"
        // + "실행 버튼을 클릭한 후 VMS 리스트 상단의 갱신 버튼을 한번 클릭해주시기 바랍니다.<br>"
        // + "만약 설치되지 않았다면 다운로드 버튼을 클릭하여 설치를 먼저해주시기 바랍니다."
        popupContents.value.show = true;
      }
      if(leopardState.value === 'loginError'){
        popupContents.value.type = 'error';
        popupContents.value.message = "로그인 정보가 올바르지 않습니다. 계정을 확인해주세요."
        popupContents.value.show = true;
      }
    })

    const refresh = () => {
      getVmsInfo();
      window.addEventListener('beforeunload', handleRefresh); 
      vmsSelectedCameraRef.value.removeAllCamera();
    }

    onMounted(() => {
      getVmsInfo();
      window.addEventListener('beforeunload', handleRefresh); 
    });

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', handleRefresh);
      vmsStore.setShowVmsListState(true);
      vmsStore.initVms();
    });

    return {
      locale,
      language,
      showVmsList,
      setPopupState,
      popupContents,
      // loaded,
      milestoneState,
      refresh,
      downloadClick,
      accessClick,
      vmsSelectedCameraRef
    };
  },
});
