import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { getVideos, deleteSelectVideo, updateVideosAllow } from '../api';

export interface Video {
  name: string, // 영상 이름
  videoId: string,  // 영상 ID
  id: string, // 사용자 ID
  uploadAt: Date, // 업로드 날짜
  period: Date, // 저장 기간
  beginDt: Date,  // 영상 시작시간
  endDt: Date,  // 영상 종료시간
  memo: string, // 영상 메모
  playlist: JSON, // 영상 플레이리스트 정보
  place: string, // 장소
  contents: string, // 목적
  userName: string, // 성함
  division: string, // 소속
  officeNum: string, // 공문번호
  deviceId: string, // VMS 업로드 일 경우만 존재하는 카메라 ID
  isAllow: number
}

interface Videos {
  videos: Ref<Video[]>;
}

// 업로드 완료된 파일의 정보를 저장하는 store
export const useVideosStore = defineStore('videos', {
  state: (): Videos => ({
    videos: ref<Video[]>([]),
  }),
  actions: {
    async getVideos() {
      try {
        // console.log('get videos');
        const res = await getVideos();
        this.videos = res;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    async deleteSelectVideo(videos:any){
      try{
        await deleteSelectVideo(videos);
        // const res = await deleteSelectVideo(videos);
        return true;
      } catch (error) {
        console.error('Error fetching users:', error);
        // throw error;
        return false;
      }
    },
    async updateVideosAllow(videos:any){
      try{
        await updateVideosAllow(videos, 1);
        // const res = await updateVideosAllow(videos, 1);
        return true;
      } catch (error){
        console.error('Error fetching users:', error);
        // throw error;
        return false;
      }
    }
    
  },
  getters: {
    getData(state): Video[] {
      return state.videos;
    },
  },
});