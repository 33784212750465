import { defineStore } from 'pinia'
import { updateAccept, autoDeleteRequest } from '../api';
import moment from 'moment';
import { useUploadOptionsStore } from './uploadOption';

export const useAcceptStore = defineStore('accept', {
  state: () => ({

  }),
  actions: {
    async updateAccept(name:string, value:any){
      try {
        await updateAccept(name, value);
        // const res = await updateAccept(name, value);
        useUploadOptionsStore().getOptions();
      } catch (error){
        console.error(error);
      }
    },
    async autoDeleteRequest(currDate: any){
      try{
        const res = await autoDeleteRequest(currDate);
        res.response = true;
        return res;
      } catch (error) {
        console.error('Error setting allAllow:', error);
        return false;
      }
    },
    async funcAutoDelete(){
      try{
        const autoDelete = useUploadOptionsStore().getAutoDelete;
        const deleteTime = autoDelete * 24 * 60 * 60 * 1000 // 일 * 시간 * 분 * 초

        const checkTime = new Date();
        checkTime.setTime(checkTime.getTime() - deleteTime);

        const currDate = moment(checkTime).format('YYYY-MM-DD');
        const res = await this.autoDeleteRequest(currDate);
        return res;

      } catch (error) {
        console.error('Error setting allAllow:', error);
        return false;
      }
      
    },
    async dayAutoDelete(){
      try{
        const autoDate = useUploadOptionsStore().getAutoDate;
        const checkDate = new Date(autoDate);
        const currDate = new Date();
  
        if(currDate.getTime() - checkDate.getTime() > 24 * 60 * 60 * 1000) {
          const res = await this.funcAutoDelete();
          
          if(res.response){
            const res2 = await this.updateAccept('autoDate', moment(currDate).format('YYYY-MM-DD HH:mm:ss'));
            return res2;
          }
        }
      } catch (error) {
        console.error('Error setting allAllow:', error);
        return false;
      }
      // autoDate 값이 오늘 날짜가 아니라면, 실행
      
    }
  },
  getters: {

  }
})