import { defineStore } from 'pinia'

// 요약 옵션 열림/닫힘 상태 관리
export const useSummaryMenuStore = defineStore('summarymenu', {
  state: () => ({
    menu: {
      text: true,
      detail: false,
      face: false
    },
    option: {
      object: false,
      color: false,
      direction: false,
      area: false,
      route: false,
      section: false,
      timestamp: false
    },
    popup: {
      dwell : false,
      summary : false,
      summaryLoading : false,
      masking : false,
      dwellVideo: false,
    },
    tab:{
      dwellShow: false,
    },
    masking : {
      summaryOption : false,
      maskingOption : true,
    }
  }),
  actions: {
    initMenuOpenState() {
      this.menu.text = false;
      this.menu.detail = false;
      this.menu.face = false;
    },
    setMenuOpenState(menu: string, state: boolean) {
      this.initMenuOpenState();

      if(menu == 'text') {
        this.menu.text = state;
      } else if(menu == 'detail') {
        this.menu.detail = state;
      } else if(menu == 'face') {
        this.menu.face = state;
      }
    },
    initOptionOpenState() {
      this.option.object = false;
      this.option.color = false;
      this.option.direction = false;
      this.option.area = false;
      this.option.route = false;
      this.option.section = false;
      this.option.timestamp = false;
      this.popup.dwell = false;
    },
    setOptionOpenState(option: string, state: boolean) {
      this.initOptionOpenState();

      if(option == 'object') {
        this.option.object = state;
      } else if(option == 'color') {
        this.option.color = state;
      } else if(option == 'direction') {
        this.option.direction = state;
      } else if(option == 'area') {
        this.option.area = state;
      } else if(option == 'route') {
        this.option.route = state;
      } else if(option == 'section') {
        this.option.section = state;
      } else if(option == 'timestamp') {
        this.option.timestamp = state;
      }
    },
    showDwellPopup(state: boolean){
      this.popup.dwell = state;
    },
    setDwellPopup(){
      this.popup.dwell = false;
      this.option.area = false;
    },
    setDwellVideoPopup(state: boolean){
      this.popup.dwellVideo = state;
    },
    showSummarySelect(state: boolean){
      this.popup.summary = state;
    },
    showMaskingInfo(state: boolean){
      this.masking.summaryOption = state;
      this.masking.maskingOption = false;
    },
    showMaskingCondition(state: boolean){
      this.masking.maskingOption = state;
      this.masking.summaryOption = false;
    },
    showMaskingSelect(state: boolean){
      this.popup.masking = state;
    },
    setDwellShow(state: boolean){
      this.tab.dwellShow = state;
      if(!state){
        this.setDwellVideoPopup(false);
      }
    },
    
  },
  getters: {
    getMenuState(state) {
      return state.menu;
    },
    getOptionState(state) {
      return state.option;
    },
    getPopupState(state){
      return state.popup;
    },
    getMaskingState(state){
      return state.masking;
    },
    getTabState(state){
      return state.tab;
    }
  },
});
