
import { defineComponent, ref, onMounted, computed } from 'vue';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';
import DatePickerModule from '@/components/Management/DatePickerModule.vue';
import { useEditVideoStore } from '@/store/editVideo';
import moment from 'moment';
import PopupModule from '../Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    ComboboxEdit,
    DatePickerModule,
    PopupModule
},
  props:{
  },
  setup(props, {emit}) {
    const editType = ref<string>("file");
    const editVideoStore = useEditVideoStore();
    const localeStore = useLocaleStore();
    const editData = computed(() => editVideoStore.getData);
    const directContentInput = ref<string>("");
    const popupContents = ref({ message: '', type: 'success', show: false });
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const combobox = ref<any>([
      {
        type: "contents",
        items: [
        { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
          { id: false, label: language.value["COMBOBOX"]["contents_option2"][locale.value] }, // 실종
          { id: false, label: language.value["COMBOBOX"]["contents_option3"][locale.value] }, // 자살의심(자해)
          { id: false, label: language.value["COMBOBOX"]["contents_option4"][locale.value] }, // 주차뺑소니(물피도주)
          { id: false, label: language.value["COMBOBOX"]["contents_option5"][locale.value] }, // 공공시설물 파손
          { id: false, label: language.value["COMBOBOX"]["contents_option6"][locale.value] }, // 절도(용의자 추적)
          { id: false, label: language.value["COMBOBOX"]["contents_option7"][locale.value] }, // 폭행(특수상해)
          { id: false, label: language.value["COMBOBOX"]["contents_option8"][locale.value] }, // 무단투기
          { id: false, label: language.value["COMBOBOX"]["contents_option9"][locale.value] }, // 교통사고(차량)
          { id: false, label: language.value["COMBOBOX"]["contents_option10"][locale.value] }, // 교통사고(대인)
          { id: false, label: language.value["COMBOBOX"]["contents_option11"][locale.value] }, // 음주운전 의심
          { id: false, label: language.value["COMBOBOX"]["contents_option12"][locale.value] }, // 사고발견(위험신고)
          { id: false, label: language.value["COMBOBOX"]["contents_option13"][locale.value] }, // 주거침입
          { id: true, label: language.value["COMBOBOX"]["contents_option14"][locale.value] }, // 기타
        ],
        selected: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
      },
      {
        type: "place",
        items: [
        { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
          { id: '1', label: language.value["COMBOBOX"]["place_option2"][locale.value] },  // 실내
        ],
        selected: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
      },
    ])

    const comboContents = (item: any) => {
      combobox.value[0].selected = item;
      editData.value.contents = item.label;
      if(!item.id){
        directContentInput.value = "";
      }
    }

    const comboPlace = (item: any) => {
      combobox.value[1].selected = item;
      editData.value.place = item.id;
    }

    const setStartDate = (date: any) => {
      editData.value.beginDt = moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    const setPeriodDate = (date: any) => {
      editData.value.period = moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    const closePopup = () => {
      emit('closePopup');
    };
    // 메모는 비어있다면 빈 문자열로 보내기 (현재 백엔드 구조상 NULL 넣기 안됨)
    const saveSetting = async (): Promise<void> => {
      popupContents.value.type='spin';
      popupContents.value.message=language.value["ADMIN"]["msg_edit_video"][locale.value] // '영상 정보 수정중...';
      popupContents.value.show = true;

      const res = await editVideoStore.updateVideoInfo(editData.value);

      if(res){
        popupContents.value.type = 'success';
        popupContents.value.message = language.value["ADMIN"]["msg_eidt_video_c"][locale.value] // '영상 정보 수정이 완료되었습니다.';
      }

    }

    const successUpdate = () => {
      popupContents.value.show = false;
      emit('closeEditPopup');
    }

    const defaultSetting = () => {
      // VMS인지 구별
      if(editData.value.deviceId !== null){
        editType.value = 'vms';
      }else{
        editType.value = 'file';
      }
      // 내용
      let flag = false;
      for(let i=0;i<combobox.value[0].items.length;i++){
        if(combobox.value[0].items[i].label === editData.value.contents){
          combobox.value[0].selected = combobox.value[0].items[i];
          flag = true;
        }
      }
      if(!flag){
        if(editData.value.contents == '' || editData.value.contents == null){
          combobox.value[0].selected = { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] };  // 선택 안 함
        }else{
          combobox.value[0].selected = { id: true, label: language.value["COMBOBOX"]["contents_option14"][locale.value] };  // 직접 입력(기타)
          directContentInput.value = editData.value.contents;
        }
      }

      //장소
      for(let i=0;i<combobox.value[1].items.length;i++){
        if(combobox.value[1].items[i].id === editData.value.place.toString()){
          combobox.value[1].selected = combobox.value[1].items[i];
        }
      }
    }


    onMounted(() => {
      defaultSetting();
    })

    return {
      closePopup,
      editType,
      editData,
      combobox,
      comboContents,
      directContentInput,
      comboPlace,
      setStartDate,
      setPeriodDate,
      saveSetting,
      popupContents,
      successUpdate,
      locale,
      language
    };
  },
});
