import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b8b2040"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "gray-fill-box right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_ObjectOption = _resolveComponent("ObjectOption")!
  const _component_SummaryOption = _resolveComponent("SummaryOption")!
  const _component_SummaryTab = _resolveComponent("SummaryTab")!
  const _component_StatisticOption = _resolveComponent("StatisticOption")!
  const _component_MaskingOption = _resolveComponent("MaskingOption")!
  const _component_TopBox = _resolveComponent("TopBox")!
  const _component_OriginVideo = _resolveComponent("OriginVideo")!
  const _component_SummaryVideo = _resolveComponent("SummaryVideo")!
  const _component_MaskingVideo = _resolveComponent("MaskingVideo")!
  const _component_Object = _resolveComponent("Object")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_SummarySelectPopup = _resolveComponent("SummarySelectPopup")!
  const _component_MaskingSelectPopup = _resolveComponent("MaskingSelectPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_ObjectOption, { onPlayObject: _ctx.playObject }, null, 8, ["onPlayObject"]), [
        [_vShow, _ctx.currPage == 'object']
      ]),
      _withDirectives(_createVNode(_component_SummaryOption, null, null, 512), [
        [_vShow, _ctx.currPage == 'origin']
      ]),
      _withDirectives(_createVNode(_component_SummaryTab, null, null, 512), [
        [_vShow, _ctx.currPage == 'summary']
      ]),
      _withDirectives(_createVNode(_component_StatisticOption, null, null, 512), [
        [_vShow, _ctx.currPage == 'statistics']
      ]),
      _withDirectives(_createVNode(_component_MaskingOption, { onPlayObject: _ctx.playObject }, null, 8, ["onPlayObject"]), [
        [_vShow, _ctx.currPage == 'masking']
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TopBox),
        _withDirectives(_createVNode(_component_OriginVideo, { ref: "originVideoTab" }, null, 512), [
          [_vShow, _ctx.currPage == 'origin']
        ]),
        _withDirectives(_createVNode(_component_SummaryVideo, { onPlayObject: _ctx.playObject }, null, 8, ["onPlayObject"]), [
          [_vShow, _ctx.currPage == 'summary' || _ctx.currPage == 'masking']
        ]),
        _withDirectives(_createVNode(_component_MaskingVideo, null, null, 512), [
          [_vShow, _ctx.currPage == 'masking' && (_ctx.maskingStatus == 'showing' || _ctx.maskingStatus == 'complete' || _ctx.maskingStatus == 'error')]
        ]),
        _withDirectives(_createVNode(_component_Object, null, null, 512), [
          [_vShow, _ctx.currPage == 'object']
        ]),
        _withDirectives(_createVNode(_component_Statistics, null, null, 512), [
          [_vShow, _ctx.currPage == 'statistics']
        ])
      ]),
      (_ctx.popup.summary)
        ? (_openBlock(), _createBlock(_component_SummarySelectPopup, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.popup.masking)
        ? (_openBlock(), _createBlock(_component_MaskingSelectPopup, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}