import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/library/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-6f8f1010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-background" }
const _hoisted_2 = { class: "popup-box" }
const _hoisted_3 = { class: "join-input-wrap" }
const _hoisted_4 = { class: "popup-title" }
const _hoisted_5 = { class: "join-input-box" }
const _hoisted_6 = { class: "input-wrap" }
const _hoisted_7 = { class: "input-wrap" }
const _hoisted_8 = { class: "input-wrap" }
const _hoisted_9 = { class: "input-wrap" }
const _hoisted_10 = { class: "input-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComboboxEdit = _resolveComponent("ComboboxEdit")!
  const _component_PopupModule = _resolveComponent("PopupModule")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "close-btn",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closePopup()))
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.language["ADMIN"]["add_vms"][_ctx.locale]), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ComboboxEdit, {
              onSelectedItem: _ctx.selectedItem,
              items: _ctx.searchArray,
              selected: _ctx.selectedSearch,
              boxStyle: {height:'4.6', background:'#25272A', border:'#575D64'},
              currCombo: 'vmsAdd'
            }, null, 8, ["onSelectedItem", "items", "selected"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "gray-box",
              placeholder: "IP",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputData.ip) = $event))
            }, null, 512), [
              [_vModelText, _ctx.inputData.ip]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "gray-box",
              placeholder: "PORT",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputData.port) = $event))
            }, null, 512), [
              [_vModelText, _ctx.inputData.port]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "gray-box",
              placeholder: "ID",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputData.id) = $event))
            }, null, 512), [
              [_vModelText, _ctx.inputData.id]
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              class: "gray-box",
              placeholder: "PW",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputData.password) = $event))
            }, null, 512), [
              [_vModelText, _ctx.inputData.password]
            ])
          ])
        ]),
        _createElementVNode("button", {
          class: "blue-box",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.vmsAdd()))
        }, _toDisplayString(_ctx.language["ADMIN"]["add_vms"][_ctx.locale]), 1)
      ])
    ]),
    (_ctx.popupContents.show)
      ? (_openBlock(), _createBlock(_component_PopupModule, {
          key: 0,
          message: _ctx.popupContents.message,
          type: _ctx.popupContents.type,
          onErrorClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.popupContents.show = false)),
          onCheckClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.successCheck())),
          onCancelClick: _cache[8] || (_cache[8] = ($event: any) => {_ctx.popupContents.show = false;_ctx.loadingCheck=false;}),
          onDownloadClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.downloadClick())),
          onAccessClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.accessClick()))
        }, null, 8, ["message", "type"]))
      : _createCommentVNode("", true)
  ]))
}