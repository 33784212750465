
import { defineComponent, ref, computed, watch } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { API_BASE_URL } from '@/api';
import VueHighcharts from 'vue3-highcharts';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    VueHighcharts
  },
  setup() {
    const summaryStore = useSummaryStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const summaryResult = computed(() => summaryStore.getSummaryResult);
    const statsData = ref();
    const chartData = ref();
    const heatmap = ref();
    const pathmap = ref();
    const showChart = ref(false);
    const showMap = ref(true); // true일 경우 히트맵, false일 경우 디렉션 이미지를 보여줌
    const sum = ref(0);
    const calcStatsSum = () => { // 모든 데이터의 합
      sum.value = 0;
      for (let key in statsData.value) {
        sum.value += statsData.value[key];
      }
    }

    const chartOptions = ref({});
    const total = ref<any>([]);
    
    const calcChartSum = () => { // 카테고리별 합
      total.value = [];
      const timeCategory = ref<any>([]);
      const person = ref<any>([]);
      const animal = ref<any>([]);
      const bike = ref<any>([]);
      const car = ref<any>([]);

      for (let i = 0; i < chartData.value.length; i++) {
        const data = chartData.value[i];
        const time = data.t.split(' ');
        timeCategory.value.push(time[0] + '<br/><b style="color: #2E8EFF;">' + time[1] + '</b>');
        total.value.push({
          name: time[0] + '<br/>' + time[1],
          y: data.man + data.woman + data.cat + data.dog + data.bike + data.mortorcycle + data.car + data.truck + data.bus
        });
        person.value.push(data.man + data.woman);
        animal.value.push(data.cat + data.dog);
        bike.value.push(data.bike + data.mortorcycle);
        car.value.push(data.car + data.truck + data.bus);
      }
      chartOptions.value = {
        chart: {
          backgroundColor: "transparent"
        },
        "legend": {
          itemStyle: {
            fontSize: '1.5vmin',
            color: 'rgb(200,200,200)'
          },
          itemHoverStyle: {
            color: 'rgb(220,220,220)',
            fontSize: '1.7vmin'
          }
        },
        credits: {
          "enabled": false
        },
        "title": {
          "text": "",
        },
        xAxis: {
          labels: {
            rotation: 0,
            allowOverlap: true,
            style: {
              fontSize: '1.5vmin',
              color: 'rgb(212,212,212)'
            }
          },
          categories: timeCategory
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            Formatter: "formatYAxis",
            style: {
              fontSize: '1.5vmin',
              color: 'rgb(212,212,212)'
            }
          },
        },
        plotOptions: {
          spline: {
            lineWidth: 5,
            marker: {
              enabled: false
            }
          },
          column: {
            stacking: "normal",
          },
          "series": {
            borderWidth: 0,
            "dataLabels": {
              "enabled": false,
            }
          }
        },
        series: [
          {
            name: language.value["PLAYER"]["people"][locale.value],
            color: "#3F59F0",
            type: "column",
            data: person.value
          },
          {
            name: language.value["PLAYER"]["animal"][locale.value],
            color: "#6B6ED6",
            type: "column",
            data: animal.value
          },
          {
            name: "2"+language.value["PLAYER"]["wheel"][locale.value],
            color: "#139AC9",
            type: "column",
            data: bike.value
          },
          {
            name: "4"+language.value["PLAYER"]["wheel"][locale.value],
            color: "#6DC363",
            type: "column",
            data: car.value
          },
          {
            name: language.value["PLAYER"]["statistics_total"][locale.value],
            color: '#FFFFFF',
            type: "line",
            data: total.value
          },
        ]
      };
      showChart.value = true;
    }

    watch(() => summaryResult.value, () => {
      heatmap.value = summaryResult.value.heatmap;
      pathmap.value = summaryResult.value.pathmap;
      
      if(summaryResult.value.statistic !== null) {
        const res = JSON.parse(summaryResult.value.statistic);
        statsData.value = res[0];
        chartData.value = res[1];

        calcStatsSum();
        calcChartSum();
      }
    });

    return {
      summaryResult,
      chartOptions,
      statsData,
      chartData,
      calcStatsSum,
      total,
      sum,
      showChart,
      API_BASE_URL,
      heatmap,
      pathmap,
      showMap,
      locale,
      language
    };
  },
});
