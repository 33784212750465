import axios from "axios";
import {milestoneVideoUpload} from "@/api";
import moment from "moment";


export var MSAPI = {
  login(ip,port,id,passwd,callback){
    let checkConnect = function(a){
      if (a.response !== null) {
        if (!a.response.isError) {
          XPMobileSDKSettings.MobileServerURL = 'http://' + ip + ':' + port
          login_()
        } else {
          callback(a.response.errorCode)
        }
      }
      else{
        setTimeout(() => { checkConnect(a) }, 1000);
      }
    }
    let checkLogin = function (a){
      if (a.response !== null) {
        if (!a.response.isError) {
          XPMobileSDKSettings.isLogin = true
          callback(false,'login success')
        } else {
          XPMobileSDKSettings.MobileServerURL=''
          console.log('login error>> ',a.response.errorCode)
          callback(a.response.errorCode)
        }
      }
      else{
        setTimeout(() => { checkLogin(a) }, 1000);
      }
    }
    let login_ = function (){
      if(XPMobileSDKSettings.isLogin){
        callback(false,'login success')
      }else{
        let a = XPMobileSDK.login(id,passwd, null)
        checkLogin(a)
      }

    }
    let connect = function(){
      if(XPMobileSDKSettings.MobileServerURL!==''){
        login_()
      }else{
        let a = XPMobileSDK.connect('http://' + ip + ':' + port)
        checkConnect(a)

      }
    }

    if(window.XPMobileSDK ===undefined){
      window.LoadMobileSdk(connect)
    }
    else{
      connect()
    }
  },
  getCameraList: function (loginInfo, callback) {
    XPMobileSDK.getAllViews(function (items) {
      let resultCam=[]
      if(items !== undefined){
        // console.log('milestone camera 1 >> ', items);
        for (let i = 0; i < items[0].Items[0].Items[0].Items.length; i++) {
          resultCam.push({
            type: "MILESTONE",
            vms_index: loginInfo.vmsIndex,
            url: loginInfo.url,
            auth_token: loginInfo.auth_token,
            api_serial: loginInfo.api_serial, 
            user_serial: loginInfo.user_serial,
            id: items[0].Items[0].Items[0].Items[i].Id,
            name: items[0].Items[0].Items[0].Items[i].Name,
            thumbnail: "",
            selected: false,
            login: {
              id: loginInfo.accountId,
              password: loginInfo.accountPw
            }
          })
        }
        callback(false,resultCam)
      }
      else{
        callback(true)
      }
      
    })
  },
  getCamThumbnail: function (dev_id, callback) {
    let b64toBlob = function(b64Data, contentType, sliceSize){
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
    let succGetThumbnail=function(thumbnail){
      const imageBlob = b64toBlob(thumbnail, 'image/jpeg', 512);
      let result = window.URL.createObjectURL(imageBlob);
      callback(false,result)
    }
    let failGetThumbnail = function(err){
      console.log(err)
      callback(err)
    }
    let params = {
      cameraId: dev_id,
      width: 1280,
      height: 720,
      time: Date.now()
    };
    XPMobileSDK.getThumbnailByTime(params,succGetThumbnail,failGetThumbnail)
  },
  async downloadExport(url){
    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], "result.zip");
    return file
  },
  downloadVideo: async function ( dev_id, sDate, fDate, vid, fileValue, index,  callback){
    // console.log('milestone sDate >>>>>>>>>>>>>>>>>>>>>>>>>>> ', sDate);
    let investigationId = undefined
    let start = sDate.getTime();
    let end = fDate.getTime();
    let vidInfo = {video_id:vid,req_date:sDate};

    let getExportState = function (exportId){
      XPMobileSDK.getExport(exportId, function(status){
        // console.log('status >> ', status.State);
        if ((status.State >= 0) & (status.State <= 100)) {
          setTimeout(() => { getExportState(exportId) }, 1000);
        }
        else if (status.State == 101) {
          XPMobileSDK.createExportDownloadLink(status.ExportId, status.InvestigationId, 'MKV', function(res){
            let exportLink = XPMobileSDKSettings.MobileServerURL + "/" + res;
            // console.log(">>>>>>>>>>>>>>>>>>>>createExportDownloadLink<<<<<<<<<<<<<<<<<<<", exportLink);
            MSAPI.downloadExport(exportLink).then(
              async function (file){
                let response = await milestoneVideoUpload(fileValue, start, file, index);
                callback(false, response);
                XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
              }
            )
          },
          function(err){
            vidInfo.err = err
            console.log("createExportDownloadLink error >> ", err)
            callback(vidInfo)
            XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
          })
        }
        else{
          vidInfo.err = {errorCode : status.StatusProgress}
          console.log("getExport error 2 >> ", status.StatusProgress)
          callback(vidInfo)
          XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
        }
      },function (err){
          vidInfo.err = err
          console.log("getExport error >> ", err)
          callback(vidInfo)
          XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
      })
    }
    let getInvestigationState=function (investigationId){
      XPMobileSDK.getInvestigation(investigationId,function (res){
        if(res.StatusProgress>=0 && res.StatusProgress<=100) {
          setTimeout(() => {
            getInvestigationState(investigationId)
          }, 1000);
        }else if (res.StatusProgress == 101) {
          // console.log('complete');
          XPMobileSDK.startInvestigationExport(investigationId,'MKV','No',"",function (data){
            getExportState(data)
          }, function (err){
            vidInfo.err = err
            console.log("startInvestigationExport error >> ", err)
            callback(vidInfo)
            XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
          })
        }
        else{
          console.log("startInvestigationExport error >> ", res.StatusProgress)
          callback({msg:'export'})
          XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
        }
      },function (err){
        vidInfo.err = err
        console.log("getInvestigationState error >> ", err)
        callback(vidInfo)
        XPMobileSDK.deleteInvestigation(investigationId,function (res){console.log('deleteInvestigation',res),function (err){console.log('err deleteInvestigation',err)}})
      })
    }
    XPMobileSDK.createInvestigation({
      StartTime:start,
      EndTime:end,
      Name:vid+'_'+sDate.getTime().toString(),
      State:'saving',
      CameraId:[dev_id],
      ProcessingMessage:'Yes'},
    function (res) {
        investigationId = res.ItemId
        getInvestigationState(res.ItemId)
    },function (err) {
        vidInfo.err = err
        console.log("cresteinvest error >> ", err)
        callback(vidInfo)
    })

  }

}