import { createApp } from 'vue'
// import store from './store'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router';
import { setupCalendar, DatePicker } from 'v-calendar';
import VueKonva from 'vue-konva';
import VueVideoPlayer from '@videojs-player/vue';
import VueHighcharts from 'vue3-highcharts';

const app = createApp(App);
app.use(VueKonva);
app.use(VueVideoPlayer);
app.use(VueHighcharts);
app.use(createPinia()).use(router).use(setupCalendar, {}).mount('#app');
app.component('VDatePicker', DatePicker);