import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a8e89b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "player-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoTimeBox = _resolveComponent("VideoTimeBox")!
  const _component_video_player = _resolveComponent("video-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VideoTimeBox, { class: "video-time-area" }),
    (_ctx.video !== undefined)
      ? (_openBlock(), _createBlock(_component_video_player, {
          key: 0,
          class: "video-js",
          ref: "originVideoRef",
          src: `${_ctx.API_BASE_URL}${_ctx.video.videoPath}`,
          type: "video/mp4",
          crossorigin: "anonymous",
          playsinline: "",
          controls: "",
          autoplay: "any",
          loop: true,
          "playback-rates": [0.5, 1.0, 1.5, 2.0],
          onMounted: _ctx.handleMounted,
          onReady: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleEvent(_ctx.player))),
          onPlay: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleEvent($event))),
          onPause: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleEvent($event))),
          onPlaying: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleEvent($event))),
          onTimeupdate: _cache[4] || (_cache[4] = ($event: any) => (_ctx.timeUpdate(_ctx.player?.currentTime())))
        }, null, 8, ["src", "onMounted"]))
      : _createCommentVNode("", true)
  ]))
}