
import { defineComponent, computed, } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';

export default defineComponent({
  components:{

  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const colorCondition = computed(() => conditionStore.getColor);  
    const allCheckColor = computed(() => conditionStore.getAllColor); 

    // 색상 클릭
    const colorSelect = (value:any) => {
      value.checked = !value.checked;
      conditionStore.setColor(colorCondition.value)
      // emit('colorSelect', colorCondition);
      checkColorAll();
    };
    // 색상이 전부 선택되었는지 확인하여 색상 전체 선택 상태 변경
    const checkColorAll = () => {
      let check = true;
      for(let i=0;i<colorCondition.value.length;i++){
        if(!colorCondition.value[i].checked){
          check = false;
          break;
        }
      }
      conditionStore.setAllColor(check);
    };
    // 색상 전체 선택 클릭
    const colorAllCheck = () => {
      let check = !allCheckColor.value;

      for(let i=0;i<colorCondition.value.length;i++){
        colorCondition.value[i].checked = check;
      }

      conditionStore.setAllColor(check);
      conditionStore.setColor(colorCondition.value)
    };

    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    const reset = () => {
      for(let i=0;i<colorCondition.value.length;i++){
        colorCondition.value[i].checked = true;
      }
      conditionStore.setAllColor(true);
    }

    return {
      locale,
      language,
      setOptionOpenState,
      colorCondition,
      allCheckColor,
      colorSelect,
      colorAllCheck,
      reset
    }
  }
  
})
