import { defineStore } from 'pinia'

interface Popup {
  name: string,
  state: boolean
}

interface PopupState {
  popupState: Popup[];
}

export const useUploadPopupStateStore = defineStore('uploadpopup', {
  state: (): PopupState => ({
    popupState: [
      {
        name: "isOpen", // 팝업이 한 개라도 열려있는지 체크
        state: false
      },
      {
        name: "uploadPopup", // 업로드 방식 선택 팝업
        state: false
      },
      { 
        name: "fileUploadPopup", // 파일 업로드 팝업
        state: false
      },
      { 
        name: "vmsUploadPopup", // vms 업로드 팝업
        state: false
      },
      { 
        name: "progressPopup", // 업로드 퍼센트 팝업
        state: false
      },
    ],
  }),
  actions: {
    setState(name: string, isOpen: boolean) {
      const popup = this.popupState;
      for(let i=0; i<popup.length; i++) {
        popup[i].state = false;
        
        if(popup[i].name == name) {
          popup[i].state = isOpen;
        }

        if(isOpen) {
          popup[0].state = true;
        }
      }
    }
  },
  getters: {
    getpopupState(state): Popup[] {
      return state.popupState;
    },
    getIsOpen(state): boolean {
      const popupState = state.popupState.find(popup => popup.name === "isOpen");
      return popupState ? popupState.state : false;
    },
    getUploadPopup(state): boolean {
      const popupState = state.popupState.find(popup => popup.name === "uploadPopup");
      return popupState ? popupState.state : false;
    },
    getFileUploadPopup(state): boolean {
      const popupState = state.popupState.find(popup => popup.name === "fileUploadPopup");
      return popupState ? popupState.state : false;
    },
    getVmsUploadPopup(state): boolean {
      const popupState = state.popupState.find(popup => popup.name === "vmsUploadPopup");
      return popupState ? popupState.state : false;
    },
    getProgressPopup(state): boolean {
      const popupState = state.popupState.find(popup => popup.name === "progressPopup");
      return popupState ? popupState.state : false;
    },
  },
});
