
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationStore } from '../../store/navigation'
import { useLocaleStore } from '../../store/locale'
import { useUserStore } from '../../store/loginUser'
import { useLibraryMenuStore } from '../../store/libraryMenu';

export default defineComponent({
  components: {
  },
  setup() {
    const navigationStore = useNavigationStore();
    const router = useRouter();
    const goToAboutPage = (page: string) => {
      router.push({'name': page});
      navigationStore.setCurrentPage(page);
    }

    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const inputId = ref('');
    const inputPassword = ref('');
    const showErrorMsg = ref(false);

    const libraryMenuStore = useLibraryMenuStore();

    const login = async (): Promise<void> => {
      const res = await useUserStore().getUser(inputId.value, inputPassword.value);
      if(res !== null) {
        if(res.isLoggedIn) {
          goToAboutPage('home');
          useUserStore().checkAccept();
          libraryMenuStore.setCurrTab('videos');
        } else {
          showErrorMsg.value = true;
        }
      }
    }

    return {
      goToAboutPage,
      locale,
      language,
      login,
      inputId,
      inputPassword,
      showErrorMsg
    };
  },
});
