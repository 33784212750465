import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3815321e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "dwell-video-box",
  ref: "dwellVideoBoxRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.videoSrc !== undefined)
      ? (_openBlock(), _createBlock(_component_video_player, {
          key: 0,
          controls: "",
          ref: "dwellVideoRef",
          src: `${_ctx.API_BASE_URL}${_ctx.videoSrc}`,
          class: "summary-video",
          autoplay: "true",
          muted: "muted",
          onMounted: _ctx.handleMounted,
          onPause: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPauseDwellVideo(_ctx.player?.currentTime()))),
          onSeeking: _ctx.onSeekingDwellVideo,
          onSeeked: _ctx.onSeekedDwellVideo,
          onPlay: _ctx.onPlayDwellVideo
        }, null, 8, ["src", "onMounted", "onSeeking", "onSeeked", "onPlay"]))
      : _createCommentVNode("", true)
  ], 512))
}