import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_Library = _resolveComponent("Library")!
  const _component_UploadPopup = _resolveComponent("UploadPopup")!
  const _component_FileUploadPopup = _resolveComponent("FileUploadPopup")!
  const _component_VmsUploadPopup = _resolveComponent("VmsUploadPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavBar),
    _createVNode(_component_Library),
    (_ctx.uploadPopupState)
      ? (_openBlock(), _createBlock(_component_UploadPopup, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.fileUploadPopupState)
      ? (_openBlock(), _createBlock(_component_FileUploadPopup, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.vmsUploadPopupState)
      ? (_openBlock(), _createBlock(_component_VmsUploadPopup, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}