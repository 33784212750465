import { defineStore } from 'pinia'
import { useSummaryMenuStore } from './summaryMenu';

// 요약 옵션 열림/닫힘 상태 관리
export const useSummaryPageStore = defineStore('summarypage', {
  state: () => ({
    currPage: "origin" // [origin, summary, object, masking, statistics]
  }),
  actions: {
    setCurrPage(page: string) {
      this.currPage = page;
      useSummaryMenuStore().setDwellShow(false);
      useSummaryMenuStore().setDwellVideoPopup(false);
    }
  },
  getters: {
    getCurrPage(state) {
      return state.currPage;
    },
  },
});
1