
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useUserStore } from '../../store/loginUser';
import { useUploadPopupStateStore } from '../../store/uploadPopup';
import { useFilesStore, FileData } from '../../store/files';
import { useDatePickerStore } from '../../store/datepicker';
import { useProgressStore } from '../../store/progress';
import { useLocaleStore } from '@/store/locale';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';
import DatePicker from "../../components/Common/DatePicker.vue";
import PopupModule from '@/components/Popup/PopupModule.vue';
import lodash from 'lodash';

export default defineComponent({
  components: {
    DatePicker,
    PopupModule,
    ComboboxEdit
  },
  setup() {
    /* store */
    const localeStore = useLocaleStore();
    const userStore = useUserStore();
    const popupStateStore = useUploadPopupStateStore();
    const filesStore = useFilesStore();
    const dpStore = useDatePickerStore();
    const progressStore = useProgressStore();
    
    /* data */
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const userId = userStore.getUserId;
    const userName = userStore.getUserName;
    const division = userStore.getDivision;
    const files = computed(() => filesStore.getFiles); // 업로드한 파일 정보
    const fileInput = ref<HTMLInputElement | null>(null);
    const isDragOver = ref(false);
    const isShiftKeyPressed = ref(false); // Shift 키 눌림 여부
    const isCtrlKeyPressed = ref(false); // Ctrl 키 눌림 여부
    const allSaved = ref(false);
    const selectedIndexes = ref<number[]>([0]);
    const selectedDate = computed(() => dpStore.getDate);
    const uploading = computed(() => progressStore.getUploadState);
    const popupContents = ref({ 
      message: '',
      type: '', 
      show: false 
    });
    // const watchDirectInput = computed(() => contentsStore.getSelectedItem); // 직접 입력 선택 시 focus 하기 위한 변수
    const inputBox = ref<HTMLInputElement | null>(null);
    const totalSize = ref(0);

    const combobox = ref<any>([
      {
        type: "contents",
        items: [
          { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
          { id: false, label: language.value["COMBOBOX"]["contents_option2"][locale.value] }, // 실종
          { id: false, label: language.value["COMBOBOX"]["contents_option3"][locale.value] }, // 자살의심(자해)
          { id: false, label: language.value["COMBOBOX"]["contents_option4"][locale.value] }, // 주차뺑소니(물피도주)
          { id: false, label: language.value["COMBOBOX"]["contents_option5"][locale.value] }, // 공공시설물 파손
          { id: false, label: language.value["COMBOBOX"]["contents_option6"][locale.value] }, // 절도(용의자 추적)
          { id: false, label: language.value["COMBOBOX"]["contents_option7"][locale.value] }, // 폭행(특수상해)
          { id: false, label: language.value["COMBOBOX"]["contents_option8"][locale.value] }, // 무단투기
          { id: false, label: language.value["COMBOBOX"]["contents_option9"][locale.value] }, // 교통사고(차량)
          { id: false, label: language.value["COMBOBOX"]["contents_option10"][locale.value] }, // 교통사고(대인)
          { id: false, label: language.value["COMBOBOX"]["contents_option11"][locale.value] }, // 음주운전 의심
          { id: false, label: language.value["COMBOBOX"]["contents_option12"][locale.value] }, // 사고발견(위험신고)
          { id: false, label: language.value["COMBOBOX"]["contents_option13"][locale.value] }, // 주거침입
          { id: true, label: language.value["COMBOBOX"]["contents_option14"][locale.value] }, // 직접 입력(기타)
        ],
        selected: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
        defaultItem: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
      },
      {
        type: "place",
        items: [
          { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
          { id: '1', label: language.value["COMBOBOX"]["place_option2"][locale.value] },  // 실내
        ],
        selected: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
        defaultItem: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
      },
      {
        type: "period",
        items: [
          { id: '7', label: '7'+language.value["COMBOBOX"]["period_option1"][locale.value] }, // 7일
          { id: '30', label: '1'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 1개월
          { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
          { id: '180', label: '6'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 6개월
          { id: '36500', label: language.value["COMBOBOX"]["period_option3"][locale.value] }, // 제한 없음
        ],
        selected: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
        defaultItem: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
      },
    ])

    const contentsComboRef = ref();
    const placeComboRef = ref();
    const periodComboRef = ref();

    const initData = ref<FileData>({
      file: {} as File,
      data: {
        videoId: "",
        fileName: "",
        date: new Date(),
      },
      info: {
        contents: combobox.value[0].defaultItem,
        contentsInput: "",
        place: combobox.value[1].defaultItem,
        period: combobox.value[2].defaultItem,
        userId: userId,
        name: userName,
        division: division,
        officeNum: "",
        memo: "",
        saved: false
      },
    });
    const selected = ref(initData.value);

    /* comboboxEdit */ 
    const comboContents = (item: any) => {
      combobox.value[0].selected = item;
      if(!item.id){
        selected.value.info.contentsInput = "";
      } 
      // 직접 입력 선택 시 자동 focus 기능
      // else {
      //   if (inputBox.value) {
      //     inputBox.value.focus();
      //   }
      // }
    }

    const comboPlace = (item: any) => {
      combobox.value[1].selected = item;
    }

    const comboPeriod = (item: any) => {
      combobox.value[2].selected = item;
    }
    /* comboboxEdit */ 

    /* function */
    const setPopupState = () => {
      popupStateStore.setState("fileUploadPopup", false);
    }
    const openFileInput = () => { // 파일 첨부 버튼 클릭 시
      if(fileInput.value) {
        fileInput.value.click();
      }
    };
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      isDragOver.value = true;
    };
    const handleDragEnter = (event: DragEvent) => {
      event.preventDefault();
    };
    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      isDragOver.value = false;
    };
    const handleFileInputChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if(input.files) {
        setFiles(Array.from(input.files));
      }
    };
    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      isDragOver.value = false;
      if(event.dataTransfer && event.dataTransfer.files) {
        setFiles(Array.from(event.dataTransfer.files));
      }
    };
    const setFiles = (uploadFiles: File[]) => {
      for(let i=0; i<uploadFiles.length; i++) {
        if((uploadFiles[i].type).includes("video")) {
          let now = new Date();
          let videoId = 'VD_' + now.getTime() + '_' + Math.random().toString(36).slice(-4);
          let dateTime = new Date(uploadFiles[i].lastModified);
          dateTime.setSeconds(0);
          dateTime.setMilliseconds(0);

          const newFileData: FileData = {
            file: uploadFiles[i],
            data: {
              videoId: videoId,
              fileName: uploadFiles[i].name,
              date: dateTime,
            },
            info: {
              contents: combobox.value[0].defaultItem,
              contentsInput: "",
              place: combobox.value[1].defaultItem,
              period: combobox.value[2].defaultItem,
              userId: userId,
              name: userName,
              division: division,
              officeNum: "",
              memo: "",
              saved: false
            },
          };
          filesStore.setFile(newFileData);
          setSelectedFile();
        }
      }
      allSaved.value = false;
    }
    const setSelectedFile = () => {
      selected.value = lodash.cloneDeep(files.value[selectedIndexes.value[0]]);
      const file = selected.value;

      combobox.value[0].selected = file.info.contents;
      combobox.value[1].selected = file.info.place;
      combobox.value[2].selected = file.info.period;
      dpStore.setDate(file.data.date);
    }
    const initList = () => {
      filesStore.initFiles();
      selected.value = initData.value;
      selectedIndexes.value = [0];
      allSaved.value = false;
      dpStore.setDate(new Date());
    }
    const removeFile = (idx: number) => {
      filesStore.removeFile(idx);
      selectedIndexes.value = [0];

      if(files.value.length > 0) {
        setSelectedFile();
        checkAllSaved();
      } else {
        selected.value = initData.value;
        allSaved.value = false;
      }
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if(event.shiftKey) {
        isShiftKeyPressed.value = true;
      }
      if(event.ctrlKey) {
        isCtrlKeyPressed.value = true;
      }
    };
    const handleKeyUp = () => {
      isShiftKeyPressed.value = false;
      isCtrlKeyPressed.value = false;
    };
    const handleListClick = (event: MouseEvent) => {
      event.preventDefault(); // 파일 리스트를 클릭할 때 텍스트 선택을 방지하기 위해 이벤트의 기본 동작을 막음
    };
    const handleItemClick = (index: number, event: MouseEvent) => {
      if(isShiftKeyPressed.value) {
        if(selectedIndexes.value.length > 0) {
          const lastIndex = selectedIndexes.value[selectedIndexes.value.length - 1];
          const minIndex = Math.min(index, lastIndex);
          const maxIndex = Math.max(index, lastIndex);
          const newSelectedIndexes = [...selectedIndexes.value];
          for (let i = minIndex; i <= maxIndex; i++) {
            if (!newSelectedIndexes.includes(i)) {
              newSelectedIndexes.push(i);
            }
          }
          selectedIndexes.value = newSelectedIndexes;
        }
      } else if(isCtrlKeyPressed.value) {
        if(!selectedIndexes.value.includes(index)) {
          selectedIndexes.value.push(index);
        } else {
          const indexToRemove = selectedIndexes.value.indexOf(index);
          if (indexToRemove !== -1) {
            selectedIndexes.value.splice(indexToRemove, 1);
          }
        }
      } else {
        selectedIndexes.value = [index];
        setSelectedFile();
      }
      setOpenState("");
      event.stopPropagation(); // 이벤트 전파 막기
    };
    const isSelected = (index: number) => {
      return selectedIndexes.value.includes(index);
    };
    const saveHandler = () => {
      if(selectedIndexes.value.length == 1) {
        confirmHandler();
      } else {
        popupContents.value.type = "save";
        popupContents.value.message = language.value["UPLOAD"]["msg_all_save"][locale.value]; // 영상 제목을 제외한 모든 설정값을 동일하게 저장합니다.
      }
      popupContents.value.show = true;
    }
    const confirmHandler = () => {
      popupContents.value.type = "success";
      popupContents.value.message = language.value["UPLOAD"]["msg_saved"][locale.value]; // 영상 정보가 저장되었습니다.
      saveChanges();
    }
    const saveChanges = () => {
      const combo = combobox.value;
      const file = selected.value;

      // combobox 값 적용
      file.info.contents = combo[0].selected;
      file.info.place = combo[1].selected;
      file.info.period = combo[2].selected;
      file.data.date = selectedDate.value;
      
      for(let i=0; i<selectedIndexes.value.length; i++) {
        if(i == 0) { // 첫 번째 선택한 파일만 name, date 저장
          if(file.data.fileName == "") { // 제목이 빈 값인 경우 파일 이름을 자동으로 넣어줌
            file.data.fileName = file.file.name;
          }
          filesStore.editFileData(selectedIndexes.value[i], file);
        }
        filesStore.editFileInfo(selectedIndexes.value[i], file);
        checkAllSaved();
      }
      setSelectedFile();
    }
    const checkAllSaved = () => { // 모든 정보가 저장됐는지 체크한다
      for(let i=0; i<files.value.length; i++) {
        if(files.value[i].info.saved) {
          allSaved.value = true;
        } else {
          allSaved.value = false;
          break;
        }
      }
    }
    const uploadFiles = async (): Promise<void> => {
      if(allSaved.value) {
        popupStateStore.setState("fileUploadPopup", false);

        for(let i=0; i<files.value.length; i++) {
          progressStore.setProgressFile({
            file: files.value[i],
            camera: undefined,
            type: "file",
            videoId: files.value[i].data.videoId,
            name: files.value[i].data.fileName,
            percent: 0,
            completed: false,
            inProgress: false,
            res: {state: true, msg: ""},
            cancelToken: undefined
          });

          if(i == files.value.length-1) {
            if(!uploading.value){
              progressStore.uploadVideoTotal();
            }
            popupStateStore.setState("progressPopup", true);
          }
        }
      }
    };
    const setOpenState = (target: string) => {
      if(target == 'contents') {
        placeComboRef.value.toggleClose();
        periodComboRef.value.toggleClose();
      }
      else if(target == 'place') {
        contentsComboRef.value.toggleClose();
        periodComboRef.value.toggleClose();
      }
      else if(target == 'period') {
        contentsComboRef.value.toggleClose();
        placeComboRef.value.toggleClose();
      }
      else {
        contentsComboRef.value.toggleClose();
        placeComboRef.value.toggleClose();
        periodComboRef.value.toggleClose();
      }

    }
    const handleComboboxSelect = () => {
      if (inputBox.value) {
        inputBox.value.focus();
      }
    };

    const handleRefresh = (e: BeforeUnloadEvent) => { // 새로고침 시 alert
      e.preventDefault();
      e.returnValue = '';
    };

    const fileLength = computed(() => filesStore.getFilesLength);
    const checkTotalSize = () => {
      totalSize.value = 0;
      if(files.value.length !== 0){
        for(let i=0;i<files.value.length;i++){
          totalSize.value += files.value[i].file.size;
        }
      }
    }

    // 파일 사이즈 변환
    const changeFileSize = (size:number) => {
      // console.log('changeFileSize');
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (size === 0) 
        return '0 Byte';
      const i = (Math.floor(Math.log(size) / Math.log(1000)));
      return (Math.round((size / Math.pow(1024, i))*100)/100).toFixed(2) + ' ' + sizes[i];
    }

    watch(() => fileLength.value, () => {
      checkTotalSize();
    })

    onMounted(() => {
      filesStore.initFiles();
      fileInput.value = document.querySelector('input[type="file"]');
      inputBox.value = document.querySelector('.direct-input');
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      window.addEventListener('beforeunload', handleRefresh); 
    });

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', handleRefresh);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      setOpenState(""); // combobox close
    });

    return {
      locale,
      language,
      files,
      initList,
      selected,
      removeFile,
      openFileInput,
      setPopupState,
      handleFileInputChange,
      handleDragOver,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      handleItemClick,
      isSelected,
      handleListClick,
      isDragOver,
      selectedIndexes,
      saveHandler,
      saveChanges,
      setOpenState,
      allSaved,
      handleComboboxSelect,
      uploadFiles,
      popupContents,
      confirmHandler,
      comboContents,
      comboPlace,
      comboPeriod,
      combobox,
      contentsComboRef,
      placeComboRef,
      periodComboRef,
      totalSize,
      changeFileSize
    };
  },
});
