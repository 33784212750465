
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationStore } from '../../store/navigation';
import { useUserStore } from '../../store/loginUser';
import { useServerStateStore } from '../../store/serverState';
import { useUploadPopupStateStore } from '@/store/uploadPopup';
import { useProgressStore } from '../../store/progress';
import EditAccount from '../Management/EditAccount.vue';
import { useLocaleStore } from '@/store/locale';
import { cancelVmsUpload } from '@/api';
import PopupModule from '@/components/Popup/PopupModule.vue';

export default defineComponent({
  components: {
    EditAccount,
    PopupModule
},
  setup() {
    const userStore = useUserStore();
    const router = useRouter();
    const navigationStore = useNavigationStore();
    const serverStore = useServerStateStore();
    const popupStateStore = useUploadPopupStateStore();
    const progressStore = useProgressStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const uploading = computed(() => progressStore.getUploadState);
    const progressFiles = computed(() => progressStore.getProgressFiles);
    const popupContents = ref({ message: '', type: '', show: false });

    const openProgressPopup = computed(() => popupStateStore.getProgressPopup);
    const toggleProgressPopup = () => {
      popupStateStore.setState("progressPopup", !openProgressPopup.value);
    }

    const goToAboutPage = (page: string) => {
      router.push({'name': page});
      navigationStore.setCurrentPage(page);
    }

    const showEditAccount = ref<any>(false);
    const selectUser = ref<any>({ 
      id: '', 
      name: '', 
      role: '', 
      division: '',
      password: '',
      salt: '',
      lastAccess: ''
    });

    /* user info box */
    const loginUser = computed(() => userStore.getUserInfo);
    const userBoxIsOpen = ref(false);
    const toggleUserBox = (state: boolean) => {
      userBoxIsOpen.value = !state;
    }
    const comboBoxHeight = computed(() => ({ 
      height: userBoxIsOpen.value ? "clamp(84px, 11.6vh, 11.6vh)" : "clamp(26px, 3.7vh, 3.7vh)"
    }));
    const logout = () => {
      if(uploading.value){
        popupContents.value.message = language.value["COMMON"]["logout_cancel"][locale.value];  // "로그아웃 할 경우 업로드 중인 영상들은 취소됩니다.<br>로그아웃 하시겠습니까?"
        popupContents.value.type = 'warning';
        popupContents.value.show = true;
      }else {
        useUserStore().removeUser();
        goToAboutPage("login");
      }
    }

    const confirmHandler = () => {
      progressStore.allCancleVideo();
      useUserStore().removeUser();
      goToAboutPage("login");
    }
    /* user info box */

    /* server state */
    const getServerInfo = () => {
      serverStore.startPollingServerState();
    }
    const stopServerInfo = computed(() => serverStore.stopServerInfoPolling());
    const serverState = computed(() => serverStore.getServerState);
    const diskCapacity = computed(() => serverStore.getDiskCapacity);
    const serverError = computed(() => serverStore.getServerError); // 하나 이상의 서버에 에러가 발생한 경우 true
    /* server state */

    // 내 정보 수정
    const editUser = () => {
      let currUser = sessionStorage.getItem('userInfo');
      if(currUser !== null){
        let changeUser = JSON.parse(currUser);
        if(changeUser !==null){
          selectUser.value.id = changeUser.id;
          selectUser.value.name = changeUser.name;
          selectUser.value.division = changeUser.division;
          showEditAccount.value = true;
        }
      }
    }

    const goLibrary = () => {
      router.push({'name': 'home'});
    }

    const uploadCancel = (file: any) => {
      if(!file.inProgress){
        file.inProgress = true;
        file.completed = true;
        if(file.type === 'vms'){
          file.res = {state: false, msg: "vms_cancel"}
        }
        else if(file.type === 'file'){
          file.res = {state: false, msg: "file_cancel"}
        }
      }
      else{
        if(file.type === 'vms'){
          // file.completed = true;
          file.res = {state: false, msg: "vms_cancel_ing"}
          if(file.camera.camera.type !== 'MILESTONE'){
            cancelVmsUpload(file.videoId);
          }
          else {
            useProgressStore().setCancelVideo(file.videoId);
          }
        }
        else if(file.type === 'file'){
          file.cancelToken.cancel();
        }
      }
    }

    onMounted(() => {
      getServerInfo(); // get server state
    });

    onUnmounted(() => {
      stopServerInfo.value;
    });

    return {
      loginUser,
      logout,
      userBoxIsOpen,
      toggleUserBox,
      comboBoxHeight,
      goToAboutPage,
      serverState,
      diskCapacity,
      serverError,
      editUser,
      showEditAccount,
      selectUser,
      openProgressPopup,
      toggleProgressPopup,
      progressFiles,
      uploading,
      goLibrary,
      locale,
      language,
      uploadCancel,
      popupContents,
      confirmHandler
    };
  },
});
