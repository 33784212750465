
import { defineComponent, computed } from 'vue';
import { useUploadPopupStateStore } from '../../store/uploadPopup';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const popupStateStore = useUploadPopupStateStore();
    
    const setPopupState = (name: string, state: boolean) => {
      popupStateStore.setState(name, state);
    }
    
    return {
      locale,
      language,
      setPopupState
    };
  },
});
