type Messages = {
  [key: string]: {
    [key: string]: string | string[];
  };
};

export const messages: Messages = {
  ko: {
    "setting": "언어 설정",
    "lang" : ["한국어", "영어"]
  },
  en: {
    "setting": "Language",
    "lang" : ["Korean", "English"]
  },
};