
import { defineComponent, ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { useLibraryMenuStore } from '../../store/libraryMenu';
import { useLibrarySortStore } from '@/store/librarySort';
import { useLocaleStore } from '@/store/locale';
import { useAcceptStore } from '../../store/accept';
import Menu from "./Menu.vue";
import VideoList from "./VideoList.vue";
import Playlist from './Playlist.vue';
import SharedList from './SharedList.vue';
import BookmarkList from './BookmarkList.vue';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';
import moment from 'moment';
import DatePickerModule from '@/components/Management/DatePickerModule.vue';
import { useUploadOptionsStore } from '../../store/uploadOption';
import { useLibraryVideosStore, LibraryVideo } from '../../store/libraryVideo';
import { useSummaryStore } from '../../store/summary';

export default defineComponent({
  components: {
    Menu,
    VideoList,
    Playlist,
    SharedList,
    BookmarkList,
    ComboboxEdit,
    DatePickerModule
},
  setup() {
    const libraryMenuStore = useLibraryMenuStore();
    const librarySortStore = useLibrarySortStore();
    const localeStore = useLocaleStore();
    const acceptStore = useAcceptStore();
    const uploadOptionsStore = useUploadOptionsStore();
    const libraryStore = useLibraryVideosStore();
    const summaryStore = useSummaryStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const currTab = computed(() => libraryMenuStore.getCurrTab);
    const showFilterBox = ref(false);

    const videoListRef = ref();
    const playlistRef = ref();
    const sharedListRef = ref();
    const bookmarkListRef = ref();

    const sortComboRef = ref();
    const searchComboRef = ref();

    // 검색 기능
    const searchArray = ref<any>([
      { id: 'title', label: language.value["LIBRARY"]["title"][locale.value] }, // 제목
      { id: 'memo', label: language.value["LIBRARY"]["memo"][locale.value]  }, // 메모
    ]);
    const selectedSearch = ref<any>({ id: 'title', label: language.value["LIBRARY"]["title"][locale.value] });  // 제목
    const searchText = ref<any>("");
    const selectedSearchEvt = (item: any) => {
      selectedSearch.value = item;
      librarySortStore.setSelectedSearch(item);
    }

    const inputSearch = () => {
      librarySortStore.setSearchText(searchText.value);
    }
    // 검색 리셋
    const resetSearch = () => {
      selectedSearch.value = { id: 'title', label: language.value["LIBRARY"]["title"][locale.value] };  // 제목
      searchText.value = "";
    }

    // 정렬 기능
    const sortArray = ref<any>([
      { id: 'upload', label: language.value["LIBRARY"]["order_upload"][locale.value] }, // 등록 날짜순
      { id: 'name', label: language.value["LIBRARY"]["order_name"][locale.value] }, // 이름순
      { id: 'date', label: language.value["LIBRARY"]["order_date"][locale.value] }, // 영상 날짜순
      { id: 'period', label: language.value["LIBRARY"]["order_period"][locale.value] }, // 남은 저장 기간순
    ]);
    const selectedSort = ref<any>({ id: 'upload', label: language.value["LIBRARY"]["order_upload"][locale.value] }); // 등록 날짜순
    const selectedSortEvt = (item: any) => {
      selectedSort.value = item;
      librarySortStore.setSelectedSort(item);
    }
    // 정렬 리셋
    const resetSort = () => {
      selectedSort.value = { id: 'upload', label: language.value["LIBRARY"]["order_upload"][locale.value] }; // 등록 날짜순
    }

    // 필터링 기능
    const currFilter = computed(() => librarySortStore.getFilter);
    const doFilter = computed(() => librarySortStore.getFiltering);
    const filterData = ref<any>({
      date: { value: moment(new Date()).format('YYYY-MM-DD'), checked: false },
      place: { value: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, checked: false }, // 실외
      contents: { value: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, checked: false }, // 선택 안 함
      period: { value: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] }, checked: false }, // 3개월
    })
    const filterCombo = ref<any>([
      {
        type: "contents",
        items: [
          { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
          { id: false, label: language.value["COMBOBOX"]["contents_option2"][locale.value] }, // 실종
          { id: false, label: language.value["COMBOBOX"]["contents_option3"][locale.value] }, // 자살의심(자해)
          { id: false, label: language.value["COMBOBOX"]["contents_option4"][locale.value] }, // 주차뺑소니(물피도주)
          { id: false, label: language.value["COMBOBOX"]["contents_option5"][locale.value] }, // 공공시설물 파손
          { id: false, label: language.value["COMBOBOX"]["contents_option6"][locale.value] }, // 절도(용의자 추적)
          { id: false, label: language.value["COMBOBOX"]["contents_option7"][locale.value] }, // 폭행(특수상해)
          { id: false, label: language.value["COMBOBOX"]["contents_option8"][locale.value] }, // 무단투기
          { id: false, label: language.value["COMBOBOX"]["contents_option9"][locale.value] }, // 교통사고(차량)
          { id: false, label: language.value["COMBOBOX"]["contents_option10"][locale.value] }, // 교통사고(대인)
          { id: false, label: language.value["COMBOBOX"]["contents_option11"][locale.value] }, // 음주운전 의심
          { id: false, label: language.value["COMBOBOX"]["contents_option12"][locale.value] }, // 사고발견(위험신고)
          { id: false, label: language.value["COMBOBOX"]["contents_option13"][locale.value] }, // 주거침입
          { id: true, label: language.value["COMBOBOX"]["contents_other"][locale.value] }, // 기타
        ],
        selected: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
        opened: false,
      },
      {
        type: "place",
        items: [
          { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
          { id: '1', label: language.value["COMBOBOX"]["place_option2"][locale.value] },  // 실내
        ],
        selected: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
        opened: false,
      },
      {
        type: "duration",
        items: [
          { id: '7', label: '7'+language.value["COMBOBOX"]["period_option1"][locale.value] }, // 7일
          { id: '30', label: '1'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 1개월
          { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
          { id: '180', label: '6'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 6개월
          { id: '36500', label: language.value["COMBOBOX"]["period_option3"][locale.value] }, // 제한 없음
        ],
        selected: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
        opened: false,
      },
    ])
    // 콤보박스 선택 시 값 넣어주기
    const filterContent = (item:any) => {
      filterCombo.value[0].selected = item;
      filterData.value.contents.value = item;
    }
    const filterPlace = (item:any) => {
      filterCombo.value[1].selected = item;
      filterData.value.place.value = item;
    }
    const filterPeriod = (item:any) => {
      filterCombo.value[2].selected = item;
      filterData.value.period.value = item;
    }
    const setDate = (date: any) => {
      filterData.value.date.value = moment(date).format('YYYY-MM-DD');
    }
    // 체크박스
    const clickCheckBox = (item: any) => {
      filterData.value[item].checked=!filterData.value[item].checked;
      filterCombo.value[0].opened = false;
      filterCombo.value[1].opened = false;
      filterCombo.value[2].opened = false;
    }
    // 다른 콤보 박스 클릭 시 닫히게
    const clickComboArea = (item: any) => {
      filterCombo.value[0].opened = false;
      filterCombo.value[1].opened = false;
      filterCombo.value[2].opened = false;
      
      filterCombo.value[item].opened = true;
    }
    const closeAllOption = () => {
      // console.log('sortComboRef', sortComboRef.value);
      if(sortComboRef.value!==undefined && sortComboRef.value!==null){
        sortComboRef.value.toggleClose();
      }
      if(searchComboRef.value!==undefined && searchComboRef.value!==null){
        searchComboRef.value.toggleClose();
      }

    }
    const comboClick = (value:string) => {
      if(value === 'sort'){
        if(searchComboRef.value!==undefined && searchComboRef.value!==null){
          searchComboRef.value.toggleClose();
        }
      }
      else if(value === 'search'){
        if(sortComboRef.value!==undefined && sortComboRef.value!==null){
          sortComboRef.value.toggleClose();
        }
      }

    }
    // 필터 적용 활성화 체크
    const filterCheck = () => {
      let flag = true;

      if(!filterData.value.date.checked && !filterData.value.place.checked && 
         !filterData.value.contents.checked && !filterData.value.period.checked){
        flag = false;
      }

      return flag;

    }
    // 필터 적용 누를 경우
    const filterAccept = () => {
      // console.log('filter accept >> ', filterData.value);
      let filter = filterData.value;
      let inputDate = filter.date.checked?filter.date.value:undefined;
      let inputPlace = filter.place.checked?filter.place.value:undefined;
      let inputContents = filter.contents.checked?filter.contents.value:undefined;
      let inputPeriod = filter.period.checked?filter.period.value:undefined;

      librarySortStore.setFilter(inputDate, inputPlace, inputContents, inputPeriod);
      switch(currTab.value){
        case 'videos':
          videoListRef.value.searchEvt();
          break;
        case 'playlist':
          playlistRef.value.searchEvt();
          break;
        case 'shared':
          sharedListRef.value.searchEvt();
          break;
      }
      
      showFilterBox.value = false;
    }
    // 필터 초기화 누를 경우
    const resetFilterClick = () => {
      resetFilter();
      switch(currTab.value){
        case 'videos':
          videoListRef.value.searchEvt();
          break;
        case 'playlist':
          playlistRef.value.searchEvt();
          break;
        case 'shared':
          sharedListRef.value.searchEvt();
          break;
      }
      showFilterBox.value = false;
    }
    // 필터 초기화
    const resetFilter = () => {
      closeAllOption();
      filterData.value = {
        date: { value: moment(new Date()).format('YYYY-MM-DD'), checked: false },
        place: { value: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, checked: false }, // 실외
        contents: { value: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, checked: false }, // 선택 안 함
        period: { value: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] }, checked: false },  // 3개월
      };
      filterCombo.value[0].selected = { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }; // 선택 안 함
      filterCombo.value[1].selected = { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }; // 실외
      filterCombo.value[2].selected = { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] };  // 3개월
      librarySortStore.resetFilter();
      
    }
    // 필터 팝업박스 뜰 때 값 설정
    const showFilter = () => {
      closeAllOption();
      showFilterBox.value = !showFilterBox.value;
      // console.log('check >> ', currFilter.value);

      if(doFilter.value){
        inputCurrFilter();
      }
      else{
        resetFilter();
      }
      
      // console.log('input >> ', filterData.value);
    }
    // 필터를 적용한 상태일 경우 상태 반영
    const inputCurrFilter = () => {
      if(currFilter.value.date !== undefined){
        filterData.value.date.checked = true;
        filterData.value.date.value = currFilter.value.date;
      }
      else{
        filterData.value.date.checked = false;
        filterData.value.date.value = moment(new Date()).format('YYYY-MM-DD');
      }

      if(currFilter.value.contents !== undefined){
        filterData.value.contents.checked = true;
        filterData.value.contents.value = currFilter.value.contents;
        filterCombo.value[0].selected = currFilter.value.contents;
      }
      else{
        filterData.value.contents.checked = false;
        filterData.value.contents.value = { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }; // 선택 안 함
        filterCombo.value[0].selected = { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }; // 선택 안 함
      }

      if(currFilter.value.place !== undefined){
        filterData.value.place.checked = true;
        filterData.value.place.value = currFilter.value.place;
        filterCombo.value[1].selected = currFilter.value.place;
      }
      else{
        filterData.value.place.checked = false;
        filterData.value.place.value = { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }; // 실외
        filterCombo.value[1].selected = { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }; // 실외
      }

      if(currFilter.value.period !== undefined){
        filterData.value.period.checked = true;
        filterData.value.period.value = currFilter.value.period;
        filterCombo.value[2].selected = currFilter.value.period;
      }
      else{
        filterData.value.period.checked = false;
        filterData.value.period.value = { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] };  // 3개월
        filterCombo.value[2].selected = { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] };  // 3개월
      }
    }
    // 페이지 이동 시 전부 초기화
    const resetOption = () => {
      resetFilter();
      resetSearch();
      resetSort();
    }

    const autoDeleteVideoCash = () => {
      acceptStore.dayAutoDelete();
    }

    onBeforeMount(() => {
      librarySortStore.initValue();
      uploadOptionsStore.getOptions();
      resetOption();
    });

    // watch(() => currTab.value, () => {
    //   switch(currTab.value){
    //     case 'videos':
    //       videoListRef.value.searchEvt();
    //       break;
    //     case 'playlist':
    //       playlistRef.value.setCurrVideos();
    //       break;
    //     case 'shared':
    //       libraryStore.getSharedVideoList();
    //       break;
    //     case 'bookmark':
    //       summaryStore.getObjBookmarkList();
    //       break;
    //   }
    // })

    onMounted(() => {
      autoDeleteVideoCash();
    })

    return {
      locale,
      language,
      currTab,
      showFilterBox,
      selectedSort,
      sortArray,
      selectedSortEvt,
      searchArray,
      selectedSearch,
      searchText,
      inputSearch,
      selectedSearchEvt,
      filterCombo,
      filterPlace,
      filterContent,
      filterPeriod,
      filterData,
      setDate,
      clickCheckBox,
      clickComboArea,
      filterCheck,
      filterAccept,
      resetFilter,
      showFilter,
      videoListRef,
      playlistRef,
      sharedListRef,
      bookmarkListRef,
      sortComboRef,
      searchComboRef,
      resetOption,
      resetFilterClick,
      comboClick,
      closeAllOption,
      doFilter,
    };
  },
});
