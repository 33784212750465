
import { defineComponent, ref, computed } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useUserStore } from '@/store/loginUser';
import { useSummaryPageStore } from '../../store/summaryPage';
import MemoModule from '@/components/Popup/MemoModule.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '../../store/summaryMenu';

export default defineComponent({
  components: {
    MemoModule,
    PopupModule
  },
  emits:['playObject'],
  setup(props, {emit}) {
    const summaryStore = useSummaryStore();
    const summaryPageStore = useSummaryPageStore();
    const localeStore = useLocaleStore();
    const summaryMenuStore = useSummaryMenuStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const selectObject = computed(() => summaryStore.getSelectObject);
    const loginId = useUserStore().getUserId;
    const videos = computed(() => summaryStore.getVideo);
    const memoPopup = ref({ open: false });
    const popupContents = ref({ message: '', type: 'success', show: false });

    const playObject = () => {
      emit('playObject', selectObject.value.frame);
    };

    const openPopup = () => {
      if(selectObject.value.id !== null) {
        memoPopup.value.open = true;
      }
    }

    const setBookmark = (memo: string) => {
      memoPopup.value.open = false;
      popupContents.value.show = true;
      selectObject.value.memo = memo;
      selectObject.value.isBookmark = 1;
    }

    const insertBookmarkHandler = (memo: string) => {
      setBookmark(memo);
      popupContents.value.message = language.value["PLAYER"]["msg_complete_add"][locale.value]; //'객체 북마크 추가가 완료되었습니다.';
    }

    const updateBookmarkHandler = (memo: string) => {
      setBookmark(memo);
      popupContents.value.message = language.value["PLAYER"]["msg_complete_edit"][locale.value]; //'객체 북마크 수정이 완료되었습니다.';
    }

    const closePopup = () => {
      memoPopup.value.open = false;
    }
    
    const closeConfirmPopup = () => {
      popupContents.value.show = false;
    }

    const goOrigin = () => {
      summaryPageStore.setCurrPage('origin');
      summaryMenuStore.initOptionOpenState();
    }

    return {
      selectObject,
      playObject,
      memoPopup,
      openPopup,
      insertBookmarkHandler,
      updateBookmarkHandler,
      closePopup,
      popupContents,
      closeConfirmPopup,
      videos,
      loginId,
      goOrigin,
      locale,
      language
    };
  },
});
