import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { getLibraryVideoList, shareVideo, deleteVideo, updateVideoMemo,
   getSharedVideoList, videoStatusEach, deleteSelectVideo } from '../api';

export interface LibraryVideo {
  videoId: string;
  userId: string;
  uploadAt: Date;
  startDate: Date;
  endDate: Date;
  fileName: string;
  realPath: string;
  background: string;
  memo: string;
  availableUntil: Date;
  availableUntilValue: number;
  bookmark: number;
  playlist: JSON;
  place: string;
  contents: string;
  name: string;
  division: string;
  officeNum: string;
  isShare: number;
  checked: boolean;
  status: string;
  statusPct: number;
  isAllow: number;
  size: number;
  changeSize: string;
  callSize: number;
}

interface LibraryVideosState {
  videos: Ref<LibraryVideo[]>;
  sharedVideos: Ref<LibraryVideo[]>;
  videoStatus: Ref<any>;
}

// 업로드 완료된 파일의 정보를 저장하는 store
export const useLibraryVideosStore = defineStore('libraryVideo', {
  state: (): LibraryVideosState => ({
    videos: ref<LibraryVideo[]>([]),
    sharedVideos: ref<LibraryVideo[]>([]),
    videoStatus: ref<any>({})
  }),
  actions: {
    async getLibraryVideoList() {
      try {
        const result = await getLibraryVideoList();
        this.videos = result;
        this.checkVideoPeriod();
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    async getSharedVideoList() { // 공유된 비디오 리스트
      try {
        const result = await getSharedVideoList();
        this.sharedVideos = result;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    initVideos() {
      this.videos = [];
    },
    // 현재는 새로 생성할 경우 영상 playlist 정보 업데이트 함수
    updateVideoPlaylist(playlist:any) {
      const setVideoPlaylist = [];  // 각 비디오 마다 플레이리스트 해당되는거 체크

      for(let i=0;i<this.videos.length;i++){
        for(let j=0;j<playlist.length;j++){
          if(this.videos[i].videoId === playlist[j].videos.videoId){
            setVideoPlaylist.push(playlist[j].name);
          }
        }
      }
    },
    async updateVideoMemo(video: LibraryVideo) {
      try {
        const result = await updateVideoMemo(video.videoId, video.memo);
        return result;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    async shareVideo(state: number) {
      try {
        const result = await shareVideo(state);
        return result;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    async deleteVideo() {
      try {
        const result = await deleteVideo();
        // usePlaylistStore().getPlaylist();
        // useSummaryStore().getBookmarkList();
        return result;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    resetVideoList(){
      for(let i=0;i<this.videos.length;i++){
        this.videos[i].checked = false;
      }
    },
    videoStatusEach(videoId:string){
      // let result = {status:'ANAL',  statusPct:0}
      try{
        const res = videoStatusEach(videoId);
        this.videoStatus = res;
        return this.videoStatus;
      } catch (error){
        console.error('Error fetching users:', error);
        return null;
      }
    },
    checkVideoPeriod(){
      const deleteVideo = [];
      for(let i=0; i<this.videos.length; i++){
        const remainingDay = (new Date(this.videos[i].availableUntil).getTime() - new Date().getTime())/(60*24*60*1000)
        if(remainingDay < 0){
          deleteVideo.push(this.videos[i]);
        }
      }
      // console.log('delete Video >> ', deleteVideo);
      if(deleteVideo.length !== 0){
        deleteSelectVideo(deleteVideo);
      }

    }
  },
  getters: {
    getVideoList(state): LibraryVideo[] {
      return state.videos;
    },
    getSharedList(state): LibraryVideo[] {
      return state.sharedVideos;
    },
  },
});