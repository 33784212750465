import { defineStore } from 'pinia';

// 라이브러리 > 활성화된 메뉴를 저장하는 store
export const useLibraryMenuStore = defineStore('libraryMenu', {
  state: () => ({
    currTab: "videos"
  }),
  actions: {
    setCurrTab(tab: string) {
      this.currTab = tab;
    }
  },
  getters: {
    getCurrTab(state) {
      return state.currTab;
    }
  },
});