
import { defineComponent, ref, computed } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';
import PopupModule from '@/components/Popup/PopupModule.vue';

export default defineComponent({
  components:{
    PopupModule
  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const popupContents = ref({message : '', type: '', show:false});

    const faceImg = ref("");
    const faceInfo = ref("");
    const faceFile = ref(null);
    const faceImgArray = ref<any>([]);  // 안면인식 입력값
    const inputImageFileRef = ref();

    const selectUploadFaceDialog = () => {
      inputImageFileRef.value.click();
    };
    
    // 안면인식 박스 클릭 시 이미지 가져오는 함수
    const onUploadFaceBtn = (evt: any) => {
      if(evt.target.files[0] !== undefined){
        let img = new Image;
        img.src = URL.createObjectURL(evt.target.files[0]);
        faceImg.value = img.src;
        faceInfo.value = evt.target.files[0].name;
        faceFile.value = evt.target.files[0];
        
        inputImageFileRef.value.value = ''
      }
    };
    // 안면인식 이미지 및 정보값 넣기
    const inputFace = () => {
      faceImgArray.value.push({
        src:faceImg.value, 
        info:faceInfo.value!==''?faceInfo.value:(faceFile.value!==null?faceFile.value['name']:''), 
        file:faceFile.value});
      faceImg.value = '';
      faceInfo.value = '';
      faceFile.value = null;
      conditionStore.setFace(faceImgArray.value);
    };
    // 모든 안면인식 파일 지우기 팝업
    const allFaceRemove = () => {
      popupContents.value.message = language.value["PLAYER"]["msg_delete"][locale.value]; //"등록한 이미지를 모두 삭제하시겠습니까?";
      popupContents.value.type = "delete";
      popupContents.value.show = true;
    }
    // 모든 안면인식 파일 지우기 
    const deleteAllFace = () => {
      faceImgArray.value = [];
      popupContents.value.show = false;
      popupContents.value.message = "";
      popupContents.value.type = "";
      // summaryStore.resetFaceValue();
      conditionStore.resetFace();
    }
    // 선택한 안면인식 파일 지우기
    const deleteFace = (index: any) => {
      faceImgArray.value.splice(index,1);
      conditionStore.setFace(faceImgArray.value);
      if(faceImgArray.value.length === 0){
        // summaryStore.resetFaceValue();
        conditionStore.resetFace();
      }
    }

    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    return {
      locale,
      language,
      setOptionOpenState,
      popupContents,
      faceImg,
      faceInfo,
      faceImgArray,
      selectUploadFaceDialog,
      onUploadFaceBtn,
      inputFace,
      allFaceRemove,
      deleteAllFace,
      deleteFace,
      inputImageFileRef
    }
  }
  
})
