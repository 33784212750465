
import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import PopupModule from '@/components/Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    VueSlider,
    PopupModule
  },
  setup() {
    const summaryStore = useSummaryStore();
    const summaryOption = summaryStore.currSummaryOption;
    const summaryMenuStore = useSummaryMenuStore();
    const durationTime = computed(() => summaryStore.getDurationTime);
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const popupContents = ref({message : '', type: '', show:false});
    const autoSlide = {value:6, min:2, max:10, format : language.value["PLAYER"]["auto"][locale.value]};  // 자동
    const manualSlide = {value:2, min:2, max:10, format:'{value}'+language.value["COMMON"]["min"][locale.value]}  // 분

    const setSummary = (compress: string) => {
      autoSlide.value = 6;
      let condition = {
        face: ref<any>(null), // 안면인식
        arrow: ref<any>([]), // 경로지정
        class: ref<string[] | null>([]), // 객체
        color: ref<string[] | null>([]), // 색상
        dwell: ref<any>(null), // 관심영역
        object: ref<any>(null), // 안면인식/텍스트검색 결과(객체 아이디)
        ir_query: ref<any>(null), // 텍스트검색 문구
        direction: ref<any>(null), // 방향지정
        timerange: ref<any>(null), // 부분요약
        ir_threshold: ref<any>(null), // 텍스트검색 임계값(숫자)
        license_plate: null, // 차량검색
        compressibility: 2, // 자동/수동 요약 설정 (자동 : 2, 수동 : 사용자 설정 시간)
        size: null, // 미사용
        speed: null, // 미사용,
        is_dvideo:ref<any>(0), // 관심영역 확대 영상 사용 유무(1: 사용, 0: 미사용)
      };

      // 객체 설정
      let classCondition = summaryOption.class;
      let inputClass = [];
      let count = 0;
      for(let i=0;i<classCondition.length;i++){
        for(let j=0;j<classCondition[i].sub.length;j++){
          count ++;
          if(classCondition[i].sub[j].checked){
            inputClass.push(classCondition[i].sub[j].type);
          }
        }
      }
      if(inputClass.length === 0 || inputClass.length === count){
        condition.class.value = null;
      }
      else{
        condition.class.value = inputClass;
      }

      if(inputClass.length === 0){
        popupContents.value.message = language.value["PLAYER"]["msg_no_select_object"][locale.value];  //"선택된 객체값이 없습니다.";
        popupContents.value.type = "error";
        popupContents.value.show = true;
        return;
      }

      //색상 설정
      let colorCondition = summaryOption.color;
      let inputColor = [];
      for(let i=0;i<colorCondition.length;i++){
        if(colorCondition[i].checked===true){
          inputColor.push(colorCondition[i].send);
        }
      }
      if(inputColor.length === 0 || inputColor.length === colorCondition.length){
        condition.color.value = null;
      }
      else{
        condition.color.value = inputColor;
      }
      if(inputColor.length === 0){
        popupContents.value.message = language.value["PLAYER"]["msg_no_select_color"][locale.value];  //"선택된 색상값이 없습니다.";
        popupContents.value.type = "error";
        popupContents.value.show = true;
        return;
      }

      //방향 설정
      let direction = summaryOption.direction;
      let checkDirection = [];
      for(let i=0;i<direction.length;i++){
        if(direction[i].checked===true){
          checkDirection.push(direction[i].send);
        }
      }
      // 하나만 입력되어 있을 경우 해당 방향으로 설정
      if(checkDirection.length===1){
        condition.direction.value = checkDirection[0];
      }

      //관심영역 설정
      let dwellCondition = summaryOption.dwell;

      if(dwellCondition.secondPoints.length !== 0){
        let setDwell = {'0':dwellCondition.firstPoints, '1':dwellCondition.secondPoints, 'o':dwellCondition.inputType};
        condition.dwell.value = setDwell;
      }
      else if(dwellCondition.firstPoints.length !== 0){
        let setDwell = {'0':dwellCondition.firstPoints, '1':[]};
        condition.dwell.value = setDwell;
      }
      else{
        condition.dwell.value = null;
      }

      condition.is_dvideo.value = summaryOption.is_dvideo;

      //경로지정 설정
      let arrowCondition = summaryOption.arrow;
      if(arrowCondition.line.length !== 0){
        condition.arrow.value = arrowCondition.line;
      }
      else{
        condition.arrow.value = null;
      }

      //부분요약 설정
      let timerange = summaryOption.timerange;
      if(timerange.length === 0){
        condition.timerange.value = null;
      }else{
        if(timerange[0] === 0 && timerange[1] === 0){
          condition.timerange.value = null;
        }
        else{
          condition.timerange.value = timerange;
        }
      }


      // 자동/수동
      if(compress === 'auto'){
        condition.compressibility = 2;
      }
      else{
        condition.compressibility = manualSlide.value;
      }

      let isIrValue = false;
      let isFaceValue = false;

      // IR = 텍스트검색
      if(summaryOption.ir_query !== ""){
        isIrValue = true;
        condition.ir_query.value = summaryOption.ir_query;
        condition.ir_threshold.value = summaryOption.ir_threshold;
      }
      
      // 안면인식
      if(summaryOption.face.length!==0){
        isFaceValue = true;
      }

      if(isIrValue && isFaceValue ){
        popupContents.value.message = language.value["PLAYER"]["msg_no_double"][locale.value];  //"텍스트 검색과 안면 인식은 동시에 선택할 수 없습니다.";
        popupContents.value.type = "error";
        popupContents.value.show = true;
        return;
      }
      else if(isIrValue){
        summaryStore.setIrOption(condition);
        summaryMenuStore.showSummarySelect(false);
      }
      else if(isFaceValue){
        summaryMenuStore.showSummarySelect(false);
        const faceUpload = async (): Promise<void> => {
          const res = await summaryStore.uploadFaceFile();
          condition.face.value = res;
          summaryStore.setFaceOption(condition);
        };
        faceUpload();
      }
      else{
        summaryStore.setSummaryOption(condition);
        summaryMenuStore.showSummarySelect(false);
      }
      
    };

    const closeSelectSummary = () => {
      summaryMenuStore.showSummarySelect(false);
    }

    onBeforeMount(() => {
      manualSlide.max = Math.floor(durationTime.value / 60) < 2 ? 2 : Math.floor(durationTime.value / 60);
    })
    
    return {
      setSummary,
      summaryOption,
      closeSelectSummary,
      autoSlide,
      manualSlide,
      popupContents,
      locale,
      language
    };
  },
});
