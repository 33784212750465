
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useComboSearchVideoStore } from '../../store/comboSearchVideo';
import { useUploadOptionsStore } from '../../store/uploadOption';
import { useVideosStore } from '../../store/videos';
import { useAcceptStore } from '../../store/accept';
import { useEditVideoStore } from '@/store/editVideo';
import { usePageStore } from '../../store/pageNumber';
import { useLocaleStore } from '@/store/locale';
import moment from 'moment';
import PageNumber from '../../components/Common/PageNumber.vue';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';
import PopupModule from '../Popup/PopupModule.vue';
import EditVideo from './EditVideo.vue';
import lodash from 'lodash';

export default defineComponent({
  components: {
    PageNumber,
    ComboboxEdit,
    PopupModule,
    EditVideo
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const videosStore = useVideosStore();
    const comboboxStore = useComboSearchVideoStore();
    const uploadOptionsStore = useUploadOptionsStore();
    const acceptStore = useAcceptStore();
    const editVideoStore = useEditVideoStore();
    const isOpen = computed(() => comboboxStore.getIsOpen);
    // const acceptOptions = computed(() => uploadOptionsStore.getOption);
    const allAllow = computed(() => uploadOptionsStore.getAllAllow);
    const autoDelete = computed(() => uploadOptionsStore.getAutoDelete);
    const autoDate = computed(() => uploadOptionsStore.getAutoDate);
    const videos = computed(() => videosStore.getData);
    /* pagenation */
    const pageStore = usePageStore();
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref([] as any)
    const searchVideos = ref([] as any)
    /* pagenation */

    const popupContents = ref({ message: '', type: 'success', show: false });
    const allowPopupContents = ref({ message: '', type: 'success', show: false });
    const deletePopupContents = ref({ message: '', type: 'success', show: false });

    const editVideoShow = ref(false);
    const selectCount = ref<any>(0);
    const isAllow = ref(false);

    const searchArray = ref<any>([
      { id: 'title', label: language.value["ADMIN"]["video_title"][locale.value] }, // 영상 제목
      { id: 'account', label: language.value["ADMIN"]["upload_account"][locale.value] }, // 업로드한 계정
    ]);
    const selectedSearch = ref<any>({  id: 'title', label: language.value["ADMIN"]["video_title"][locale.value]}); // 영상 제목
    const searchText = ref<any>('');

    const sortData = ref("uploadAtDown")
    const arrowStyle = ref({
      title : {'transform':'rotate(0)'},
      account : {'transform':'rotate(0)'},
      upload : {'transform':'rotate(0)'},
      period : {'transform':'rotate(0)'},
      allow : {'transform':'rotate(0)'},
    })

    // 일반 닫기
    const closePopup = () => {
      editVideoShow.value = false;
      // videosStore.getVideos();
    }

    // 저장 후 닫기
    const closeEditPopup = () => {
      editVideoShow.value = false;
      videosStore.getVideos();
    }

    const selectVideoEvent = (video:any) => {
      editVideoStore.setData(lodash.cloneDeep(video));
      editVideoShow.value = true;
    }

    /* 자동 삭제 기능 */
    const ago = language.value["ADMIN"]["ago"][locale.value]; // n일 이전
    const autoDeleteList = ref([
      {value:10, label:'10'+ago},
      {value:20, label:'20'+ago},
      {value:30, label:'30'+ago},
    ]);
    const showDrop = ref(false);
    const selectAutoDelete = ref({value:30, label:'30'+ago})
    const completeDate = ref(new Date());

    const setAutoDelete = (value:any) => {
      selectAutoDelete.value = value;
      showDrop.value = !showDrop.value;
      acceptStore.updateAccept('autoDelete', selectAutoDelete.value.value);
      // acceptStore.dayAutoDelete();
    }

    const autoDeletePopup = () => {
      // 삭제하시겠습니까? 팝업
      deletePopupContents.value.type = 'warning';
      deletePopupContents.value.message = language.value["ADMIN"]["msg_delete_cash"][locale.value]; // 현재 설정한 기간으로<br>영상 캐시가 삭제됩니다.<br>삭제하시겠습니까?
      deletePopupContents.value.show = true;
    }

    const successDelete = () => {
      // 성공시 삭제한 날짜로 업데이트
      acceptStore.updateAccept('autoDate', moment(completeDate.value).format('YYYY-MM-DD HH:mm:ss'));

      deletePopupContents.value.show = false;
    }

    // 자동 삭제 동작 함수
    const funcAutoDelete = async (): Promise<void> => {
      deletePopupContents.value.type = 'spin';
      deletePopupContents.value.message = language.value["ADMIN"]["msg_delete_cash_i"][locale.value]+'...'; // 캐시 삭제중
      deletePopupContents.value.show = true;

      const res = await acceptStore.funcAutoDelete();

      if(res.response){
        completeDate.value = res.date;
        deletePopupContents.value.type = 'success';
        deletePopupContents.value.message = language.value["ADMIN"]["msg_delete_cash_c"][locale.value]; // 캐시 삭제가 완료되었습니다.
        deletePopupContents.value.show = true;
      }
    }

    const autoDateFormat = () => {
      return moment(autoDate.value).format('YYYY-MM-DD HH:mm:ss');
    }
    /* 자동 삭제 기능 */

    const setOpenState = () => {
      comboboxStore.setIsOpen(!isOpen.value);
    }

    const setAllAllow = () => {
      uploadOptionsStore.setAllAllow(Math.abs(allAllow.value - 1));
    }

    const allowPopupCheck = () => {
      allowPopupContents.value.type = 'warning';
      allowPopupContents.value.message = language.value["ADMIN"]["msg_allow_video"][locale.value]; // 선택한 영상을 승인하시겠습니까?
      allowPopupContents.value.show = true;
    }

    // 선택 승인 이벤트
    const updateAllowVideo = async (): Promise<void> => {
      let updateValue = [];
      for(let i=0;i<pageList.value.length;i++){
        if(pageList.value[i].checked){
          updateValue.push(pageList.value[i]);
        }
      }

      allowPopupContents.value.type = 'spin';
      allowPopupContents.value.message = language.value["ADMIN"]["msg_allow_video_i"][locale.value]+'...'; // 영상 승인중

      const res = await videosStore.updateVideosAllow(updateValue);
      if(res){
        allowPopupContents.value.type = 'success';
        allowPopupContents.value.message = language.value["ADMIN"]["msg_allow_video_c"][locale.value]; // 영상 승인이 완료되었습니다.
        allowPopupContents.value.show = true;
      }
    }

    const deletePopupCheck = () => {
      popupContents.value.type = 'delete';
      popupContents.value.message = language.value["ADMIN"]["msg_delete_video"][locale.value]; // 선택한 영상을 삭제하시겠습니까?
      popupContents.value.show = true;
    }

    // 선택 삭제 이벤트
    const deleteVideo = async (): Promise<void> => {
      let deleteValue = [];
      for(let i=0;i<pageList.value.length;i++){
        if(pageList.value[i].checked){
          deleteValue.push(pageList.value[i]);
        }
      }

      popupContents.value.type = 'spin';
      popupContents.value.message = language.value["ADMIN"]["msg_delete_video_i"][locale.value]+'...'; // 영상 삭제중

      const res = await videosStore.deleteSelectVideo(deleteValue);
      if(res){
        popupContents.value.type = 'success';
        popupContents.value.message = language.value["ADMIN"]["msg_delete_video_c"][locale.value]; // 선택한 영상이 삭제되었습니다.
        popupContents.value.show = true;
      }
    }

    const successCheck = () => {
      popupContents.value.show = false;
      allowPopupContents.value.show = false;
      videosStore.getVideos();
    }

    const calcDate = (period: Date) => {
      const now = new Date();
      const date = new Date(period);
      const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));
      const remain = ref();
      if(diffDate >= 3650) {
        remain.value = language.value["LIBRARY"]["permanent"][locale.value]; // 영구 보관
      } else {
        remain.value = diffDate + language.value["COMMON"]["days"][locale.value]; // 일
      }
      
      return remain.value;
    }

    const apprStatus = (status: number) => {
      if(status == 1) {
        return language.value["COMMON"]["complete"][locale.value]; // 완료
      }
      return language.value["COMMON"]["wait"][locale.value]; // 대기
    }

    const setFormat = (lastAccess: Date) => {
      return moment(lastAccess).format('YYYY-MM-DD HH:mm');
    };

    // 비디오 리스트 클릭 시
    const selectList = (video: any) => {
      video.checked = !video.checked;
      if(video.checked){
        selectCount.value++;
      }
      else{
        selectCount.value--;
      }

      let count = 0;
      for(let i=0;i<pageList.value.length;i++){
        if(pageList.value[i].isAllow === 0 && pageList.value[i].checked){
          count++;
        }
      }

      if(count === selectCount.value){
        isAllow.value = true;
      }
      else{
        isAllow.value = false;
      }
    }

    // 콤보박스 선택시
    const selectedItem = (item: any) => {
      selectedSearch.value = item;
      searchVideoEvent();
    }

    // 검색 이벤트
    const searchVideoEvent = () => {
      searchVideos.value = [];
      pageStore.setCurrNumber(1);
      for(let i=0;i<videos.value.length;i++){
        let log = videos.value[i];
        let flag = true;

        if(searchText.value !== ''){
          switch(selectedSearch.value.id){
            case 'account':
              if(log.id.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
            case 'title':
              if(log.name.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
          }
        }

        if(flag){
          let inputData = {
            name: log.name, 
            videoId: log.videoId,
            id: log.id, 
            uploadAt: log.uploadAt,
            period: log.period,
            beginDt: log.beginDt,
            endDt: log.endDt,
            memo: log.memo,
            place: log.place,
            contents: log.contents,
            userName: log.userName,
            division: log.division,
            officeNum: log.officeNum,
            deviceId: log.deviceId,
            isAllow: log.isAllow,
            checked: false
          }
          searchVideos.value.push(inputData);
        }
      }
      pageStore.setTotalPage(searchVideos.value.length, 13);

      // 검색했을 경우 정렬 초기화
      sortData.value = "uploadAtDown";
      arrowStyle.value = {
        title : {'transform':'rotate(0)'},
        account : {'transform':'rotate(0)'},
        upload : {'transform':'rotate(0)'},
        period : {'transform':'rotate(0)'},
        allow : {'transform':'rotate(0)'},
      }
      getCurrPage();
    }
    
    // 정렬 함수
    const sortVideo = (value:string) => {
      arrowStyle.value = {
        title : {'transform':'rotate(0)'},
        account : {'transform':'rotate(0)'},
        upload : {'transform':'rotate(0)'},
        period : {'transform':'rotate(0)'},
        allow : {'transform':'rotate(0)'},
      }

      switch(value){
        case 'title':
          if(sortData.value === 'titleUp'){
            // 내림차순 문자 -> 숫자
            sortData.value = 'titleDown';
            searchVideos.value.sort(function(a:any,b:any){
              return a.name > b.name ? -1 : a.name < b.name ? 1 : 0;
            })
            arrowStyle.value.title = {'transform':'rotate(180deg)'};
          }
          else{
            sortData.value = 'titleUp';
            searchVideos.value.sort(function(a:any,b:any){
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            })
          }
          break;
        case 'account':
          if(sortData.value === 'accountUp'){
            // 내림차순 문자 -> 숫자
            sortData.value = 'accountDown';
            searchVideos.value.sort(function(a:any,b:any){
              return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
            })
            arrowStyle.value.account = {'transform':'rotate(180deg)'};
          }
          else{
            sortData.value = 'accountUp';
            searchVideos.value.sort(function(a:any,b:any){
              return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
            })
          }
          break;
        case 'uploadAt':
          if(sortData.value === 'uploadAtDown'){
            //최신 날짜부터
            sortData.value = 'uploadAtUp';
            searchVideos.value.sort(function(a:any,b:any){
              return new Date(a.uploadAt) < new Date(b.uploadAt) ? -1 : new Date(a.uploadAt) > new Date(b.uploadAt) ? 1 : 0;
            })
            arrowStyle.value.upload = {'transform':'rotate(180deg)'};
          }
          else{
            // 옛날 날짜부터
            sortData.value = 'uploadAtDown';
            searchVideos.value.sort(function(a:any,b:any){
              return new Date(a.uploadAt) > new Date(b.uploadAt) ? -1 : new Date(a.uploadAt) < new Date(b.uploadAt) ? 1 : 0;
            })
          }
          break;
        case 'period':
          if(sortData.value === 'periodUp'){
            // 내림차순 문자 -> 숫자
            sortData.value = 'periodDown';
            searchVideos.value.sort(function(a:any,b:any){
              return a.period > b.period ? -1 : a.period < b.period ? 1 : 0;
            })
            arrowStyle.value.period = {'transform':'rotate(180deg)'};
          }
          else{
            sortData.value = 'periodUp';
            searchVideos.value.sort(function(a:any,b:any){
              return a.period < b.period ? -1 : a.period > b.period ? 1 : 0;
            })
          }
          break;
        case 'allow':
          if(sortData.value === 'allowUp'){
            // 내림차순 문자 -> 숫자
            sortData.value = 'allowDown';
            searchVideos.value.sort(function(a:any,b:any){
              return a.isAllow > b.isAllow ? -1 : a.isAllow < b.isAllow ? 1 : 0;
            })
            arrowStyle.value.allow = {'transform':'rotate(180deg)'};
          }
          else{
            sortData.value = 'allowUp';
            searchVideos.value.sort(function(a:any,b:any){
              return a.isAllow < b.isAllow ? -1 : a.isAllow > b.isAllow ? 1 : 0;
            })
          }
          break;
      }
      getCurrPage();
    }

    const getCurrPage = () => {
      selectCount.value = 0;
      pageList.value = [];
      
      for(let i=(currNumber.value -1)*13; i<Math.min((currNumber.value)*13, searchVideos.value.length);i++){
        let log = searchVideos.value[i];
        let inputData = {
            name: log.name, 
            videoId: log.videoId,
            id: log.id, 
            uploadAt: log.uploadAt,
            period: log.period,
            beginDt: log.beginDt,
            endDt: log.endDt,
            memo: log.memo,
            place: log.place,
            contents: log.contents,
            userName: log.userName,
            division: log.division,
            officeNum: log.officeNum,
            deviceId: log.deviceId,
            isAllow: log.isAllow,
            checked: false
          }
        pageList.value.push(inputData);
      }
    }

    onMounted(() => {
      pageStore.setCurrNumber(1);
      uploadOptionsStore.getOptions();
      videosStore.getVideos();
    });

    watch(() => autoDelete.value, () => {
      if(autoDelete.value === 10){
        selectAutoDelete.value = {value:10, label:'10'+ago}
      }
      else if(autoDelete.value === 20){
        selectAutoDelete.value = {value:20, label:'20'+ago}
      }
      else{
        selectAutoDelete.value = {value:30, label:'30'+ago}
      }
    })
    watch(() => autoDate.value, () => {
      autoDateFormat();
    })
    /* pagenation */
    watch(() => videos.value, () => {
      searchVideoEvent();
    });
    watch(() => currNumber.value, () => {
      getCurrPage();
    })
    /* pagenation */

    return {
      locale,
      language,
      setAllAllow,
      allAllow,
      videos,
      setFormat,
      calcDate,
      apprStatus,
      setOpenState,
      selectedItem,
      searchArray,
      selectedSearch,
      selectList,
      selectCount,
      sortVideo,
      searchVideoEvent,
      searchText,
      arrowStyle,
      sortData,
      popupContents,
      successCheck,
      deleteVideo,
      deletePopupCheck,
      isAllow,
      allowPopupCheck,
      allowPopupContents,
      updateAllowVideo,
      setAutoDelete,
      showDrop,
      autoDeleteList,
      selectAutoDelete,
      autoDate,
      autoDateFormat,
      autoDeletePopup,
      deletePopupContents,
      successDelete,
      funcAutoDelete,
      closePopup,
      closeEditPopup,
      editVideoShow,
      selectVideoEvent,
      /* pagenation */
      getCurrPage,
      pageList
      /* pagenation */
    };
  },
});
