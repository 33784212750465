import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/common/option-close-icon.svg'
import _imports_1 from '@/assets/common/radio-check.svg'
import _imports_2 from '@/assets/common/radio-uncheck.svg'
import _imports_3 from '@/assets/player/summary/notimestamp_w.svg'
import _imports_4 from '@/assets/player/summary/notimestamp_g.svg'
import _imports_5 from '@/assets/player/summary/timestamp_w.svg'
import _imports_6 from '@/assets/player/summary/timestamp_g.svg'


const _withScopeId = n => (_pushScopeId("data-v-5a6fec91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "option-gray-box" }
const _hoisted_2 = { class: "option-top-box" }
const _hoisted_3 = { class: "bottom-box" }
const _hoisted_4 = { class: "timestamp-icon-box" }
const _hoisted_5 = {
  key: 0,
  class: "radio-btn",
  src: _imports_1
}
const _hoisted_6 = {
  key: 1,
  class: "radio-btn",
  src: _imports_2
}
const _hoisted_7 = {
  key: 2,
  src: _imports_3
}
const _hoisted_8 = {
  key: 3,
  src: _imports_4
}
const _hoisted_9 = { class: "timestamp-icon-box" }
const _hoisted_10 = {
  key: 0,
  class: "radio-btn",
  src: _imports_1
}
const _hoisted_11 = {
  key: 1,
  class: "radio-btn",
  src: _imports_2
}
const _hoisted_12 = {
  key: 2,
  src: _imports_5
}
const _hoisted_13 = {
  key: 3,
  src: _imports_6
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.language["SUMMARY"]["time_stamp"][_ctx.locale]), 1),
      _createElementVNode("img", {
        class: "close-btn",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOptionOpenState('timestamp', false)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["timestamp-box", _ctx.timeStampCondition===0?'active-item':'']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTimeStamp(0))),
        style: {"margin-bottom":"clamp(6px, 0.9vh, 0.9vh)"}
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.timeStampCondition === 0)
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : (_openBlock(), _createElementBlock("img", _hoisted_6)),
          (_ctx.timeStampCondition === 0)
            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
            : (_openBlock(), _createElementBlock("img", _hoisted_8))
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.language["SUMMARY"]["timestamp_off"][_ctx.locale]), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["timestamp-box", _ctx.timeStampCondition===1?'active-item':'']),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTimeStamp(1)))
      }, [
        _createElementVNode("div", _hoisted_9, [
          (_ctx.timeStampCondition === 1)
            ? (_openBlock(), _createElementBlock("img", _hoisted_10))
            : (_openBlock(), _createElementBlock("img", _hoisted_11)),
          (_ctx.timeStampCondition === 1)
            ? (_openBlock(), _createElementBlock("img", _hoisted_12))
            : (_openBlock(), _createElementBlock("img", _hoisted_13))
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.language["SUMMARY"]["timestamp_on"][_ctx.locale]), 1)
      ], 2)
    ])
  ]))
}