import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

export interface PageNumber{
  currNumber : Ref<number>,  // 현재 페이지 1, 2, 3 ... 
  currPage : Ref<number>, // 현재 페이지가 속한 탭 1~10 : 1, 11~20 : 2...
  totalPage : Ref<number> // 전체 페이지
}

export const usePageStore = defineStore('pageNumber', {
  state: (): PageNumber => ({
    currNumber : ref(1),
    currPage : ref(1),
    totalPage : ref(0)
  }),
  actions:{
    setCurrNumber(num: number){
      this.currNumber = num;
      if(this.currNumber%10 === 0){
        this.currPage =  Math.floor(this.currNumber/10);
      }
      else{
        this.currPage =  Math.floor(this.currNumber/10) + 1;
      }
    },
    setCurrPage(num: number){
      this.currPage = num;
    },
    //length : 전체 데이터 개수, unit : 한 페이지 개수
    setTotalPage(length: number, unit: number){
      this.totalPage = Math.floor((length + (unit - 1))/unit);
    }
  },
  getters:{
    getCurrNumber(state){
      return state.currNumber;
    },
    getCurrPage(state){
      return state.currPage;
    },
    getTotalPage(state){
      return state.totalPage;
    }
  }
})