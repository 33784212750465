import { defineStore } from 'pinia';
import { Item, ComboBoxState } from './comboBox';

export const useComboSearchVideoStore = defineStore('combosearchvideo', {
  state: (): ComboBoxState => ({
    defaultItem: { id: 'title', label: '영상 제목' },
    selectedItem: { id: 'title', label: '영상 제목' },
    items: [
      { id: 'title', label: '영상 제목' },
      { id: 'id', label: '업로드한 계정' },
    ],
    isOpen: false
  }),
  actions: {
    setSelectedItem(item: Item) {
      this.selectedItem = item;
    },
    setIsOpen(state: boolean) {
      this.isOpen = state;
    }
  },
  getters: {
    getDefaultItem(state) {
      return state.defaultItem;
    },
    getSelectedItem(state) {
      return state.selectedItem;
    },
    getItems(state) {
      return state.items;
    },
    getIsOpen(state) {
      return state.isOpen;
    }
  },
});
