import { defineStore } from 'pinia'
import { ref } from 'vue';
// import { Camera } from './cameras';
import { downloadVmsVideo, uploadVideoFile, milestonVideoInsert, milestonFailVideoInsert, cancelVmsUpload } from '../api';
import { MSAPI } from '../milestone-api';
import { SelectedCamera } from './vms';

export interface ProgressFile {
  file: any,
  camera: SelectedCamera | undefined,
  type: string,
  videoId: string,
  name: string,
  percent: number,
  completed: boolean,
  inProgress: boolean,
  res: any,
  cancelToken: any,
}

export const useProgressStore = defineStore('progress', {
  state: () => ({
    progressFiles: ref<ProgressFile[]>([]),
    uploading: false,
    request: ref<any>([]),
    cancelVideo: ref<any>([]),
  }),
  actions: {
    setProgressFile(data: ProgressFile) {
      this.progressFiles.push(data);
    },
    initProgressFiles() {
      this.progressFiles = [];
      this.uploading = false;
    },
    setUploadState(state: boolean) {
      this.uploading = state;
    },
    checkUploadState(){
      let flag = true;
      // console.log('progressFiles >> ', this.progressFiles);
      for(let i=0;i<this.progressFiles.length;i++){
        if(!this.progressFiles[i].completed){
          flag = false;
          break;
        }
      }
      if(flag){
        this.setUploadState(false);
      }
    },
    async uploadVideoTotal(){
      for(let i=0; i<this.progressFiles.length; i++){
        const file = this.progressFiles[i];
        if(!file.inProgress){
          if(file.type === 'vms'){
            try {
              await downloadVmsVideo(file);
            } catch (error) {
              console.error('Error fetching users:', error);
            }
          }
          else if(file.type === 'file'){
            try {
              await uploadVideoFile(this.progressFiles[i]);
            } catch (error) {
              console.error('Error fetching users:', error);
            }
          }
        }

        if(i == this.progressFiles.length-1) {
          this.uploading = false;
        }
      }
    },
    async getVmsVideo() { // vms에서 선택한 카메라의 영상을 받아온다.
      for(let i=0; i<this.progressFiles.length; i++) {
        const file = this.progressFiles[i];
        if(file.type=="vms" && !file.inProgress) {
          try {
            await downloadVmsVideo(file);
          } catch (error) {
            console.error('Error fetching users:', error);
          }
        }

        if(i == this.progressFiles.length-1) {
          this.uploading = false;
        }
      }
    },
    async uploadVideoFile() {
      for(let i=0; i<this.progressFiles.length; i++) {
        const file = this.progressFiles[i];
        if(file.type=="file" && !file.inProgress) {
          try {
            await uploadVideoFile(this.progressFiles[i]);
          } catch (error) {
            console.error('Error fetching users:', error);
          }
        }
      }
    },
    setCancelVideo(videoId: string) {
      this.cancelVideo.push(videoId);
    },
    allCancleVideo(){
      for(let i=0;i<this.progressFiles.length;i++){
        let file = this.progressFiles[i];
        if(!file.inProgress){
          file.inProgress = true;
          file.completed = true;
          if(file.type === 'vms'){
            file.res = {state: false, msg: "vms_cancel"}
          }
          else if(file.type === 'file'){
            file.res = {state: false, msg: "file_cancel"}
          }
        }
        else {
          if(file.type === 'vms'){
            file.res = {state: false, msg: "vms_cancel_ing"}
            if(file.camera !== undefined){
              if(file.camera.camera.type !== 'MILESTONE'){
                cancelVmsUpload(file.videoId);
              }
              else {
                this.setCancelVideo(file.videoId);
              }
            }
          }
          else {
            file.cancelToken.cancel();
          }
        }
      }
      console.log('cancle video >> ', this.progressFiles);
    },
    async milestoneDownloadFunc(file: any) {
      const camera = file.camera

      const minute = 1000 * 60; // 1분
      const offset = 9; // 실제로 사용되는 영상의 길이
      const startDate = new Date(camera.info.startDate);
      const endDate = new Date(camera.info.endDate);
      const diffSec =  endDate.getTime() - startDate.getTime();
      const diffMin = Math.floor((diffSec / (60 * 1000)));
      
      const times = Math.ceil(diffMin / offset); // 영상을 나눠서 다운로드할 횟수
      const remainder = diffMin % offset; // 마지막 영상 길이

      let request = [];

      let contents = camera.info.contents.label;
      if(camera.info.contents.id) {
        contents = camera.info.contentsInput;
      }

      for(let i=0; i<times; i++) {
        let time = startDate.getTime() + (minute * offset * i); // 다운로드 요청할 영상의 시작 시간
        let start = new Date(time);
        let end = new Date(time + (minute * offset + minute)); // 시작시간에 + offset + 1분
        let range = 0;
        if(i === times-1 && remainder > 0) {
          end = new Date(time + (minute * remainder + minute));
          range = remainder;
          request.push({range: range, start:start, end:end, status:'WAIT', res: undefined})
        } else {
          range = offset;
          request.push({range: range, start:start, end:end, status:'WAIT', res: undefined})
        }
      }

      file.camera.request = request;
      this.checkMileDownload(file);
    },
    async checkMileDownload(file: any){
      let runningCount = 0;
      let successCount = 0;
      let errorCount = 0;
      let percent = 0;

      let cancel = false;

      for(let i=0; i<file.camera.request.length; i++){
        // console.log(i , '>> ', file.camera.request[i].status);
        if(this.cancelVideo.indexOf(file.videoId) == -1){
          if(file.camera.request[i].status === 'RUNNING'){
            runningCount +=1;
          }
          else if(file.camera.request[i].status === 'WAIT'){
            if(runningCount < 1){
              runningCount += 1;
              let inputData = file.camera.request[i];
              // setTimeout(() => {file.camera.request[i].status = 'SUCCESS'}, 5000);
              await MSAPI.downloadVideo(file.camera.camera.id, inputData.start, inputData.end, file.videoId, file, i, 
                function(err:any, res:any){
                  if(err){
                    console.log('milestone donwload error >>>>>>>>>> ', err);
                    file.camera.request[i].status = 'ERROR';
                    file.camera.request[i].res = '';
                  }
                  else{
                    file.camera.request[i].status = 'SUCCESS';
                    file.camera.request[i].uploadPath = res.upload_path;
                  }
                }
              )
              file.camera.request[i].status = 'RUNNING';
            }
          }
          else if(file.camera.request[i].status === 'SUCCESS'){
            successCount += 1;
          }
          else if(file.camera.request[i].status === 'ERROR'){
            errorCount += 1;
          }
        }
        else {
          cancel = true;
          break;
        }
      }

      if(cancel){
        file.res = {state:false, msg:'vms_cancel_ing'};
        file.completed = true;
        this.cancelVideo.splice(this.cancelVideo.indexOf(file.videoId), 1);
        milestonFailVideoInsert(file, file.camera.request);
        return;
      }

      // console.log('is cancel?');

      percent = Math.ceil( (successCount + errorCount ) * 100 / file.camera.request.length);
      file.percent = percent;
      
      if(successCount + errorCount == file.camera.request.length){
        if(errorCount == 0){
          milestonVideoInsert(file, file.camera.request);
        }
        else {
          file.res = {state:false, msg:'export'};
          file.completed = true;
        }
      }
      else{
        setTimeout(() => {this.checkMileDownload(file)}, 1000);
      }
    }
  },
  getters: {
    getProgressFiles(state) {
      return state.progressFiles;
    },
    getUploadState(state) {
      return state.uploading;
    }
  },
});
