import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aad5b124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "join-wrap" }
const _hoisted_2 = { class: "join-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectLanguage = _resolveComponent("SelectLanguage")!
  const _component_JoinInput = _resolveComponent("JoinInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectLanguage, { class: "select-lang-box" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_JoinInput, {
        currPage: 'signup',
        onClosePopup: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveLogin()))
      })
    ])
  ]))
}