
import { defineComponent, ref, computed, onMounted, shallowRef } from 'vue';
import { useMaskingStore } from '@/store/masking';
import { API_BASE_URL } from '@/api';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import { VideoPlayer, VideoPlayerState } from '@videojs-player/vue'
import { useLocaleStore } from '@/store/locale';

type VideoJsPlayer = ReturnType<typeof videojs>

export default defineComponent({
  components: {
    VideoPlayer,
  },
  setup() {
    const maskingStore = useMaskingStore();
    const localeStore = useLocaleStore();

    const isSeeking = ref<boolean>(false);
    const isSummaryVideoPause = ref<boolean>(false);
    const maskingVideoRef = ref();

    const maskingInfo = computed(() => maskingStore.getMaskingInfo);
    const maskingStatus = computed(() => maskingStore.getMaskingStatus);

    const language = computed(() => localeStore.getLanguage);
    const locale = computed(() => localeStore.getLocale);

    const player = shallowRef<VideoJsPlayer>()
    const state = shallowRef<VideoPlayerState>()
    const handleMounted = (payload: any) => {
      player.value = payload.player;
      state.value = payload.state
    }
    const onSeekingSummaryVideo = () => {
      isSeeking.value = true;
    };
    const onSeekedSummaryVideo = () => {
      isSeeking.value = false;
    };
    const onPlaySummaryVideo = () => {
      isSummaryVideoPause.value = false;
    };

    onMounted(() => {
      // setObject();
    })

    return {
      maskingVideoRef,
      handleMounted,
      onSeekingSummaryVideo,
      onPlaySummaryVideo,
      onSeekedSummaryVideo,
      maskingInfo,
      API_BASE_URL,
      language,
      locale,
      maskingStatus
  
    };
  },
});
