
import { defineComponent, computed,  } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';

export default defineComponent({
  components:{

  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const directionCondition = computed(() => conditionStore.getDirection);  
    const allCheckDirection = computed(() => conditionStore.getAllDirection);
    const currDirection = computed(() => conditionStore.getCurrDirection);

    // 방향 설정
    const directionSelect = (value:any) => {
      for(let i=0;i<directionCondition.value.length;i++){
        directionCondition.value[i].checked = false;
      }
      value.checked = true;

      conditionStore.setAllDirection(false);
      conditionStore.setDirection(directionCondition.value, value);
    };
    // 모든 방향 설정 
    const directionAllCheck = () => {
      let check = !allCheckDirection.value;
      for(let i=0;i<directionCondition.value.length;i++){
        directionCondition.value[i].checked = check;
      }

      conditionStore.setAllDirection(check);
      conditionStore.setDirection(directionCondition.value, {type:'', send:0, checked:true, name:''});
    };

    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    return {
      locale,
      language,
      setOptionOpenState,
      directionCondition,
      allCheckDirection,
      currDirection,
      directionSelect,
      directionAllCheck
    }
  }
  
})
