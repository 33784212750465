
import { defineComponent, ref, computed, watch } from 'vue';
import { useSummaryStore } from '../../store/summary';
import PopupModule from '@/components/Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    PopupModule
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
  },
  setup(props, { emit }) {
    const summaryStore = useSummaryStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const selectObject = computed(() => summaryStore.getSelectObject);
    const bookmark = computed(() => summaryStore.getBookmark);
    const input = ref('');
    const popupContents = ref(
      { message: language.value["LIBRARY"]["msg_memo_cancel"][locale.value], // '저장되지 않은 메모가 있습니다.<br>취소하시겠습니까?', 
      type: 'warning', 
      show: false });
    const keyup = ref(false);

    const setBookmark = (type: string) => {
      if(type == 'insert') {
        emit('insert', input.value);
        summaryStore.insertBookmark();
      } else if(type == 'update') {
        emit('update', input.value);
        summaryStore.updateBookmark();
      }
    }

    const closeHandler = () => {
      if(keyup.value) {
        popupContents.value.show = true;
      } else {
        emit('close-popup');
      }
    }

    const confirmHandler = () => {
      popupContents.value.show = false;
      keyup.value = false;
      emit('close-popup');
    }

    const cancelHandler = () => {
      popupContents.value.show = false;
    }

    watch(() => props.open, () => {
      if(props.open) {
        keyup.value = false;
        input.value = "";
        for (let i = 0; i < bookmark.value.length; i++) {
          if(bookmark.value[i]['id'] == Number(selectObject.value.id)) {
            input.value = bookmark.value[i]['bookmarkMemo'];
            break;
          }
        }
      }
    });

    return {
      setBookmark,
      closeHandler,
      input,
      selectObject,
      bookmark,
      popupContents,
      keyup,
      confirmHandler,
      cancelHandler,
      locale,
      language
    };
  },
});
