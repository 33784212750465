import { defineStore } from 'pinia'
import { getUsers, createAccount, updateAccount, deleteAccount } from '../api';

interface User {
  id: string,
  name: string,
  role: string,
  division: string,
  password: string,
  salt: string,
  lastAccess: Date,
  checked: boolean
}

interface Users {
  users: User[]
}

export const useUsersStore = defineStore('users', {
  state: (): Users => ({
    users: []
  }),
  actions: {
    async getUserList() {
      try {
        const res = await getUsers();
        this.users = res;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      return null;
    },
    removeUser() { // logout 
      this.users = [];
    },
    async createAccount(accountInfo:any){
      try{
        await createAccount(accountInfo);
        // const res = await createAccount(accountInfo);
        return true;
      } catch (error){
        console.error('Error fetching users:', error);
        return false;
      }
    },
    async updateAccount(editInfo:any){
      try{
        await updateAccount(editInfo);
        // const res = await updateAccount(editInfo);
        return true;
      } catch (error){
        console.error('Error fetching users:', error);
        return false;
      }
    },
    async deleteAccount(deleteUsers:any){
      try{
        await deleteAccount(deleteUsers);
        // const res = await deleteAccount(deleteUsers);
        return true;
      } catch (error){
        console.error('Error fetching users:', error);
        return false;
      }
    },
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
  },
});