
import { defineComponent, computed } from 'vue';
import { useSummaryPageStore } from '../../store/summaryPage';
import SummaryInfo from '@/components/Player/SummaryInfo.vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '../../store/summaryMenu';

export default defineComponent({
  components: {
    SummaryInfo
  },
  setup() {
    const summaryPageStore = useSummaryPageStore();
    const localeStore = useLocaleStore();
    const summaryMenuStore = useSummaryMenuStore();
    
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const goOrigin = () => {
      summaryPageStore.setCurrPage('origin');
      summaryMenuStore.initOptionOpenState();
    }

    return {
      goOrigin,
      locale,
      language
    };
  },
});
