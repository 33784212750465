
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useLibraryVideosStore, LibraryVideo } from '../../store/libraryVideo';
import moment from 'moment';
import { API_BASE_URL } from '@/api';
import { usePageStore } from '../../store/pageNumber';
import PageNumber from '../../components/Common/PageNumber.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import VideoMemoModule from '@/components/Popup/VideoMemoModule.vue';
import { usePlaylistStore } from '@/store/playlist';
import { useLibrarySortStore } from '@/store/librarySort';
import { useLocaleStore } from '@/store/locale';
import { useRouter } from 'vue-router';
import { useLibraryMenuStore } from '../../store/libraryMenu';

export default defineComponent({
  components: {
    PageNumber,
    PopupModule,
    VideoMemoModule
  },
  setup() {
    const libraryStore = useLibraryVideosStore();
    const playlistStore = usePlaylistStore();
    const pageStore = usePageStore();
    const librarySortStore = useLibrarySortStore();
    const localeStore = useLocaleStore();
    const router = useRouter();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const libraryMenuStore = useLibraryMenuStore();

    const playlist = computed(() => playlistStore.getCurrPlaylist);
    const currPlaylist = computed(() => playlistStore.getSelectPlaylist);
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref<any>([]);
    const videos = computed(() => libraryStore.getVideoList);
    const currVideos = ref<any>([]);
    const filterVideos = ref<any>([]);
    const popupContents = ref({ message: '', type: '', show: false, state: '' });
    const memoPopup = ref({ contents: '', open: false });
    const target = ref<LibraryVideo>();

    const searchText = computed(() => librarySortStore.getSearchText);
    const selectedSearch = computed(() => librarySortStore.getSelectedSearch);
    const selectedSort = computed(() => librarySortStore.getSelectedSort);
    const selectedFilter = computed(() => librarySortStore.getFilter);
    const doFilter = computed(() => librarySortStore.getFiltering);

    const setCurrVideos = () => {
      pageStore.setCurrNumber(1);
      if(playlist.value.length !== 0){
        libraryStore.resetVideoList();
        uncheckHandler();
        // console.log('currPlaylist.value >> ', currPlaylist.value);
        if(currPlaylist.value !== undefined){
          currVideos.value = videos.value.filter(x => currPlaylist.value.videos.includes(x.videoId));
          // console.log(currVideos.value.length)
          pageStore.setTotalPage(currVideos.value.length, 12);
          searchEvt();
        }
      }
      else{
        currVideos.value = [];
        pageList.value = [];
      }
    }

    // 검색 및 필터 함수
    const searchEvt = () => {
      filterVideos.value= [];
      pageStore.setCurrNumber(1);
      uncheckHandler();
      
      for(let i=0;i<currVideos.value.length;i++){
        let log = currVideos.value[i];
        let flag = true;

        if(log.status.indexOf('WEB') !== -1){
          flag = false;
        }
        // 검색
        if(searchText.value !== ''){
          switch(selectedSearch.value.id){
            case 'title':
              if(log.fileName.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
            case 'memo':
              if(log.memo.toLowerCase().indexOf(searchText.value.toLowerCase()) === -1){
                flag = false;
              }
              break;
          }
        }

        // 필터링 
        if(doFilter.value){
          if(selectedFilter.value.date !== undefined){
            let logDate = new Date(log.startDate);
            let filterDate = new Date(selectedFilter.value.date)
            if(logDate<filterDate){
              flag = false;
            }
          }
          if(selectedFilter.value.contents !== undefined){
            if(selectedFilter.value.contents["label"] == language.value["COMBOBOX"]["contents_other"][locale.value]){
              // 전체 리스트와 비교, 포함되면 false
              switch(log.contents){
                case language.value["COMBOBOX"]["contents_option1"][locale.value]: // 선택 안함
                case language.value["COMBOBOX"]["contents_option2"][locale.value]: // 실종
                case language.value["COMBOBOX"]["contents_option3"][locale.value]: // 자살의심(자해)
                case language.value["COMBOBOX"]["contents_option4"][locale.value]: // 주차뺑소니(물피도주)
                case language.value["COMBOBOX"]["contents_option5"][locale.value]: // 공공시설물 파손
                case language.value["COMBOBOX"]["contents_option6"][locale.value]: // 절도(용의자 추적)
                case language.value["COMBOBOX"]["contents_option7"][locale.value]: // 폭행(특수상해)
                case language.value["COMBOBOX"]["contents_option8"][locale.value]: // 무단투기
                case language.value["COMBOBOX"]["contents_option9"][locale.value]: // 교통사고(차량)
                case language.value["COMBOBOX"]["contents_option10"][locale.value]: // 교통사고(대인)
                case language.value["COMBOBOX"]["contents_option11"][locale.value]: // 음주운전 의심
                case language.value["COMBOBOX"]["contents_option12"][locale.value]: // 사고발견(위험신고)
                case language.value["COMBOBOX"]["contents_option13"][locale.value]: // 주거침입
                  flag = false;
                  break;
              }
              if(log.contents === ''){
                flag = false;
              }
            }
            else if(selectedFilter.value.contents["label"] == language.value["COMBOBOX"]["contents_option1"][locale.value]){ // 선택 안함
              if(log.contents !== '' && log.contents !== selectedFilter.value.contents["label"] ){
                flag = false;
              }
            }
            else{
              if(log.contents !== selectedFilter.value.contents){
                flag = false;
              }
            }
          }
          if(selectedFilter.value.place !== undefined){
            if(log.place !== selectedFilter.value.place["id"]){
              flag = false;
            }
          }
          if(selectedFilter.value.period !== undefined){
            const now = new Date();
            const date = new Date(log.availableUntil);
            const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));
            if(Number(selectedFilter.value.period["id"]) < diffDate){
              flag = false;
            }
          }
        }
        
        if(flag){
          const changeSize = changeFileSize(log.size);
          log.changeSize = changeSize;
          filterVideos.value.push(log);
        }
      }
      pageStore.setTotalPage(filterVideos.value.length, 12);
      sortEvt();
    }

    // 파일 사이즈 변환
    const changeFileSize = (size:number) => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (size === 0) 
        return '0 Byte';
      const i = (Math.floor(Math.log(size) / Math.log(1000)));
      return (Math.round((size / Math.pow(1024, i))*100)/100).toFixed(2) + ' ' + sizes[i];
    }

    // 정렬 함수
    const sortEvt = () => {
      let originArray = filterVideos.value;

      switch(selectedSort.value.id){
        case 'upload':
          // 업로드 날짜 최신 날짜부터
          originArray.sort(function(a:any,b:any){
            return new Date(a.uploadAt) > new Date(b.uploadAt) ? -1 : new Date(a.uploadAt) < new Date(b.uploadAt) ? 1 : 0;
          })
          break;
        case 'name':
          // 내림차순 문자 -> 숫자
          originArray.sort(function(a:any,b:any){
            return a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0;
          })
          break;
        case 'date':
          // 영상 날짜순 과거부터
          originArray.sort(function(a:any,b:any){
            return new Date(a.startDate) < new Date(b.startDate) ? -1 : new Date(a.startDate) > new Date(b.startDate) ? 1 : 0;
          })
          break;
        case 'period':
          // 남은저장기간순 적은것부터
          originArray.sort(function(a:any,b:any){
            return calcDateNumber(a.availableUntil) < calcDateNumber(b.availableUntil) ? -1 : 
                   calcDateNumber(a.availableUntil) > calcDateNumber(b.availableUntil) ? 1 : 0;
          })
          break;
      }
      getCurrPage();
    }

    const calcDateNumber = (period: Date) => {
      const now = new Date();
      const date = new Date(period);
      const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));

      return diffDate;
    }

    const getCurrPage = () => {
      pageList.value = [];
      for (let i = (currNumber.value - 1) * 12; i < Math.min((currNumber.value) * 12, filterVideos.value.length); i++) {
        let log = filterVideos.value[i];
        pageList.value.push(log);
      }
    }

    const calcDate = (period: Date) => {
      const now = new Date();
      const date = new Date(period);
      const diffDate = Math.floor(Math.abs((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)));
      const remain = ref();
      if (diffDate >= 3650) {
        remain.value = language.value["LIBRARY"]["permanent"][locale.value]; // 영구 보관
      } else {
        remain.value = diffDate + language.value["COMBOBOX"]["period_option1"][locale.value]; // 일
      }

      return remain.value;
    }

    const setFormat = (lastAccess: Date) => {
      return moment(lastAccess).format('YYYY-MM-DD HH:mm');
    };

    const activeCheckbox = ref(false);
    const selectedCount = ref(0);
    const checkHandler = (video: LibraryVideo) => {
      video.checked = !video.checked;
      activeCheckbox.value = false;
      selectedCount.value = 0;

      for(let i=0; i<currVideos.value.length; i++) {
        if(currVideos.value[i].checked) {
          activeCheckbox.value = true;
          selectedCount.value ++;
        }
      }
    }

    const uncheckHandler = () => {
      for (let i = 0; i < currVideos.value.length; i++) {
        currVideos.value[i].checked = false;
      }
      activeCheckbox.value = false;
      selectedCount.value = 0;
    }

    const openPopup = (state: string) => {
      const popup = popupContents.value;
      popup.state = state;
      popup.type = 'warning';

      if (state == 'share') {
        popup.message = language.value["LIBRARY"]["msg_share"][locale.value]; // '선택한 영상을 공유하시겠습니까?';
      } else if (state == 'private') {
        popup.message = language.value["LIBRARY"]["msg_unshare"][locale.value]; // '선택한 영상을 공유 해제하시겠습니까?';
      } else {
        if(selectedCount.value === currVideos.value.length){
          popup.type = 'error';
          popup.message = language.value["LIBRARY"]["msg_delete_error"][locale.value]; // '플레이리스트에는 반드시<br>하나 이상의 영상이 있어야 합니다.';
        }else{
          popup.type = 'delete';
          popup.message = language.value["LIBRARY"]["msg_delete_video"][locale.value]; // '현재 플레이리스트에서<br>선택한 영상을 삭제하시겠습니까?';
        }
      }
      popup.show = true;
    }

    const confirmHandler = async (): Promise<void> => {
      const popup = popupContents.value;
      const state = popup.state;
      const res = ref();

      popup.message = language.value["LIBRARY"]["msg_doing"][locale.value]; // 작업 진행 중입니다.
      popup.type = 'spin';

      if(state == 'share') {
        res.value = await libraryStore.shareVideo(1);
      } else if(state == 'private') {
        res.value = await libraryStore.shareVideo(0);
      }
      
      if(res.value) {
        popup.message = language.value["LIBRARY"]["msg_success"][locale.value]; // 완료되었습니다.
        popup.type = 'success';
      }
    }

    const cancelHandler = () => {
      const popup = popupContents.value;
      popup.show = false;
    }

    const successHandler = () => {
      const popup = popupContents.value;
      const memo = memoPopup.value;
      popup.show = false;
      memo.open = false;
      activeCheckbox.value = false;
      playlistStore.getPlaylist();
    }

    const deleteVideo = async (): Promise<void> => {
      const popup = popupContents.value;
      const res = ref();

      popup.message = language.value["LIBRARY"]["msg_doing"][locale.value]; // 작업 진행 중입니다.
      popup.type = 'spin';

      let deleteVideos = [];
      for(let i=0;i<currVideos.value.length;i++){
        if(currVideos.value[i].checked){
          deleteVideos.push(currVideos.value[i].videoId);
        }
      }

      res.value = await playlistStore.deleteVideo(currPlaylist.value.name, deleteVideos);
      
      if(res.value) {
        popup.message = language.value["LIBRARY"]["msg_success"][locale.value]; // 완료되었습니다.
        popup.type = 'success';
      }
    }

    const editMemo = (video: LibraryVideo) => {
      const memo = memoPopup.value;
      target.value = video;
      memo.contents = video.memo;
      memo.open = true;
    }
    
    const saveMemo = async (memo: any): Promise<void> => {
      const popup = popupContents.value;
      
      if(target.value !== undefined) {
        target.value.memo = memo;
        const res = await libraryStore.updateVideoMemo(target.value);
        if(res) {
          popup.message = language.value["LIBRARY"]["msg_save_memo"][locale.value]; // '메모가 저장되었습니다.';
          popup.type = 'success';
          popup.show = true;
        }
      }
    }

    const closeHandler = () => {
      memoPopup.value.open = false;
    }

    const checkVideo = ref<boolean>(true);
    const currTab = computed(() => libraryMenuStore.getCurrTab);

    const statusUpdate = async (): Promise<void> => {
      checkVideo.value = true;
      if(router.currentRoute.value.name == 'home' && currTab.value === 'playlist'){
        for (let i=0;i<currVideos.value.length;i++){
          if(currVideos.value[i].status !== "COMPLETE" && currVideos.value[i].status.indexOf('FAIL')==-1){
            checkVideo.value  = false;
            
            let result = await libraryStore.videoStatusEach(currVideos.value[i].videoId);
            if(result != undefined) {
              currVideos.value[i].status = result.status;
              currVideos.value[i].statusPct = result.statusPct;
              currVideos.value[i].background = result.background;
              currVideos.value[i].size = result.size;
              const changeSize = changeFileSize(result.size);
              currVideos.value[i].changeSize = changeSize;
            }
          }
        }
      }
      if(!checkVideo.value && router.currentRoute.value.name == 'home' && currTab.value === 'playlist'){
        setTimeout(() => {statusUpdate()}, 3000);
      }
    }

    watch(() => currPlaylist.value, () => {
      setCurrVideos();
      statusUpdate();
    });

    watch(() => playlist.value, () => {
      setCurrVideos();
    });

    watch(() => currNumber.value, () => {
      getCurrPage();
      uncheckHandler();
    });

    watch(() => searchText.value, () => {
      searchEvt();
    })
    watch(() => selectedSearch.value, () => {
      searchEvt();
    })

    watch(() => selectedSort.value, () => {
      searchEvt();
    })  

    onMounted(() => {
      // libraryStore.getLibraryVideoList();
      setCurrVideos();
    });

    return {
      currVideos,
      calcDate,
      setFormat,
      API_BASE_URL,
      checkHandler,
      uncheckHandler,
      activeCheckbox,
      selectedCount,
      popupContents,
      openPopup,
      confirmHandler,
      cancelHandler,
      successHandler,
      memoPopup,
      editMemo,
      saveMemo,
      closeHandler,
      pageList,
      deleteVideo,
      playlist,
      searchEvt,
      locale,
      language,
      setCurrVideos
    };
  },
});
