import { defineStore } from 'pinia'
import { getUser, getUploadOptions } from '../api';
import { usePlaylistStore } from './playlist';

function setSessionStorage(user: User) {
  const userInfo = JSON.stringify(user);
  sessionStorage.setItem('userInfo', userInfo);
}

function getSessionStorage(): User | null {
  const userInfo = sessionStorage.getItem('userInfo');
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  sessionStorage.removeItem('userInfo');
  return null;
}

function removeSessionStorage() {
  sessionStorage.removeItem('userInfo');
}

interface User {
  id: string,
  name: string,
  role: string,
  token: string,
  isLoggedIn: boolean,
  division: string,
}

// 현재 로그인 되어있는 유저의 정보를 저장
export const useUserStore = defineStore('user', {
  state: (): { user: User | null } => ({
    user: getSessionStorage() || null
  }),
  actions: {
    async getUser(inputId: string, inputPassword: string): Promise<User | null> {
      try {
        const res = await getUser(inputId, inputPassword);
        this.user = res;
        // console.log(res);
        setSessionStorage(res);
        return res;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      return null;
    },
    removeUser() { // logout 
      this.user = null;
      removeSessionStorage();
    },
    async checkAccept() {
      try {
        const res = await getUploadOptions();
        const acceptOption = res[0];

        if(acceptOption.update_playlist === 0){
          usePlaylistStore().upgradePlaylistSet();
        }
      } catch (error){
        console.error('Error fetching users:', error);
      }
    },
    async checkPassword(inputId: string, inputPassword: string): Promise<User | null> {
      try {
        const res = await getUser(inputId, inputPassword);

        return res;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      return null;
    },
    setUserInfo(user: any){
      const currUser = getSessionStorage();
      if(currUser !== null){
        currUser.name = user.name;
        currUser.division = user.division;
        setSessionStorage(currUser);
      }
    }
  },
  getters: {
    getUserInfo(state): User | null {
      return state.user;
    },
    getUserToken(state): string {
      if(state.user !== null) {
        return state.user.token;
      }
      return '';
    },
    getUserId(state): string {
      if(state.user !== null) {
        return state.user.id;
      }
      return '';
    },
    getUserName(state): string {
      if(state.user !== null) {
        return state.user.name;
      }
      return '';
    },
    getDivision(state): string {
      if(state.user !== null) {
        return state.user.division;
      }
      return '';
    },
    getUserIsLoggedIn(state): boolean {
      if(state.user !== null) {
        return state.user.isLoggedIn;
      }
      return false;
    },
    // getUserRole(state): string {
    //   if(state.user !== null) {
    //     return state.user.role;
    //   }
    //   return '';
    // }
  },
});