
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useLocaleStore } from '../../store/locale'
import { messages } from '../../locale/language';

export default defineComponent({
  setup() {
    const language = [
      { 
        type: 'ko'
      },
      { 
        type: 'en'
      }, 
    ];
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const changeLocale = (locale: string) => {
      localeStore.changeLocale(locale);
    };

    const msg = ref(messages);
    const isOpened = ref(false);
    const toggleLocale = (state: boolean) => {
      isOpened.value = !state;
    }
    const comboBoxHeight = computed(() => ({ 
      height: isOpened.value ? 
      'clamp('+ ((language.length + 1)*33 + 5) + 'px, ' + (((language.length + 1) * 4.6) + 0.8) + 'vh, ' + (((language.length + 1) * 4.6) + 0.8) + 'vh)'
      : "clamp(33px, 4.6vh, 4.6vh)"
    }));

    onMounted(() => {
      // localeStore.getLanguageData();
    });
    
    return {
      locale,
      msg,
      language,
      changeLocale,
      isOpened,
      toggleLocale,
      comboBoxHeight
    };
  },
});
