
import { defineComponent, computed } from 'vue';
import { useSummaryStore } from '@/store/summary';
import { useLocaleStore } from '@/store/locale';
import moment from 'moment';

export default defineComponent({
  components: {

  },
  setup() {
    const summaryStore = useSummaryStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const video = computed(() => summaryStore.getVideo);
    const currVideoTime = computed(() => summaryStore.currVideoTime);
    const finishVideoTime = computed(() => summaryStore.getFinishVideoTime);

    return {
      locale,
      language,
      video,
      currVideoTime,
      finishVideoTime,
      moment
    }
  }

})
