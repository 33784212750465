
import { defineComponent, ref, computed, watch } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import { useSummaryPageStore } from '../../store/summaryPage';
import { useLocaleStore } from '@/store/locale';
import { API_BASE_URL } from '@/api';

export default defineComponent({
  components: {
  },
  setup() {
    const summaryStore = useSummaryStore();
    const summaryMenuStore = useSummaryMenuStore();
    const summaryPageStore = useSummaryPageStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const faceImg = computed(() => summaryStore.getFaceImg);
    const summaryOption = computed(() => summaryStore.getApplySummaryOption);
    const summaryStatus = computed(() => summaryStore.getSummaryStatus);

    const currPage = computed(() => summaryPageStore.getCurrPage);
    const menu = computed(() => summaryMenuStore.getMaskingState);

    const timeStamp = computed(() => summaryStore.applyTimeStamp);
    const directionOption = ref("");
    const dwellOption = ref("")
    const timeRangeOption = ref("");

    const classHover = ref(false);
    const classText = ref("");
    const colorHover = ref(false);

    const setDirectionOption = () => {
      if(summaryOption.value.direction !== null){
        switch (summaryOption.value.direction) {
          case 0:
            directionOption.value = language.value["SUMMARY"]["e"][locale.value] //"동"
            break;
          case 45:
            directionOption.value = language.value["SUMMARY"]["ne"][locale.value] //"북동"
            break;
          case 90:
            directionOption.value = language.value["SUMMARY"]["n"][locale.value] //"북"
            break;
          case 135:
            directionOption.value = language.value["SUMMARY"]["nw"][locale.value] //"북서"
            
            break;
          case 180:
            directionOption.value = language.value["SUMMARY"]["w"][locale.value] //"서"
            break;
          case 225:
          directionOption.value = language.value["SUMMARY"]["sw"][locale.value] //"남서"
            break;
          case 270:
            directionOption.value = language.value["SUMMARY"]["s"][locale.value] //"남"
            break;
          case 315:
          directionOption.value = language.value["SUMMARY"]["se"][locale.value] //"남동"
            break;
          
        }
      }
    };

    const setDwellOption = () => {
      if(summaryOption.value.dwell !== null){
        if(summaryOption.value.dwell['o'] !== undefined){
          if(summaryOption.value.dwell['o'] === 'and'){
            dwellOption.value = language.value["SUMMARY"]["dwell_and_selected"][locale.value]; // 2곳 모두
          }
          else if(summaryOption.value.dwell['o'] === 'or'){
            dwellOption.value = language.value["SUMMARY"]["dwell_or_selected"][locale.value]  //'1곳 이상';
          }
        }
        else{
          dwellOption.value = language.value["SUMMARY"]["dwell_one_selected"][locale.value]  //'1곳만';
        }
      }else{
        dwellOption.value = "";
      }
    };

    const setTimeRange = () => {
      if(summaryOption.value.timerange !== null){
        let start = summaryOption.value.timerange[0];
        let finish = summaryOption.value.timerange[1];

        let startHour = Math.floor(start / 60);
        let startMin = start - startHour * 60;

        let startTime = (startHour>10?(startHour):('0'+startHour)) + ':' + (startMin>10?(startMin):('0'+startMin));

        let finishHour = Math.floor(finish / 60);
        let finishMin = finish - finishHour * 60;

        let finishTime = (finishHour>10?(finishHour):('0'+finishHour)) + ':' + (finishMin>10?(finishMin):('0'+finishMin));

        timeRangeOption.value = startTime+"~"+finishTime;

      }
    }
    
    const setMaskingInfo = () => {
      summaryMenuStore.showMaskingInfo(!menu.value.summaryOption);
    };

    const changeClassText = () => {
      classText.value = "";
      const text = ref("");
      if(summaryOption.value.class !== null){
        // summaryOption.value.class.length가 작동하지 않아 해당 방법 사용
        const classArray = ref<any>([]);
        classArray.value = summaryOption.value.class;

        for(let i=0;i<classArray.value.length;i++){
          text.value = language.value["SUMMARY"][classArray.value[i]][locale.value]
          if(i!==classArray.value.length-1){
            text.value += ","
          }
          classText.value += '<p style="margin-right: 5px; line-height: clamp(20px, 1.8vh, 1.8vh);">' + text.value + '</p>';
        }
      }
    }

    watch(summaryStatus, () => {
      setDirectionOption();
      setDwellOption();
      setTimeRange();
      changeClassText();
    })

    return {
      summaryOption,
      directionOption,
      setDwellOption,
      dwellOption,
      timeStamp,
      timeRangeOption,
      summaryStatus,
      currPage,
      menu,
      setMaskingInfo,
      locale,
      language,
      API_BASE_URL,
      faceImg,
      classHover,
      colorHover,
      classText,
      
    };
  },
});
