import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58a36c34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "masking-box" }
const _hoisted_2 = {
  key: 0,
  class: "error-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.maskingStatus === 'error')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.language["PLAYER"]["masking_error"][_ctx.locale]), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.maskingInfo !== undefined)
      ? (_openBlock(), _createBlock(_component_video_player, {
          key: 1,
          controls: "",
          ref: "maskingVideoRef",
          src: `${_ctx.API_BASE_URL}${_ctx.maskingInfo.result}`,
          class: "summary-video",
          autoplay: "true",
          muted: "muted",
          onMounted: _ctx.handleMounted,
          onSeeking: _ctx.onSeekingSummaryVideo,
          onSeeked: _ctx.onSeekedSummaryVideo,
          onPlay: _ctx.onPlaySummaryVideo
        }, null, 8, ["src", "onMounted", "onSeeking", "onSeeked", "onPlay"]))
      : _createCommentVNode("", true)
  ]))
}