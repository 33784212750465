import { defineStore } from 'pinia'

interface NavigationState {
  page: string;
}

// router
export const useNavigationStore = defineStore('navigation', {
  state: (): NavigationState => ({
    page: 'login',
  }),
  actions: {
    setCurrentPage(page: string): void {
      this.page = page;
    },
  },
  getters: {
    getCurrPage(state) {
      return state.page;
    },
  },
});