import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ObjectRequest, getRequestStatusEach, maskingRequest } from '../api';
import { useSummaryMenuStore } from './summaryMenu';
import { useSummaryStore } from './summary';
import { useDatePickerRangeStore } from './datepickerRange';

// 마스킹 관련 정보
export const useMaskingStore = defineStore('masking', {
  state: () => ({
    maskingSelectObject:ref<any>([]),
    maskingOption: {
      type:'',
      class:[],
      id:[],
      box:1,
      reverse:0,
      mosaic:10,
      timerange: null
    },
    maskingStatus : 'wait',
    maskingPercent : 0,
    maskingInfo : {
      maskingId : '',
      result : ''
    },
    doMasking : ref<any>(false),
    selectObjectInfo : ref<any>([]),
    overMinFrame : ref<any>(0),
    overMaxFrame : ref<any>(0),
    overObject: ref<any>([]),
  }),
  actions: {
    setMaskingSelectObject(value:any){
      this.maskingSelectObject = value;
    },
    pushMaskingSelectObject(value:any){
      this.maskingSelectObject.push(value[0]);
    },
    spliceMaskingSelectObject(value:any){
      this.maskingSelectObject.splice(value, 1);
    },
    setMaskingOption(condition:any){
      this.maskingOption = condition;
      console.log('set masking >> ', this.maskingOption);
    },
    checkExistence(objects:any, targets:any) {
      return targets.map((target:any) => {
          return objects.some((item:any) => item.o.includes(target));
      });
    },
    async checkObjectTime(start:any, end:any, selectObject: any){
      console.log('start >> ', start, ' / end >> ', end);
      try{
        this.overObject = [];
        const res = await ObjectRequest();
        // console.log('object request >> ', res);
        // 조건에 맞지 않는 id를 기록할 배열
        const invalidIds: any[] = [];
        let overallMinFrameNum = res[0].min_frameNum;
        let overallMaxFrameNum = res[0].max_frameNum;

        // 결과 처리
        res.forEach((result:any) => {
          if (result.min_frameNum < start || result.max_frameNum > end) {
            invalidIds.push(result.id);
          }

          if (result.min_frameNum < overallMinFrameNum) {
            overallMinFrameNum = result.min_frameNum;
          }
          if (result.max_frameNum > overallMaxFrameNum) {
            overallMaxFrameNum = result.max_frameNum;
          }
        });

        this.overMinFrame = overallMinFrameNum;
        this.overMaxFrame = overallMaxFrameNum;

        
        const isValid = invalidIds.length === 0;

        if(!isValid){
          selectObject.forEach((obj:any) => {
            invalidIds.forEach((id: any) => {
              if(obj.id == id){
                this.overObject.push(obj);
              }
            })
          })
        }

        return isValid;
      }
      catch (error) {
        console.error('Error object: ', error);
        return false;
      }
    },
    checkObjectTimeNope(timerange:any){
      // 객체 등장시간 기준으로 선택한 시간 내에 이 객체가 등장하는지 아닌지 구별하는 함수
      // 이미 등장하고 있을때 (객체 등장 도중 시간을 시작시간으로 잡았을때)를 분류할 수 없어서 사용은 안하지만 혹시 몰라 삭제는 안함
      // let res = useSummaryStore().getSummaryResult;
      // let video = useSummaryStore().getVideo;
      // if(res.result_images !== null && video !== undefined){
      //   let resObject = JSON.parse(res.result_images);
      //   console.log(resObject);

        
      //   this.maskingSelectObject.forEach((id:any) => {
      //     const foundObject = resObject.find((obj:any) => obj.i == id);
      //     if(foundObject){
      //       this.selectObjectInfo.push({id:foundObject.i, frame:Number(foundObject.f), img:foundObject.p});
      //     }
      //   });
        
      //   console.log('found >> ', this.selectObjectInfo);
      //   let start = useDatePickerRangeStore().getStartDate;
      //   let end = useDatePickerRangeStore().getEndDate;
      //   let flag = true;
        
      //   let startTime = new Date(video.beginDate);
      //   for(let i=0;i<this.selectObjectInfo.length;i++){
      //     let objTime = new Date(startTime.getTime() + (this.selectObjectInfo[i].frame/8 *1000));
    
      //     if(new Date(start) > objTime || new Date(end) < objTime){
      //       flag = false;
      //       console.log('objTime false >> ',this.selectObjectInfo[i], ' : ', objTime);
      //     }
      //     else{
      //       console.log('objTime true >> ',this.selectObjectInfo[i], ' : ', objTime);
      //     }
      //   }
        
      //   console.log('flag >> ', flag);
      //   return flag;
      // }

      // 각 프레임 별 등장하는 객체 ID 생성
      let res = useSummaryStore().getSummaryResult;
      let video = useSummaryStore().getVideo;
      if(res.result_objects!==null && video!==undefined){
        let resObject = JSON.parse(res.result_objects);
        
        let changeObject = resObject.map((item:any) => {
            let firstNumbers = item.o.map((str:any) => {
                let numbers = str.split(',')[0];
                return parseInt(numbers);
            });
            return {
                f: item.f,
                o: firstNumbers
            };
        });
        console.log('change resobject >> ', changeObject);

        let startFrame = timerange[0]*8;
        let endFrame = timerange[1]*8 + 8;

        console.log('timerange >> ', timerange);
        console.log('startFrame >> ', startFrame);
        console.log('endFrame >> ', endFrame);

        let filteredObjects = changeObject.filter((item:any) => item.f >= startFrame && item.f < endFrame);

        

        let result = this.checkExistence(filteredObjects, this.maskingSelectObject);
        console.log('result >> ', result);

      }

    //   let changeObject = [
    //     {"f":0, "o":[0, 1, 2, 3, 4, 5, 55, 61, 103, 104, 105, 280]},
    //     {"f":1, "o":[0, 1, 2, 3, 4, 5, 8, 64, 65, 103, 104, 105, 108, 287, 288, 289]},
    //     {"f":2, "o":[0, 1, 2, 3, 4, 5, 55, 61, 103, 104, 105, 280]},
    //     {"f":3, "o":[0, 1, 2, 3, 4, 5, 8, 64, 65, 103, 104, 105, 108, 287, 288, 289]},
    //     {"f":4, "o":[0, 1, 2, 3, 4, 5, 55, 61, 103, 104, 105, 280]},
    //     {"f":5, "o":[0, 1, 2, 3, 4, 5, 8, 64, 65, 103, 104, 105, 108, 287, 288, 289]},
    //     {"f":6, "o":[0, 1, 2, 3, 4, 5, 55, 61, 103, 104, 105, 280]},
    //     {"f":7, "o":[0, 1, 2, 3, 4, 5, 8, 64, 65, 103, 104, 105, 108, 287, 288, 289]},
    //     {"f":8, "o":[0, 1, 2, 3, 4, 5, 55, 61, 103, 104, 105, 280]},
    //     {"f":9, "o":[0, 1, 2, 3, 4, 5, 8, 64, 65, 103, 104, 105, 108, 287, 288, 289]},
    //     {"f":10, "o":[0, 1, 2, 3, 4, 5, 55, 61, 103, 104, 105, 280]},
    //   ];
      
    //   let selectObject = [0, 3,7, 64, 108]; // 예시로 더 많은 숫자들을 포함한 경우
      
    //   // f가 3에서 5 사이인 객체들 필터링
    //   let filteredObjects = changeObject.filter(item => item.f >= 3 && item.f <= 5);
      
    //   // selectObject에 포함된 숫자가 "o" 배열에 모두 포함되어 있는지 확인하는 함수
    //   function checkExistence(objects:any, targets:any) {
    //     return targets.map((target:any) => {
    //         return objects.some((item:any) => item.o.includes(target));
    //     });
    // }
      
    //   // 결과 출력
    //   let result = checkExistence(filteredObjects, selectObject);
    //   console.log('result >> ', result); // 예상 출력 결과: true 또는 false
    
    },
    async insertMaskingRequest(){
      this.maskingStatus = 'running';
      this.maskingPercent = 0;
      this.maskingInfo = {
        maskingId : '',
        result : ''
      };
      try{
        const res = await maskingRequest();
        this.maskingInfo.maskingId = res.serial;
        this.doMasking = true;
        this.checkMasking(res.serial);
      } catch (error){
        console.error('Error masking: ', error);
        throw error;
      }
    },
    // 영상 주소가 나올때까지만 반복
    async checkMasking(serial:string){
      if(this.doMasking){
        try{
          const res = await getRequestStatusEach(serial);
          // console.log('res masking >> ', res);
  
          if(res.status == 'INVALID') {
            this.maskingStatus = 'invalid';
          }
          if(res.status == 'FAILED'){
            this.maskingStatus = 'error';
          }
          else if(res.result === null){
            setTimeout(() => {this.checkMasking(serial) }, 1000);
          } 
          else {
            this.maskingStatus = 'showing';
            this.maskingInfo.result = res.result;
            setTimeout(() => {this.checkMaskingPercent(serial) }, 1000);
          }
          
        } catch (error){
          console.error('Error masking check: ', error);
          throw error;
        }
      }
    },
    // 영상 주소가 나온 후 진행도 확인을 위한 반복
    async checkMaskingPercent(serial:string){
      if(this.doMasking){
        try{
          const res = await getRequestStatusEach(serial);

          if(res.status !== 'COMPLETE'){
            this.maskingPercent = res.status_pct;
            setTimeout(() => {this.checkMasking(serial) }, 1000);
          } 
          else{
            this.maskingStatus = 'complete';
          }
        } catch (error){
          console.error('Error masking percent: ', error);
          throw error;
        }
      }
    },
    resetMaskingOption(){
      this.doMasking = false;
      this.maskingStatus = 'wait';
      this.maskingPercent = 0;
      this.maskingSelectObject = [];
      this.maskingOption = {
        type:'',
        class:[],
        id:[],
        box:1,
        reverse:0,
        mosaic:10,
        timerange: null
      };
      useSummaryMenuStore().showMaskingCondition(true);
    },
    resetMaskingOptionSetting(){
      this.maskingSelectObject = [];
      this.maskingOption = {
        type:'',
        class:[],
        id:[],
        box:1,
        reverse:0,
        mosaic:10,
        timerange: null
      };
    }
  },
  getters: {
    getMaskingSelectObject(state){
      return state.maskingSelectObject;
    },
    getMaskingOption(state){
      return state.maskingOption;
    },
    getMaskingStatus(state){
      return state.maskingStatus;
    },
    getMaskingPercent(state){
      return state.maskingPercent;
    },
    getMaskingInfo(state){
      return state.maskingInfo;
    },
    getTipTime(state){
      return {minFrame: state.overMinFrame, maxFrame: state.overMaxFrame};
    },
    getOverObject(state){
      return state.overObject;
    }
  },
});