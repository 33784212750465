
import { defineComponent } from 'vue';
import JoinInput from '../Login/JoinInput.vue';

export default defineComponent({
  components: {
    JoinInput
  },
  props:{
    currPage: {
      type: String,
      required: false,
    }
  },
  setup(props, {emit}) {
    const closePopup = () => {
      emit('closePopup');
    };

    return {
      closePopup,
    };
  },
});
