
import { defineComponent, ref, computed, watch } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useSummaryPageStore } from '../../store/summaryPage';
import moment from 'moment';
import VideoMemoModule from '@/components/Popup/VideoMemoModule.vue';
import PopupModule from '@/components/Popup/PopupModule.vue';
import { useLibraryVideosStore } from '../../store/libraryVideo';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';

export default defineComponent({
  components: {
    VideoMemoModule,
    PopupModule
  },
  setup() {
    const summaryStore = useSummaryStore();
    const summaryPageStore = useSummaryPageStore();
    const libraryStore = useLibraryVideosStore();
    const localeStore = useLocaleStore();
    const summaryMenuStore = useSummaryMenuStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const video = computed(() => summaryStore.getVideo);
    const currPage = computed(() => summaryPageStore.getCurrPage);
    const summaryResult = computed(() => summaryStore.getSummaryResult);
    const objects = ref();
    /* player change */
    const currVideoTime = computed(() => summaryStore.currVideoTime);
    const finishVideoTime = computed(() => summaryStore.getFinishVideoTime);
    const summaryStatus = computed(() => summaryStore.getSummaryStatus);

    const popupContents = ref({ message: '', type: '', show: false, state: '' });
    const memoPopup = ref({ contents: '', open: false });
    const target = ref<any>();

    const editMemo = () => {
      if(video.value !==undefined){
        const memo = memoPopup.value;
        target.value = video.value;
        memo.contents = video.value.memo;
        memo.open = true;
      }
    }

    const saveMemo = async (memo: any): Promise<void> => {
      const popup = popupContents.value;

      if (target.value !== undefined) {
        target.value.memo = memo;
        const res = await libraryStore.updateVideoMemo(target.value);
        if (res) {
          popup.message = language.value["LIBRARY"]["msg_save_memo"][locale.value]; // '메모가 저장되었습니다.';
          popup.type = 'success';
          popup.show = true;
        }
      }
    }

    const closeHandler = () => {
      memoPopup.value.open = false;
    }

    const successHandler = () => {
      const popup = popupContents.value;
      const memo = memoPopup.value;
      popup.show = false;
      memo.open = false;
    }

    watch(() => summaryResult.value, (newVal) => {
      if (newVal.result_images !== null) {
        objects.value = JSON.parse(newVal.result_images);
      }
    })

    const setPage = (page: string) => {
      summaryPageStore.setCurrPage(page);

      if(page == 'object') {
        summaryStore.initSelectObject();
      }
      if(page == 'origin'){
        summaryMenuStore.initOptionOpenState();
      }
    }

    return {
      video,
      setPage,
      currPage,
      objects,
      currVideoTime,
      finishVideoTime,
      summaryStatus,
      moment,
      editMemo,
      memoPopup,
      saveMemo,
      closeHandler,
      popupContents,
      successHandler,
      locale,
      language
    };
  },
});
