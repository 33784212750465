import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { getVideoFile } from '../api';
import { Item } from './comboBox';

export interface FileData {
  file: File,
  data: {
    videoId: string,
    fileName: string,
    date: Date,
  },
  info: {
    contents: Item,
    contentsInput: string,
    place: Item,
    period: Item,
    userId: string,
    name: string,
    division: string,
    officeNum: string,
    memo: string,
    saved: boolean
  },
}

interface FileDataState {
  files: Ref<FileData[]>;
}

// 업로드 완료된 파일의 정보를 저장하는 store
export const useFilesStore = defineStore('files', {
  state: (): FileDataState => ({
    files: ref<FileData[]>([]),
  }),
  actions: {
    async getFile(videoId: string) {
      try {
        await getVideoFile(videoId);
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
    setFile(file: FileData) {
      this.files.push(file);
    },
    editFileData(index: number, file: FileData) {
      this.files[index].data = file.data;
    },
    editFileInfo(index: number, file: FileData) {
      /* 영상 시간까지 동일하게 저장하려면 주석 해제. 지우지 마시오!! 
        this.files[index].data.date = file.data.date; */
      this.files[index].info = file.info;
      this.files[index].info.saved = true;
    },
    initFiles() {
      this.files = [];
    },
    removeFile(index: number) {
      this.files.splice(index, 1);
    },
  },
  getters: {
    getFiles(state): FileData[] {
      return state.files;
    },
    getFilesLength(state): number {
      return state.files.length;
    }
  }
});