
import { defineComponent, ref, computed, onBeforeUnmount, watch } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useVmsStore, SelectedCamera } from '../../store/vms';
import { useUploadPopupStateStore } from '../../store/uploadPopup';
import { useProgressStore } from '../../store/progress';
import moment from 'moment';
import lodash from 'lodash';

export default defineComponent({
  components: {
  },
  setup() {
    /* store */
    const localeStore = useLocaleStore();
    const vmsStore = useVmsStore();
    const popupStateStore = useUploadPopupStateStore();
    const progressStore = useProgressStore();

    /* data */
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const selectedCameras = computed(() => vmsStore.getSelectedCameras);
    const selectedCamera = computed(() => vmsStore.getSelectedCamera);
    const showVmsList = computed(() => vmsStore.getShowVmsListState);
    const showCheckBox = computed(() => vmsStore.getShowCheckBoxState);
    const checkedCount = computed(() => vmsStore.getCheckedCount);
    const uploading = computed(() => progressStore.getUploadState);

    /* function */
    const handleListClick = (event: MouseEvent) => {
      event.preventDefault(); // 리스트를 클릭할 때 텍스트 선택을 방지하기 위해 이벤트의 기본 동작을 막음
    };

    const handleItemClick = (camera: SelectedCamera, index: number, event: MouseEvent) => {
      if(!showVmsList.value && !showCheckBox.value) {
        vmsStore.setSelectedCamera(camera, index);
      } else if (showCheckBox.value) {
        camera.checked = !camera.checked;
        if(camera.checked) { vmsStore.setCheckedCount(checkedCount.value + 1) }
        else { vmsStore.setCheckedCount(checkedCount.value - 1) }
      }
      event.stopPropagation(); // 이벤트 전파 막기
    };

    const allSelect = () => {
      for(let i=0; i<selectedCameras.value.length; i++) {
        selectedCameras.value[i].checked = true;
      }
      vmsStore.setCheckedCount(selectedCameras.value.length);
    }

    const allUnselect = () => {
      for(let i=0; i<selectedCameras.value.length; i++) {
        selectedCameras.value[i].checked = false;
      }
      vmsStore.setCheckedCount(0);
    }

    const removeCamera = (target: SelectedCamera) => {
      if(selectedCamera.value === target) {
        vmsStore.initSelectedCamera();
      }
      vmsStore.popSelectedCamera(target.camera);
      vmsStore.toggleCamera(target.camera);
    }

    const removeAllCamera = () => {
      if(selectedCameras.value.length > 0) {
        for(let i=selectedCameras.value.length-1; i>=0; i--) {
          removeCamera(selectedCameras.value[i]);
        }
        vmsStore.setCheckBoxState(false);
        vmsStore.setCheckedCount(0);
      }
    }

    const openSettingPage = () => {
      if(selectedCameras.value.length > 0){
        vmsStore.setShowVmsListState(false);
      }
    }

    const openVmsListPage = () => {
      vmsStore.setShowVmsListState(true);
    }

    const setTimeFormat = (startTime: Date, endTime: Date) => {
      return `${moment(startTime).format('YYYY-MM-DD HH:mm')} ~ ${moment(endTime).format('YYYY-MM-DD HH:mm')}`
    }

    const downloadVmsVideo = () => {
      if(!showCheckBox.value && selectedCameras.value.length>0) {
        popupStateStore.setState("vmsUploadPopup", false);

        for(let i=0; i<selectedCameras.value.length; i++) {
          const target = selectedCameras.value[i];
          const now = new Date();
          
          if(target.split) {
            let minute = 1000 * 60; // 1분
            const divTime = 60; // 분단위, 60 = 1시간
            const diffSec =  target.info.endDate.getTime() - target.info.startDate.getTime();
            const diffMin = Math.floor((diffSec / (60 * 1000)));  // 시간

            const times = Math.ceil(diffMin / divTime);
            const remainder = diffMin % divTime;  // 남은 분
            
            if(times > 0) {
              for(let j=0; j<times; j++) {
                let time = selectedCameras.value[i].info.startDate.getTime() + (minute * divTime * j);
                let start = new Date(time)
                let end = new Date(time + (minute * divTime))

                if(j===times -1 && remainder > 0){
                  console.log('done');
                  end = new Date(time + (minute * remainder))
                }

                const videoId = 'VT_' + now.getTime() + '_' + Math.random().toString(36).slice(-4);

                let addCamera = lodash.cloneDeep(target);
                addCamera.info.startDate = start;
                addCamera.info.endDate = end;
                addCamera.camera.name = target.camera.name + ' (' + (j+1) + '/' + times +')';

                progressStore.setProgressFile({
                  file: '',
                  camera: addCamera,
                  type: "vms",
                  videoId: videoId,
                  name: addCamera.camera.name,
                  percent: 0,
                  completed: false,
                  inProgress: false,
                  res: {state: true, msg: ""},
                  cancelToken: undefined
                });
              }
            }
          }
          else {
            const videoId = 'VT_' + now.getTime() + '_' + Math.random().toString(36).slice(-4);
            progressStore.setProgressFile({
              file: '',
              camera: target,
              type: "vms",
              videoId: videoId,
              name: target.camera.name,
              percent: 0,
              completed: false,
              inProgress: false,
              res: {state: true, msg: ""},
              cancelToken: undefined
            });
          }

          if(i == selectedCameras.value.length-1) {
            if(!uploading.value) {
              progressStore.uploadVideoTotal();
            }
            popupStateStore.setState("progressPopup", true);
          }
        }
      }
    }

    const selectCancel = () => {
      vmsStore.setCheckBoxState(false);
      vmsStore.initSelectedState();
    }

    watch(() => showCheckBox.value, (value) => {
      if(!value) {
        vmsStore.setCheckedCount(0);
      }
    });

    onBeforeUnmount(() => {
      vmsStore.initSelectedCameras();
      vmsStore.setCheckBoxState(false);
    });

    return {
      locale,
      language,
      removeCamera,
      removeAllCamera,
      handleListClick,
      handleItemClick,
      selectedCameras,
      selectedCamera,
      openSettingPage,
      openVmsListPage,
      showVmsList,
      showCheckBox,
      setTimeFormat,
      downloadVmsVideo,
      checkedCount,
      selectCancel,
      allSelect,
      allUnselect
    };
  },
});
