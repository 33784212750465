
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useDatePickerRangeStore } from '../../store/datepickerRange';
import { useLocaleStore } from '@/store/locale';
import 'v-calendar/style.css';
import "@/css/style.css";

export default defineComponent({
  name: 'DatePicker',
  components: {
  },
  props:{
    reSize: {
      type: Boolean,
      required: false,
      default: () => (true) // true=반응형, false=고정
    },
    isMasking: {
      type: Boolean,
      required: false,
      default: () => (false) // true=마스킹의 시간설정, false=마스킹 외
    },
    setStartTime: {
      type: Date,
      required: false,
      default: () => (null)
    },
    setFinishTime: {
      type: Date,
      required: false,
      default: () => (null)
    }
  },
  setup(props, {emit}) {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const dpStore = useDatePickerRangeStore();
    const startDate = computed(() => dpStore.getStartDate);
    const endDate = computed(() => dpStore.getEndDate);
    const pickStart = ref(startDate.value);
    const pickEnd = ref(endDate.value);
    const isFocused = ref(false);

    const error = computed(() => dpStore.getErrorState);
    

    const popover = ref({
      visibility: 'click',
    });

    const setStartDate = () => {
      const start = new Date(pickStart.value);
      const end = new Date(pickEnd.value);

      if(!props.isMasking){
        console.log('set 0');
        start.setSeconds(0);
        start.setMilliseconds(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
      }

      // console.log('start >> ', start);
      // console.log('default start >> ', props.setStartTime);
      // console.log('diff start >> ', start <= props.setStartTime);

      if(pickStart.value == null || start >= end) {
        dpStore.setErrorstate(true);
        pickStart.value = pickEnd.value;
      } else if(props.setStartTime!==null?(start < props.setStartTime):false){
        dpStore.setErrorstate(true);
        pickStart.value = props.setStartTime;
      }
      else {
        dpStore.setErrorstate(false);
      }
      dpStore.setStartDate(pickStart.value);
    }
    
    const setEndDate = () => {
      // console.log('end >> ', pickEnd.value);
      // console.log('default end >> ', props.setFinishTime);
      // console.log('diff end >> ', pickEnd.value >= props.setFinishTime);

      if(pickEnd.value == null || pickEnd.value > new Date() || pickStart.value >= pickEnd.value) {
        dpStore.setErrorstate(true);
        pickEnd.value = pickStart.value;
      } else if( props.setFinishTime!==null?((pickEnd.value > props.setFinishTime)):false){
        dpStore.setErrorstate(true);
        pickEnd.value = props.setFinishTime;
      }
      else {
        dpStore.setErrorstate(false);
      }
      dpStore.setEndDate(pickEnd.value);
    }

    watch(startDate, (newDate) => {
      pickStart.value = newDate;
    });

    watch(endDate, (newDate) => {
      pickEnd.value = newDate;
    });

    const timeAccuracy = ref(2);
    const masks = ref();
    if(props.isMasking){
      timeAccuracy.value = 3;
      masks.value = {
        inputDateTime24hr: ['YYYY.MM.DD HH:mm:ss']
      }
    }

    return {
      locale,
      language,
      startDate,
      endDate,
      popover,
      isFocused,
      setStartDate,
      setEndDate,
      pickStart,
      pickEnd,
      error,
      timeAccuracy,
      masks,
    };
  },
});
