
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useUserStore } from '../../store/loginUser';
import { useVmsStore, Information, SelectedCamera } from '../../store/vms';
import { useDatePickerRangeStore } from '../../store/datepickerRange';
import { useLocaleStore } from '@/store/locale';
import DatePickerRange from "../../components/Common/DatePickerRange.vue";
import PopupModule from '@/components/Popup/PopupModule.vue';
import lodash from 'lodash';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';

export default defineComponent({
  components: {
    ComboboxEdit,
    DatePickerRange,
    PopupModule
  },
  setup() {
    /* store */
    const localeStore = useLocaleStore();
    const userStore = useUserStore();
    const vmsStore = useVmsStore();
    const dpStore = useDatePickerRangeStore();
    
    /* data */
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    // const currentDate = new Date();
    const popupContents = ref({ 
      message: language.value["UPLOAD"]["msg_saved"][locale.value], // 영상 정보가 저장되었습니다. 
      type: 'success', 
      show: false 
    });
    const split = computed(() => vmsStore.getSplitState); // 분할 다운로드 여부
    const userId = userStore.getUserId;
    const division = userStore.getDivision;
    const selectedCameras = computed(() => vmsStore.getSelectedCameras);
    const selectedCamera = computed(() => vmsStore.getSelectedCamera);
    const vmsList = computed(() => vmsStore.getVmsList);
    const startDate = computed(() => dpStore.getStartDate);
    const endDate = computed(() => dpStore.getEndDate);
    const checkedCount = computed(() => vmsStore.getCheckedCount);
    const inputBox = ref<HTMLInputElement | null>(null);
    const error = computed(() => dpStore.getErrorState);
    const showTooltip = ref(false);
    const combobox = ref<any>([
      {
        type: "contents",
        items: [
          { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
          { id: false, label: language.value["COMBOBOX"]["contents_option2"][locale.value] }, // 실종
          { id: false, label: language.value["COMBOBOX"]["contents_option3"][locale.value] }, // 자살의심(자해)
          { id: false, label: language.value["COMBOBOX"]["contents_option4"][locale.value] }, // 주차뺑소니(물피도주)
          { id: false, label: language.value["COMBOBOX"]["contents_option5"][locale.value] }, // 공공시설물 파손
          { id: false, label: language.value["COMBOBOX"]["contents_option6"][locale.value] }, // 절도(용의자 추적)
          { id: false, label: language.value["COMBOBOX"]["contents_option7"][locale.value] }, // 폭행(특수상해)
          { id: false, label: language.value["COMBOBOX"]["contents_option8"][locale.value] }, // 무단투기
          { id: false, label: language.value["COMBOBOX"]["contents_option9"][locale.value] }, // 교통사고(차량)
          { id: false, label: language.value["COMBOBOX"]["contents_option10"][locale.value] }, // 교통사고(대인)
          { id: false, label: language.value["COMBOBOX"]["contents_option11"][locale.value] }, // 음주운전 의심
          { id: false, label: language.value["COMBOBOX"]["contents_option12"][locale.value] }, // 사고발견(위험신고)
          { id: false, label: language.value["COMBOBOX"]["contents_option13"][locale.value] }, // 주거침입
          { id: true, label: language.value["COMBOBOX"]["contents_option14"][locale.value] }, // 기타
        ],
        selected: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
        defaultItem: { id: false, label: language.value["COMBOBOX"]["contents_option1"][locale.value] }, // 선택 안 함
      },
      {
        type: "place",
        items: [
          { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
          { id: '1', label: language.value["COMBOBOX"]["place_option2"][locale.value] },  // 실내
        ],
        selected: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
        defaultItem: { id: '2', label: language.value["COMBOBOX"]["place_option1"][locale.value] }, // 실외
      },
      {
        type: "period",
        items: [
          { id: '7', label: '7'+language.value["COMBOBOX"]["period_option1"][locale.value] }, // 7일
          { id: '30', label: '1'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 1개월
          { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
          { id: '180', label: '6'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 6개월
          { id: '36500', label: language.value["COMBOBOX"]["period_option3"][locale.value] }, // 제한 없음
        ],
        selected: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
        defaultItem: { id: '90', label: '3'+language.value["COMBOBOX"]["period_option2"][locale.value] },  // 3개월
      },
    ]);
    const contentsComboRef = ref();
    const placeComboRef = ref();
    const periodComboRef = ref();

    const initData = ref<Information>({
      startDate: vmsStore.getStartDate,
      endDate: vmsStore.getEndDate,
      contents: combobox.value[0].defaultItem,
      contentsInput: "",
      place: combobox.value[1].defaultItem,
      period: combobox.value[2].defaultItem,
      userId: userId,
      division: division,
      officeNum: "",
      memo: "",
    });

    const initInfo = (info: Information) => {
      setOpenState("");
      target.value = lodash.cloneDeep(info);
      combobox.value[0].selected = target.value.contents;
      combobox.value[1].selected = target.value.place;
      combobox.value[2].selected = target.value.period;
      dpStore.setStartDate(target.value.startDate);
      dpStore.setEndDate(target.value.endDate);
      dpStore.setErrorstate(false);
    };
    const target = ref<Information>(lodash.cloneDeep(initData.value));
    const showCheckBox = computed(() => vmsStore.getShowCheckBoxState);
    
    /* comboboxEdit */ 
    const comboContents = (item: any) => {
      combobox.value[0].selected = item;
      if(!item.id){
        target.value.contentsInput = "";
      } 
    }
    const comboPlace = (item: any) => {
      combobox.value[1].selected = item;
    }
    const comboPeriod = (item: any) => {
      combobox.value[2].selected = item;
    }
    /* comboboxEdit */ 

    /* function */
    const setOpenState = (target: string) => {
      if(target == 'contents') {
        placeComboRef.value.toggleClose();
        periodComboRef.value.toggleClose();
      }
      else if(target == 'place') {
        contentsComboRef.value.toggleClose();
        periodComboRef.value.toggleClose();
      }
      else if(target == 'period') {
        contentsComboRef.value.toggleClose();
        placeComboRef.value.toggleClose();
      }
      else {
        contentsComboRef.value.toggleClose();
        placeComboRef.value.toggleClose();
        periodComboRef.value.toggleClose();
      }
    }
    
    const openVmsListPage = () => {
      vmsStore.setShowVmsListState(true);
      vmsStore.setCheckBoxState(false);
      vmsStore.unselectCameras();
      vmsStore.initSelectedState();
    }

    const selectCCTV = () => {
      vmsStore.setCheckBoxState(true);
      vmsStore.unselectCameras();
    }

    const saveHandler = () => {
      if(checkedCount.value > 0) {
        popupContents.value.show = true;
        vmsStore.setCheckBoxState(false);

        setOpenState('');
        target.value.contents = combobox.value[0].selected;
        target.value.place = combobox.value[1].selected;
        target.value.period = combobox.value[2].selected;

        const selectedCam = selectedCameras.value;
        const newVal = lodash.cloneDeep(target.value);
        for(let i=0; i<selectedCam.length; i++) {
          if(selectedCam[i].checked) {
            selectedCam[i].info = newVal;
            selectedCam[i].checked = false;
            selectedCam[i].split = split.value;
          }
        }
      }
    }

    const checkHandler = () => {
      vmsStore.setSplitState(!split.value);
    }

    watch(() => selectedCamera.value, (value) => {
      if(value != undefined) {
        initInfo(value.info);
      } else {
        initInfo(initData.value);
      }
    });

    watch(() => startDate.value, (value) => {
      target.value.startDate = value;
    });

    watch(() => endDate.value, (value) => {
      target.value.endDate = value;
    });

    onMounted(() => {
      inputBox.value = document.querySelector('.direct-input');
      dpStore.initDate();
    });

    onBeforeUnmount(() => {
      vmsStore.initSelectedCamera();
      vmsStore.setSplitState(false)
    });

    return {
      locale,
      language,
      vmsList,
      setOpenState,
      combobox,
      saveHandler,
      target,
      popupContents,
      comboContents,
      comboPlace,
      comboPeriod,
      contentsComboRef,
      placeComboRef,
      periodComboRef,
      error,
      openVmsListPage,
      selectedCameras,
      selectedCamera,
      selectCCTV,
      showCheckBox,
      initInfo,
      initData,
      checkedCount,
      split,
      checkHandler,
      showTooltip
    };
  },
});
