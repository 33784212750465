import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useLocaleStore } from './locale';

// 라이브러리 > 활성화된 메뉴를 저장하는 store
export const useLibrarySortStore = defineStore('librarySort', {
  state: () => ({
    selectedSort: { id: 'upload', label: '등록 날짜순' },
    selectedSearch: { id: 'title', label: '제목' },
    searchText: ref<string>(''),
    filter: {
      date: undefined,
      place: undefined,
      contents: undefined,
      period: undefined,
    },
    filter2: [],
    filtering: ref<boolean>(false),
  }),
  actions: {
    setSelectedSort(item: any){
      this.selectedSort = item;
    },
    setSelectedSearch(item: any){
      this.selectedSearch = item;
    },
    setSearchText(item: string){
      this.searchText = item;
    },
    setFilter(date: any, place: any, contents: any, period: any){
      this.filter.date = date;
      this.filter.place = place;
      this.filter.contents = contents;
      this.filter.period = period;

      this.setFiltering(true);
    },
    setFiltering(item: boolean){
      this.filtering = item;
    },
    resetFilter(){
      this.filter.date = undefined;
      this.filter.place = undefined;
      this.filter.contents = undefined;
      this.filter.period = undefined;

      this.setFiltering(false);
    },
    resetAll(){
      this.resetFilter();
      this.initValue();
    },
    initValue(){
      const localeStore = useLocaleStore();
      const locale = computed(() => localeStore.getLocale);
      const language = computed(() => localeStore.getLanguage);

      this.selectedSort = { id: 'upload', label: language.value["LIBRARY"]["order_upload"][locale.value] };
      this.selectedSearch = { id: 'title', label: language.value["LIBRARY"]["title"][locale.value]  };
      this.searchText = '';
    }
  },
  getters: {
    getSelectedSort(state){
      return state.selectedSort;
    },
    getSelectedSearch(state){
      return state.selectedSearch;
    },
    getSearchText(state){
      return state.searchText;
    },
    getFilter(state){
      return state.filter;
    },
    getFiltering(state){
      return state.filtering;
    }
  },
});