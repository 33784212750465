
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useUploadPopupStateStore } from '../../store/uploadPopup';
import { useLibraryMenuStore } from '../../store/libraryMenu';
import { useLibraryVideosStore } from '../../store/libraryVideo';
import { useLibrarySortStore } from '@/store/librarySort';
import { usePlaylistStore } from '@/store/playlist';
import PlaylistPopup from '../Popup/PlaylistPopup.vue';
import PopupModule from '../Popup/PopupModule.vue';
import { useLocaleStore } from '@/store/locale';

export default defineComponent({
  components: {
    PlaylistPopup,
    PopupModule
  },
  setup(props, {emit}) {
    const summaryStore = useSummaryStore();
    const libraryMenuStore = useLibraryMenuStore();
    const libraryVideosStore = useLibraryVideosStore();
    const popupStateStore = useUploadPopupStateStore();
    const playlistStore = usePlaylistStore();
    const librarySortStore = useLibrarySortStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const currTab = computed(() => libraryMenuStore.getCurrTab);
    const videoList = computed(() => libraryVideosStore.getVideoList);
    const playlistPopupShow = ref<boolean>(false);
    const playlist = computed(() => playlistStore.getCurrPlaylist);
    const popupContents = ref({ message: '', type: 'success', show: false });
    const sharedVideoList = computed(() => libraryVideosStore.getSharedList);
    const objects = computed(() => summaryStore.getBookmark);

    const currPlaylist = ref<any>({});
    const selectPlaylist = ref<any>({});

    const moveTab = (tab: string) => {
      libraryMenuStore.setCurrTab(tab);
      if(tab === 'playlist'){
        showPlaylist(playlist.value[0]);
      }
      librarySortStore.resetAll();
      emit('resetOption');
    }

    const setPopupState = () => {
      popupStateStore.setState("uploadPopup", true);
    }

    // 플레이리스트 생성
    const createPlaylist = () => {
      currPlaylist.value = {};
      playlistPopupShow.value=true;
    }

    // 플레이리스트 수정
    const editPlaylist = (list:any) => {
      currPlaylist.value = list;
      playlistPopupShow.value=true;
    }

    // 플레이리스트 삭제
    const selectDelete = (list:any) => {
      // console.log(language.value.LIBRARY);
      currPlaylist.value = list;
      popupContents.value.message = language.value["LIBRARY"]["msg_delete_playlist"][locale.value] ;  
      // "선택한 플레이리스트를 삭제하시겠습니까?<br>삭제할 경우 플레이리스트에 있는<br>영상 리스트도 함께 삭제됩니다."
      popupContents.value.type = 'delete';
      popupContents.value.show = true;
    }

    // 팝업에서 삭제 클릭 시
    const deletePlaylist = async (): Promise<void> => {
      const popup = popupContents.value;
      
      const res = await playlistStore.deletePlaylist(currPlaylist.value.name);
      if(res) {
        popup.message = language.value["LIBRARY"]["msg_delete_complete"][locale.value]; // '플레이리스트 삭제가 완료되었습니다.'
        popup.type = 'success';
        popup.show = true;
      }
      else{
        popup.message = language.value["LIBRARY"]["msg_delete_fail"][locale.value]; // '플레이리스트 삭제에 실패하였습니다.'
        popup.type = 'error';
        popup.show = true;
      }
    }

    // 삭제 완료 시 플레이리스트 갱신
    const deleteCheck = () => {
      popupContents.value.show = false;
      playlistStore.getPlaylist();
    }

    // 플레이리스트 선택
    const showPlaylist = (list: any) => {
      selectPlaylist.value = list;
      playlistStore.setSelectPlaylist(list);
    }

    watch(() => playlist.value, () => {
      if(playlist.value.length !== 0){
        showPlaylist(playlist.value[0]);
      }
    });

    onMounted(() => {
      playlistStore.getPlaylist();
      libraryVideosStore.getSharedVideoList();
      summaryStore.getObjBookmarkList();
    })

    return {
      locale,
      language,
      currTab,
      videoList,
      moveTab,
      setPopupState,
      playlistPopupShow,
      playlist,
      currPlaylist,
      createPlaylist,
      editPlaylist,
      selectDelete,
      deletePlaylist,
      popupContents,
      deleteCheck,
      showPlaylist,
      selectPlaylist,
      sharedVideoList,
      objects
    };
  },
});
