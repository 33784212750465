import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/library/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-3c293209"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-background" }
const _hoisted_2 = { class: "popup-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JoinInput = _resolveComponent("JoinInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "close-btn",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closePopup()))
      }),
      _createVNode(_component_JoinInput, {
        currPage: _ctx.$props.currPage,
        userInfo: _ctx.$props.userInfo,
        currEditType: _ctx.$props.currEditType,
        onClosePopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closePopup()))
      }, null, 8, ["currPage", "userInfo", "currEditType"])
    ])
  ]))
}