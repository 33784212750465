
import { defineComponent, computed } from 'vue';
import { useLocaleStore } from '@/store/locale';
import { useSummaryMenuStore } from '@/store/summaryMenu';
import { useConditionStore } from '@/store/condition';

export default defineComponent({
  components:{

  },
  setup(){
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const timeStampCondition = computed(() => conditionStore.getTimeStamp);

    const setTimeStamp = (value: number) => {
      conditionStore.setTimeStamp(value);
      summaryMenuStore.setOptionOpenState('timestamp', false);
    };
    const setOptionOpenState = (target: string, state: boolean) => {
      summaryMenuStore.setOptionOpenState(target, state);
    };

    return {
      locale,
      language,
      setOptionOpenState,
      timeStampCondition,
      setTimeStamp
    }
  }
  
})
