
import { defineComponent, ref, computed, onMounted, watch } from 'vue';

export default defineComponent({
  name: 'ComboBox',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [{ id: '', label: '' }]
    },
    selected: {
      type: Object,
      required: false,
      default: () => ({ id: '', label: '' })
    },
    // 박스 높이가 40px이 아닌 경우, 백그라운드 색상이 다를 경우에 넣어줘야함
    boxStyle:{
      type: Object,
      required: false,
      default: () => ({height:'3.7', background:'#393A3F', border:'#90959E'})
    },
    boxOpen: {
      type: Boolean,
      required: false,
    },
    currCombo: {
      type: String,
      required: false,
    },
    reSize: {
      type: Boolean,
      required: false,
      default: () => (true) // true=반응형, false=고정
    }
  },
  setup(props, {emit}) { // setup 함수의 매개변수로 props를 명시적으로 받아옴
    const isDropdownOpen = ref(false); // 드롭다운 메뉴 열림 여부 상태 변수
    const chageStyle = ref({});
    const changeHeight = ref({});
    const changeTextHeight = ref({});
    const changeTop = ref({});

    // 선택된 항목 표시를 위한 게터
    const selectedItem = ref(props.selected);

    // 항목 선택 메서드
    const selectItem = (item: any) => {
      emit('selectedItem', item);
      isDropdownOpen.value = false;
    };

    // 드롭다운 메뉴 열기/닫기 메서드
    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const toggleClose = () => {
      isDropdownOpen.value = false;
    }

    // 접혀있을때
    const minBoxHeight = () => {
      // 반응형
      if(props.reSize){
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // vh 값
          let minVh = 7.2;  // 1280*720일때 1vh의 값
          return 'clamp('+ (inputHeight * minVh) + 'px, ' + inputHeight + 'vh, ' + inputHeight + 'vh)';
        }else{
          return "clamp(26px, 3.7vh, 3.7vh)";
        }
      }
      // 고정
      else{
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // px 값
          return inputHeight+'px';
        }else{
          return "40px";
        }
      }
      
    }

    // 펼쳤을때
    const openBoxHeight = () => {
      let count = props.items.length + 1; // 아이템 개수
      // 반응형
      if(props.reSize){
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // vh 값
          let minVh = 7.2;  // 1280*720일때 1vh의 값
          return 'clamp('+ (inputHeight * minVh)*count + 'px, ' + inputHeight*count + 'vh, ' + inputHeight*count + 'vh)';
        }else{
          return "clamp("+26*count+"px, "+3.7*count+"vh, "+3.7*count+"vh)";
        }
      }
      // 고정
      else{
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // px 값
          return inputHeight*count + 'px';
        }else{
          return 40*count+"px";
        }
      }
      
    }
    // 펼쳐졌을때 박스의 최대 높이
    const openMaxHeight = () => {
      let count = 6; // 아이템 최대 개수
      // 반응형
      if(props.reSize){
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // vh 값
          let minVh = 7.2;  // 1280*720일때 1vh의 값
          return 'clamp('+ (inputHeight * minVh)*count + 'px, ' + inputHeight*count + 'vh, ' + inputHeight*count + 'vh)';
        }else{
          return "clamp("+26*count+"px, "+3.7*count+"vh, "+3.7*count+"vh)";
        }
      }
      // 고정
      else{
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // px 값
          return inputHeight*count + 'px';
        }else{
          return 40*count+"px";
        }
      }
      
    }
    // 펼쳐졌을때 스크롤 영역의 최대 높이
    const openMaxScrollHeight = () => {
      let count = 5; // 아이템 개수
      // 반응형
      if(props.reSize){
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // vh 값
          let minVh = 7.2;  // 1280*720일때 1vh의 값
          return 'clamp('+ (inputHeight * minVh)*count + 'px, ' + inputHeight*count + 'vh, ' + inputHeight*count + 'vh)';
        }else{
          return "clamp("+26*count+"px, "+3.7*count+"vh, "+3.7*count+"vh)";
        }
      }
      // 고정
      else{
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height;  // px 값
          return inputHeight*count + 'px';
        }else{
          return 3.7*count+"px";
        }
      }
      
    }

    // 글자 높이
    const textHeight = () => {
      // 반응형
      if(props.reSize){
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height - 0.45;  // vh 값
          let minVh = 7.2;  // 1280*720일때 1vh의 값
          return 'clamp('+ (inputHeight * minVh) + 'px, ' + inputHeight + 'vh, ' + inputHeight + 'vh)';
        }else{
          return "clamp(23px, 3.25vh, 3.25vh)";
        }
      }
      // 고정
      else{
        if(props.boxStyle !== undefined){
          let inputHeight = props.boxStyle.height - 5;  // px 값
          return inputHeight + 'px';
        }else{
          return "35px";
        }
      }
      
    }

    const comboBoxHeight = computed(() => ({ 
      height: isDropdownOpen.value ? openBoxHeight() : minBoxHeight(),
      'max-height':openMaxHeight()
    }));
    const comboBoxHeightChange = computed(() => ({
      'max-height':openMaxHeight(), 
      'border-color':props.boxStyle.border,
      'background':props.boxStyle.background,
      height: isDropdownOpen.value ? openBoxHeight() : minBoxHeight()
    }));

    onMounted(() => {
      selectedItem.value = props.selected;
      if(props.boxStyle !== undefined){
        chageStyle.value = {
          background: props.boxStyle.background
        };
        changeHeight.value = {
          height: minBoxHeight()
        };
        changeTextHeight.value = {
          height: textHeight()
        };
        changeTop.value = {
          top: minBoxHeight(),
          'max-height':openMaxScrollHeight()
        }
      }
    });

    watch(() => props.selected, (newVal) => {
      selectedItem.value = newVal;
    });

    watch(() => props.boxOpen, (newVal) => {
      isDropdownOpen.value = newVal;
    })

    return {
      isDropdownOpen,
      selectItem,
      toggleDropdown,
      comboBoxHeight,
      comboBoxHeightChange,
      chageStyle,
      changeHeight,
      changeTextHeight,
      changeTop,
      toggleClose
    };
  },
});
