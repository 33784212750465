
import { defineComponent, ref, computed} from 'vue';
import { useSummaryStore } from '../../store/summary';
import { useSummaryMenuStore } from '../../store/summaryMenu';
import { API_BASE_URL } from '@/api';
import { useLocaleStore } from '@/store/locale';
import { useConditionStore } from'@/store/condition';
import { first } from 'lodash';

export default defineComponent({
  components: {

  },
  setup(){
    const summaryStore = useSummaryStore();
    const summaryMenuStore = useSummaryMenuStore();
    const conditionStore = useConditionStore();
    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const video = computed(() => summaryStore.getVideo);
    const dwellCondition = computed(() => conditionStore.getDwell);
    const dwellData = computed(() => conditionStore.getDwellData);
    const count = (dwellCondition.value.selectType === 'one')?1:2;
    const activeCheckbox = ref(false);

    activeCheckbox.value = conditionStore.getDwellVideo==0?false:true;
    
    const firstPoints = ref<any>([]);
    const secondPoints = ref<any>([]);
    const firstPolygon = ref({
      points: ref<number[]>([]),
      stroke: 'white',
      strokeWidth: 1,
      closed:false,
      fill: 'rgba(0,0,0,0.3)'
    });
    const secondPolygon = ref({
      points: ref<number[]>([]),
      stroke: 'white',
      strokeWidth: 1,
      closed:false,
      fill: 'rgba(0,0,0,0.3)'
    });
    const inputCount = ref(0);
    const showError = ref(false);

    console.log('inputCount mounted >> ', inputCount.value);

    if(dwellData.value.firstPolygon.length!==0){
      console.log('dwell data >> ', dwellData.value);
      firstPolygon.value.points = dwellData.value.firstPolygon;
      firstPoints.value = dwellData.value.firstPoint;
      firstPolygon.value.closed = true;
      secondPolygon.value.points = dwellData.value.secondPolygon;
      secondPoints.value = dwellData.value.secondPoint;
      secondPolygon.value.closed = true;
      
      inputCount.value = count;
    }

    // 관심영역 지정 함수 
    const canvasClick = (mouse: any) => {
      showError.value = false;
      if(count !== inputCount.value){
        if(inputCount.value === 0){
          let currentPoly = firstPolygon.value;

          if(firstPoints.value.length === 3){
            currentPoly.closed = true;
            inputCount.value = 1;
          }

          currentPoly.points.push(mouse.evt.layerX);
          currentPoly.points.push(mouse.evt.layerY);
          firstPolygon.value = JSON.parse(JSON.stringify(currentPoly));
          firstPoints.value.push([mouse.evt.layerX, mouse.evt.layerY]);
          
        }
        else{
          let currentPoly = secondPolygon.value;

          if(secondPoints.value.length === 3){
            currentPoly.closed = true;
            inputCount.value = 2;
          }

          currentPoly.points.push(mouse.evt.layerX);
          currentPoly.points.push(mouse.evt.layerY);
          secondPolygon.value = JSON.parse(JSON.stringify(currentPoly));
          secondPoints.value.push([mouse.evt.layerX, mouse.evt.layerY]);
        }
      }
    };

    const checkHandler = () => {
      activeCheckbox.value = !activeCheckbox.value;
    }

    // 선택한 관심영역 초기화
    const resetDwell = () => {
      firstPoints.value = [];
      firstPolygon.value.points = [];
      firstPolygon.value.closed = false;
      secondPoints.value = [];
      secondPolygon.value.points = [];
      secondPolygon.value.closed = false;
      inputCount.value = 0;
      showError.value = false;
    }

    // 선택한 관심영역 store에 저장 
    const setDwellOption = () => {
      // 입력이 없을 경우 dwell 초기화, 확대영상 설정값이 같을 경우
      // console.log('activeCheckbox >> ', activeCheckbox.value)
      // console.log('inputCount >> ', inputCount.value)
      // console.log('conditionStore.getDwellVideo >> ', conditionStore.getDwellVideo)
      if(inputCount.value === 0 ){
        console.log('dwell reset');
        conditionStore.resetDwell();
        summaryMenuStore.setDwellPopup();
        conditionStore.setIsDwellVideo(false);
      }
      // 입력과 필요값이 같을 경우 dwell 설정
      else if(inputCount.value === count) {
        console.log('dwell set >> ', firstPoints.value);
        let firstValue = ref<any>([]);
        let secondValue = ref<any>([]);
        let width = 520;
        let height = 320;
        
        for(let i=0;i<firstPoints.value.length;i++){
          firstValue.value.push([firstPoints.value[i][0] / width, firstPoints.value[i][1] / height]);
        }
        if(dwellCondition.value.selectType !== 'one'){
          for(let i=0;i<secondPoints.value.length;i++){
            secondValue.value.push([secondPoints.value[i][0] / width, secondPoints.value[i][1] / height]);
          }
        }

        let inputType = dwellCondition.value.selectType;
        conditionStore.setDwell(
          {
            firstValue: firstValue.value, 
            secondValue: secondValue.value, 
            inputType:inputType
          },
          { 
            firstPolygon:firstPolygon.value.points, 
            secondPolygon:secondPolygon.value.points,
            firstPoint: firstPoints.value,
            secondPoint: secondPoints.value
          });
        conditionStore.setIsDwellVideo(activeCheckbox.value);
        summaryMenuStore.setDwellPopup();
      }
      // 입력값이 부족할 경우 에러 메세지
      else{
        if(count === 2 ){
          showError.value = true;
        }
      }
    }

    return {
      video,
      canvasClick,
      firstPolygon,
      secondPolygon,
      inputCount,
      firstPoints,
      secondPoints,
      resetDwell,
      setDwellOption,
      API_BASE_URL,
      count,
      locale,
      language,
      showError,
      activeCheckbox,
      checkHandler
    };
  }
})

