
import { onMounted, defineComponent, ref, computed } from 'vue';
import { useVmsAddStore } from '../../store/vmsAdd';
import { useLocaleStore } from '@/store/locale';
import ComboboxEdit from '../combobox/ComboboxEdit.vue';
import PopupModule from '../Popup/PopupModule.vue';
import { API_BASE_URL, vmsConnectCheck } from '@/api'
import axios from 'axios'
import { useUploadOptionsStore } from '@/store/uploadOption'

export default defineComponent({
  components: {
    ComboboxEdit,
    PopupModule
},
  setup(props, {emit}) {
    const localeStore = useLocaleStore();
    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);
    const vmsAddStore = useVmsAddStore();
    const vms = computed(() => vmsAddStore.getVmsAddInfo);
    const optionStore = useUploadOptionsStore();
    const installerPath = computed(() => optionStore.getInstallerPath);

    const searchArray = ref<any>([
      { id: 'VURIX', label: 'VURIX' },
      { id: 'DANUSYS', label: 'DANUSYS' },
      { id: 'MILESTONE', label: 'MILESTONE' },
      { id: 'EMSTONE', label: 'EMSTONE' },
      { id: 'LEOPARD', label: 'LEOPARD' },
      { id: 'HANWHA', label: 'HANWHA' },
    ]);
    const selectedSearch = ref<any>(searchArray.value[0]);
    const popupContents = ref({ message: '', type: 'success', show: false });
    const loadingCheck = ref(true);

    const inputData = ref({
      type: 'VURIX',
      ip: '',
      port: '',
      id: '',
      password: '',
    })

    const apiData = ref({
      ip: '',
      port: '',
    })

    // 콤보박스 선택시
    const selectedItem = (item: any) => {
      selectedSearch.value = item;
      inputData.value.type = item.id;
      // if(item.id !== 'LEOPARD'){
      //   apiData.value.ip = '';
      //   apiData.value.port = '';
      // }
    }

    const vmsAdd = async (): Promise<void> => {

      if(inputData.value.id == '' || inputData.value.ip == '' || inputData.value.password == '' || inputData.value.port == ''){
        popupContents.value.type = 'error';
        popupContents.value.message = language.value["ADMIN"]["msg_alert_info"][locale.value]; // VMS 정보를 전부 입력해주세요.
        popupContents.value.show = true;
        return;
      }

      loadingCheck.value = true;
      vmsAddStore.setInsertVmsInfo(inputData.value);
      popupContents.value.type = 'loading';
      popupContents.value.message = language.value["ADMIN"]["msg_try"][locale.value]; // VMS 접속 시도 중입니다.
      popupContents.value.show = true;

      if(inputData.value.type === 'LEOPARD'){
        const checkConnect = await vmsConnectCheck(inputData.value, 'add');
        if(checkConnect){
          const res = await vmsAddStore.vmsAccess();
          if(loadingCheck.value){
            if(res){
              inputVms();
            }
            else{
              popupContents.value.type = 'error';
              popupContents.value.message = language.value["ADMIN"]["msg_fail"][locale.value]; // VMS 접속에 실패하였습니다.<br>입력한 내용을 확인해주세요.
              popupContents.value.show = true;
            }
          }
        }
        else{
          popupContents.value.type = 'download';
          popupContents.value.message = language.value["UPLOAD"]["msg_err_leopard2"][locale.value]; //"API 서버 접속에 실패하였습니다.<br>서버 상태를 확인하거나,<br>프로그램을 다운로드하여 설치해주세요."
          popupContents.value.show = true;
        }
      }
      else {
        const res = await vmsAddStore.vmsAccess();
        if(loadingCheck.value){
          if(res){
            inputVms();
          }
          else{
            popupContents.value.type = 'error';
            popupContents.value.message = language.value["ADMIN"]["msg_fail"][locale.value]; // VMS 접속에 실패하였습니다.<br>입력한 내용을 확인해주세요.
            popupContents.value.show = true;
          }
        }
      }
      
    }


    const downloadClick = () => {
      // LeopardAPIServer.msi
      // let url = API_BASE_URL+'/download/API_server_setup.zip';
      let url = API_BASE_URL+installerPath.value;

      axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'API_server_setup.zip';
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error)
        popupContents.value.show = false;
    }

    const accessClick = () => {
      window.open('Apiserver://');
      popupContents.value.show = false;
    }

    const inputVms = async (): Promise<void> => {
      const inputRes = await vmsAddStore.insertVmsInfo();

      if(inputRes){
        popupContents.value.type = 'success';
        popupContents.value.message = language.value["ADMIN"]["msg_add_vms"][locale.value]; // VMS가 추가되었습니다.
        popupContents.value.show = true;
      }
      else{
        popupContents.value.type = 'error';
        popupContents.value.message = language.value["ADMIN"]["msg_fail_vms"][locale.value]; // VMS 추가에 실패하였습니다.
        popupContents.value.show = true;
      }
    }

    const successCheck = () => {
      popupContents.value.show = false;
      emit('successCheck');
      emit('closePopup');
    }

    const closePopup = () => {
      emit('closePopup');
    }

    onMounted(() => {
      //
    });

    return {
      locale,
      language,
      vms,
      vmsAdd,
      searchArray,
      selectedItem,
      selectedSearch,
      inputData,
      closePopup,
      popupContents,
      successCheck,
      loadingCheck,
      apiData,
      downloadClick,
      accessClick
    };
  },
});
