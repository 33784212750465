import { defineStore } from 'pinia';
import { updateVideoInfo } from '../api';
import moment from 'moment';

// 영상 정보 편집 store
export const useEditVideoStore = defineStore('editVideo', {
  state: () => ({
    video: {
      videoId: "",
      name: "",
      beginDt: "",
      endDt: "",
      contents: "",
      place: "",
      period: "",
      userName: "",
      division: "",
      officeNum: "",
      memo: "",
      id: "",
      deviceId: "",
      uploadAt: new Date(),
    },
  }),
  actions: {
    setData(data:any) {
      // console.log('done', data);
      this.video = data;
      this.video.period = this.setFormat(this.video.period);
      this.video.beginDt = this.setFormat(this.video.beginDt);
      if(this.video.endDt !== null){
        this.video.endDt = this.setFormat(this.video.endDt);
      }
    },
    setFormat(date: any) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    async updateVideoInfo(info:any){
      try{
        await updateVideoInfo(info);
        // const res = await updateVideoInfo(info);
        return true;
      } catch (error) {
        console.error('Error fetching update info:', error);
        return false;
      }
    }
  },
  getters: {
    getData(state){
      return state.video;
    },
  },
});