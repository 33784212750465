
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useUserStore } from '@/store/loginUser';
import { useSummaryStore } from '../../store/summary';
import moment from "moment";
import PageNumber from '@/components/Common/PageNumber.vue';
import { API_BASE_URL } from '@/api';
import { usePageStore } from '../../store/pageNumber';
import { useLocaleStore } from '@/store/locale';
import { useSummaryPageStore } from '../../store/summaryPage';

export default defineComponent({
  components: {
    PageNumber,
  },
  setup() {
    const summaryStore = useSummaryStore();
    const summaryResult = computed(() => summaryStore.getSummaryResult);
    const objects = ref();
    const loginId = useUserStore().getUserId;
    const videos = computed(() => summaryStore.getVideo);
    const bookmark = computed(() => summaryStore.getBookmark);

    const localeStore = useLocaleStore();

    const locale = computed(() => localeStore.getLocale);
    const language = computed(() => localeStore.getLanguage);

    const objDate = ref(new Date());
    const pageStore = usePageStore();
    const currNumber = computed(() => pageStore.getCurrNumber);
    const pageList = ref<any>([]);
    const pageCount = ref(50);  // 한 페이지에 보여질 객체 수
    const pageCountList = ref([50,100,200]);
    const showDrop = ref(false);

    const setObject = () => {
      if(summaryResult.value.result_images !== null) {
        objects.value = JSON.parse(summaryResult.value.result_images);
      }
    };

    // 객체 페이징
    const getCurrPage = () => {
      const beginDate = ref();
      if(videos.value !== undefined){
        beginDate.value = new Date(videos.value.beginDate);
      }
      if(objects.value == undefined) {
        return
      }
      pageList.value = [];
      for(let i=(currNumber.value -1)*pageCount.value; i<Math.min((currNumber.value)*pageCount.value, objects.value.length);i++){
        let log = objects.value[i];
        let objTime = new Date(beginDate.value.getTime() + (log.f / 8 * 1000));
        let inputData = {
          thumbnail: API_BASE_URL + log.p, 
          realPath: log.p,
          date: moment(objTime).format('hh:mm A'),
          frame: log.f, 
          isBookmark: 0,
          id: log.i, 
          textTime : moment(objTime).format('YYYY-MM-DD hh:mm A'), 
          realTime : objTime,
          memo: ''
        };

        for(let j=0;j<bookmark.value.length;j++){
          if(bookmark.value[j]['id'] === Number(inputData.id)){
            inputData.isBookmark = 1;
            break;
          }
        }
        
        pageList.value.push(inputData);
      }
    };

    // 페이지당 객체수 변경
    const setPageViewCount = (count:number) => {
      pageCount.value = count;
      pageStore.setCurrNumber(1);
      pageStore.setTotalPage(objects.value.length, pageCount.value);
      showDrop.value = !showDrop.value;
      getCurrPage();
    };

    const selectedIndex = ref();
    // 객체 선택
    const selectObject = (object: any, index: number) => {
      selectedIndex.value = index;
      summaryStore.setSelectObject(object);
    };

    watch(() => summaryResult.value, (newVal) => {
      if(newVal.result_images !== null) {
        setObject();
        summaryStore.getBookmarkList();
      }
    });

    watch(() => objects.value, () => {
      pageStore.setTotalPage(objects.value.length, pageCount.value);
      getCurrPage();
    });

    watch(() => currNumber.value, () => {
      selectedIndex.value = '';
      summaryStore.resetSelectObject();
      getCurrPage();
    })

    watch(() => bookmark.value, () => {
      getCurrPage();
    })

    watch(() => useSummaryPageStore().getCurrPage, () => {
      selectedIndex.value = '';
    })

    onMounted(() => {
      pageStore.setCurrNumber(1);
    });

    return {
      loginId,
      summaryResult,
      objects,
      videos,
      objDate,
      moment,
      API_BASE_URL,
      getCurrPage,
      pageList,
      setPageViewCount,
      pageCount,
      selectObject,
      pageCountList,
      showDrop,
      locale,
      language,
      selectedIndex
    };
  },
});
