import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { getLogs } from '../api';

export interface Log {
  type: string,
  id: string,
  date: Date,
  info: string
}

interface Logs {
  logs: Ref<Log[]>;
}

// 업로드 완료된 파일의 정보를 저장하는 store
export const useLogsStore = defineStore('logs', {
  state: (): Logs => ({
    logs: ref<Log[]>([]),
  }),
  actions: {
    async getLogs() {
      try {
        const res = await getLogs();
        // console.log('logs >> ', res);
        this.logs = res;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    },
  },
  getters: {
    getData(state): Log[] {
      return state.logs;
    },
  },
});